import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dbb6bf1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = { class: "txt" }
const _hoisted_3 = { class: "btn-container" }
const _hoisted_4 = { class: "table-header" }
const _hoisted_5 = { class: "table-cell" }
const _hoisted_6 = { class: "tooltip-txt" }
const _hoisted_7 = { class: "tooltip-txt" }
const _hoisted_8 = { class: "tooltip-txt" }
const _hoisted_9 = { class: "tooltip-txt" }
const _hoisted_10 = { class: "tooltip-txt" }
const _hoisted_11 = { class: "tooltip-txt" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 3,
  class: "img-container"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { style: {"padding":"8px"} }
const _hoisted_16 = { class: "pagination" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = { class: "label-form" }
const _hoisted_19 = { class: "label-form" }
const _hoisted_20 = { class: "label-form" }
const _hoisted_21 = { class: "label-form" }
const _hoisted_22 = { class: "label-form" }
const _hoisted_23 = { class: "label-form" }
const _hoisted_24 = { class: "label-form" }
const _hoisted_25 = { class: "ant-upload-drag-icon" }
const _hoisted_26 = { class: "ant-upload-text" }
const _hoisted_27 = { class: "ant-upload-hint" }
const _hoisted_28 = { class: "info-txt" }
const _hoisted_29 = { class: "info-title" }
const _hoisted_30 = { class: "info-txt" }
const _hoisted_31 = { class: "info-title" }
const _hoisted_32 = { class: "info-txt" }
const _hoisted_33 = { class: "info-title" }
const _hoisted_34 = { class: "info-txt" }
const _hoisted_35 = { class: "info-title" }
const _hoisted_36 = { class: "info-txt" }
const _hoisted_37 = { class: "info-txt" }
const _hoisted_38 = { class: "info-txt" }
const _hoisted_39 = { class: "info-title" }
const _hoisted_40 = { class: "info-txt" }
const _hoisted_41 = { class: "info-title" }
const _hoisted_42 = { class: "info-txt" }
const _hoisted_43 = { class: "label-form" }
const _hoisted_44 = { class: "label-form" }
const _hoisted_45 = { class: "label-form" }
const _hoisted_46 = { class: "label-form" }
const _hoisted_47 = { class: "label-form" }
const _hoisted_48 = { class: "label-form" }
const _hoisted_49 = { class: "ant-upload-drag-icon" }
const _hoisted_50 = { class: "ant-upload-text" }
const _hoisted_51 = { class: "ant-upload-hint" }
const _hoisted_52 = { key: 0 }
const _hoisted_53 = { class: "photo-title" }
const _hoisted_54 = { class: "btns-container" }
const _hoisted_55 = { class: "modal-title" }
const _hoisted_56 = { class: "btns-container" }
const _hoisted_57 = { class: "modal-title" }
const _hoisted_58 = { class: "label-form" }
const _hoisted_59 = { class: "label-form" }
const _hoisted_60 = { class: "label-form" }
const _hoisted_61 = { class: "btns-container" }
const _hoisted_62 = { class: "modal-title" }
const _hoisted_63 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_KeyOutlined = _resolveComponent("KeyOutlined")!
  const _component_LockOutlined = _resolveComponent("LockOutlined")!
  const _component_SettingOutlined = _resolveComponent("SettingOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_PaperClipOutlined = _resolveComponent("PaperClipOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Loader, {
      "is-loaded": !_ctx.loading
    }, null, 8, ["is-loaded"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Meet.Users')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      class: "table-container",
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Meet.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      headerCell: _withCtx(({ column }) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(column.title), 1)
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        _createElementVNode("div", _hoisted_5, [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Meet.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn tbl-btn",
                        onClick: () => _ctx.showDisplayDrawer(record.id)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Meet.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "tbl-btn",
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record.id)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Meet.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "tbl-btn",
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record.id))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Meet.Permissions')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "wd-btn tbl-btn",
                        onClick: ($event: any) => (_ctx.showPermissions(record.id))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_KeyOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Meet.Password')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "pwd-btn tbl-btn",
                        onClick: ($event: any) => (_ctx.openUpdatePass(record.id))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_LockOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Meet.Role')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "role-btn tbl-btn",
                        onClick: ($event: any) => (_ctx.openUpdateRole(record.id))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_SettingOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key=='name')
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(record.first_name) + " " + _toDisplayString(record.last_name), 1))
            : _createCommentVNode("", true),
          (column.key=='role')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (record.role==='ADMIN')
                  ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.ADMIN')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (record.role==='EMPLOYEE')
                  ? (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.EMPLOYEE')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (record.role==='MANAGER')
                  ? (_openBlock(), _createBlock(_component_a_tag, { key: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.MANAGER')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.key === 'img')
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                (record.photo!==null)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "img-table",
                      src: record.photo,
                      height: "100%",
                      width: "100%"
                    }, null, 8, _hoisted_14))
                  : _createCommentVNode("", true),
                (record.photo===null)
                  ? (_openBlock(), _createBlock(_component_a_avatar, { key: 1 }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_UserOutlined)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
        _createElementVNode("div", _hoisted_15, [
          (column.key === 'name')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Search')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Reset')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_a_pagination, {
        current: _ctx.currentPage,
        "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
        pageSize: _ctx.perPage,
        "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
        total: _ctx.state.totalPages,
        "default-page-size": _ctx.perPage,
        showSizeChanger: false,
        onChange: _ctx.handlePageChange,
        class: "paginationArrows"
      }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
      (_ctx.state.totalPages>10)
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.selectPagination,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
            onChange: _ctx.handleSelectPaginationChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: "10" }, {
                default: _withCtx(() => [
                  _createTextVNode("10 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "20" }, {
                default: _withCtx(() => [
                  _createTextVNode("20 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "50" }, {
                default: _withCtx(() => [
                  _createTextVNode("50 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "100" }, {
                default: _withCtx(() => [
                  _createTextVNode("100 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Meet.Adduser')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "first_name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Meet.FisrtName')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.first_name,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.first_name) = $event)),
                          placeholder: _ctx.t('Meet.FisrtName'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "last_name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Meet.LastName')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.last_name,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addForm.last_name) = $event)),
                          placeholder: _ctx.t('Meet.LastName'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "email" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Meet.Email')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.email,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addForm.email) = $event)),
                          placeholder: _ctx.t('Meet.Email'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "phone" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Meet.Phone')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.phone,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addForm.phone) = $event)),
                          placeholder: _ctx.t('Meet.Phone'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      "has-feedback": "",
                      name: "pass"
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Meet.Password')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_password, {
                          value: _ctx.addForm.pass,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addForm.pass) = $event)),
                          placeholder: _ctx.t('Meet.Password'),
                          type: "password",
                          autocomplete: "off"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      "has-feedback": "",
                      name: "checkPass"
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Meet.Confirmpassword')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_password, {
                          value: _ctx.addForm.checkPass,
                          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addForm.checkPass) = $event)),
                          placeholder: _ctx.t('Meet.Confirmpassword'),
                          type: "password",
                          autocomplete: "off"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "role_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Meet.Role')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.addForm.role_id,
                          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addForm.role_id) = $event)),
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.roles, (role) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                label: role.name,
                                key: role.id,
                                value: role.id,
                                selected: role.id === _ctx.addForm.role_id 
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(role.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["label", "value", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "photo" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Meet.Photo')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_upload_dragger, {
                          fileList: _ctx.fileList,
                          "onUpdate:fileList": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.fileList) = $event)),
                          name: "file",
                          multiple: false,
                          "max-count": 1,
                          onChange: _ctx.handleChange,
                          onDrop: _ctx.handleDrop,
                          "before-upload": _ctx.beforeUpload
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_25, [
                              _createVNode(_component_InboxOutlined)
                            ]),
                            _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.t('Meet.dropTitle1')), 1),
                            _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.t('Meet.dropTitle2')), 1)
                          ]),
                          _: 1
                        }, 8, ["fileList", "onChange", "onDrop", "before-upload"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
        }, _toDisplayString(_ctx.t('Meet.userInformation')), 3)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              flex: "90px",
              class: "photo-container"
            }, {
              default: _withCtx(() => [
                (_ctx.form.photo!==null)
                  ? (_openBlock(), _createBlock(_component_a_image, {
                      key: 0,
                      width: "100%",
                      height: "100%",
                      src: _ctx.form.photo,
                      class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
                    }, null, 8, ["src", "class"]))
                  : _createCommentVNode("", true),
                (_ctx.form.photo===null)
                  ? (_openBlock(), _createBlock(_component_a_avatar, { key: 1 }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_UserOutlined)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { flex: "auto" }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.form.first_name) + " " + _toDisplayString(_ctx.form.last_name), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t('Meet.Email')) + " :", 1),
                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.form.email), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('Meet.Phone')) + " :", 1),
                  _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.form.phone), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('Meet.Ad_intergation')) + " :", 1),
                  _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.form.ad_integration), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('Meet.Role')) + " :", 1),
                  (_ctx.form.role==='ADMIN')
                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.t('Meet.ADMIN')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.form.role==='EMPLOYEE')
                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.t('Meet.EMPLOYEE')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.form.role==='MANAGER')
                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 2 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.t('Meet.MANAGER')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('Meet.MemberSince')) + " :", 1),
                  _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.formatDateDrawer(_ctx.form.created_at)), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.t('Meet.Permissions')) + " :", 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          key: "permissions",
          class: _normalizeClass(["permission-container", { 'is-rtl': _ctx.textDirection==='rtl'}])
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions_array, (item) => {
              return (_openBlock(), _createBlock(_component_a_tag, {
                key: item.id,
                bordered: false,
                class: "permission-tag"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_42, _toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.open,
      "onUpdate:open": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.open) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      width: _ctx.modalWidth,
      placement: _ctx.locale === 'ar' ? 'left' : 'right'
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Meet.Updateeuser')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.update(_ctx.form.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Update')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.cancelUpdate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.rulesUpdate,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "first_name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.t('Meet.FisrtName')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.first_name,
                          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.first_name) = $event)),
                          placeholder: _ctx.t('Meet.FisrtName'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "last_name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.t('Meet.LastName')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.last_name,
                          "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.last_name) = $event)),
                          placeholder: _ctx.t('Meet.LastName'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "email" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.t('Meet.Email')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.email,
                          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.email) = $event)),
                          placeholder: _ctx.t('Meet.Email'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "phone" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.t('Meet.Phone')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.phone,
                          "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.phone) = $event)),
                          placeholder: _ctx.t('Meet.Phone'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "ad_integration" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.t('Meet.Ad_intergation')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.ad_integration,
                          "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.ad_integration) = $event)),
                          placeholder: _ctx.t('Meet.Ad_intergation'),
                          class: "input-form",
                          disabled: ""
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "photo" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.t('Meet.Photo')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_upload_dragger, {
                          fileList: _ctx.fileList,
                          "onUpdate:fileList": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.fileList) = $event)),
                          name: "file",
                          multiple: false,
                          "max-count": 1,
                          onChange: _ctx.handleUpdateChangeFile,
                          onDrop: _ctx.handleDrop,
                          "before-upload": _ctx.beforeUpload
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_49, [
                              _createVNode(_component_InboxOutlined)
                            ]),
                            _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.t('Meet.dropTitle1')), 1),
                            _createElementVNode("p", _hoisted_51, _toDisplayString(_ctx.t('Meet.dropTitle2')), 1)
                          ]),
                          _: 1
                        }, 8, ["fileList", "onChange", "onDrop", "before-upload"]),
                        (_ctx.form.photo!==null && !_ctx.state.fileChanged)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                              _createElementVNode("p", {
                                class: "photo-info",
                                onClick: _cache[20] || (_cache[20] = () => _ctx.setVisible(true))
                              }, [
                                _createVNode(_component_PaperClipOutlined),
                                _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.extractFilename(_ctx.form.photo)), 1),
                                _createTextVNode(),
                                _createVNode(_component_DeleteOutlined, { onClick: _ctx.deletePhoto }, null, 8, ["onClick"])
                              ]),
                              _createVNode(_component_a_image, {
                                width: 200,
                                style: { display: 'none' },
                                preview: {
                                visible: _ctx.visible,
                                onVisibleChange: _ctx.setVisible,
                                },
                                src: _ctx.form.photo
                              }, null, 8, ["preview", "src"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "width", "placement"]),
    _createVNode(_component_a_modal, {
      open: _ctx.openModal,
      "onUpdate:open": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.openModal) = $event)),
      onCancel: _ctx.resetPermission
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_54, [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.addPermissions
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Confirm')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.resetPermission
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, [
          _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.t('Meet.Permissions')), 1)
        ], 2)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formPermRef",
          model: _ctx.FormPerm,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "permissions" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.FormPerm.permissions,
                          "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.FormPerm.permissions) = $event)),
                          mode: "multiple",
                          style: {"width":"100%"},
                          placeholder: _ctx.t('Meet.Permissions'),
                          "show-search": "",
                          "option-filter-prop": 'label',
                          onChange: _ctx.handleMultiChange
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: option.value,
                                value: option.value,
                                disabled: _ctx.isPermissionDisabled(option.value)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(option.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "placeholder", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "class"])
      ]),
      _: 1
    }, 8, ["open", "onCancel"]),
    _createVNode(_component_a_modal, {
      open: _ctx.openPassModal,
      "onUpdate:open": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.openPassModal) = $event)),
      onCancel: _ctx.cancelPassModal
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_56, [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.updateUserPass
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Confirm')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.cancelPassModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, [
          _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.t('Meet.UpdateUsersPassword')), 1)
        ], 2)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "securityRef",
          model: _ctx.securityForm,
          rules: _ctx.securityRules,
          layout: "vertical"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      "has-feedback": "",
                      name: "current"
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.t('Meet.CurrentPass')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_password, {
                          value: _ctx.securityForm.current,
                          "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.securityForm.current) = $event)),
                          autocomplete: "off"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      "has-feedback": "",
                      name: "pass"
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.t('Meet.Password')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_password, {
                          value: _ctx.securityForm.pass,
                          "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.securityForm.pass) = $event)),
                          type: "password",
                          autocomplete: "off"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      "has-feedback": "",
                      name: "checkPass"
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.t('Meet.Confirmpassword')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_password, {
                          value: _ctx.securityForm.checkPass,
                          "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.securityForm.checkPass) = $event)),
                          type: "password",
                          autocomplete: "off"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["open", "onCancel"]),
    _createVNode(_component_a_modal, {
      open: _ctx.openRoleModal,
      "onUpdate:open": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.openRoleModal) = $event)),
      onCancel: _ctx.cancelRoleModal
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_61, [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.updateUserRole
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Confirm')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.cancelRoleModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, [
          _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.t('Meet.UpdateUserRole')), 1)
        ], 2)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "roleRef",
          model: _ctx.roleForm,
          layout: "vertical"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "role_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_63, _toDisplayString(_ctx.t('Meet.Role')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.role_id,
                          "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.form.role_id) = $event)),
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.roles, (role) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                label: role.name,
                                key: role.id,
                                value: role.id,
                                selected: role.id === _ctx.form.role_id 
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(role.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["label", "value", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["open", "onCancel"])
  ], 64))
}