<template>

  
  <a-layout style="height: 100vh; overflow: hidden;" :class="{ 'is-rtl': textDirection==='rtl'}">
    <LbrxLoaderSpinner :is-loaded="!loading" />

    <a-config-provider  
      :theme="{
      token: {
        colorPrimary: themeTemplate.primaryColor,
        fontFamily: 'Exo 2',
      },

      components: {
        Menu: {
          colorItemBgHover: $secondaryColor,
          colorItemBgSelected: $secondaryColor,
          colorItemTextSelected: $primaryColor,
        },
      }
      
      }"
    ></a-config-provider>

   
    
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible :width="siderWidth" class="layout-sider"  >
      <div class="container">
        <div class="logo">
          <img v-if="collapsed && $logo !=='nologo' " :src="$logo" class="logo-img-collapsed" alt="meetix-image"  @click="navigatePage('/', '0')" />
          <img v-if="!collapsed  && $logo !=='nologo' " :src="$logo" class="logo-img" alt="meetix-image" width="75%" @click="navigatePage('/', '0')" />  
          <!-- no logo -->
          <img v-if="collapsed && $logo ==='nologo' " src="@/assets/img/meetix-logo.png" class="logo-img-collapsed" alt="meetix-image"  @click="navigatePage('/', '0')" />
          <img v-if="!collapsed  && $logo ==='nologo' " src="@/assets/img/meetix-logo.png" class="logo-img" alt="meetix-image" width="75%" @click="navigatePage('/', '0')" />  

        </div>
        <a-menu v-model:selectedKeys="selectedKeys"  mode="inline"  class="sider-menu">

          <a-menu-item key="0" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
            <div class="icon-container" @click="navigatePage('/','0')">
              <HomeOutlined class="home-icon" />
              <span class="home-txt">{{t('Meet.Dashboard')}}</span>
            </div>
          </a-menu-item>

          <a-sub-menu key="1" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
            <template #title>
              <div class="icon-container">
                <span>
                  <ApartmentOutlined class="home-icon" />
                  <span v-show="!collapsed" class="home-txt">{{t('Meet.Rooms')}}</span>
                </span>
              </div>
            </template>
            <a-menu-item key="1-1" @click="navigatePage('/rooms','1-1')">
              <div class="icon-container">{{t('Meet.Rooms')}}</div>
            </a-menu-item>
            <a-menu-item key="1-2"  @click="navigatePage('/locations','1-2')">
              <div class="icon-container">{{t('Meet.Locations')}}</div>
            </a-menu-item>

          
          </a-sub-menu>

          <a-sub-menu key="2">
            <template #title>
              <div class="icon-container">
                <span>
                  <CalendarOutlined class="home-icon" />
                  <span v-show="!collapsed"  class="home-txt">{{t('Meet.Meetings')}}</span>
                </span>
              </div>
            </template>
            <a-menu-item key="2-1" @click="navigatePage('/meetings','2-1')" >
              <div class="icon-container">{{t('Meet.Meetings')}}</div>
            </a-menu-item>
            <a-menu-item key="2-2"  @click="navigatePage('/meeting-requests','2-2')" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
              <div class="icon-container">{{t('Meet.ReservationRequests')}}</div>
            </a-menu-item>

            <a-sub-menu key="2-3" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">

              <template #title>
                <span>
                  <div class="icon-container">
                    <span>{{t('Meet.InternalRequests')}}</span>
                  </div>
                </span>
              </template>

              <a-menu-item key="3-3-1" @click="navigatePage('/service-requests','3-3-1')"  class="submenu-container">
                <div class="icon-container">{{t('Meet.Services')}}</div>
              </a-menu-item>
              <a-menu-item key="3-3-2"  @click="navigatePage('/equipment-requests','3-3-2')"  class="submenu-container">
                <div class="icon-container">{{t('Meet.Equipements')}}</div>
              </a-menu-item>

            </a-sub-menu>

         

          
          </a-sub-menu>

        

          <a-menu-item key="3" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
            <div class="icon-container" @click="navigatePage('/equipments','3')">
              <DesktopOutlined class="home-icon" />
              <span class="home-txt">{{t('Meet.Equipements')}}</span>
            </div>
          </a-menu-item>

          <a-menu-item key="4" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
            <div class="icon-container" @click="navigatePage('/services','4')">
              <SettingOutlined class="home-icon" />
              <span class="home-txt">{{t('Meet.Services')}}</span>
            </div>
          </a-menu-item>

        
          <a-menu-item key="5" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' " @click="navigatePage('/kiosk','5')">
            <div class="icon-container" >
              <DesktopOutlined class="home-icon" />
              <span class="home-txt" >{{t('Meet.Kiosk')}}</span>
            </div>
          </a-menu-item>

          
          <a-menu-item key="6" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' "  @click="navigatePage('/users','6')">
            <div class="icon-container">
              <TeamOutlined class="home-icon" />
              <span class="home-txt">{{t('Meet.Users')}}</span>
            </div>
          </a-menu-item>

          <a-menu-item key="7" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' "  @click="navigatePage('/feedback','7')">
            <div class="icon-container">
              <LikeOutlined class="home-icon" />
              <span class="home-txt">{{t('Meet.FeedBack')}}</span>
            </div>
          </a-menu-item>

          <a-menu-item key="8" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' " @click="navigatePage('/statistics','8')">
            <div class="icon-container">
              <LineChartOutlined class="home-icon"/>
              <span class="home-txt">{{t('Meet.statistics')}}</span>
            </div>
          </a-menu-item>

          <a-menu-item key="9" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
            <div class="icon-container" @click="navigatePage('/settings','9')">
              <SettingOutlined />
              <span>{{t('Meet.Settings')}}</span>
            </div>
          </a-menu-item>

        </a-menu>
      </div>
    </a-layout-sider>

    <a-layout style="overflow-y: auto;" >

      <a-layout-header  class="layout-header" >

        <div class="trigger" style="margin-left: 16px" >
          <menu-unfold-outlined v-if="collapsed" @click="() => (collapsed = !collapsed)" />
          <menu-fold-outlined v-else @click="() => (collapsed = !collapsed)" />
        </div>

        <div v-if="$logo !=='nologo'" class="header-img-container">
          <img src="@/assets/img/meetix-logo.png" class="header-img" alt="meetix-image"  />  
        </div>
        
        <div :class="[locale === 'ar' ? 'avatar-container-rtl' : 'avatar-container']" >
          
          <a-space>
            
            <a-dropdown class="lang-drop" >
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1" @click="setLanguage('fr')">
                    {{ t('Meet.French') }}
                  </a-menu-item>
                  <a-menu-item key="2" @click="setLanguage('ar')">
                    {{ t('Meet.Arabic') }}
                  </a-menu-item>
                  <a-menu-item key="3" @click="setLanguage('en')">
                    {{ t('Meet.English') }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button class="drop-btn">
                {{ currentLanguageText }}
                <DownOutlined />
              </a-button>
            </a-dropdown>

            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" @click.prevent>
                <a-avatar  :size="35" class="avatar-icon" v-if="state.user.photo===null">
                  <template #icon><UserOutlined /></template>
                </a-avatar>

                <a-avatar
                  :size="35"
                  :src="state.user.photo"
                  v-if="state.user.photo!==null"
                />  
               

              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0"  @click="navigatePage('/profile','')">
                {{t('Meet.Profile')}}
                  </a-menu-item>

                  <a-menu-divider />

                  <a-menu-item key="1" @click="logout()">{{t('Meet.Logout')}}</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-space>
          
           
        </div>

      </a-layout-header>

      <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: 'auto' }" :class="{ 'dashboard-bg': noBgRoute}">
        <router-view></router-view>
      </a-layout-content>

      <a-layout-footer class="footer">
       
        <p>{{t('Meet.Copyright')}}</p>
      </a-layout-footer>

    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, reactive, watchEffect, onBeforeUnmount,  } from 'vue';
import { useRouter } from 'vue-router';
import { authService, userService } from '@/_services';
import { useI18n } from 'vue-i18n';

import {
  CalendarOutlined,
  HomeOutlined,
  DesktopOutlined,
  ApartmentOutlined,
  DownOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  LikeOutlined,
  TeamOutlined,
  LineChartOutlined

  
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'HomeView',
  components: {
    CalendarOutlined,
    HomeOutlined,
    DesktopOutlined,
    ApartmentOutlined,
    DownOutlined,
    UserOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    LikeOutlined,
    TeamOutlined,
    LineChartOutlined
    

    
  },
  setup() {
    

    const { t, locale } = useI18n();
    const currentLanguage = ref(locale.value);
    const currentLanguageText = ref(t(`Meet.${currentLanguage.value === 'fr' ? 'French' : currentLanguage.value === 'en' ? 'English' : 'Arabic'}`));
    const setLanguage = (lang:any) => {
      localStorage.setItem('Language', lang);
      location.reload();
    };

    interface User{
      name:string,
      photo:string;
    }

    const state = reactive({
      stores: [] as any[],
      storeName:'',
      user: {
        name: '',
        photo: ''
      } as User,
      currentPath:'',
      userRole:'',
    });

    const route = useRoute();

    const noBgRoute = computed(() => {
      return route.path === '/' || route.path === '/profile';
    });



    interface Theme {
      [key: string]: string;
    }

    const iconSize = computed(() => collapsed.value ? 30 : 24);

    const selectedKeys = ref<string[]>(['0']); 

    
    function getSelectedKey(path:any) {
      switch (path) {

        case '/':
          return '0';

        case '/rooms':
          return '1-1';

        case '/locations':
          return '1-2';

        case '/meetings':
          return '2-1';

        case '/meeting-requests':
          return '2-2';

        case '/service-requests':
          return '3-3-1';
          
        case '/equipment-requests':
          return '3-3-2';

        case '/equipments':
          return '3';

        case '/services':
          return '4';

        case '/kiosk':
          return '5';

        case '/users':
          return '6';

        case '/feedback':
          return '7';

        case '/statistics':
          return '8';

        case '/settings':
          return '9';

        case '/profile':
          return '';

        default:
          return ''; 
      }
    }

    

    const collapsed = ref<boolean>(false);
    const router = useRouter();

    watchEffect(() => {
      const currentPath = router.currentRoute.value.path;
      state.currentPath=currentPath;
      selectedKeys.value=[getSelectedKey(state.currentPath)];
      console.log('Current Pathsss:', currentPath);
    });

    const navigatePage = (link: string, key:string) => {

      router.push(link).then(() => {
        selectedKeys.value = [key];
        localStorage.setItem('selectedKey', key);

        selectedKeys.value = [getSelectedKey(router.currentRoute.value.path)];

        console.log('selected Key', selectedKeys.value);
      });
    };


    const FrLanguage=()=> {
      localStorage.setItem('Language', 'fr')
      location.reload();
    }

    const ArLanguage=()=> {
      localStorage.setItem('Language', 'ar')
      location.reload();
    }

    const EnLanguage=()=> {
      localStorage.setItem('Language', 'en')
      location.reload();
    }


    const isSelected = (key: string) => {
      return selectedKeys.value.includes(key);
    };

    // ---
    const themeTemplate = {
      primaryColor: "#8e1c77",
      confirmationColor: "#09aa29",
      cart: {
          selectCartItemColor: "#09aa29"
      }
    }
    const themeString = window.localStorage.getItem('themeTemplate');
    let theme: Theme = {};
    if (themeString) {
      theme = JSON.parse(themeString) ;
      themeTemplate.primaryColor = theme.primaryColor;
      themeTemplate.confirmationColor = theme.confirmationColor;
      console.log('rrrrrrrr : ', themeTemplate)
      const customStyles = `
      .bg-primary {
          background-color: ${themeTemplate.primaryColor};
      }
      .text-primary {
          color: ${themeTemplate.primaryColor};
      }
      .border-primary {
          border-color: ${themeTemplate.primaryColor};
      }
      .bg-confirmation {
          background-color: ${themeTemplate.confirmationColor};
      }
      .text-confirmation {
          color: ${themeTemplate.confirmationColor};
      }
      .accent-primary {
          accent-color: ${themeTemplate.primaryColor};
      }
      `;

      const styleElement = document.createElement('style');
      styleElement.type = 'text/css';

      if ('styleSheet' in styleElement) {
          (styleElement as any).styleSheet.cssText = customStyles;
      } else {
          styleElement.appendChild(document.createTextNode(customStyles));
      }

      document.head.appendChild(styleElement);
    }

    const logout = () => {
        authService.logout().then(() => {
          // 
          localStorage.removeItem("user");
          localStorage.removeItem("organization");
          localStorage.setItem('selectedKey', '0');
          location.reload();
          
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        console.log('finish')
      })
    };

   

    const loading = ref(false);

    const getUserInfo=()=>{
      const user = JSON.parse(localStorage.getItem('user') || 'null');

      userService.show(user.id).then((res)=>{
        state.user.name=res.data.first_name+" "+res.data.last_name;
        state.user.photo=res.data.photo;
      }).catch((error)=>{
        console.log(error);
      });
     
    }

      const getUserRole=()=>{
        let user_role = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).role : '';
        console.log('user role', user_role);
        state.userRole=user_role;
      }

      const siderWidth = ref('159px');

      const updateSiderWidth = () => {
        siderWidth.value = window.innerWidth >= 1200 ? '236px' : '159px';
        collapsed.value = window.innerWidth >= 1200 ? false : true;
      };

      const logo = ref('');

      const getLogo=()=>{
        const themeTemplate = localStorage.getItem('themeTemplate');
        if(themeTemplate){
          const theme= JSON.parse(themeTemplate);
          console.log('logo', theme.logo)
          logo.value = theme.logo;
          
        }else{
          logo.value='@/assets/img/gat_meet_logo.png';
        }
        console.log('logoo', logo.value);
      }



      

     
      onMounted(() => { 
        getLogo();


        updateSiderWidth();
        window.addEventListener('resize', updateSiderWidth);


        getUserRole();
        getUserInfo();

        const currentPath=useRouter();
        console.log('current paaaatth mount', currentPath.currentRoute.value.name);
        selectedKeys.value=[getSelectedKey(currentPath.currentRoute.value.name)];

        // const storedSelectedKeys = localStorage.getItem('selectedKey');
        // selectedKeys.value = storedSelectedKeys ? [storedSelectedKeys] : [];
      });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateSiderWidth);
    });



    //rtl
    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );



    return {
      logo,
      currentLanguageText,
      setLanguage,
      t,
      locale,
      textDirection,
      state,
      selectedKeys,
      collapsed,
      navigatePage,
      iconSize,
      isSelected,
      themeTemplate,
      logout,
      noBgRoute,
      loading,
      FrLanguage,
      ArLanguage,
      EnLanguage,
      siderWidth,
    };
  }
});
</script>
<style scoped>

 
  

  .icon-container{
    display: flex;
    align-items: center;
    font-size: 14px;
  }


  .icon{
    margin-right: 8px;
  }

  .logo {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .logo p{
    font-size: 20px;
    cursor: pointer;
    font-family: "Exo 2", sans-serif;
    font-weight: bold;
    font-size: 25px;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: var(--primary-color, #171717);
  }

 .trigger:hover {
    color:  var(--primary-color, #171717);
  } 

  .footer{
    display: flex;
    padding: 12px 20px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    background: #f5f5f5;
  }
  .footer p{
    color: var(--primary-color, #171717);
  }

  .layout-sider{
    /* overflow: 'auto';
    height: '100vh';
    position: 'fixed'; */


    width: 200px;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;

    
    /*border-right: 2px solid #d1d5db;*/
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  }

  .avatar-container {
    margin-left: auto; /*to the right*/
    margin-right: 16px;
  }

  .avatar-container-rtl{
    margin-right: auto;
    margin-left: 16px;
  }

  .avatar-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background: #fff;
    color: var(--primary-color, #171717);
  }

  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #FFF2E8;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.3);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  .sider-menu{
    background: #fff ;
    font-size: 13px;
    color: black;
    font-family: "Exo 2", sans-serif; 

    height: '100%';

  }

  .menu-item{
    flex: initial !important;
  }

  .layout-header{
    background: #fff;
    padding: 0;
    display: flex;
    top: 0;
    position: sticky;
    align-items: center;
    /*border-bottom: 2px solid #d1d5db; */
  
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;


  }

 
  .drop-btn{
    font-family: "Exo 2";
  }

  .lang-drop{
    color: #fff;
    background: var(--primary-color, #171717);
    border-color: var(--primary-color, #171717);
    box-shadow: none;
  }

  .lang-drop:hover{
    color: #fff;
    border-color:var(--primary-color, #171717); ;
  }

  .dashboard-bg{
    background: none !important;
  }

  .logo-img{
    cursor: pointer;
    width: 30%;
    height: auto;
  }

  .logo-img-collapsed{
    max-height: 100px;
    max-width: 35px;

    cursor: pointer;
  }

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-img-container{
    display: flex;
    align-items: center;
  }

  .header-img{
    width:70px;
    margin: 0px 15px;
  }

  /* CSS for RTL */
  .is-rtl {
    direction:rtl;
  }

  @media (max-width: 768px) {
    .lang-drop {
      font-size: 12px; 
      margin-left: 8px;
    }

    .drop-btn {
      font-size: 12px; 
    }

    .ant-menu-item {
      font-size: 12px; 
    }

  }

  .container{
    height: 100vh;
    overflow-y: auto;
  }

  /**scroll */
  .container::-webkit-scrollbar {
    width: 6px; 
    margin-left: 10px;
  }

  .container::-webkit-scrollbar-thumb {
    background-color: #cecccc; 
    border-radius: 10px; /* Rounded corners for the handle */
  }

  .container::-webkit-scrollbar-thumb:hover {
    background-color: rgb(171, 171, 171); /* Darker color when hovering */
  }

  .container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Background color of the track */
    border-radius: 10px; /* Rounded corners for the track */
  }

  .home-txt{
    font-size: 14px;
  }

  .home-icon{
    font-size: 20px;
  }

  /*responsive*/
  @media (min-width: 768px) {

    .home-txt,
    .icon-container{
      font-size: 16px;
    }

    .home-icon{
      font-size: 22px;
    }

  }

  @media (min-width: 1024px) {
    .home-txt,
    .icon-container{
      font-size: 16px;
    }

    .home-icon{
      font-size: 24px;
    }

  }



  



 

  
 
  

  

 
 

</style>
