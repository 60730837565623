<template>
    <Looder :is-loaded="!loading"></Looder>
   
   <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            <span class="txt">{{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> 
            <span class="txt">{{t('Meet.Devices')}} </span>
        </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

     <div class="btn-container" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
        <a-button class="add-btn" @click="showAddDrawer" >
            {{ t('Meet.Add') }}
        
        </a-button>
    </div>

    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
        
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template>

        <template #bodyCell="{ column, record }">
            <div class="table-cell">
                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                            </template>
                            <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.id)">
                                <template #icon>
                                <EyeOutlined  class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Edit') }}</span>
                            </template>

                            <a-button  class="tbl-btn" type="primary" @click="() => showDrawer(record.id)">
                            <template #icon>
                                <EditOutlined  class="tbl-icon" />
                            </template>
                            </a-button>

                        </a-tooltip>


                        <a-tooltip v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Delete') }}</span>
                            </template>
                            <a-button  class="tbl-btn" type="primary" danger @click="showDeletePopup(record.id)">
                                <template #icon>
                                <DeleteOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' " >
                            <template #title>
                                <span class="tooltip-txt">{{t('Meet.Login')}}</span>
                            </template>
                            <a-button class="login-btn tbl-btn"  @click="showModal(record)" >
                            <template #icon>
                                <LoginOutlined class="tbl-icon"/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{t('Meet.LogOut')}}</span>
                            </template>
                            <a-button danger  class="tbl-btn" @click="logout(record.id)" >
                                <template #icon>
                                    <LogoutOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip  v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{t('Meet.Refresh')}}</span>
                            </template>
                            <a-button class="refresh-btn tbl-btn" @click="refresh(record.id)">
                                <template #icon>
                                    <RedoOutlined class="tbl-icon"  />
                                </template>
                            </a-button>
                        </a-tooltip>
            
                    </a-space>
                </template>

                <span >{{ record[column.dataIndex] }}</span>

            </div>

           

        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows" />

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Meet.page')}}</a-select-option>
        </a-select>
    </div>

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >

        
        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.DeviceInfo')}}</p>
        </template>

        
        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.Name') }} :</span> 
                    <span class="info-txt">{{form.name}}</span>
                </p>
            </a-col> 

        </a-row>

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.Description') }} :</span> 
                    <span class="info-txt">{{form.description}}</span>
                </p>
            </a-col> 

        </a-row>

        <a-row :gutter="16">
            <a-col :span="8">
                <p>
                    <span class="info-title">{{ t('Meet.PrimaryColor') }} </span>
                    <input type="color" v-model="form.primaryColor" disabled> 
                </p>
            </a-col>

            <a-col :span="8">
                <p>
                    <span class="info-title">{{ t('Meet.SecondaryColor') }} </span>
                    <input type="color" v-model="form.secondaryColor" disabled> 
                </p>
                
            </a-col>
            <a-col :span="8">
                <p>
                    <span class="info-title">{{ t('Meet.ThirdColor') }}  </span>
                    <input type="color" v-model="form.thirdColor" disabled> 
                </p>
            </a-col>

        </a-row>

        

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.Room') }} :</span> 
                    <span class="info-txt">{{getRoomName(form.room_id)}}</span>

                </p>
            </a-col> 

        </a-row>


        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.allowVirtualKeyboard') }}</span>
                    <check-circle-two-tone two-tone-color="#52c41a" v-if="form.virtualKeyboard === true" />
                    <close-circle-two-tone two-tone-color="#ff4d4f" v-else/>
                </p>
            </a-col>

        </a-row>
           


        



       
       

    </a-drawer>

    <!-- add drawer --> 
    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.AddDevice')}}</p>
        </template>


        <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.name" :placeholder="t('Meet.Name')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item   name="description" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="addForm.description" :rows="4" :placeholder="t('Meet.Description')" />
                    </a-form-item>
                </a-col>
            </a-row>

           <a-row :gutter="16">
                    

                <a-col :span="8">
                    <a-form-item  name="primaryColor">
                        <template #label>
                            <span class="label-form">{{ t('Meet.PrimaryColor') }}</span>
                        </template>
                        <input type="color" v-model="addForm.primaryColor" >

                        
                    </a-form-item>
                </a-col>

                <a-col :span="8">
                    <a-form-item  name="secondaryColor">
                        <template #label>
                            <span class="label-form">{{ t('Meet.SecondaryColor') }}</span>
                        </template>
                        <input type="color" v-model="addForm.secondaryColor" >
                    </a-form-item>
                </a-col>

                <a-col :span="8">
                    <a-form-item  name="thirdColor">
                        <template #label>
                            <span class="label-form">{{ t('Meet.ThirdColor') }}</span>
                        </template>
                        <input type="color" v-model="addForm.thirdColor" >
                    </a-form-item>
                </a-col>


            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-item  name="room_id">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Room') }}</span>
                        </template>
                        <a-select 
                            show-search
                            allowClear 
                            v-model:value="addForm.room_id"
                            :option-filter-prop="'label'"
                           
                        >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                            <a-select-option v-for="room in state.rooms" :key="room.id" :value="room.id" :selected="room.id === addForm.room_id " :label="room.name">{{ room.name }}</a-select-option>
                        </a-select>

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="12">
                    <a-form-item name="virtualKeyboard">
                        <template #label>
                            <span class="label-form">{{ t('Meet.allowVirtualKeyboard') }}</span>
                        </template>
                        <a-switch v-model:checked="addForm.virtualKeyboard" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="photo">
                    <template #label>
                        <a-tooltip>
                            <template #title>{{t('Meet.bgImageTxt')}}</template>
                            <QuestionCircleOutlined class="tooltip-icon"/>
                        </a-tooltip>
                        <span class="label-form">{{ t('Meet.BgImage') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleChange"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                        <p class="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                        <p class="ant-upload-hint">
                        {{ t('Meet.dropTitle2') }}
                        </p>

                    </a-upload-dragger>   
                   


                    </a-form-item>
                </a-col>
            </a-row> -->

          
          
            
        </a-form>
        <template #footer>
            <a-space>
            <a-button  type="primary" @click="add">{{ t('Meet.Add') }}</a-button>
            <a-button type="primary" danger  @click="resetFormAdd">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>
    </a-drawer>

    
    <!-- update drawer -->
    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :width="modalWidth"
        :placement="locale === 'ar' ? 'left' : 'right'"

    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.UpdateDevice')}}</p>
        </template>

        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row>
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="form.name" :placeholder="t('Meet.Name')" class="input-form"  />
                    </a-form-item>
                </a-col>     
            </a-row>
            
            <a-row>
                <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Meet.Description')" />
                    </a-form-item>
                </a-col>   
            </a-row>

           

            <a-row :gutter="16">
                    

                <a-col :span="8">
                    <a-form-item  name="primaryColor">
                        <template #label>
                            <span class="label-form">{{ t('Meet.PrimaryColor') }}</span>
                        </template>
                        <input type="color" v-model="form.primaryColor" >

                        
                    </a-form-item>
                </a-col>

                <a-col :span="8">
                    <a-form-item  name="secondaryColor">
                        <template #label>
                            <span class="label-form">{{ t('Meet.SecondaryColor') }}</span>
                        </template>
                        <input type="color" v-model="form.secondaryColor" >
                    </a-form-item>
                </a-col>

                <a-col :span="8">
                    <a-form-item  name="thirdColor">
                        <template #label>
                            <span class="label-form">{{ t('Meet.ThirdColor') }}</span>
                        </template>
                        <input type="color" v-model="form.thirdColor" >
                    </a-form-item>
                </a-col>


            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-item  name="room_id">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Room') }}</span>
                        </template>
                        <a-select 
                            show-search
                            allowClear 
                            v-model:value="form.room_id"
                            :option-filter-prop="'label'"
                        >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                            <a-select-option v-for="room in state.rooms" :key="room.id" :value="room.id" :selected="room.id === form.room_id " :label="room.name">{{ room.name }}</a-select-option>
                        </a-select>

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="12">
                    <a-form-item name="virtualKeyboard">
                        <template #label>
                            <span class="label-form">{{ t('Meet.allowVirtualKeyboard') }}</span>
                        </template>
                        <a-switch v-model:checked="form.virtualKeyboard" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="photo">
                    <template #label>
                        <span class="label-form">{{ t('Meet.Photo') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleChange"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                        <p class="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                        <p class="ant-upload-hint">
                         {{ t('Meet.dropTitle2') }}
                        </p>

                    </a-upload-dragger>   
                   


                    </a-form-item>
                </a-col>
            </a-row> -->
           
        </a-form>


        <template #footer>
            <a-space>
                <a-button  type="primary" @click="update(form.id)">{{ t('Meet.Update') }}</a-button>
                <a-button type="primary" danger @click="cancelUpdate">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>

    </a-drawer>

    <!-- login modal --> 
    <a-modal v-model:open="loginModal" :confirm-loading="confirmLoading" @ok="handleLogin" @cancel="resetFormLogin">

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}"  class="drawer-title">{{t('Meet.Login')}} </p>
        </template>


        <template #footer>
            <a-button key="submit" type="primary"  @click="handleLogin">{{ t('Meet.Login') }}</a-button>
            <a-button key="back" @click="handleCancel">{{ t('Meet.Cancel') }}</a-button>
        </template>



        <a-form ref="logInFormRef" :model="loginForm" :rules="logInRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-form-item  has-feedback name="code">
                <template #label>
                    <span class="label-form">{{ t('Meet.DeviceAuthCode') }}</span>
                </template>
                <a-input v-model:value="loginForm.code"  autocomplete="off" />
            </a-form-item>
        </a-form>

    </a-modal>

    
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import { notification, UploadChangeParam, UploadFile, UploadProps } from 'ant-design-vue';
import Swal from 'sweetalert2';
import {
  
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  //InboxOutlined,
  //QuestionCircleOutlined,
  LoginOutlined,
  LogoutOutlined,
  RedoOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,



} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../components/Looder.vue';
import { kioskService, roomsService, serviceService } from '@/_services';
export default defineComponent({
    name: "kioskIndex",
    components: {
        Looder,
        SearchOutlined,
        DeleteOutlined,
        EyeOutlined,
        EditOutlined,
        //InboxOutlined,
        //QuestionCircleOutlined,
        LoginOutlined,
        LogoutOutlined,
        RedoOutlined,
        CheckCircleTwoTone,
        CloseCircleTwoTone,
    },
    setup() {

        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const loading = ref(false);

        const { t } = useI18n();

        const searchInput = ref<HTMLInputElement | null>(null);

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            rooms: [] as any[],
            userRole:'',
        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:t('Meet.Name'),
                key:'name',
                dataIndex:'name',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                            if (input instanceof HTMLInputElement) {
                                searchInput.value = input;
                                searchInput.value.focus();
                            }
                        }, 100);
                    }
                },

            },
            {
                title:t('Meet.Description'),
                key:'description',
                dataIndex:'description'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        //form
        const addFormRef = ref();
        const formRef = ref();

        const form = reactive({
            id:'',
            name:'',
            description:'',
            config:'',
            primaryColor:'',
            secondaryColor:'',
            thirdColor:'',
            photo:'',
            room_id:'',
            virtualKeyboard:false,
            
        });

        const addForm = reactive({
            id:'',
            name:'',
            description:'',
            config:'',
            primaryColor:'',
            secondaryColor:'',
            thirdColor:'',
            photo:'',
            room_id:'',
            virtualKeyboard:false,
        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true,message:t('Meet.RequiredField')}],
            room_id: [{ required: true,message:t('Meet.RequiredField')}],

        };

        
        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        
        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    kioskService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                kioskService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any, pageSize:number) => {
            currentPage.value = newPage;
            perPage.value=pageSize;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    kioskService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
               


            }else{
                loading.value = true;
                kioskService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        //search 
        const filter=(key:string, value:string)=>{
            loading.value=true;
            currentPage.value=1;
            kioskService.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
                loading.value=false;
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText) {
                filter(dataIndex,state.searchText);
            }
        };
            
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getAll();
        };

        const getRoomName=(id:any)=>{
           
            const room = state.rooms.find(item => item.id === id);
            return room ? room.name : null;
        }


        const getById=(id:string)=>{
            loading.value=true;
            kioskService.show(id).then((res)=>{
                form.id=res.data.id;
                form.name=res.data.name;
                form.description=res.data.description;
                const config=JSON.parse(res.data.config);
                form.config=config;

                form.primaryColor=config.primaryColor?config.primaryColor:"";
                form.secondaryColor=config.secondaryColor?config.secondaryColor:"";
                form.thirdColor= config.thirdColor?config.thirdColor:"";
                form.room_id=config.room_id?config.room_id:"";
                form.virtualKeyboard=config.allowVirtualKeyboard? config.allowVirtualKeyboard === 1 :false;
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                loading.value=false;
            });
        }

        
        // display drawer 
        const openDisplay = ref<boolean>(false);
        const showDisplayDrawer = (id: string) => {
            updateModalWidth();
            getRooms();
            getById(id);
            openDisplay.value = true;
        };

        //update drawer
        const open = ref<boolean>(false);
        const showDrawer = (id:string) => {
            updateModalWidth();
            getRooms();
            getById(id);
            open.value = true;
        };
        const cancelUpdate=()=>{
            open.value=false;
        }

        const update=(id:string)=>{
            formRef.value.validate().then(()=>{

                let config={
                    primaryColor:form.primaryColor ? form.primaryColor : "#000",
                    secondaryColor:form.secondaryColor ? form.secondaryColor : "#000",
                    thirdColor:form.thirdColor ? form.thirdColor : "#000",
                    room_id:form.room_id,
                    photo: '',
                    allowVirtualKeyboard:form.virtualKeyboard ? 1:0
                }

                let payload={
                    name:form.name,
                    description:form.description,
                    config:JSON.stringify(config),
                }
                
               

                loading.value = true;
                kioskService.update(id,payload).then((res)=>{
                    showSuccessNotification(t('Meet.UpdateDeviceSuccess'));
                    getAll();
                }).catch((error) => {
                    console.error('Error adding device:', error); 
                }).finally(()=>{
                    loading.value = false;
                    open.value=false;
                }); 


            }).catch(()=>{
                console.log('');
            })
        }


        //add darwer 
        const openAdd = ref<boolean>(false);
        const showAddDrawer = () => {
            updateModalWidth();
            getRooms();
            openAdd.value = true;
        };

    
        const resetFormAdd = () => {
            addFormRef.value.resetFields();
            fileList.value=[];
        }

      

        
        const add=()=>{
            addFormRef.value.validate().then(async ()=>{
                let formData = new FormData();
                formData.append('name', addForm.name);
                formData.append('description', addForm.description);
                let config={
                    primaryColor:addForm.primaryColor ? addForm.primaryColor : "#000",
                    secondaryColor:addForm.secondaryColor ? addForm.secondaryColor : "#000",
                    thirdColor:addForm.thirdColor ? addForm.thirdColor : "#000",
                    room_id:addForm.room_id,
                    photo: '',
                    allowVirtualKeyboard:addForm.virtualKeyboard ? 1:0
                }

                formData.append('config', JSON.stringify(config));

                loading.value = true;
                kioskService.create(formData).then((res)=>{
                    showSuccessNotification(t('Meet.AddDeviceSuccess'));
                    getAll();
                    resetFormAdd();
                }).catch((error) => {
                    console.error('Error adding device:', error); 
                }).finally(()=>{
                    loading.value = false;
                    openAdd.value=false;
                }); 

            }).catch(()=>{
                console.log('');
            })
        }
        



        //file

        interface FileInfoWithBase64 {
            uid: string;
            size?: number;
            name: string;
            fileName?: string;
            lastModified?: number;
            lastModifiedDate?: Date;
            preview?: string;
            base64?: string; 
        }
        const fileList = ref([]);
        const beforeUpload = () => {
            return false;
        };
        const fileInfo = ref<FileInfoWithBase64  | null>(null);

        function getBase64s(file: Blob, callback: (arg0: string|ArrayBuffer|null) => any) {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(file);
        }

        const handleChange = (info: UploadChangeParam) => {
            fileInfo.value=info.file;
           //getBaseeee64(info.file)
        };

        function handleDrop(e: DragEvent) {
            console.log(e);
        }

        function getBaseeee64(file: File): Promise<string> {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        resolve(reader.result);
                    } else {
                        reject(new Error('Failed to read file as base64.'));
                    }
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        }

        function getBasee64(file: File) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        function getBase64(element:any) {
            var reader = new FileReader();
            reader.onloadend = function() {
             console.log('RESULT', reader.result)
            }
            reader.readAsDataURL(element);



            
            // return new Promise((resolve, reject) => {
            //     const reader = new FileReader();
            //     reader.onload = () => {
            //         if (typeof reader.result === 'string') {
            //             resolve(reader.result);
            //         } else {
            //             reject(new Error('Failed to read file as base64.'));
            //         }
            //     };
            //     reader.onerror = (error) => reject(error);
            //     reader.readAsDataURL(file);
            // });
        }


        
        const deleteKiosk=(id:string)=>{
            loading.value=true;
            kioskService.delete(id).then((res)=>{
                getAll();
                showSuccessNotification(t('Meet.DeleteDeviceSuccess'))
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                loading.value=false;
            })
        }

        const showDeletePopup = (id: string) => {
            Swal.fire({
                title: t('Meet.TitleDeleteDevice'),
                text: t('Meet.TextDeleteDevice'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Delete'),
                cancelButtonText: t('Meet.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteKiosk(id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const getRooms=()=>{
            roomsService.getAll().then((res)=>{
                state.rooms=res.data;
            }).catch((error)=>{
                console.log("error get rooms", error);
            });
        }

        const getAll= async ()=>{
            loading.value=true;
            kioskService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.total;
            }).catch((error)=>{
                console.log("error get devices", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        //login modal
        const loginModal = ref<boolean>(false);
        const confirmLoading = ref<boolean>(false);
        let deviceId:any = null;
        const showModal = (record:any) => {
            loginModal.value = true;
            deviceId = record.id;
        };

        const handleCancel = () => {
           loginModal.value = false;
            resetFormLogin();
        };
        const logInFormRef=ref();

        const loginForm = reactive({
            code:'',
        });

        const logInRules : Record<string, Rule[]> = {
            code: [
                { required: true, message: t('Meet.RequiredField') },
                {
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject( t('Meet.numericField'));
                    }
                }
            ],
           
        };

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                    color: 'white'
                }
            });
        };

        const resetFormLogin = () => {
            logInFormRef.value.resetFields();
        };


        const handleLogin = async () => {

            logInFormRef.value.validate().then(()=>{
                try {
                    confirmLoading.value = true;
                    let payload ={
                        code:loginForm.code
                    }

                    kioskService.connectDevice(deviceId, payload).then((res)=>{
                        showSuccessNotification(t('Meet.ConnectDeviceSuccess'));
                    }).catch((error)=>{
                        if(error===400){
                            showErrorNotification(t('Meet.InvalidCode'));
                        }
                        
                    }).finally(()=>{
                        confirmLoading.value = false;
                        loginModal.value = false;
                       
                        resetFormLogin();
                    });
                
                
                } catch (error) {
                    console.error('Error connecting device:', error);
                }

            }).catch((error:any) => {
            console.error('Validation error:', error);
            });
            
        };

        const logout=(id:any)=>{
            Swal.fire({
                title: t('Meet.TitleLogout'),
                text: t('Meet.TextLogout'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Logout'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Meet.Cancel'),
                customClass: {
                popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    kioskService.logoutDevice(id).then((res)=>{
                        showSuccessNotification(t('Meet.DeviceLogoutSuccess'));
                    }).catch((error)=>{
                        console.log('error', error);
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log("cancel delete ");
                }
            })
        }

        const refresh=(id:any)=>{
            Swal.fire({
                title: t('Meet.TitleRefresh'),
                text: t('Meet.TextRefresh'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Refresh'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Meet.Cancel'),
                customClass: {
                popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    kioskService.refreshDevice(id).then((res)=>{
                        showSuccessNotification(t('Meet.RefreshDeviceSuccess'));
                    }).catch((error)=>{
                        console.log('error', error);
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log("cancel delete ");
                }
            })
        }

        const getUserRole= async ()=>{
            let user_role = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).role : '';
            console.log('user role', user_role);
            state.userRole=user_role;
        }

        const getTemplate=  async ()=>{
            const themeTemplate = localStorage.getItem('themeTemplate');
            if(themeTemplate){
                const theme= JSON.parse(themeTemplate);
                addForm.primaryColor = theme.primaryColor;
                addForm.secondaryColor = theme.secondaryColor;
                addForm.thirdColor = theme.thirdColor;
            }else{

                addForm.primaryColor='#171717';
                addForm.secondaryColor='#fc8019';
                addForm.thirdColor='#d35100';

            }

        }

      

        
        onMounted( async () => {

            await getTemplate();
            await getUserRole();
            await getAll();
        });

        return{
            t,
            locale,
            textDirection,
            simpleImage,
            loading,
            state,
            navigatePage,
            columns,
            addFormRef,
            formRef,
            form,
            addForm,
            rules,
            perPage,
            currentPage,
            selectPagination,
            handleSelectPaginationChange,
            handlePageChange,
            handleSearch,
            handleReset,
            openDisplay,
            showDisplayDrawer,
            open,
            showDrawer,
            cancelUpdate,
            openAdd,
            showAddDrawer,
            resetFormAdd,
            showDeletePopup,
            fileList,
            beforeUpload,
            fileInfo,
            handleChange,
            handleDrop,
            add,
            getRoomName,
            update,
            loginModal,
            confirmLoading,
            showModal,
            loginForm,
            logInFormRef,
            handleLogin,
            resetFormLogin,
            logInRules,
            handleCancel,
            logout,
            refresh,
            modalWidth









            





















        }

        
    },
})
</script>

<style scoped>

.table-container {
  overflow-x: auto;
}



.breadcrumb-icon{
  cursor: pointer;
}

.add-btn {
    color: var(--primary-color, #171717);
    border: 2px solid var(--primary-color, #171717);
    box-shadow: inset 0 0 0 0 var(--primary-color, #171717);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 var(--primary-color, #171717);
    border-color: var(--primary-color, #171717);
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}




.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 10px;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.tooltip-icon{
    margin-right: 5px;
}

.login-btn{
    color: #28c76f;
    border-color: #28c76f;
    background: #fafafa;
}

.login-btn :hover{
    color: #28c76f !important;
    border-color: #28c76f !important;
    background: #fafafa;
}


.refresh-btn{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
}

.refresh-btn:hover{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
}

.table-container {
  overflow-x: auto;
}


/* CSS for RTL */
.is-rtl {
    direction:rtl;
}


.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

@media (min-width: 768px) {
   .add-btn{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}
</style>
