<template>
    <Looder :is-loaded="!loading"></Looder>

   <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            <span class="txt">{{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>  
            <span class="txt">{{t('Meet.Meetings')}} </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> 
            <span class="txt">{{t('Meet.ReservationRequests')}} </span>
        </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
        
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template>

        <template #bodyCell="{ column, record }">
            <div class="table-cell">

                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                            </template>
                            <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.id)">
                                <template #icon>
                                    <EyeOutlined  class="tbl-icon"/>
                                </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key==='date'">
                    <p>{{t('Meet.ReservationFor')}} {{formatDate(record.start)}} {{t('Meet.At')}} {{formatDisplayTime(record.start)}}</p>
                </template>

                <template v-if="column.key==='room'">
                    <p>{{record.room.name}}</p>
                </template>

                <template v-if="column.key==='approved'">
                    <a-switch :checked="isApproved(record.approved)" @change="toggleApproval(record)" size="small" />
                </template>
                <span v-if=" column.key!=='room' && column.key!=='approved' && column.key!=='date'">{{ record[column.dataIndex] }}</span>

            </div>



           
        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

                <template v-if="column.key === 'date'">
                    <div  class="date-picker-container">
                        <a-date-picker v-model:value="datePicker" class="date-input"  />
                    </div>
                </template>


                
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows"/>

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Meet.page')}}</a-select-option>
        </a-select>
    </div>


    <!--- display drawer -->
        <a-drawer
            v-model:open="openDisplay"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">
                    <a-row>
                        <a-col :span="12">
                            <span class="drawer-title">{{t('Meet.MeetingInformation')}}</span>
                        </a-col>

                        <a-col :span="12" class="approve-tag-container">   
                            <a-tag v-if="form.approved ===true " color="green" >
                                <template #icon>
                                    <check-circle-outlined />
                                </template>
                                {{t('Meet.Approved')}}
                            </a-tag>

                            <a-tag v-if="form.approved ===false" color="purple" >
                                <template #icon>
                                    <CloseCircleOutlined />
                                </template>
                                {{t('Meet.Disapproved')}}
                            </a-tag>
                            
                        </a-col>
                    </a-row>

                </p>
            </template>

            <a-row  :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="24">
                    <p>
                        <span class="info-title">{{ t('Meet.Motif') }} :</span>
                        <span v-if="form.motif !== ''" class="info-txt">{{form.motif}}</span>
                        <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                    </p>
                </a-col>
            </a-row>

           

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Meet.Date') }} :</span>
                        <span v-if="form.start !== ''" class="info-txt">{{formatDate(form.start)}}</span>
                        <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                    </p>
                </a-col>
                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Meet.from') }} </span> 
                        <span v-if="form.start !== ''" class="start-date">{{extractTime(form.start)}}</span>
                        <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag> 
                        <span class="info-title">{{ t('Meet.to') }} </span>
                        <span v-if="form.end !== ''" class="info-txt">{{extractTime(form.end)}}</span>
                        <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag> 
                    </p>
                </a-col>
                
            </a-row>

         
            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Meet.Room') }} :</span>
                        <span v-if="form.room_name !== ''" class="info-txt">{{form.room_name}}</span>
                        <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                    </p>
                </a-col>

                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Meet.CreatedBy') }} :</span>
                        <span v-if="form.created_by_name !== ''" class="info-txt">{{form.created_by_name}}</span>
                        <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                    </p>

                </a-col>
            </a-row>

           

            <a-row :class="{ 'is-rtl': textDirection==='rtl'}" v-if="particpants_array.length !== 0"> 
                <a-col :span="24">                    
                    <p><span class="info-title">{{ t('Meet.Participants') }} :</span></p>
                </a-col>
            </a-row>

           <a-row v-for="item in particpants_array" :key="item" class="list-item-container" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col flex="25px">
                    <a-avatar>
                        <template #icon><UserOutlined /></template>
                    </a-avatar>
                </a-col>
                <a-col flex="auto">
                    <p  class="title-list info-txt">{{ item.name }}</p>
                </a-col>
            </a-row>

            <a-row :class="{ 'is-rtl': textDirection==='rtl'}" v-if="services_array.length !== 0">
                <a-col :span="24">                    
                    <p><span class="info-title">{{ t('Meet.Services') }} :</span></p>
                </a-col>
            </a-row>

            <a-row v-for="item in services_array" :key="item" class="list-item-container" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="24">
                    <a-badge :color="$primaryColor" :text="item.displayName"  />
                </a-col>
            </a-row>


            <a-row :class="{ 'is-rtl': textDirection==='rtl'}" v-if="equipments_array.length !== 0">
                <a-col :span="24">                    
                    <p><span class="info-title">{{ t('Meet.Equipment') }} :</span></p>
                </a-col>
            </a-row>

            <a-row v-for="item in equipments_array" :key="item" class="list-item-container" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="24">
                    <a-badge :color="$primaryColor" :text="item.displayName" />
                </a-col>
              
            </a-row>







        

        </a-drawer>
    <!-- display drawer -->


    

</template>

<script  lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import { notification, UploadFile, UploadProps } from 'ant-design-vue';
import Swal from 'sweetalert2';
import {
  
  SearchOutlined,
  EyeOutlined,
  UserOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined
  
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../../components/Looder.vue';
import { reservationsService, roomsService } from '@/_services';
import dayjs , { Dayjs }from 'dayjs'; 

export default defineComponent({

    name: "reservationRequestsIndex",
    components: {
        Looder,
        SearchOutlined,
        EyeOutlined,
        UserOutlined,
        CloseCircleOutlined,
        CheckCircleOutlined
        
    },
    setup() {
        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:t('Meet.Motif'),
                key:'motif',
                dataIndex:'motif',
            },
            {
                title:t('Meet.Date'),
                key:'date',
                dataIndex:'date',
                

            },
            {
                title:t('Meet.Room'),
                key:'room',
                dataIndex:'room'

            },
            {
                title:t('Meet.Approved'),
                key:'approved',
                dataIndex:'approved'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');


        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    roomsService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                
                reservationsService.getAllWithPaginationOrdred(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages = response.meta.total;
                }).catch((error)=>{
                    console.log("error get rooms", error);
                }).finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    roomsService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
               


            }else{
                loading.value = true;

                reservationsService.getAllWithPaginationOrdred(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages = response.meta.total;
                }).catch((error)=>{
                    console.log("error get rooms", error);
                }).finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        //search 
        const datePicker = ref<Dayjs>();

        const filter=(key:string, value:string)=>{
            loading.value=true;
            roomsService.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText) {
                filter(dataIndex,state.searchText);
            }
        };
            
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getReservationRequest();
        };


        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 20,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };
        
        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                    color: 'white'
                }
            });
        };


        //convert date format from "YYYY-MM-DD" to "DD-MM-YYYY"
        function formatDate(dateString:string) {
            const [datePart, timePart] = dateString.split(' '); 
            const [year, month, day] = datePart.split('-');
            return `${day}-${month}-${year}`;
        }


        //extract time from datetime string "YYYY-MM-DD HH:mm:ss"
        function extractTime(dateTimeString:string) {
            return dateTimeString.split(' ')[1];
        }

        function formatDisplayTime(dateTimeString:string) {
            const time =  dateTimeString.split(' ')[1];
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;

        }

        function toggleApproval(record:any) {
            console.log('record', record);
            if (record.approved === 1) {
                console.log('Already approved');
                showErrorNotification(t('Meet.approvalErrorRes'));
                return;
            }

            const currentDate = dayjs();
            const startDate = dayjs(record.start);

            if (startDate.isBefore(currentDate, 'day')) {
                showErrorNotification(t('Meet.approvalErrorTxt')); 
                return; 
            }

            //record.approved = isApproved(record.approved) ? 0 : 1;
            const approved = isApproved(record.approved) ? 0 : 1;
            let formData = new FormData();
            formData.append('approved', approved.toString());
            formData.append('room_id', record.room_id);
            formData.append('end', record.end);
            formData.append('start', record.start);

            loading.value=true;
            reservationsService.update(record.id, formData).then((res)=>{
                loading.value=false;
                getReservationRequest();
                showSuccessNotification(t('Meet.MeetingApprovedSuccess'));
            }).catch((error)=>{
                loading.value=false;
                console.log(error);
            });
        }

        function isApproved(value:any) {
            return value === 1;
        }

        
        const form = reactive({
            id:'',
            motif:'',
            room_id:'',
            room_name:'',
            status:'',
            description:'',
            date:[],
            participants:'',
            created_by_name:'',
            participant_names:[],
            start:'',
            end:'',
            start_date: null as Dayjs | null,
            end_date: null as Dayjs | null,
            approved:false,
        });

        //display 
        const openDisplay = ref<boolean>(false);

        interface Participant {
            id: string;
            name:string;
            photo:string;
        }
        const particpants_array = ref<Participant[]>([]); 
        const services_array = ref<Participant[]>([]); 
        const equipments_array = ref<Participant[]>([]); 

        const getReservationById = async (id: string) => {
            loading.value=true;
            try{

                const res = await reservationsService.show(id);
                form.id = res.data.id ? res.data.id :0;
                form.motif=res.data.motif ? res.data.motif :'';
                form.room_id = res.data.room_id ? res.data.room_id : 1;
                form.room_name = res.data.room ? res.data.room.name :'';
                form.created_by_name = res.data.user ? res.data.user.first_name+" "+res.data.user.last_name : "";
                form.start=res.data.start ?res.data.start : '';
                form.end = res.data.end ? res.data.end : '';

                form.start_date = res.data.start?  dayjs(res.data.start) : null;
                form.end_date = res.data.end? dayjs(res.data.end) : null ;
                form.approved=res.data.approved === 1;

                particpants_array.value = res.data.participants.map((participant: any) => {
                    return {
                        id: participant.id,
                        name: participant.first_name + " " + participant.last_name,
                        photo: participant.photo
                    };
                });

                form.participants = res.data.participants.map((participant: any) => participant.first_name+" "+participant.last_name);
                form.approved=res.data.approved === 1;


                services_array.value = res.data.services.map((service: any) => {
                    return {
                        id: service.id,
                        name: service.name,
                        qt:service.quantity,
                        displayName:service.quantity+"x "+service.name
                    };
                });


                equipments_array.value = res.data.equipments.map((equipment: any) => {
                    return {
                        id: equipment.id,
                        name: equipment.name,
                        qt:equipment.quantity,
                        displayName:equipment.quantity+"x "+equipment.name
                    };
                });


                

                
                console.log('participants ', particpants_array.value);


                loading.value = false;


            }catch(error){
                console.error('Error fetching reservation by id:', error);
            }
        }

        const showDisplayDrawer =(id:string)=>{
            updateModalWidth();
            getReservationById(id);

            openDisplay.value = true;
        }


        const getReservationRequest=()=>{
            loading.value=true;


            reservationsService.getAllWithPaginationOrdred(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.meta.total;
            }).catch((error)=>{
                console.log("error get rooms", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        onMounted(() => {
            getReservationRequest();
        });



        return{
            services_array,
            equipments_array,
            formatDisplayTime,
            modalWidth,
            locale,
            textDirection,
            simpleImage,
            loading,
            t,
            state,
            navigatePage,
            columns,
            perPage,
            currentPage,
            selectPagination,
            formatDate,
            extractTime,
            handleSelectPaginationChange,
            handlePageChange,
            toggleApproval,
            isApproved,
            openDisplay,
            particpants_array,
            showDisplayDrawer,
            form,
            datePicker,
            handleReset,
            handleSearch,
















        }
        
    },
})
</script>



<style scoped>

.breadcrumb-icon{
  cursor: pointer;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}


.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 10px;
}

 .title-list{
    font-weight: 400 !important;
    color: black !important;
    margin-left:10px ;
}

.list-item-container{
    display: flex !important;
    align-items: baseline !important;
    margin-left: 15px;
    margin-bottom: 10px;

}


.approve-tag-container{
    display: flex;
    justify-content: flex-end;
}

.start-date{
    margin-right: 15px;
    font-size: 14px;
}

.date-picker-container{
    display: flex;
    margin-bottom: 10px;
}

.date-input{
    width: 100%;
}

.table-container {
  overflow-x: auto;
}


/* CSS for RTL */
.is-rtl {
    direction:rtl;
}

.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

@media (min-width: 768px) {
   .add-btn,
   .start-date{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-titleة
    .start-date{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}


</style>