<template>
   <Looder :is-loaded="!loading"></Looder>

   <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            <span class="txt">{{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>  <span class="txt">{{t('Meet.Rooms')}}</span> </a-breadcrumb-item>
        <a-breadcrumb-item>  <span class="txt">{{t('Meet.Rooms')}}</span> </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
        <a-button  class="add-btn" @click="showAddDrawer" >
            {{ t('Meet.Add') }}
        </a-button>
    </div>
    <a-table  class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
        
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template>

        <template #bodyCell="{ column, record }">
            <div class="table-cell">
                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                            </template>
                            <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.id)">
                                <template #icon>
                                    <EyeOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Edit') }}</span>
                            </template>

                            <a-button  class="tbl-btn" type="primary" @click="() => showDrawer(record.id)">
                            <template #icon>
                                <EditOutlined   class="tbl-icon"/>
                            </template>
                            </a-button>

                        </a-tooltip>


                        <a-tooltip  v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Delete') }}</span>
                            </template>
                            <a-button class="tbl-btn" type="primary" danger @click="showDeletePopup(record.id)">
                                <template #icon>
                                <DeleteOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.WorkingDays') }}</span>
                            </template>
                            <a-button type="primary" class="wd-btn tbl-btn" @click="showWorkingDays(record.id)">
                                <template #icon>
                                    <CalendarOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                    
                    </a-space>
                </template>

                <template v-if="column.key=='active'">

                    <check-circle-two-tone two-tone-color="#52c41a" v-if="record.active === 1 " />
                    <close-circle-two-tone two-tone-color="#ff4d4f" v-else/>

                </template>

                <span v-if="column.key!=='active'">{{ record[column.dataIndex] }}</span>

            </div>

        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

                
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination  v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows" />

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">
                10 / {{t('Meet.page')}}
            </a-select-option>
            <a-select-option value="20">
                20 / {{t('Meet.page')}}
            </a-select-option>
            <a-select-option value="50">
                50 / {{t('Meet.page')}}
            </a-select-option>
            <a-select-option value="100">
                100 / {{t('Meet.page')}}
            </a-select-option>
        </a-select>
    </div>

    <!-- add drawer --> 
    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >

        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.AddRoom')}}</p>
        </template>


        <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.name" :placeholder="t('Meet.Name')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item name="ad_address" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.adAddress') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.ad_address" :placeholder="t('Meet.adAddress')" class="input-form"/>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="ad_password" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.passwordAd') }}</span>
                        </template>
                        <a-input-password  v-model:value="addForm.ad_password" v-model:visible="visiblePass" :placeholder="t('Meet.passwordAd')" class="input-form"/>
                    </a-form-item>
                </a-col>

            </a-row>

            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item  name="location_id">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Location') }}</span>
                        </template>

                        <a-select v-model:value="addForm.location_id" show-search :option-filter-prop="'label'">

                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option v-for="location in state.locations" :label="location.name" :key="location.id" :value="location.id" :selected="location.id === addForm.location_id ">{{ location.name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item  name="status">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Status') }}</span>
                        </template>
                        <a-select v-model:value="addForm.status" show-search :option-filter-prop="'label'">
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                            <a-select-option value="available" :label="t('Meet.Available')">{{ t('Meet.Available') }}</a-select-option>
                            <a-select-option value="occupied" :label="t('Meet.Occupied')">{{ t('Meet.Occupied') }}</a-select-option>
                        </a-select>

                       
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-item name="capacity">

                        <template #label>
                            <span class="label-form">{{ t('Meet.Capacity') }}</span>
                        </template>

                        <a-input v-model:value="addForm.capacity" :placeholder="t('Meet.Capacity')" />
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="equipments">

                        <template #label>
                            <span class="label-form">{{ t('Meet.Equipements') }}</span>
                        </template>


                            <a-select
                                v-model:value="addForm.equipmenets"
                                mode="multiple"
                                style="width: 100%"
                                :placeholder="t('Meet.Equipements')"
                                :options="options"
                                @change="handleMultiChange"
                                show-search
                                :option-filter-prop="'label'"
                            >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                        </a-select>

                    </a-form-item>
                </a-col>
            </a-row>


            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item name="managers">

                        <template #label>
                            <span class="label-form">{{ t('Meet.Managers') }}</span>
                        </template>

                        <a-select
                            v-model:value="state.managers"
                            mode="multiple"
                            label-in-value
                            :placeholder="t('Meet.selectManagers')"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetching ? undefined : null"
                            :options="state.allManagers"
                            @search="fetchManager"
                        >

                            <template v-if="state.fetching" #notFoundContent>
                                <a-spin size="small" />
                            </template>

                        </a-select>

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row >
                <a-col :span="12">
                    <a-form-item  name="color">
                        <template #label>
                            <span class="label-form">{{ t('Meet.color') }}</span>
                        </template>
                        <input type="color" v-model="addForm.color" >
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="approval">
                        <template #label>
                            <span class="label-form">{{ t('Meet.approvalTxt') }}</span>
                        </template>
                        <a-switch v-model:checked="addForm.approval" />
                    </a-form-item>
                </a-col>

            </a-row>

            <a-row>
                <a-col :span="12">
                    <a-form-item name="approval">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Active') }}</span>
                        </template>
                        <a-switch v-model:checked="addForm.active" />
                    </a-form-item>
                </a-col>
            </a-row>

           


           

           

            


           
            
        </a-form>
        <template #footer>
            <a-space>
                <a-button  type="primary" @click="add">
                    {{ t('Meet.Add') }}
                </a-button>
                <a-button  type="primary" danger  @click="resetFormAdd">
                    {{ t('Meet.Cancel') }}
                </a-button>
            </a-space>
        </template>
    </a-drawer>


    
    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >

        
        <template #title>

            <a-row>

                <a-col :span="12">
                    <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.RoomInformation')}}</p>
                </a-col>

                <a-col :span="12"  class="approve-tag-container">
                    <a-tag v-if="form.active === true" color="green" >
                        <template #icon>
                            <check-circle-outlined />
                        </template>
                        <span class="info-txt">{{t('Meet.Active')}}</span>
                    </a-tag>

                    <a-tag v-if="form.active === false" color="red" >
                        <template #icon>
                            <CloseCircleOutlined />
                        </template>
                        <span class="info-txt">{{t('Meet.inactive')}}</span>
                    </a-tag>
                </a-col>

            </a-row>
        </template>

        
        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Name') }} :</span> 
                    <span class="info-txt">{{form.name}}</span>
                </p>
            </a-col>
        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p><span class="info-title">{{ t('Meet.adAddress') }} :</span> {{form.ad_address}}</p>
            </a-col> 
        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">

            <a-col :span="12">
                <p><span class="info-title">{{ t('Meet.Capacity') }} :</span> {{form.capacity}}</p>
            </a-col> 
            <a-col :span="12">
                <p><span class="info-title">{{ t('Meet.Location') }} :</span> {{form.location_name}}</p>
            </a-col>
           
        </a-row>

        <a-row  :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Status') }} :</span> 

                    <a-tag v-if="form.status==='available'" color="green" >
                    <template #icon>
                        <check-circle-outlined />
                    </template>
                    {{t('Meet.Available')}}
                </a-tag>

                <a-tag v-if="form.status==='occupied'" color="red" >
                    <template #icon>
                        <CloseCircleOutlined />
                    </template>
                    {{t('Meet.Occupied')}}
                </a-tag>
                </p>
            </a-col>

            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.color') }} :</span> 
                    <input type="color" v-model="form.color" disabled>
                </p>
            </a-col> 
        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.approvalTxt') }}</span>
                    <check-circle-two-tone two-tone-color="#52c41a" v-if="form.approval === true" />
                    <close-circle-two-tone two-tone-color="#ff4d4f" v-else/>
                </p>
            </a-col>
        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="24">                    
                <p><span class="info-title">{{ t('Meet.Equipements') }} :</span></p>
            </a-col>
        </a-row>

        <ul v-for="item in equipmenets_array " :key="item" :class="{ 'is-rtl': textDirection==='rtl'}">
            <li >{{item.name}}</li>
        </ul>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">

            <a-col :span="24">
                <p><span class="info-title">{{ t('Meet.Photo') }} :</span></p>

                <a-image
                    v-if="form.photos.length > 0"
                    :preview="{ visible: false }"
                    :width="200"
                    :src="form.photos[0]"
                    @click="visible = true"
                />
                <div style="display: none">
                    <a-image-preview-group :preview="{ visible, onVisibleChange: vis => (visible = vis) }">
                        <template v-for="(photo, index) in form.photos" :key="index">
                            <a-image :src="photo" :width="200" />
                        </template>
                    </a-image-preview-group>
                </div>

            </a-col>
           
        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="24">                    
                <p><span class="info-title">{{ t('Meet.Managers') }} :</span></p>
            </a-col>
        </a-row>

        <ul v-for="item in form.managers " :key="item" :class="{ 'is-rtl': textDirection==='rtl'}">
            <li>{{item.label}}</li>
        </ul>

    </a-drawer>


    <!-- update drawer -->
    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :width="modalWidth"
        :placement="locale === 'ar' ? 'left' : 'right'"

    >

        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.UpdateRoo')}}</p>
        </template>

        <a-form ref="formRef" :model="form" :rules="updateRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row>
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="form.name" :placeholder="t('Meet.Name')" class="input-form"  />
                    </a-form-item>
                </a-col>     
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item name="ad_address" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.adAddress') }}</span>
                        </template>
                        <a-input  v-model:value="form.ad_address" :placeholder="t('Meet.adAddress')" class="input-form"/>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="ad_password" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.passwordAd') }}</span>
                        </template>
                        <a-input-password  v-model:value="form.ad_password" v-model:visible="visiblePass" :placeholder="t('Meet.passwordAd')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item  name="location_id">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Location') }}</span>
                        </template>

                        <a-select v-model:value="form.location_id" show-search :option-filter-prop="'label'">

                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option v-for="location in state.locations" :label="location.name" :key="location.id" :value="location.id" :selected="location.id === form.location_id ">{{ location.name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item  name="status">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Status') }}</span>
                        </template>
                        <a-select v-model:value="form.status" show-search :option-filter-prop="'label'">
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                            <a-select-option value="available" :label="t('Meet.Available')">{{ t('Meet.Available') }}</a-select-option>
                            <a-select-option value="occupied" :label="t('Meet.Occupied')">{{ t('Meet.Occupied') }}</a-select-option>
                        </a-select>

                       
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-item name="capacity">

                        <template #label>
                            <span class="label-form">{{ t('Meet.Capacity') }}</span>
                        </template>

                        <a-input v-model:value="form.capacity" :placeholder="t('Meet.Capacity')" />
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="equipments">

                        <template #label>
                            <span class="label-form">{{ t('Meet.Equipements') }}</span>
                        </template>


                            <a-select
                                v-model:value="form.equipmenets"
                                mode="multiple"
                                style="width: 100%"
                                :placeholder="t('Meet.Equipements')"
                                :options="optionsUpdate"
                                @change="handleMultiChangeUpdate"
                                show-search
                                :option-filter-prop="'label'"
                            >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                        </a-select>

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-item  name="managers">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Managers') }}</span>
                        </template>
                        <a-select
                            v-model:value="form.managers"
                            mode="multiple"
                            label-in-value
                            :placeholder="t('Meet.selectManagers')"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetchingUpdate ? undefined : null"
                            :options="state.allManagersUpdate"
                            @search="fetchManagerUpdate"
                        >
                            <template v-if="state.fetchingUpdate" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="12">
                    <a-form-item  name="color">
                        <template #label>
                            <span class="label-form">{{ t('Meet.color') }}</span>
                        </template>
                        <input type="color" v-model="form.color" >
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="approval">
                        <template #label>
                            <span class="label-form">{{ t('Meet.approvalTxt') }}</span>
                        </template>
                        <a-switch v-model:checked="form.approval" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="12">
                    <a-form-item name="approval">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Active') }}</span>
                        </template>
                        <a-switch v-model:checked="form.active" />
                    </a-form-item>
                </a-col>
            </a-row>


           
            <a-row>
                <a-col :span="24">
                    <span class="label-form">{{ t('Meet.Photo') }}</span>

                    <a-upload
                        v-model:file-list="fileList"
                        list-type="picture-card"
                        :beforeUpload="beforeUpload"
                        @preview="handlePreview"

                        @remove="handleRemove"
                    >
                        <div v-if="fileList.length < 4">
                            <plus-outlined />
                            <div style="margin-top: 8px">{{ t('Meet.Upload') }}</div>
                        </div>
                    </a-upload>

                   
                    <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-col>
            </a-row>

           
        </a-form>


        <template #footer>
            <a-space>
                <a-button  type="primary" @click="update(form.id)">{{ t('Meet.Update') }}</a-button>
                <a-button type="primary" danger @click="cancelUpdate">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>

    </a-drawer>
    <!-- wd modal -->
    <a-modal v-model:open="openWdModal" @cancel="resetWDForm">

      <template #footer>
        <div class="btns-container">
            <a-button key="submit" type="primary"  @click="addWd"  v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">{{ t('Meet.Confirm') }}</a-button>
            <a-button key="back" @click="handleCancelWD"  v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">{{ t('Meet.Cancel') }}</a-button>
        </div>
       
      </template>

      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}"><span class="modal-title">{{ t('Meet.AddWorkingDays') }}</span></p>
      </template>

       <a-form  ref="formWdRef" :model="WdForm" :rules="WdRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

            <a-row class="wd-container">
                <a-col :span="4">
                    <span class="wd-title">{{t('Meet.Monday')}}</span>
                </a-col>
                <a-col :span="16">
                    <a-form-item   name="monday_time" >
                        <a-time-range-picker  v-model:value="monday_time" format="HH:mm" :bordered="false" :disabled="state.userRole === 'EMPLOYEE' || !activeMon" />
                    </a-form-item>
                </a-col>
                <a-col  :span="4">
                    <a-switch v-model:checked="activeMon" size="small" :disabled="state.userRole === 'EMPLOYEE'" /><span  :class="[locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt']" >{{t('Meet.Active')}}</span>
                </a-col>

            </a-row>


            <a-row class="wd-container">
                <a-col :span="4">
                    <span class="wd-title">{{t('Meet.Tuesday')}}</span>
                </a-col>
                <a-col :span="16">
                    <a-form-item   name="tuesday_time" >
                        <a-time-range-picker  v-model:value="tuesday_time" format="HH:mm" :bordered="false"  :disabled="state.userRole === 'EMPLOYEE'  || !activeTue " />
                    </a-form-item>

                </a-col>
                <a-col  :span="4">
                    <a-switch v-model:checked="activeTue"  :disabled="state.userRole === 'EMPLOYEE'" size="small" /> <span :class="[locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt']">{{t('Meet.Active')}}</span>
                </a-col>

            </a-row>

            <a-row class="wd-container">
                <a-col :span="4">
                    <span class="wd-title">{{t('Meet.Wednesday')}}</span>
                </a-col>
                <a-col :span="16">
                    <a-form-item   name="wednesday_time" >
                        <a-time-range-picker  v-model:value="wednesday_time" format="HH:mm" :bordered="false"   :disabled="state.userRole === 'EMPLOYEE' || !activeWed " />
                    </a-form-item>
                </a-col>
                <a-col  :span="4">
                    <a-switch v-model:checked="activeWed"  :disabled="state.userRole === 'EMPLOYEE'" size="small" /><span :class="[locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt']">{{t('Meet.Active')}}</span>
                </a-col>
            </a-row>

            <a-row class="wd-container">
                <a-col :span="4">
                    <span class="wd-title">{{t('Meet.Thursday')}}</span>
                </a-col>
                <a-col :span="16">
                    <a-form-item  name="thursday_time" >
                        <a-time-range-picker  v-model:value="thursday_time" format="HH:mm" :bordered="false"  :disabled="state.userRole === 'EMPLOYEE' || !activeThur " />
                    </a-form-item>
                </a-col>
                <a-col  :span="4">
                    <a-switch v-model:checked="activeThur"  :disabled="state.userRole === 'EMPLOYEE'" size="small" /><span :class="[locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt']">{{t('Meet.Active')}}</span>
                </a-col>
            </a-row>

            <a-row class="wd-container">
                <a-col :span="4">
                    <span class="wd-title">{{t('Meet.Friday')}}</span>
                </a-col>
                <a-col :span="16">
                    <a-form-item  name="friday_time" >
                        <a-time-range-picker v-model:value="friday_time" format="HH:mm" :bordered="false"  :disabled="state.userRole === 'EMPLOYEE' || !activeFri " />
                    </a-form-item>
                </a-col>
                <a-col  :span="4">
                    <a-switch v-model:checked="activeFri"  :disabled="state.userRole === 'EMPLOYEE'" size="small" /><span :class="[locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt']">{{t('Meet.Active')}}</span>
                </a-col>
            </a-row>

            <a-row class="wd-container">
                <a-col :span="4">
                    <span class="wd-title">{{t('Meet.Saturday')}}</span>
                </a-col>
                <a-col :span="16">
                    <a-form-item  name="saturday_time" >
                        <a-time-range-picker  v-model:value="saturday_time" format="HH:mm" :bordered="false" :disabled="state.userRole === 'EMPLOYEE' || !activeSat" />
                    </a-form-item>
                </a-col>
                <a-col  :span="4">
                    <a-switch v-model:checked="activeSat"  :disabled="state.userRole === 'EMPLOYEE'"  size="small" /><span :class="[locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt']">{{t('Meet.Active')}}</span>
                </a-col>
            </a-row>

            <a-row class="wd-container">
                <a-col :span="4">
                    <span class="wd-title">{{t('Meet.Sunday')}}</span>
                </a-col>
                <a-col :span="16">
                    <a-form-item  name="sunday_time" >

                        <a-time-range-picker v-model:value="sunday_time" format="HH:mm" :bordered="false"  :disabled="state.userRole === 'EMPLOYEE' || !activeSun " />
                    </a-form-item>
                </a-col>
                <a-col  :span="4">
                    <a-switch v-model:checked="activeSun" size="small"  :disabled="state.userRole === 'EMPLOYEE'" /><span :class="[locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt']">{{t('Meet.Active')}}</span>
                </a-col>
            </a-row> 

           
           
           
        
        </a-form>

    </a-modal>



</template>
<script lang="ts">

import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import { notification, UploadFile, UploadProps } from 'ant-design-vue';
import Swal from 'sweetalert2';
import {
  
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CalendarOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../components/Looder.vue';
import { equipementsService, roomLocationService, roomsService, userService } from '@/_services';
import dayjs , { Dayjs }from 'dayjs'; 

export default defineComponent({
    name: "roomsIndex",
    components: {
        Looder,
        SearchOutlined,
        DeleteOutlined,
        EyeOutlined,
        EditOutlined,
        CheckCircleOutlined,
        CloseCircleOutlined,
        PlusOutlined,
        CalendarOutlined,
        CheckCircleTwoTone,
        CloseCircleTwoTone,
      
    },
    setup() {
        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            locations: [] as any[],
            room_id:'',
            userRole:'',

            managers: [] as any[],
            fetching: false,
            allManagers: []  as any[],

            fetchingUpdate: false,
            allManagersUpdate: []  as any[],



        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:t('Meet.Name'),
                key:'name',
                dataIndex:'name',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                            if (input instanceof HTMLInputElement) {
                                searchInput.value = input;
                                searchInput.value.focus();
                            }
                        }, 100);
                    }
                },

            },
            {
                title:t('Meet.Capacity'),
                key:'capacity',
                dataIndex:'capacity'

            },
            {
                title:t('Meet.Active'),
                key:'active',
                dataIndex:'active'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];


        //form
        const addFormRef = ref();
        const formRef = ref();


        const form = reactive({
            id:'',
            name:'',
            location_id:'',
            capacity:'',
            status:'',
            location_name:'',
            photos:[] as string[],
            equipmenets: [] as any,
            equipmenets_ids:'',
            color:'',
            ad_address:'',
            ad_password:'',
            managers:[] as any[],
            approval:false,
            active:true,
        });

        const addForm = reactive({
            id:'',
            name:'',
            location_id:'',
            capacity:'',
            status:'',
            equipmenets: [] as any,
            equipmenets_ids:'',
            color:'',
            ad_address:'',
            ad_password:'',
            approval:false,
            active:true,

        });
        const visiblePass = ref<boolean>(false);

        const validEmail = (email: string) => {
            let re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        };


        const rules: Record<string, Rule[]> = {
            name: [{ required: true,message:t('Meet.RequiredField')}],
            ad_address: [
                { required: true,message:t('Meet.RequiredField')},
                {
                    validator: (_, value) => {
                        if (!value) {
                            return Promise.resolve();
                        }
                        if (validEmail(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(t('Meet.invalidEmail'));
                    }
                }

            ],
            ad_password:[{ required: true,message:t('Meet.RequiredField')}],
            capacity: [
                { required: true, message:  t('Meet.RequiredField') },
                { 
                validator: (_, value) => {
                    if (!isNaN(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject( t('Meet.numericField'));
                }
                }
            ],
            status: [{ required: true,message:t('Meet.RequiredField')}],
            location_id: [{ required: true,message:t('Meet.RequiredField')}],
            color: [{ required: true,message:t('Meet.RequiredField')}],


        };

        const updateRules: Record<string, Rule[]> = {
            name: [{ required: true,message:t('Meet.RequiredField')}],
            ad_address: [
                { required: true,message:t('Meet.RequiredField')},
                {
                    validator: (_, value) => {
                        if (!value) {
                            return Promise.resolve();
                        }
                        if (validEmail(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(t('Meet.invalidEmail'));
                    }
                }

            ],
            capacity: [
                { required: true, message:  t('Meet.RequiredField') },
                { 
                validator: (_, value) => {
                    if (!isNaN(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject( t('Meet.numericField'));
                }
                }
            ],
            status: [{ required: true,message:t('Meet.RequiredField')}],
            location_id: [{ required: true,message:t('Meet.RequiredField')}],
            color: [{ required: true,message:t('Meet.RequiredField')}],


        };

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    roomsService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                roomsService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any, pageSize:number) => {
            currentPage.value = newPage;
            perPage.value=pageSize;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    roomsService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
               


            }else{
                loading.value = true;
                roomsService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        //search 
        const filter=(key:string, value:string)=>{
            loading.value=true;
            currentPage.value=1;
            roomsService.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText) {
                filter(dataIndex,state.searchText);
            }
        };
            
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getAll();
        };


        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 20,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };


        const getLocation=()=>{
            roomLocationService.getAll().then((res)=>{
                state.locations=res.data;
            }).catch((error)=>{
                console.log(error);
            })
        }

        const getAll=()=>{
            loading.value=true;
            roomsService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.meta.total;
            }).catch((error)=>{
                console.log("error get rooms", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        // display drawer 
        const openDisplay = ref<boolean>(false);

        const showDisplayDrawer = (id: string) => {
            updateModalWidth();
            getById(id);
            openDisplay.value = true;
        };

        interface Equipment {
            id: string;
            name:string;
            qte:string;
            approved:string;
        }
        const equipmenets_array = ref<Equipment[]>([]); 

        const getById=(id:string)=>{
            loading.value=true;
            roomsService.show(id).then((res)=>{
                form.id=res.data.id;
                form.name=res.data.name;
                form.location_id=res.data.location_id;
                form.capacity=res.data.capacity;
                form.status=res.data.status;
                form.location_name=res.data.location.name;
                form.color=res.data.color;
                form.ad_address=res.data.ad_address;
                form.photos = res.data.pictures.map((picture: { url: string , id:string}) => picture.url);
                form.managers= res.data.users.map((user: { id: string; first_name: string; last_name: string }) => ({
                    label: `${user.first_name} ${user.last_name}`,
                    value: user.id
                }));
                form.approval= res.data.approved_by_default === 1;
                form.active = res.data.active === 1;

                


                fileList.value = res.data.pictures.map((picture: { url: string , id:number}) => ({
                    uid: picture.id,
                    url: picture.url,
                    name: picture.url.substring(picture.url.lastIndexOf('/') + 1),
                    status: 'done',
                }));

                // fileList.value = form.photos.map((photo) => ({
                //     uid: photo.id, 
                //     url: photo,
                //     name: photo.substring(photo.lastIndexOf('/') + 1),
                //     status: 'done',
                // }));

                // get working days:
                res.data.working_days.forEach((workingDay: any) => {
                    let startTime: string | undefined;
                    let endTime: string | undefined;
                    let today = dayjs().format('YYYY-MM-DD');

                    switch (workingDay.day) {
                        case 'Monday':
                          
                            startTime = `${today}T${workingDay.start}`;
                            endTime = `${today}T${workingDay.end}`;
                            monday_time.value = [
                                dayjs(startTime),
                                dayjs(endTime)
                            ];
                            activeMon.value = !!workingDay.active;
                            break;
                        case 'Tuesday':
                            startTime = `${today}T${workingDay.start}`;
                            endTime = `${today}T${workingDay.end}`;
                            tuesday_time.value = [
                                dayjs(startTime),
                                dayjs(endTime)
                            ];
                            activeTue.value = !!workingDay.active;
                            break;
                        case 'Wednesday':
                            startTime = `${today}T${workingDay.start}`;
                            endTime = `${today}T${workingDay.end}`;
                            wednesday_time.value = [
                                dayjs(startTime),
                                dayjs(endTime)
                            ];
                            activeWed.value = !!workingDay.active;
                            break;
                        case 'Thursday':
                            startTime = `${today}T${workingDay.start}`;
                            endTime = `${today}T${workingDay.end}`;
                            thursday_time.value = [
                                dayjs(startTime),
                                dayjs(endTime)
                            ];
                            activeThur.value = !!workingDay.active;
                            break;
                        case 'Friday':
                            startTime = `${today}T${workingDay.start}`;
                            endTime = `${today}T${workingDay.end}`;
                            friday_time.value = [
                                dayjs(startTime),
                                dayjs(endTime)
                            ];
                            activeFri.value = !!workingDay.active;
                            break;
                        case 'Saturday':
                            startTime = `${today}T${workingDay.start}`;
                            endTime = `${today}T${workingDay.end}`;
                            saturday_time.value = [
                                dayjs(startTime),
                                dayjs(endTime)
                            ];
                            activeSat.value = !!workingDay.active;
                            break;
                        case 'Sunday':
                            startTime = `${today}T${workingDay.start}`;
                            endTime = `${today}T${workingDay.end}`;
                            sunday_time.value = [
                                dayjs(startTime),
                                dayjs(endTime)
                            ];
                            activeSun.value = !!workingDay.active;
                            break;
                        default:
                            break;
                    }

                  









                });



                //getting equipments
                let itemsIds: any[] = [];

                equipmenets_array.value = res.data.equipments.map((item: any) => {
                    itemsIds.push(item.id); 

                    return {
                        id: item.id,
                        name: item.name,
                    };
                });

                form.equipmenets= itemsIds;





            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                loading.value=false;
            })
        }


        //update drawer
        const open = ref<boolean>(false);
        const showDrawer = (id:string) => {
            updateModalWidth();
            getEquipmentsUpdate();
            getLocation();
            getById(id);
            open.value = true;
        };
        const cancelUpdate=()=>{
            open.value=false;
        }

        const optionsUpdate=ref();
        
        const getEquipmentsUpdate=()=>{
            equipementsService.getAll().then((res)=>{
                optionsUpdate.value = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                }));
            }).catch((error)=>{
                console.log('error getting equipments', error);
            })
        }
        const handleMultiChangeUpdate = (value: any[]) => {
            const selectedIds = value.map(item => item);
            form.equipmenets_ids = selectedIds.join(',');
            console.log('selected ids', form.equipmenets_ids);
        };
        
        //add darwer 
        const openAdd = ref<boolean>(false);
        const showAddDrawer = () => {
            updateModalWidth();
            getEquipments();
            getLocation();
            openAdd.value = true;
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
            addForm.color="";
            addForm.equipmenets=[];

            state.managers=[];
            state.allManagers=[];
            state.fetching=false;

        }

        //multi file
        const previewVisible = ref(false);
        const previewImage = ref('');
        const previewTitle = ref('');
        const fileList = ref<UploadProps['fileList']>([]);
        const fileInfo = ref<UploadFile | null>(null);

        function getBase64(file: File) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        const handleCancel = () => {
            previewVisible.value = false;
            previewTitle.value = '';
        };

        const handlePreview = async (file: UploadFile) => {

            console.log('handle preview');

            if (!file.url && !file.preview && file.originFileObj) {
                file.preview = (await getBase64(file.originFileObj)) as string;
            }
            previewImage.value = file.url || file.preview || ''; // providing a default value for previewImage
            previewVisible.value = true;
            previewTitle.value = file.name || (file.url ? file.url.substring(file.url.lastIndexOf('/') + 1) : '');

           
        };

        const handleRemove = async (file: UploadFile) => {

            console.log('handle remove', file);

            if(file.status==='done'){
               deletePhoto(file.uid);
               console.log('delete photo')
            }


           
        };

        const beforeUpload = (file:any) => {
            uplaodPhoto(file);
            return false; // Prevent default upload behavior
            };


        const uplaodPhoto=(file:any)=>{
            roomsService.addPhotos(form.id, file).then((res)=>{
               showSuccessNotification(t('Meet.PhotoAddSuccess'));
            }).catch((error)=>{
                console.log(error);
            });
        }

        const deletePhoto=(id:string)=>{
            roomsService._deletePhoto(id).then((res)=>{
                showSuccessNotification(t('Meet.PhotodeleteSuccess'));
            }).catch((error)=>{
                console.log(error);
            });
        }

        //multiselect equipments
        const options=ref();
        
        const getEquipments=()=>{
            equipementsService.getAll().then((res)=>{
                options.value = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                }));
            }).catch((error)=>{
                console.log('error getting equipments', error);
            })
        }

        const handleMultiChange = (value: any[]) => {
            const selectedIds = value.map(item => item);
            addForm.equipmenets_ids = selectedIds.join(',');
        };

        //managers 
        let debounceTimer: ReturnType<typeof setTimeout> | null = null;
        const fetchManager = (value: any) => {

            state.allManagers=[];
            
            //disable fetching when the input is empty
            if(value===''){
                return;
            }

            state.fetching = true;

            //clear previous timer
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }


            //fetch managers 
            debounceTimer = setTimeout(() => {
                userService.filterUsers("first_name",value)
                .then((res) => {
                    const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
                        label: `${user.first_name} ${user.last_name}`,
                        value: user.id,
                        external:false,
                    }));

                    
                    state.allManagers = data;
                    console.log('users',state.allManagers);
                    
                    state.fetching = false;
                    console.log('state.managers : ', state.managers);
                })
                .catch((error) => {
                    console.log('error fetching managers', error);
                });
            }, 700);





           
             

        };

       


        const add=()=>{
            addFormRef.value.validate().then(()=>{
                let payload={
                    name:addForm.name,
                    capacity:addForm.capacity,
                    status:addForm.status,
                    location_id:addForm.location_id,
                    equipments:addForm.equipmenets_ids,
                    color:addForm.color,
                    ad_address:addForm.ad_address,
                    managers:state.managers.map((manager) => manager.value).join(','),
                    approved_by_default: addForm.approval ? 1:0,
                    ad_password:addForm.ad_password,
                    active:addForm.active ? 1:0,
                }
                console.log('payload',payload);
                loading.value=true;
                roomsService.create(payload).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.RooAddedSuccess'));
                }).catch((error)=>{
                    console.log('error adding room',error);
                    loading.value=false;
                }).finally(()=>{
                    resetFormAdd();
                    openAdd.value=false;
                    loading.value=false;
                });


                
            }).catch((error:any)=>{
                console.log(error);
            });
        }

        const deleteRoom=(id:string)=>{
            loading.value=true;
            roomsService.delete(id).then(()=>{
                getAll();
                showSuccessNotification(t('Meet.RooDeletedSuccess'));

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                loading.value=false;
                console.log('finish');
            })
        }

        //update managers
        let debounceTimerUpdate: ReturnType<typeof setTimeout> | null = null;

        const fetchManagerUpdate = (value: any) => {
            state.allManagersUpdate = [];

            // Disable fetching when the input is empty
            if (value === '') {
                return;
            }

            state.fetchingUpdate = true;

            // Clear previous timer
            if (debounceTimerUpdate) {
                clearTimeout(debounceTimerUpdate);
            }

            // Fetch managers with debounce
            debounceTimerUpdate = setTimeout(() => {
                userService.filterUsers("first_name", value)
                    .then((res) => {
                        const data = res.data.map((user: { first_name: any; last_name: any; id: any }) => ({
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id,
                            external: false
                        }));

                        state.allManagersUpdate = data;
                        console.log('users', state.allManagersUpdate);

                        state.fetchingUpdate = false;
                        console.log('state.managers : ', form.managers);
                    })
                    .catch((error) => {
                        console.log('error fetching managers', error);
                    });
            }, 700);
        };

        const update=(id:string)=>{
            formRef.value
            .validate()
            .then(()=>{
                let formData= new FormData();
                formData.append('location_id', form.location_id);
                formData.append('name', form.name);
                formData.append('capacity', form.capacity);
                formData.append('status', form.status);
                formData.append('equipments', form.equipmenets_ids);
                formData.append('color',form.color);
                formData.append('ad_address', form.ad_address);
                formData.append('ad_password', form.ad_password);

                const active = form.active ? 1:0;
                formData.append('active', active.toString());

                const approved = form.approval ? 1:0;
                formData.append('approved_by_default', approved.toString());
               
                formData.append('managers', form.managers.map((manager) => manager.value).join(','));
                
                
                loading.value=true;
                roomsService.update(id,formData).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.RooUpdatedSuccess'));
                }).catch((err) => {
                    console.log(err);
                    loading.value = false;
                }).finally(() => {
                    console.log('finish')
                    open.value=false;
                    loading.value = false;
                }); 
                



            }).catch((error:any)=>{
                console.log(error);
            })
        }




        const showDeletePopup = (id: string) => {
            Swal.fire({
                title: t('Meet.TitleDeleteRoo'),
                text: t('Meet.TextDeleteRoo'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Delete'),
                cancelButtonText: t('Meet.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteRoom(id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        //show multîple photos:
        const visible = ref(false);

        const getUserRole=()=>{
            let user_role = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).role : '';
            console.log('user role', user_role);
            state.userRole=user_role;
        }


        onMounted(() => {

            getUserRole();
            getAll();
        });

        //working days
        const openWdModal = ref<boolean>(false);

        const formWdRef = ref();

        const initialTimeRange = [dayjs().hour(9).minute(0), dayjs().hour(17).minute(0)];

        const monday_time = ref<Dayjs[]>([...initialTimeRange]);
        const tuesday_time = ref<Dayjs[]>([...initialTimeRange]);
        const wednesday_time = ref<Dayjs[]>([...initialTimeRange]);
        const thursday_time = ref<Dayjs[]>([...initialTimeRange]);
        const friday_time = ref<Dayjs[]>([...initialTimeRange]);
        const saturday_time = ref<Dayjs[]>([]);
        const sunday_time = ref<Dayjs[]>([]);

        const WdForm = reactive({
            id:'',
            monday_time:monday_time,
            tuesday_time:tuesday_time,
            wednesday_time:wednesday_time,
            thursday_time:thursday_time,
            friday_time:friday_time,
            saturday_time:saturday_time,
            sunday_time:sunday_time,
            
        });

      

     

       

        






        const WdRules: Record<string, Rule[]> = {
            monday_time: [{ required: true,message:t('Meet.RequiredField')}],
            tuesday_time: [{ required: true,message:t('Meet.RequiredField')}],
            wednesday_time: [{ required: true,message:t('Meet.RequiredField')}],
            thursday_time: [{ required: true,message:t('Meet.RequiredField')}],
            friday_time: [{ required: true,message:t('Meet.RequiredField')}],
        };


        const showWorkingDays=(id:string)=>{
            getById(id);
            state.room_id=id;
            openWdModal.value=true;
        }

        const activeMon = ref(true);
        const activeTue = ref(true);
        const activeWed = ref(true);
        const activeThur = ref(true);
        const activeFri = ref(true);
        const activeSat = ref(false);
        const activeSun = ref(false);


        const resetWDForm=()=>{
            formWdRef.value.resetFields();
        }

        const handleCancelWD = () => {
            openWdModal.value = false;
            resetWDForm();
        };

        const addWd = () => {
            formWdRef.value.validate().then(() => {
                let formData = new FormData();
                formData.append('room_id', state.room_id);

                let working_days: { day: string; start: string; end: string; active: number; }[] = [];

                // process Monday to Friday
                const days = [
                    { day: 'Monday', time: monday_time.value, active: activeMon.value },
                    { day: 'Tuesday', time: tuesday_time.value, active: activeTue.value },
                    { day: 'Wednesday', time: wednesday_time.value, active: activeWed.value },
                    { day: 'Thursday', time: thursday_time.value, active: activeThur.value },
                    { day: 'Friday', time: friday_time.value, active: activeFri.value },
                    { day: 'Saturday', time: saturday_time.value, active: activeSat.value },
                    { day: 'Sunday', time: sunday_time.value, active: activeSun.value },
                ];

                days.forEach(({ day, time, active }) => {
                    if (time && time.length > 0) {
                        let working_day = {
                            day: day,
                            start: time[0].format('HH:mm'),
                            end: time[1].format('HH:mm'),
                            active: active ? 1 : 0,
                        };
                        working_days.push(working_day);
                    }
                });

                formData.append('working_days', JSON.stringify(working_days));

                loading.value = true;

                roomsService.addWorkingDays(formData).then((res) => {
                    loading.value = false;
                    openWdModal.value = false;
                    showSuccessNotification(t('Meet.WorkingDaysUpdatedSuccess'));
                }).catch((error) => {
                    console.log('error adding wd', error);
                    loading.value = false;
                    openWdModal.value = false;
                });
            }).catch(() => {
                console.log('error validating form');
            });
        };


      










        return{
            updateRules,
            visiblePass,
            fetchManagerUpdate,
            fetchManager,
            modalWidth,
            t,
            textDirection,
            locale,
            loading,
            perPage,
            currentPage,
            selectPagination,
            handleSelectPaginationChange,
            handlePageChange,
            navigatePage,
            simpleImage,
            state,
            columns,
            showDeletePopup,
            addFormRef,
            addForm,
            rules,
            openAdd,
            showAddDrawer,
            resetFormAdd,
            previewVisible,
            previewImage,
            previewTitle,
            fileList,
            handleCancel,
            handlePreview,
            add,
            open,
            showDrawer,
            formRef,
            form,
            uplaodPhoto,
            openDisplay,
            showDisplayDrawer,
            cancelUpdate,
            visible,
            handleSearch,
            handleReset,
            update,
            beforeUpload,
            deletePhoto,
            openWdModal,
            formWdRef,
            WdForm,
            showWorkingDays,
            activeMon,
            activeTue,
            activeWed,
            activeThur,
            activeFri,
            activeSat,
            activeSun,
            WdRules,
            handleCancelWD,
            resetWDForm,
            addWd,
            monday_time,
            tuesday_time,
            wednesday_time,
            thursday_time,
            friday_time,
            saturday_time,
            sunday_time,
            options,
            handleMultiChange,
            equipmenets_array,
            optionsUpdate,
            handleMultiChangeUpdate,
            handleRemove,






            











        }
    }


})

</script>

<style scoped>

.breadcrumb-icon{
  cursor: pointer;
}

.add-btn {
    color: var(--primary-color, #171717);
    border: 2px solid var(--primary-color, #171717); 
    box-shadow: inset 0 0 0 0 var(--primary-color, #171717);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 var(--primary-color, #171717);
    border-color: var(--primary-color, #171717);
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}


.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);;
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);;
    border-color: var(--secondary-color, #fc8019);;
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 10px;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.wd-btn{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
    box-shadow: none;
}

.wd-btn:hover{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
}

.wd-title{
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
}

.range-picker{
    width: 100%;
}


.wd-container{
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
}

.modal-title{
    margin-bottom: 20px;
    font-size: 14px;
}


.btns-container{
    margin-top: 10px;
}

.switch-txt{
    margin-left: 5px;
}

.switch-txt-rtl{
    margin-right: 5px;
}

.ingredient-des{
    margin-bottom: 15px;
}

.table-container {
  overflow-x: auto;
}


/* CSS for RTL */
.is-rtl {
    direction:rtl;
}

.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

@media (min-width: 768px) {
   .add-btn,
   .modal-title{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-title,
    .modal-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}


.approve-tag-container{
   display: flex;
    justify-content: flex-end;
    align-items: center;
}






</style>
