<template>
    <Looder :is-loaded="!loading"></Looder>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Meet.Dashboard')}}
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{t('Meet.Meetings')}}</a-breadcrumb-item>
        <a-breadcrumb-item> {{t('Meet.InternalRequests')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <a-table :columns="columns" :data-source="state.data"  :pagination='false'>
          
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name' || column.key ==='user'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

                <template  v-if="column.key === 'type'">
                    <div class="search-btn-radio">
                        <a-radio-group  v-model:value="type" @change="selectedType" class="search-radio-grp">
                            <a-radio value="service" class="radio-item">{{t('Meet.Service')}}</a-radio>
                            <a-radio value="equipment" class="radio-item">{{t('Meet.Equipment')}}</a-radio>
                            <a-radio value="other" class="radio-item">{{t('Meet.Other')}}</a-radio>

                        </a-radio-group>  
                    </div>   
                </template>

                <template  v-if="column.key === 'rate'">
                    <div class="rate-container">
                        <a-rate v-model:value="rateValue" />
                    </div>
                   
                </template>

                
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>



</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import { notification, UploadFile, UploadProps } from 'ant-design-vue';
import Swal from 'sweetalert2';
import {
  
  SearchOutlined,
  EyeOutlined,
  UserOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined
  
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../../components/Looder.vue';
import { reservationsService, roomLocationService, roomsService } from '@/_services';
import dayjs , { Dayjs }from 'dayjs'; 

export default defineComponent({

    name: "reservationRequestsIndex",
    components: {
        Looder,
        SearchOutlined,
        // EyeOutlined,
        // UserOutlined,
        // CloseCircleOutlined,
        // CheckCircleOutlined
        
    },
    setup() {

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:t('Meet.Type'),
                key:'type',
                dataIndex:'type',
                customFilterDropdown: true,
                

            },
            {
                title:t('Meet.Quantity'),
                key:'quantity',
                dataIndex:'quantity'

            },
             {
                title:t('Meet.Description'),
                key:'description',
                dataIndex:'description'

            },
            {
                title:t('Meet.Approved'),
                key:'approved',
                dataIndex:'approved'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        return{
            locale,
            textDirection,
            simpleImage,
            loading,
            t,
            searchInput,
            state,
            navigatePage,
            columns,
            perPage,
            currentPage,
            selectPagination













        }
        
    },
})
</script>

<style scoped>


.breadcrumb-icon{
  cursor: pointer;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}


.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
}

.title-list{
    font-weight: 400 !important;
    color: black !important;
    margin-left:10px ;
}

.search-btn-radio{
    display: flex;
}

.search-radio-grp{
    display: flex;
    flex-direction: column !important;
}



</style>
