import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18c8fe54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = { class: "txt" }
const _hoisted_3 = { class: "date-container" }
const _hoisted_4 = { class: "type-container" }
const _hoisted_5 = { class: "calendar-container" }
const _hoisted_6 = {
  key: 0,
  class: "days-container"
}
const _hoisted_7 = { class: "date-container" }
const _hoisted_8 = { class: "type-container" }
const _hoisted_9 = { class: "calendar-container" }
const _hoisted_10 = { class: "date-container" }
const _hoisted_11 = { class: "type-container" }
const _hoisted_12 = { class: "calendar-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_QuestionCircleOutlined = _resolveComponent("QuestionCircleOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Meet.statistics')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_select, {
          value: _ctx.selectValue,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectValue) = $event)),
          "show-search": "",
          class: "select-calender",
          onChange: _ctx.handleChange
        }, {
          notFoundContent: _withCtx(() => [
            _createVNode(_component_a_empty, {
              description: _ctx.t('Meet.NoData'),
              image: _ctx.simpleImage
            }, null, 8, ["description", "image"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, {
              value: "daily",
              label: _ctx.t('Meet.daily')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.daily')), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_select_option, {
              value: "period",
              label: _ctx.t('Meet.Period')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Period')), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_select_option, {
              value: "monthly",
              label: _ctx.t('Meet.monthly')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.monthly')), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_select_option, {
              value: "annual",
              label: _ctx.t('Meet.annual')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.annual')), 1)
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["value", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.selectValue==='period')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_a_tooltip, null, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Meet.daysTooltipTxt')), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_QuestionCircleOutlined)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select, {
                value: _ctx.days,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.days) = $event)),
                options: _ctx.daysOptions,
                style: {"width":"120px"},
                onChange: _ctx.handleDaysChange
              }, null, 8, ["value", "options", "onChange"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectValue==='monthly')
          ? (_openBlock(), _createBlock(_component_a_date_picker, {
              key: 1,
              value: _ctx.monthlyPicker,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.monthlyPicker) = $event)),
              picker: "month",
              class: "calender-input",
              onChange: _ctx.handleMonthly
            }, null, 8, ["value", "onChange"]))
          : _createCommentVNode("", true),
        (_ctx.selectValue==='annual')
          ? (_openBlock(), _createBlock(_component_a_range_picker, {
              key: 2,
              value: _ctx.annualRange,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.annualRange) = $event)),
              picker: "year",
              class: "calender-input",
              onChange: _ctx.handleAnnualChange
            }, null, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_a_row, { class: "chart-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 24,
          class: "chart-wrapper"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_apexchart, {
              key: _ctx.chartKey,
              type: "line",
              height: "350",
              options: _ctx.chartOptions,
              series: _ctx.series
            }, null, 8, ["options", "series"])),
            (_ctx.loadingRes)
              ? (_openBlock(), _createBlock(_component_a_spin, {
                  key: 0,
                  size: "large",
                  class: "chart-loader"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_a_select, {
          value: _ctx.selectTop,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectTop) = $event)),
          "show-search": "",
          class: "select-calender",
          onChange: _ctx.handleChangeTop
        }, {
          notFoundContent: _withCtx(() => [
            _createVNode(_component_a_empty, {
              description: _ctx.t('Meet.NoData'),
              image: _ctx.simpleImage
            }, null, 8, ["description", "image"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, {
              value: "daily",
              label: _ctx.t('Meet.daily')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.daily')), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_select_option, {
              value: "monthly",
              label: _ctx.t('Meet.monthly')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.monthly')), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_select_option, {
              value: "annual",
              label: _ctx.t('Meet.annual')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.annual')), 1)
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["value", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.selectTop==='monthly')
          ? (_openBlock(), _createBlock(_component_a_date_picker, {
              key: 0,
              value: _ctx.monthlyPickerTop,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.monthlyPickerTop) = $event)),
              picker: "month",
              class: "calender-input",
              onChange: _ctx.handleMonthlyTop
            }, null, 8, ["value", "onChange"]))
          : _createCommentVNode("", true),
        (_ctx.selectTop==='annual')
          ? (_openBlock(), _createBlock(_component_a_range_picker, {
              key: 1,
              value: _ctx.annualRangeTop,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.annualRangeTop) = $event)),
              picker: "year",
              class: "calender-input",
              onChange: _ctx.handleAnnualChangeTop
            }, null, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_a_row, {
      class: "chart-container",
      gutter: 16
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 12 }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_apexchart, {
              key: _ctx.chartSerKey,
              type: "bar",
              height: "350",
              options: _ctx.chartServicesOptions,
              series: _ctx.servicesSeries
            }, null, 8, ["options", "series"])),
            (_ctx.loadingTopSer)
              ? (_openBlock(), _createBlock(_component_a_spin, {
                  key: 0,
                  size: "large",
                  class: "chart-loader"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 12 }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_apexchart, {
              key: _ctx.chartEqKey,
              type: "bar",
              height: "350",
              options: _ctx.chartEquipmentsOptions,
              series: _ctx.equipmentsSeries
            }, null, 8, ["options", "series"])),
            (_ctx.loadingTopEq)
              ? (_openBlock(), _createBlock(_component_a_spin, {
                  key: 0,
                  size: "large",
                  class: "chart-loader"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_a_select, {
          value: _ctx.selectOccupancy,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectOccupancy) = $event)),
          "show-search": "",
          class: "select-calender",
          onChange: _ctx.handleChangeOccupancy
        }, {
          notFoundContent: _withCtx(() => [
            _createVNode(_component_a_empty, {
              description: _ctx.t('Meet.NoData'),
              image: _ctx.simpleImage
            }, null, 8, ["description", "image"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, {
              value: "daily",
              label: _ctx.t('Meet.daily')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.daily')), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_select_option, {
              value: "monthly",
              label: _ctx.t('Meet.monthly')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.monthly')), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_select_option, {
              value: "annual",
              label: _ctx.t('Meet.annual')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.annual')), 1)
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["value", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        (_ctx.selectOccupancy==='monthly')
          ? (_openBlock(), _createBlock(_component_a_date_picker, {
              key: 0,
              value: _ctx.monthlyPickerOccupancy,
              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.monthlyPickerOccupancy) = $event)),
              picker: "month",
              class: "calender-input",
              onChange: _ctx.handleMonthlyOccupancy
            }, null, 8, ["value", "onChange"]))
          : _createCommentVNode("", true),
        (_ctx.selectOccupancy==='annual')
          ? (_openBlock(), _createBlock(_component_a_range_picker, {
              key: 1,
              value: _ctx.annualRangeOccupancy,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.annualRangeOccupancy) = $event)),
              picker: "year",
              class: "calender-input",
              onChange: _ctx.handleAnnualChangeOccupancy
            }, null, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_a_row, { class: "chart-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 24,
          class: "chart-wrapper"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_apexchart, {
              key: _ctx.chartOccupancyKey,
              type: "bar",
              height: "350",
              options: _ctx.occupancyChartOptions,
              series: _ctx.occupancySeries
            }, null, 8, ["options", "series"])),
            (_ctx.loadingOccupancy)
              ? (_openBlock(), _createBlock(_component_a_spin, {
                  key: 0,
                  size: "large",
                  class: "chart-loader"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}