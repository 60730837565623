<template>

  <div class="loader-container" v-if="!isLoaded">
    <div class="backdrop"></div>
      <div class="wrapper">
        <div class="circle black"></div>
        <div class="circle black"></div>
        <div class="circle black"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'LbrxLoaderSpinner',
  props: {
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
  mounted(){
    document.onreadystatechange=()=>{
      if(document.readyState== "complete"){
        this.isloaded=true;
      }
    }
  },
  setup() {
  
    return {
    };
  },
}
</script>

<style scoped>

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); 
}


.wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1; 
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;
}

.circle.black{
  background-color: #171717;

}

.circle.purple {
  background-color: #9b2180;
}

.circle.red {
  background-color: #dc3545;
}

.circle.orange {
  background-color: #ffc107;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: .2s
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

</style>