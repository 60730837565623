import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, createSlots as _createSlots, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e3e9073"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = { class: "txt" }
const _hoisted_3 = { class: "txt" }
const _hoisted_4 = {
  key: 0,
  class: "btn-container"
}
const _hoisted_5 = { class: "table-header" }
const _hoisted_6 = { class: "table-cell" }
const _hoisted_7 = { class: "tooltip-txt" }
const _hoisted_8 = { class: "tooltip-txt" }
const _hoisted_9 = { class: "tooltip-txt" }
const _hoisted_10 = { class: "tooltip-txt" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { style: {"padding":"8px"} }
const _hoisted_13 = { class: "pagination" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = { class: "label-form" }
const _hoisted_19 = { class: "label-form" }
const _hoisted_20 = { class: "label-form" }
const _hoisted_21 = { class: "label-form" }
const _hoisted_22 = { class: "label-form" }
const _hoisted_23 = { class: "label-form" }
const _hoisted_24 = { class: "label-form" }
const _hoisted_25 = { class: "info-txt" }
const _hoisted_26 = { class: "info-txt" }
const _hoisted_27 = { class: "info-title" }
const _hoisted_28 = { class: "info-txt" }
const _hoisted_29 = { class: "info-title" }
const _hoisted_30 = { class: "info-title" }
const _hoisted_31 = { class: "info-title" }
const _hoisted_32 = { class: "info-title" }
const _hoisted_33 = { class: "info-title" }
const _hoisted_34 = { class: "info-title" }
const _hoisted_35 = { class: "info-title" }
const _hoisted_36 = { class: "info-title" }
const _hoisted_37 = { style: {"display":"none"} }
const _hoisted_38 = { class: "info-title" }
const _hoisted_39 = { class: "label-form" }
const _hoisted_40 = { class: "label-form" }
const _hoisted_41 = { class: "label-form" }
const _hoisted_42 = { class: "label-form" }
const _hoisted_43 = { class: "label-form" }
const _hoisted_44 = { class: "label-form" }
const _hoisted_45 = { class: "label-form" }
const _hoisted_46 = { class: "label-form" }
const _hoisted_47 = { class: "label-form" }
const _hoisted_48 = { class: "label-form" }
const _hoisted_49 = { class: "label-form" }
const _hoisted_50 = { class: "label-form" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = { style: {"margin-top":"8px"} }
const _hoisted_53 = ["src"]
const _hoisted_54 = { class: "btns-container" }
const _hoisted_55 = { class: "modal-title" }
const _hoisted_56 = { class: "wd-title" }
const _hoisted_57 = { class: "wd-title" }
const _hoisted_58 = { class: "wd-title" }
const _hoisted_59 = { class: "wd-title" }
const _hoisted_60 = { class: "wd-title" }
const _hoisted_61 = { class: "wd-title" }
const _hoisted_62 = { class: "wd-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Looder = _resolveComponent("Looder")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_CalendarOutlined = _resolveComponent("CalendarOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_check_circle_two_tone = _resolveComponent("check-circle-two-tone")!
  const _component_close_circle_two_tone = _resolveComponent("close-circle-two-tone")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_check_circle_outlined = _resolveComponent("check-circle-outlined")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_time_range_picker = _resolveComponent("a-time-range-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Looder, {
      "is-loaded": !_ctx.loading
    }, null, 8, ["is-loaded"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Meet.Rooms')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('Meet.Rooms')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_a_button, {
            class: "add-btn",
            onClick: _ctx.showAddDrawer
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_table, {
      class: "table-container",
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Meet.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      headerCell: _withCtx(({ column }) => [
        _createElementVNode("div", _hoisted_5, _toDisplayString(column.title), 1)
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        _createElementVNode("div", _hoisted_6, [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Meet.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn tbl-btn",
                        onClick: () => _ctx.showDisplayDrawer(record.id)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
                    ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 0 }, {
                        title: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Meet.Edit')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            class: "tbl-btn",
                            type: "primary",
                            onClick: () => _ctx.showDrawer(record.id)
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_EditOutlined, { class: "tbl-icon" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
                    ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 1 }, {
                        title: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Meet.Delete')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            class: "tbl-btn",
                            type: "primary",
                            danger: "",
                            onClick: ($event: any) => (_ctx.showDeletePopup(record.id))
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_DeleteOutlined, { class: "tbl-icon" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Meet.WorkingDays')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        class: "wd-btn tbl-btn",
                        onClick: ($event: any) => (_ctx.showWorkingDays(record.id))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_CalendarOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key=='active')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.active === 1 )
                  ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                      key: 0,
                      "two-tone-color": "#52c41a"
                    }))
                  : (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                      key: 1,
                      "two-tone-color": "#ff4d4f"
                    }))
              ], 64))
            : _createCommentVNode("", true),
          (column.key!=='active')
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(record[column.dataIndex]), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
        _createElementVNode("div", _hoisted_12, [
          (column.key === 'name')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Search')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Reset')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_a_pagination, {
        current: _ctx.currentPage,
        "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
        pageSize: _ctx.perPage,
        "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
        total: _ctx.state.totalPages,
        "default-page-size": _ctx.perPage,
        showSizeChanger: false,
        onChange: _ctx.handlePageChange,
        class: "paginationArrows"
      }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
      (_ctx.state.totalPages>10)
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.selectPagination,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
            onChange: _ctx.handleSelectPaginationChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: "10" }, {
                default: _withCtx(() => [
                  _createTextVNode(" 10 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "20" }, {
                default: _withCtx(() => [
                  _createTextVNode(" 20 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "50" }, {
                default: _withCtx(() => [
                  _createTextVNode(" 50 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "100" }, {
                default: _withCtx(() => [
                  _createTextVNode(" 100 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
        }, _toDisplayString(_ctx.t('Meet.AddRoom')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Meet.Name')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.name,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.name) = $event)),
                          placeholder: _ctx.t('Meet.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "ad_address" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Meet.adAddress')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.ad_address,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addForm.ad_address) = $event)),
                          placeholder: _ctx.t('Meet.adAddress'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "ad_password" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Meet.passwordAd')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_password, {
                          value: _ctx.addForm.ad_password,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addForm.ad_password) = $event)),
                          visible: _ctx.visiblePass,
                          "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visiblePass) = $event)),
                          placeholder: _ctx.t('Meet.passwordAd'),
                          class: "input-form"
                        }, null, 8, ["value", "visible", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "location_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Meet.Location')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.addForm.location_id,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addForm.location_id) = $event)),
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.locations, (location) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                label: location.name,
                                key: location.id,
                                value: location.id,
                                selected: location.id === _ctx.addForm.location_id 
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(location.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["label", "value", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "status" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Meet.Status')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.addForm.status,
                          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addForm.status) = $event)),
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_select_option, {
                              value: "available",
                              label: _ctx.t('Meet.Available')
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Meet.Available')), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_a_select_option, {
                              value: "occupied",
                              label: _ctx.t('Meet.Occupied')
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Meet.Occupied')), 1)
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "capacity" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Meet.Capacity')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.capacity,
                          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addForm.capacity) = $event)),
                          placeholder: _ctx.t('Meet.Capacity')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "equipments" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Meet.Equipements')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.addForm.equipmenets,
                          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.addForm.equipmenets) = $event)),
                          mode: "multiple",
                          style: {"width":"100%"},
                          placeholder: _ctx.t('Meet.Equipements'),
                          options: _ctx.options,
                          onChange: _ctx.handleMultiChange,
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          _: 1
                        }, 8, ["value", "placeholder", "options", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "managers" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Meet.Managers')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.state.managers,
                          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.managers) = $event)),
                          mode: "multiple",
                          "label-in-value": "",
                          placeholder: _ctx.t('Meet.selectManagers'),
                          style: {"width":"100%"},
                          "filter-option": false,
                          "not-found-content": _ctx.state.fetching ? undefined : null,
                          options: _ctx.state.allManagers,
                          onSearch: _ctx.fetchManager
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetching)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "color" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Meet.color')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.addForm.color) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.addForm.color]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "approval" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Meet.approvalTxt')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          checked: _ctx.addForm.approval,
                          "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.addForm.approval) = $event))
                        }, null, 8, ["checked"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "approval" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Meet.Active')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          checked: _ctx.addForm.active,
                          "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.addForm.active) = $event))
                        }, null, 8, ["checked"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth
    }, {
      title: _withCtx(() => [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
                }, _toDisplayString(_ctx.t('Meet.RoomInformation')), 3)
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 12,
              class: "approve-tag-container"
            }, {
              default: _withCtx(() => [
                (_ctx.form.active === true)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      color: "green"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_check_circle_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('Meet.Active')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.form.active === false)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 1,
                      color: "red"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_CloseCircleOutlined)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('Meet.inactive')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t('Meet.Name')) + " :", 1),
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.form.name), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t('Meet.adAddress')) + " :", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.form.ad_address), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('Meet.Capacity')) + " :", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.form.capacity), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('Meet.Location')) + " :", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.form.location_name), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t('Meet.Status')) + " :", 1),
                  (_ctx.form.status==='available')
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 0,
                        color: "green"
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_check_circle_outlined)
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.t('Meet.Available')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.form.status==='occupied')
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 1,
                        color: "red"
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_CloseCircleOutlined)
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.t('Meet.Occupied')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('Meet.color')) + " :", 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "color",
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.color) = $event)),
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, _ctx.form.color]
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.t('Meet.approvalTxt')), 1),
                  (_ctx.form.approval === true)
                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                        key: 0,
                        "two-tone-color": "#52c41a"
                      }))
                    : (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                        key: 1,
                        "two-tone-color": "#ff4d4f"
                      }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('Meet.Equipements')) + " :", 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipmenets_array, (item) => {
          return (_openBlock(), _createElementBlock("ul", {
            key: item,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            _createElementVNode("li", null, _toDisplayString(item.name), 1)
          ], 2))
        }), 128)),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.t('Meet.Photo')) + " :", 1)
                ]),
                (_ctx.form.photos.length > 0)
                  ? (_openBlock(), _createBlock(_component_a_image, {
                      key: 0,
                      preview: { visible: false },
                      width: 200,
                      src: _ctx.form.photos[0],
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.visible = true))
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_37, [
                  _createVNode(_component_a_image_preview_group, {
                    preview: { visible: _ctx.visible, onVisibleChange: vis => (_ctx.visible = vis) }
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.photos, (photo, index) => {
                        return (_openBlock(), _createBlock(_component_a_image, {
                          key: index,
                          src: photo,
                          width: 200
                        }, null, 8, ["src"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["preview"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.t('Meet.Managers')) + " :", 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.managers, (item) => {
          return (_openBlock(), _createElementBlock("ul", {
            key: item,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            _createElementVNode("li", null, _toDisplayString(item.label), 1)
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["open", "placement", "width"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.open,
      "onUpdate:open": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.open) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      width: _ctx.modalWidth,
      placement: _ctx.locale === 'ar' ? 'left' : 'right'
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
        }, _toDisplayString(_ctx.t('Meet.UpdateRoo')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.update(_ctx.form.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Update')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.cancelUpdate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.updateRules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('Meet.Name')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.name,
                          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.name) = $event)),
                          placeholder: _ctx.t('Meet.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "ad_address" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.t('Meet.adAddress')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.ad_address,
                          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.ad_address) = $event)),
                          placeholder: _ctx.t('Meet.adAddress'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "ad_password" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.t('Meet.passwordAd')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_password, {
                          value: _ctx.form.ad_password,
                          "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.ad_password) = $event)),
                          visible: _ctx.visiblePass,
                          "onUpdate:visible": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.visiblePass) = $event)),
                          placeholder: _ctx.t('Meet.passwordAd'),
                          class: "input-form"
                        }, null, 8, ["value", "visible", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "location_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.t('Meet.Location')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.location_id,
                          "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form.location_id) = $event)),
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.locations, (location) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                label: location.name,
                                key: location.id,
                                value: location.id,
                                selected: location.id === _ctx.form.location_id 
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(location.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["label", "value", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "status" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.t('Meet.Status')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.status,
                          "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.form.status) = $event)),
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_select_option, {
                              value: "available",
                              label: _ctx.t('Meet.Available')
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Meet.Available')), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_a_select_option, {
                              value: "occupied",
                              label: _ctx.t('Meet.Occupied')
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Meet.Occupied')), 1)
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "capacity" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.t('Meet.Capacity')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.capacity,
                          "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.form.capacity) = $event)),
                          placeholder: _ctx.t('Meet.Capacity')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "equipments" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.t('Meet.Equipements')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.equipmenets,
                          "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.form.equipmenets) = $event)),
                          mode: "multiple",
                          style: {"width":"100%"},
                          placeholder: _ctx.t('Meet.Equipements'),
                          options: _ctx.optionsUpdate,
                          onChange: _ctx.handleMultiChangeUpdate,
                          "show-search": "",
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          _: 1
                        }, 8, ["value", "placeholder", "options", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "managers" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.t('Meet.Managers')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.managers,
                          "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.form.managers) = $event)),
                          mode: "multiple",
                          "label-in-value": "",
                          placeholder: _ctx.t('Meet.selectManagers'),
                          style: {"width":"100%"},
                          "filter-option": false,
                          "not-found-content": _ctx.state.fetchingUpdate ? undefined : null,
                          options: _ctx.state.allManagersUpdate,
                          onSearch: _ctx.fetchManagerUpdate
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetchingUpdate)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "color" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.t('Meet.color')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.form.color) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.form.color]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "approval" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.t('Meet.approvalTxt')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          checked: _ctx.form.approval,
                          "onUpdate:checked": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.form.approval) = $event))
                        }, null, 8, ["checked"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "approval" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.t('Meet.Active')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          checked: _ctx.form.active,
                          "onUpdate:checked": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.form.active) = $event))
                        }, null, 8, ["checked"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.t('Meet.Photo')), 1),
                    _createVNode(_component_a_upload, {
                      "file-list": _ctx.fileList,
                      "onUpdate:fileList": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.fileList) = $event)),
                      "list-type": "picture-card",
                      beforeUpload: _ctx.beforeUpload,
                      onPreview: _ctx.handlePreview,
                      onRemove: _ctx.handleRemove
                    }, {
                      default: _withCtx(() => [
                        (_ctx.fileList.length < 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                              _createVNode(_component_plus_outlined),
                              _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.t('Meet.Upload')), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["file-list", "beforeUpload", "onPreview", "onRemove"]),
                    _createVNode(_component_a_modal, {
                      open: _ctx.previewVisible,
                      title: _ctx.previewTitle,
                      footer: null,
                      onCancel: _ctx.handleCancel
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          alt: "example",
                          style: {"width":"100%"},
                          src: _ctx.previewImage
                        }, null, 8, _hoisted_53)
                      ]),
                      _: 1
                    }, 8, ["open", "title", "onCancel"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "width", "placement"]),
    _createVNode(_component_a_modal, {
      open: _ctx.openWdModal,
      "onUpdate:open": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.openWdModal) = $event)),
      onCancel: _ctx.resetWDForm
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_54, [
          (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: "submit",
                type: "primary",
                onClick: _ctx.addWd
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Meet.Confirm')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: "back",
                onClick: _ctx.handleCancelWD
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, [
          _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.t('Meet.AddWorkingDays')), 1)
        ], 2)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formWdRef",
          model: _ctx.WdForm,
          rules: _ctx.WdRules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { class: "wd-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.t('Meet.Monday')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "monday_time" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_time_range_picker, {
                          value: _ctx.monday_time,
                          "onUpdate:value": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.monday_time) = $event)),
                          format: "HH:mm",
                          bordered: false,
                          disabled: _ctx.state.userRole === 'EMPLOYEE' || !_ctx.activeMon
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.activeMon,
                      "onUpdate:checked": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.activeMon) = $event)),
                      size: "small",
                      disabled: _ctx.state.userRole === 'EMPLOYEE'
                    }, null, 8, ["checked", "disabled"]),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt'])
                    }, _toDisplayString(_ctx.t('Meet.Active')), 3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "wd-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.t('Meet.Tuesday')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "tuesday_time" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_time_range_picker, {
                          value: _ctx.tuesday_time,
                          "onUpdate:value": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.tuesday_time) = $event)),
                          format: "HH:mm",
                          bordered: false,
                          disabled: _ctx.state.userRole === 'EMPLOYEE'  || !_ctx.activeTue 
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.activeTue,
                      "onUpdate:checked": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.activeTue) = $event)),
                      disabled: _ctx.state.userRole === 'EMPLOYEE',
                      size: "small"
                    }, null, 8, ["checked", "disabled"]),
                    _createTextVNode(),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt'])
                    }, _toDisplayString(_ctx.t('Meet.Active')), 3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "wd-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.t('Meet.Wednesday')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "wednesday_time" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_time_range_picker, {
                          value: _ctx.wednesday_time,
                          "onUpdate:value": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.wednesday_time) = $event)),
                          format: "HH:mm",
                          bordered: false,
                          disabled: _ctx.state.userRole === 'EMPLOYEE' || !_ctx.activeWed 
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.activeWed,
                      "onUpdate:checked": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.activeWed) = $event)),
                      disabled: _ctx.state.userRole === 'EMPLOYEE',
                      size: "small"
                    }, null, 8, ["checked", "disabled"]),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt'])
                    }, _toDisplayString(_ctx.t('Meet.Active')), 3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "wd-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.t('Meet.Thursday')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "thursday_time" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_time_range_picker, {
                          value: _ctx.thursday_time,
                          "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.thursday_time) = $event)),
                          format: "HH:mm",
                          bordered: false,
                          disabled: _ctx.state.userRole === 'EMPLOYEE' || !_ctx.activeThur 
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.activeThur,
                      "onUpdate:checked": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.activeThur) = $event)),
                      disabled: _ctx.state.userRole === 'EMPLOYEE',
                      size: "small"
                    }, null, 8, ["checked", "disabled"]),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt'])
                    }, _toDisplayString(_ctx.t('Meet.Active')), 3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "wd-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.t('Meet.Friday')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "friday_time" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_time_range_picker, {
                          value: _ctx.friday_time,
                          "onUpdate:value": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.friday_time) = $event)),
                          format: "HH:mm",
                          bordered: false,
                          disabled: _ctx.state.userRole === 'EMPLOYEE' || !_ctx.activeFri 
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.activeFri,
                      "onUpdate:checked": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.activeFri) = $event)),
                      disabled: _ctx.state.userRole === 'EMPLOYEE',
                      size: "small"
                    }, null, 8, ["checked", "disabled"]),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt'])
                    }, _toDisplayString(_ctx.t('Meet.Active')), 3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "wd-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.t('Meet.Saturday')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "saturday_time" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_time_range_picker, {
                          value: _ctx.saturday_time,
                          "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.saturday_time) = $event)),
                          format: "HH:mm",
                          bordered: false,
                          disabled: _ctx.state.userRole === 'EMPLOYEE' || !_ctx.activeSat
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.activeSat,
                      "onUpdate:checked": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.activeSat) = $event)),
                      disabled: _ctx.state.userRole === 'EMPLOYEE',
                      size: "small"
                    }, null, 8, ["checked", "disabled"]),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt'])
                    }, _toDisplayString(_ctx.t('Meet.Active')), 3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "wd-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.t('Meet.Sunday')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "sunday_time" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_time_range_picker, {
                          value: _ctx.sunday_time,
                          "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.sunday_time) = $event)),
                          format: "HH:mm",
                          bordered: false,
                          disabled: _ctx.state.userRole === 'EMPLOYEE' || !_ctx.activeSun 
                        }, null, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.activeSun,
                      "onUpdate:checked": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.activeSun) = $event)),
                      size: "small",
                      disabled: _ctx.state.userRole === 'EMPLOYEE'
                    }, null, 8, ["checked", "disabled"]),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'switch-txt-rtl' : 'switch-txt'])
                    }, _toDisplayString(_ctx.t('Meet.Active')), 3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "onCancel"])
  ], 64))
}