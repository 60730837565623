<template>
    <div>
        <Loader :is-loaded="!loading" />
    </div>

   <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                <span class="txt">{{t('Meet.Dashboard')}}</span>
                
            </a-breadcrumb-item>
            <a-breadcrumb-item>
                <span class="txt">{{t('Meet.Meetings')}}</span>
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <a-row class="btn-container" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' " :gutter="16">

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }">
           <a-select 
                v-model:value="filtredRoom" 
                style="width: 100%"
                :placeholder="t('Meet.filterResTxt')"
                show-search
                :option-filter-prop="'label'"
                @change="getReservationsRoom"
                allowClear	
                
            >
                <template #notFoundContent>
                    <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                </template>
                <a-select-option v-for="room in state.rooms" :key="room.id" :value="room.id" :label="room.name">{{ room.name }}</a-select-option>
           </a-select>
        </a-col>
        <a-col class="add-btn-container" :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }">
            <a-button class="add-btn"  @click="showAddDrawer">
                {{ t('Meet.Add') }}
                <template #icon>
                    <ScheduleOutlined />
                </template>
            </a-button>
        </a-col>
       
        
    </a-row>

    <div>
        <FullCalendar  ref="calendarRef" :options="calendarOptions" >
            <template v-slot:eventContent='arg' >

                <div 
                    class="calendar-event-title"
                >
                    <span>{{ arg.event.title }}</span>
                </div>
                
            </template>
        </FullCalendar>
    </div>

    <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"
            @close="resetFormAdd"
        >

            <template #title>
                <p  class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.AddMeeting')}}</p>
            </template>

            <a-form ref="addFormRef" :model="addForm" :rules="formRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                <a-row>

                    <a-col :span="24">
                        <a-form-item  name="motif" >
                            <template #label>
                                <a-tooltip>
                                    <template #title>{{t('Meet.motifTxt')}}</template>
                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                </a-tooltip>
                                <span class="label-form">{{ t('Meet.Motif') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.motif" :placeholder="t('Meet.Motif')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                </a-row>

               
                <a-row :gutter="16">
                    <!-- <a-col :span="12">
                        <a-form-item  name="date">
                            <template #label>
                                <span class="label-form">{{ t('Meet.ReservationTime') }}</span>
                            </template>
                            <a-range-picker  
                                class="range-picker"
                                v-model:value="addForm.date" 
                                :disabled-date="disableDates"
                                :disabled-time="disabledRangeTime"
                                :show-time="{
                                    hideDisabledOptions: true,
                                    format: 'HH:mm' 
                                }"
                                format="YYYY-MM-DD HH:mm"
                            />
                        </a-form-item>
                    </a-col> -->
                    

                    <!-- <a-col :span="12">

                        <a-form-item  name="date">
                            <template #label>
                                <span class="label-form">{{ t('Meet.ReservationTime') }}</span>
                            </template>

                            <a-range-picker
                                :show-time ="{ hideDisabledOptions : true, format :'HH:mm'}"
                                format="YYYY-MM-DD HH:mm"
                                :value="hackValue || value"
                                :disabled-date="disabledDate"
                                :disabled-time="disabledRangeTime"
                                @change="onChange"
                                @openChange="onOpenChange"
                                @calendarChange="onCalendarChange"
                            />


                        </a-form-item>

                    </a-col> -->

                    
                    <a-col :span="24">

                        <a-form-item  name="room_id">
                            <template #label>
                                <span class="label-form">{{ t('Meet.Room') }}</span>
                            </template>

                            <a-select v-model:value="addForm.room_id" show-search :option-filter-prop="'label'"  @select="getSelectedRoom" :class="{'error-border': state.validationErrors.room_id}">

                                <template #notFoundContent>
                                    <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                                </template>

                                <a-select-option v-for="room in state.rooms" :label="room.name" :key="room.id" :value="room.id" :selected="room.id === addForm.room_id ">{{ room.name }}</a-select-option>
                            </a-select>
                            <div v-if="state.validationErrors.room_id" class="error-message">
                                {{ t('Meet.RequiredField')}}
                            </div>
                        </a-form-item>

                    </a-col>

                   

                    
                </a-row>

                <a-row>
                    <a-col :span="24">
                        
                        <div class="label-container">
                            <span class="required-icon">*</span>
                            <span class="label label-form">{{ t('Meet.ReservationTime') }}</span>
                        </div>
                       
                        
                        <a-row :gutter="16">
                            <a-col :span="8">
                                <a-form-item
                                    name="dateMeet"
                                >
                                    <a-locale-provider :locale="calendarLocale">
                                        <a-date-picker 
                                            id="dateMeet"
                                            v-model:value="addForm.dateMeet" 
                                            class="date-picker-date"
                                            :disabled-date="disableDates" 
                                            :disabled="!addForm.room_id"
                                            :class="{'error-border': state.validationErrors.dateMeet}"
                                        />
                                    </a-locale-provider>
                                    <div v-if="state.validationErrors.dateMeet" class="error-message">
                                        {{ t('Meet.RequiredField')}}
                                    </div>
                                </a-form-item>
                            </a-col>

                            <a-col :span="8">
                                <a-form-item
                                    name="startTime"
                                >
                                    <a-time-picker 
                                        id="sartTime"
                                        v-model:value="addForm.startTime"
                                        format="HH:mm" 
                                        class="date-picker-date" 
                                        :minute-step="5" 
                                        :showNow="false"
                                        :disabled-hours="disableWDdHours"
                                        :hide-disabled-options="true"
                                        :disabled="!addForm.dateMeet || !addForm.room_id"
                                        @change="handleStartTime"
                                        :class="{'error-border': state.validationErrors.startTime}"


                                    />
                                    <div v-if="state.validationErrors.startTime" class="error-message">
                                        {{ t('Meet.RequiredField')}}
                                    </div>
                                </a-form-item>
                            </a-col>

                            <a-col :span="8">
                                <a-form-item
                                    name="durationTime"
                                >
                                    <a-time-picker 
                                        id="duration"
                                        v-model:value="addForm.durationTime"
                                        format="HH:mm" 
                                        class="date-picker-date" 
                                        :minute-step="5" 
                                        placeholder="Select duration" 
                                        :disabled-hours="disableDurationHours"
                                        :disabled-minutes="disableDurationMinutes"
                                        :hide-disabled-options="true"
                                        :showNow="false"
                                        :disabled="!addForm.startTime"
                                        @select="handleDurationPicker"
                                        :class="{'error-border': state.validationErrors.durationTime}"

                                    />
                                    <div v-if="state.validationErrors.durationTime" class="error-message">
                                        {{ t('Meet.RequiredField')}}
                                    </div>
                                </a-form-item>

                            </a-col>
                            
                        </a-row>
                        
                        
                    </a-col>

                </a-row>

                <a-row :gutter="16">
                    <a-col :span="24">

                        <a-form-item  name="participants">
                            <template #label>
                                <span class="label-form">{{ t('Meet.Participants') }}</span>
                            </template>

                            <a-select
                                id="participants"
                                v-model:value="state.value"
                                mode="multiple"
                                label-in-value
                                :placeholder="t('Meet.selectParticipants')"
                                style="width: 100%"
                                :filter-option="false"
                                :not-found-content="state.fetching ? undefined : null"
                                :options="state.users"
                                @search="fetchUser"
                                @change="handleUsersSelect"
                                :class="{'error-border': state.validationErrors.participants}"
                            >
                                <template v-if="state.fetching" #notFoundContent>
                                    <a-spin size="small" />
                                </template>
                            </a-select>

                            <div v-if="state.validationErrors.participants" class="error-message">
                                {{ t('Meet.RequiredField')}}
                            </div>
                           
                            
                        </a-form-item>  

                    </a-col>

                    <!-- <a-col :span="8" class="invite-users-container">
                        <a-button class="add-btn" @click="openInviteUsers">
                            {{ t('Meet.InviteParticipant') }}
                        </a-button>
                    </a-col> -->
                </a-row>

                <a-row>
                    <a-col :span="24">
                        <a-form-item   name="notifMail" >
                            <template #label>
                                <span class="label-form">{{ t('Meet.notifMailContent') }}</span>
                            </template>
                            <a-textarea v-model:value="addForm.notifMail" :rows="4" :placeholder="t('Meet.notifMailContent')" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row class="periodicty-container">

                    <a-col :span="24">
                        <div class="label-container check-container">
                            <a-switch v-model:checked="checkedPeriodicity" class="check-btn" />

                            <span class="label label-form">{{ t('Meet.periodicity') }}</span>
                        </div>

                        <a-row v-if="checkedPeriodicity === true">
                            <a-col :span="9">
                                <a-radio-group v-model:value="periodicity">

                                    <a-radio :style="radioStyle" value="daily">{{ t('Meet.Daily') }}</a-radio>
                                    <a-radio :style="radioStyle" value="weekly">{{ t('Meet.weekly') }}</a-radio>
                                    <a-radio :style="radioStyle" value="monthly">{{ t('Meet.monthly') }}</a-radio>
                                    <a-radio :style="radioStyle" value="yearly">{{ t('Meet.annual') }}</a-radio>

                                </a-radio-group>
                            </a-col>

                            <a-col :span="15">
                            

                                <div v-if="periodicity ===  'daily' ">
                                    <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConf" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday" >{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday">{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday">{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday">{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday">{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday">{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Sunday">{{ t('Meet.Sunday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config">

                                        <template v-if="weeklyConf.length === 7">
                                            <span class="periodicty-label"> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                                        </template>

                                        <template v-else>
                                            <span class="periodicty-label"> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConf" :key="day">
                                                <span>{{translateDay(day)}} , </span>
                                            </template>
                                        </template>
    
                                        <span class="periodicty-label"> {{t('Meet.until')}}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                            <a-date-picker
                                                id="periodEnDate" 
                                                v-model:value="addForm.periodEndDate" 
                                                class="period-date"
                                                :disabled-date="disablePeriodDates" 
                                                :disabled="weeklyConf.length===0"
                                                :style="{'border': state.validationErrors.periodEndDate ? '2px solid red' : ''}"
                                            />
                                            <div v-if="state.validationErrors.periodEndDate" class="error-container error-message">
                                                {{ t('Meet.RequiredField')}}
                                            </div>
                                        </a-locale-provider>

                                    </div>
                                </div>

                                <div v-if="periodicity ===  'weekly' ">
                                    <div>
                                        <a-tooltip>
                                            <template #title>{{t('Meet.weeklyTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>
                                        <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="addForm.periodicity" class="periodicity-input" />
                                        <span class="periodicty-label">{{ t('Meet.week') }}</span>
                                    </div>
                                   
                                    <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConf" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday"  :disabled="!addForm.periodicity" >{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday" :disabled="!addForm.periodicity" >{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday" :disabled="!addForm.periodicity" >{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday" :disabled="!addForm.periodicity" >{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday" :disabled="!addForm.periodicity" >{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday" :disabled="!addForm.periodicity">{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Sunday" :disabled="!addForm.periodicity">{{ t('Meet.Sunday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config">

                                        <template v-if="weeklyConf.length === 7">
                                            <span class="periodicty-label" > {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}  </span>
                                        </template>

                                        <template v-else>
                                            <span class="periodicty-label"> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConf" :key="day">
                                                <span>{{ translateDay(day) }}  , </span>
                                            </template>
                                        </template>
    
                                        <span class="periodicty-label"> {{t('Meet.until')}}</span>
                                        
                                        <a-form-item  name="periodEndDate">
                                            <a-locale-provider :locale="calendarLocale">
                                                <a-date-picker 
                                                    id="periodEnDate" 
                                                    v-model:value="addForm.periodEndDate" 
                                                    class="period-date"
                                                    :disabled-date="disablePeriodDates" 
                                                    :disabled="weeklyConf.length===0"
                                                   
                                                    :class="{'error-border': state.validationErrors.periodEndDate}" 
                                                />
                                                <div v-if="state.validationErrors.periodEndDate" class="error-container error-message">
                                                    {{ t('Meet.RequiredField')}}
                                                </div>
                                            </a-locale-provider>
                                        </a-form-item>

                                       

                                    </div>

                                </div>

                                <div v-if="periodicity ===  'monthly' ">

                                    <div class="display-container">
                                        <a-tooltip>
                                            <template #title>{{t('Meet.monthlyTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>
                                        <!-- <span class="periodicty-label">{{ t('Meet.Day') }}</span>
                                        <a-input v-model:value="addForm.dayAnnual" class="periodicity-input" /> -->
                                        <span class="periodicty-label">{{ t('Meet.ofEvery') }}</span>
                                        <a-input v-model:value="addForm.periodicity" class="periodicity-input" />
                                        <span class="periodicty-label">{{ t('Meet.Month') }}</span>
                                    </div>

                                    <div class="period-container">
                                        <span class="periodicty-label"> {{t('Meet.until')}}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                        <a-date-picker 
                                            id="periodEnDate" 
                                            v-model:value="addForm.periodEndDate" 
                                            class="period-date"
                                            :disabled-date="disablePeriodDates" 
                                            :disabled="!addForm.periodicity"
                                            :class="{'error-border': state.validationErrors.periodEndDate}" 
                                        />
                                        <div v-if="state.validationErrors.periodEndDate" class="error-container error-message">
                                            {{ t('Meet.RequiredField')}}
                                        </div>
                                        </a-locale-provider>
                                    </div>

                                    <!-- <div>
                                        <a-tooltip>
                                            <template #title>{{t('Meet.monthlyTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>
                                        <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="addForm.periodicity" class="periodicity-input" />
                                        <span class="periodicty-label">{{ t('Meet.month') }}</span>
                                        
                                    </div> 

                                    <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConf" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday">{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday">{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday">{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday">{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday">{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday">{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Sunday">{{ t('Meet.Sunday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config">
                                        
                                        <template v-if="weeklyConf.length === 7">
                                            <span> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                                        </template>

                                        <template v-else>
                                            <span> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConf" :key="day">
                                                <span>{{day}} , </span>
                                            </template>
                                        </template>
    
                                        <span> {{t('Meet.until')}}</span>
                                        <a-date-picker 
                                            v-model:value="addForm.periodEndDate" 
                                            class="period-date"
                                            :disabled-date="disableDates" 
                                            :bordered="false" 
                                        />

                                    </div> -->

                                    

                                </div>

                                <div v-if="periodicity === 'yearly'">

                                    <div>

                                        <a-tooltip>
                                            <template #title>{{t('Meet.annualTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>

                                        <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="addForm.periodicity" class="periodicity-input" />
                                        <span class="periodicty-label">{{ t('Meet.year') }}</span>

                                    </div>

                                    <!-- <div class="period-container display">
                                        <span class="periodicty-label">{{ t('Meet.On') }}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                            <a-date-picker v-model:value="monthperiod" picker="month" format="MM" class="month-input" :disabled="!addForm.periodicity" @change="getSelectedMonth" />
                                        </a-locale-provider>
                                        
                                        <a-input-number v-model:value="addForm.dayAnnual" :min="1" :max="maxDays"  :disabled="!monthperiod" class="periodicity-input" />
                                    </div> -->

                                    <div class="display">
                                        <span class="periodicty-label "> {{t('Meet.until')}}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                        <a-date-picker 
                                            id="periodEnDate" 
                                            v-model:value="addForm.periodEndDate" 
                                            class="period-date"
                                            :disabled-date="disablePeriodDates"
                                            :class="{'error-border': state.validationErrors.periodEndDate}"  
                                        />
                                        <div v-if="state.validationErrors.periodEndDate" class="error-container error-message">
                                             {{ t('Meet.RequiredField')}}
                                        </div>
                                        </a-locale-provider>
                                    </div>

                                    <!-- <div>
                                        <a-tooltip>
                                            <template #title>{{t('Meet.annualTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>
                                        <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="addForm.periodicity" class="periodicity-input" />
                                        <span class="periodicty-label">{{ t('Meet.year') }}</span>


                                    </div>

                                    <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConf" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday">{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday">{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday">{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday">{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday">{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday">{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Sunday">{{ t('Meet.Sunday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config">
                                        
                                        <template v-if="weeklyConf.length === 7">
                                            <span> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                                        </template>

                                        <template v-else>
                                            <span> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConf" :key="day">
                                                <span>{{day}} , </span>
                                            </template>
                                        </template>
    
                                        <span> {{t('Meet.until')}}</span>
                                        <a-date-picker 
                                            v-model:value="addForm.periodEndDate" 
                                            class="period-date"
                                            :disabled-date="disableDates" 
                                            :bordered="false" 
                                        />

                                    </div> -->

                                </div>

                            </a-col>
                        </a-row>
                    </a-col>

                </a-row>

                <a-row v-if="checkedPeriodicity === true ">
                    <a-col :span="5">
                        <button 
                            class="availibility-btn" 
                            @click="checkAvailability()"
                        >
                            {{t('Meet.CheckAvailibility')}}
                        </button>

                    </a-col>
                    <a-col :span="19">

                        <template  v-if="state.beginCheck  === true">
                            <span v-if="state.periodical===false" class="periodical-error">
                                {{t('Meet.periodicalError')}},
                                <span class="discover-title" @click="openDiscover">{{t('Meet.discover')}}</span>
                            </span>
                            <span class="periodical-success"  v-if="state.periodical===true && state.displaySucees === true ">
                                <check-circle-two-tone two-tone-color="#52c41a" />
                                {{t('Meet.periodicalSuccess')}}
                            </span>
                        </template>

                    </a-col>

                </a-row>

                <a-row :gutter="16" v-if="openSlots && checkedPeriodicity === true  && state.periodical===false">
                    <!--
                    {{addForm.AllSlots}}
                    <a-descriptions v-for="slot in addForm.AllSlots" :key="slot"  bordered size="small" class="mr" :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']">
                        <a-descriptions-item  :label="t('Meet.Date')" :span="3">
                            <span>{{slot.date}}</span>
                        </a-descriptions-item>

                        <a-descriptions-item :label="t('Meet.Available')">
                            <span v-if="slot.availability[0].active === 1">
                                <check-circle-two-tone two-tone-color="#52c41a" />
                            </span>
                            <span v-if="slot.availability[0].active === 0">
                                <close-circle-two-tone two-tone-color="#ff4d4f"/>
                            </span>
                        </a-descriptions-item>
                    </a-descriptions>
                    -->


                    <a-descriptions 
                        v-for="(slot, index) in addForm.AllSlots" 
                        :key="index" 
                        bordered 
                        size="small" 
                        class="mr" 
                        :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']"
                        >
                            
                        <template v-for="(availability, idx) in slot.availability" :key="idx">
                            <a-descriptions-item :label="t('Meet.Date')" :span="3">
                                <span>{{ slot.date }} - {{availability.slot}}</span>
                            </a-descriptions-item>
                            <a-descriptions-item :label="t('Meet.Available')">
                            <span v-if="availability.active === 1">
                                <check-circle-two-tone two-tone-color="#52c41a" />
                            </span>
                            <span v-if="availability.active === 0">
                                <close-circle-two-tone two-tone-color="#ff4d4f" />
                            </span>
                            </a-descriptions-item>
                        </template>
                    </a-descriptions>

                </a-row>

               
                <!-- equipments -->
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item  name="equipmenets">
                            <template #label>
                                <span class="label-form">{{ t('Meet.Equipements') }}</span>
                            </template>

                            <a-select
                                v-model:value="addForm.equipmenets"
                                mode="multiple"
                                style="width: 100%"
                                :placeholder="t('Meet.Equipements')"
                                :options="options"
                                @change="handleMultiChange"
                                show-search
                                :option-filter-prop="'label'"
                            >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                        </a-select>

                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row v-for="item in state.qtArray" :key="item" :gutter="16">

                    <a-col :span="12">
                        <span class="label-form">{{item.item_name}}</span>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item has-feedback  :name="'item' + item.item_id" :validate-status="getValidatingStatusQT(item.item_id,'item')" :help="getValidatingHelpQT(item.item_id,'item')">
                            <a-input :id="'item' + item.item_id" :placeholder="t('Meet.Quantity')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                </a-row>


                <!--services-->

                <a-row :gutter="16" >
                    <a-col :span="24">
                        <a-form-item  name="services">
                            <template #label>
                                <span class="label-form">{{ t('Meet.Services') }}</span>
                            </template>

                            <a-select
                                v-model:value="addForm.services"
                                mode="multiple"
                                style="width: 100%"
                                :placeholder="t('Meet.Services')"
                                :options="optionServices"
                                @change="handleMultiChangeServices"
                                show-search
                                :option-filter-prop="'label'"
                            >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                        </a-select>

                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row v-for="item in state.qtsArray" :key="item" :gutter="16">

                    <a-col :span="12">
                        <span class="label-form">{{item.item_name}}</span>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item has-feedback  :name="'itemS' + item.item_id" :validate-status="getValidatingStatusQT(item.item_id,'itemS')" :help="getValidatingHelpQT(item.item_id,'itemS')">
                            <a-input :id="'itemS' + item.item_id" :placeholder="t('Meet.Quantity')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                </a-row>


        
            </a-form>
            <template #footer>
            <a-space>
                <a-button :disabled="checkedPeriodicity && !state.periodical"   type="primary" @click="addReservation">{{ t('Meet.Add') }}</a-button>
                <a-button type="primary" danger @click="resetFormAdd">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
            </template>


        </a-drawer>
    <!-- add drawer -->

    <!--- display drawer -->
        <a-drawer
            v-model:open="openDisplay"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"
        >

            <template #title>
                <p  :class="{ 'is-rtl': textDirection==='rtl'}">
                    <a-row>
                        <a-col :span="12">
                            <span class="drawer-title">{{t('Meet.MeetingInformation')}}</span>
                        </a-col>

                        <a-col :span="12" class="approve-tag-container">   
                            <a-tag v-if="form.approved ===true " color="green" >
                                <template #icon>
                                    <check-circle-outlined />
                                </template>
                                {{t('Meet.Approved')}}
                            </a-tag>

                            <a-tag v-if="form.approved ===false" color="red" >
                                <template #icon>
                                    <CloseCircleOutlined />
                                </template>
                                {{t('Meet.Disapproved')}}
                            </a-tag>
                            
                        </a-col>
                    </a-row>

                </p>
            </template>

            <!-- periodical -->
            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}" v-if="form.isPeriodic === true">
                <a-col class="periodicity-display-container" :span="24">
                    
                    <span class="info-title">{{ t('Meet.periodicity') }} :</span>
                    
                    <div v-if="periodicity === 'daily' ">
                        <span class="mr-l info-txt">
                            {{ t(`Meet.${periodicity}`) }}
                        </span>
                        <span  v-if="weeklyConfUpdate.length === 7" class=" info-txt"> {{t('Meet.everyday')}}</span>
                        <span v-else>
                            <span class="info-txt" :class="[locale === 'ar' ? 'ml' : 'ml']"> {{ t('Meet.every') }} </span>
                            <template v-for="day in weeklyConfUpdate" :key="day">
                                <span>{{translateDay(day)}} , </span>
                            </template>
                        </span>
                        <span class="info-txt"> {{t('Meet.until')}} {{form.periodEndDateFormatted}}</span>
                    </div>

                    <div v-if="periodicity === 'weekly' ">
                        <span class="periodicty-label mr-l info-txt">
                            {{ t(`Meet.${periodicity}`) }}
                        </span>
                        <span  v-if="weeklyConf.length === 7" class="periodicty-label info-txt number"> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                        <span v-else>
                        <span class="periodicty-label info-txt"> {{ t('Meet.MeetingEvery') }} </span>
                            <template v-for="day in weeklyConf" :key="day">
                                <span>{{translateDay(day)}} , </span>
                            </template>
                        </span>
                        <span class="periodicty-label"> {{t('Meet.until')}}  {{form.periodEndDateFormatted}}</span>
                    </div>

                    <div  v-if="periodicity ===  'monthly' ">
                        <span class="periodicty-label mr-l info-txt">
                            {{ t(`Meet.${periodicity}`) }}
                        </span>                        <span class="periodicty-label info-txt">{{ t('Meet.Meeting') }} {{ t('Meet.every') }}</span>
                        <span class="periodicty-label info-txt number">{{form.periodicity}} {{ t('Meet.Month') }}</span>
                        <span class="periodicty-label info-txt"> {{t('Meet.until')}} {{form.periodEndDateFormatted}}</span>

                    </div>

                    <div v-if="periodicity ===  'yearly' ">
                        <span class="periodicty-label mr-l info-txt">
                            {{ t(`Meet.${periodicity}`) }}
                        </span>                        <span class="periodicty-label info-txt">{{ t('Meet.Meeting') }} {{ t('Meet.every') }}</span>
                        <span class="periodicty-label info-txt number">{{form.periodicity}} {{ t('Meet.year') }}</span>
                        <span class="periodicty-label info-txt"> {{t('Meet.until')}} {{form.periodEndDateFormatted}}</span>
                    </div>

                </a-col>
            </a-row>

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Meet.Motif') }} :</span>
                        <span class="info-txt">{{form.motif}} </span>
                    </p>
                </a-col>
            </a-row>

           

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Meet.Date') }} :</span>{{formatDisplatyDate(form.start)}}</p>
                </a-col>
                <a-col :span="12">
                    <p>
                        <span class="info-title info-txt"  :class="[locale === 'ar' ? 'mr' : 'ml']">{{ t('Meet.from') }} </span> <span class="start-date info-txt">{{extractTime(form.start)}}</span>  
                        <span class="info-title info-txt" :class="[locale === 'ar' ? 'mr' : 'ml']">{{ t('Meet.to') }} </span> <span class="info-txt">{{extractTime(form.end)}} </span>
                    </p>
                </a-col>
                
            </a-row>

         
            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Meet.Room') }} :</span>
                        <span class="info-txt">{{form.room_name}}</span>
                        
                    </p>
                </a-col>

                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Meet.CreatedBy') }} :</span>
                        <span class="info-txt">{{form.created_by_name}}</span>
                    
                    </p>

                </a-col>
            </a-row>

           

            <a-row  :class="{ 'is-rtl': textDirection==='rtl'}" v-if="particpants_array.length !== 0 || external_participants.length !== 0">
                <a-col :span="24">                    
                    <p><span class="info-title">{{ t('Meet.Participants') }} :</span></p>
                </a-col>
            </a-row>

            <a-row v-for="item in particpants_array" :key="item"  :class="[locale === 'ar' ? 'list-item-container-rtl' : 'list-item-container']" >
                <a-col flex="25px">
                    <a-avatar style="color: #f56a00; background-color: #fde3cf">
                       {{getFirstLetter(item.name)}}
                    </a-avatar>
                </a-col>
                <a-col flex="auto">
                    <p :class="[locale === 'ar' ? 'title-list-rtl' : 'title-list']">
                        <span class="info-txt">
                           {{ item.name }}
                        </span>
                    </p>
                </a-col>
            </a-row>

            <a-row v-for="item in external_participants" :key="item"  :class="[locale === 'ar' ? 'list-item-container-rtl' : 'list-item-container']" >
                <a-col flex="50px">
                    
                    <a-avatar  >
                        <template #icon><UserOutlined /></template>
                    </a-avatar>
                  
                </a-col>
                <a-col flex="auto">
                    <p :class="[locale === 'ar' ? 'title-list-rtl' : 'title-list']">
                        <span class="info-txt">{{ item.email }}</span>
                    </p>
                </a-col>
            </a-row>

           <!-- <a-list item-layout="horizontal"  :data-source="particpants_array" :bordered=false>
                <template #renderItem="{ item }">
                    <a-list-item>
                        <a-list-item-meta  class="list-item-container">
                            <template #title>
                                <p  class="title-list">{{ item.name }}</p>
                            </template>
                            <template #avatar>
                                 <a-avatar v-if="item.photo!==null" :src="item.photo"   /> 

                                <a-avatar>
                                    <template #icon><UserOutlined /></template>
                                </a-avatar>
                            </template>
                        </a-list-item-meta>
                    </a-list-item>
                </template>
            </a-list> -->

            
            
            <a-row :class="{ 'is-rtl': textDirection==='rtl'}"  v-if="equipmenets_array.length !== 0">
                <a-col :span="24">                    
                    <p><span  class="info-title">{{ t('Meet.Equipements') }} :</span></p>
                </a-col>
            </a-row>

            <template v-for="item in equipmenets_array " :key="item">
                <a-descriptions bordered size="small" :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']">
                    <a-descriptions-item :label="t('Meet.Name')" :span="3">
                        <span class="info-txt">{{item.name}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Quantity')" >
                        <span class="info-txt">{{item.qte}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Approved')" >

                        <span v-if="item.approved===1 || item.approved===true">
                          <check-circle-two-tone two-tone-color="#52c41a" />
                        </span>
                        <span  v-if="item.approved===0 || item.approved===false">
                          <close-circle-two-tone two-tone-color="#ff4d4f"/>
                        </span>
                    </a-descriptions-item>
                </a-descriptions>
            </template>

            <a-row :class="{ 'is-rtl': textDirection==='rtl'}" v-if="services_array.length !== 0">
                <a-col :span="24">                    
                    <p><span class="info-title">{{ t('Meet.Services') }} :</span></p>
                </a-col>
            </a-row>

            <template v-for="item in services_array " :key="item" >
                <a-descriptions bordered size="small" :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']" >
                    <a-descriptions-item :label="t('Meet.Name')" :span="3">
                        <span class="info-txt">{{item.name}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Quantity')" >
                        <span class="info-txt">{{item.qte}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Approved')" >

                        <span v-if="item.approved===1 || item.approved===true">
                          <check-circle-two-tone two-tone-color="#52c41a" />
                        </span>
                        <span  v-if="item.approved===0 || item.approved===false">
                          <close-circle-two-tone two-tone-color="#ff4d4f"/>
                        </span>
                    </a-descriptions-item>
                </a-descriptions>
            </template>
           








        

        </a-drawer>
    <!-- display drawer -->

    <!--update drawer -->
        <a-drawer
            v-model:open="open"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"

            @close="cancelUpdate"
        >

            <template #title>
                <p  class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.UpdateMeeting')}}</p>
            </template>

            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                <a-row>

                    <a-col :span="24">
                        <a-form-item   name="motif" >
                            <template #label>
                                <a-tooltip>
                                    <template #title>{{t('Meet.motifTxt')}}</template>
                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                </a-tooltip>
                                <span class="label-form">{{ t('Meet.Motif') }}</span>
                            </template>
                            <a-input  v-model:value="form.motif" :placeholder="t('Meet.Motif')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                </a-row>

                <a-row :gutter="16">   
                    <!--
                    <a-col :span="12">
                        
                       
                        <a-form-item  name="dates">

                            <template #label>
                                <span class="label-form">{{ t('Meet.ReservationTime') }}</span>
                            </template>

                            <a-range-picker
                                format="YYYY-MM-DD HH:mm"
                                :show-time ="{ hideDisabledOptions : true, format :'HH:mm'}"
                                :value="[form.start_date, form.end_date]"
                                :disabled-date="disableDateUpdate"
                                :disabled-time="disabledRangeTime"
                                @change="onChangeUpdate"
                                @openChange="onOpenChangeUpdate"
                                @calendarChange="onCalendarChangeUpdate"
                            />
 
                        </a-form-item>
                        


                    </a-col>
                    -->

                    <a-col :span="24">

                        <a-form-item  name="room_id">
                            <template #label>
                                <span class="label-form">{{ t('Meet.Room') }}</span>
                            </template>

                            <a-select v-model:value="form.room_id" show-search :option-filter-prop="'label'">

                                <template #notFoundContent>
                                    <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                                </template>

                                <a-select-option v-for="room in state.rooms" :label="room.name" :key="room.id" :value="room.id" :selected="room.id === form.room_id ">{{ room.name }}</a-select-option>
                            </a-select>
                        </a-form-item>

                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">

                        <div class="label-container">
                            <span class="required-icon">*</span>
                            <span class="label label-form">{{ t('Meet.ReservationTime') }}</span>
                        </div>

                        <a-row :gutter="16">
                            <a-col :span="8" >

                                <a-form-item
                                    name="dateMeet"
                                >
                                    <a-locale-provider :locale="calendarLocale">
                                        <a-date-picker 
                                            v-model:value="form.dateMeet" 
                                            class="date-picker-date"
                                            :disabled-date="disableDates" 
                                            :disabled="!form.room_id"
                                        />
                                    </a-locale-provider>
                                </a-form-item>

                            </a-col>

                            <a-col :span="8">
                                <a-form-item
                                    name="startTime"
                                >
                                    <a-time-picker 
                                        v-model:value="form.startTime"
                                        format="HH:mm" 
                                        class="date-picker-date" 
                                        :minute-step="5" 
                                        :showNow="false"
                                        :disabled-hours="disableWDdHours"
                                        :hide-disabled-options="true"
                                        :disabled="!form.dateMeet  || !form.room_id"
                                        @change="handleStartTimeUpdate"

                                    />
                                </a-form-item>
                            </a-col>

                            <a-col :span="8">
                                <a-form-item
                                    name="durationTime"
                                >
                                    <a-time-picker 
                                        v-model:value="form.durationTime"
                                        format="HH:mm" 
                                        class="date-picker-date" 
                                        :minute-step="5" 
                                        placeholder="Select duration" 
                                        :disabled-hours="disableDurationHoursUpdate"
                                        :disabled-minutes="disableDurationMinutesUpdate"
                                        :hide-disabled-options="true"
                                        :showNow="false"
                                        :disabled="!form.startTime"
                                        @select="handleDurationPicker"
                                    />
                                </a-form-item>

                            </a-col>

                        </a-row>


                    </a-col>
                </a-row>

                

                <a-row>
                    <a-col :span="24">

                        <a-form-item  name="participants">
                            <template #label>
                                <span class="label-form">{{ t('Meet.Participants') }}</span>
                            </template>

                            <a-select
                                v-model:value="form.allParticipants"
                                mode="multiple"
                                label-in-value
                                :placeholder="t('Meet.selectParticipants')"
                                style="width: 100%"
                                :filter-option="false"
                                :not-found-content="state.fetchingUpdate ? undefined : null"
                                :options="filteredUsersUpdate"
                                @search="fetchUserUpdate"
                                @change="handleUsersUpdateSelect"
                            >
                                <template v-if="state.fetchingUpdate" #notFoundContent>
                                    <a-spin size="small" />
                                </template>
                            </a-select>

                            
                            
                        </a-form-item>

                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                        <a-form-item   name="notifMail" >
                            <template #label>
                                <span class="label-form">{{ t('Meet.notifMailContent') }}</span>
                            </template>
                            <a-textarea v-model:value="form.notifMail" :rows="4" :placeholder="t('Meet.notifMailContent')" />
                        </a-form-item>
                    </a-col>
                </a-row>

               

                <a-row class="periodicty-container">

                    <a-col :span="24">
                        <div class="label-container check-container">
                            <a-switch v-model:checked="checkedPeriodicityUpdate" class="check-btn" />

                            <span class="label label-form">{{ t('Meet.periodicity') }}</span>
                        </div>

                        <a-row v-if="checkedPeriodicityUpdate === true">
                            <a-col :span="9">
                                <a-radio-group v-model:value="periodicity">
                                    <a-radio :style="radioStyle" value="daily" disabled>{{ t('Meet.Daily') }}</a-radio>
                                    <a-radio :style="radioStyle" value="weekly" disabled>{{ t('Meet.weekly') }}</a-radio>
                                    <a-radio :style="radioStyle" value="monthly" disabled>{{ t('Meet.monthly') }}</a-radio>
                                    <a-radio :style="radioStyle" value="yearly" disabled>{{ t('Meet.annual') }}</a-radio>

                                </a-radio-group>
                            </a-col>

                            <a-col :span="15">

                                <div v-if="periodicity ===  'daily' ">
                                    <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConfUpdate" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday" disabled >{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday" disabled>{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday" disabled>{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday" disabled>{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday" disabled>{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday" disabled>{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Sunday" disabled>{{ t('Meet.Sunday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config">

                                        <template v-if="weeklyConfUpdate.length === 7">
                                            <span class="periodicty-label"> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                                        </template>

                                        <template v-else>
                                            <span class="periodicty-label"> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConfUpdate" :key="day">
                                                <span>{{translateDay(day)}} , </span>
                                            </template>
                                        </template>
    
                                        <span class="periodicty-label"> {{t('Meet.until')}}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                            <a-date-picker 
                                                v-model:value="form.periodEndDate" 
                                                class="period-date"
                                                :disabled-date="disablePeriodDates" 
                                                disabled
                                                :bordered="false" 
                                            />
                                        </a-locale-provider>

                                    </div>
                                </div>


                               

                                <div v-if="periodicity ===  'weekly' ">
                                    <div class="display-container">
                                        <a-tooltip>
                                            <template #title>{{t('Meet.weeklyTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>
                                        <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="form.periodicity" class="periodicity-input" disabled/>
                                        <span class="periodicty-label">{{ t('Meet.week') }}</span>
                                    </div>
                                   
                                    <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConfUpdate" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday" disabled>{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday" disabled>{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday" disabled>{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday" disabled>{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday" disabled>{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday" disabled>{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Sunday" disabled>{{ t('Meet.Sunday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config display-container">

                                   
                                        

                                        <template v-if="weeklyConfUpdate.length === 7">
                                            <span class="periodicty-label"> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                                        </template>

                                        <template v-else>
                                            <span class="periodicty-label"> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConfUpdate" :key="day">
                                                <span>{{translateDay(day)}} , </span>
                                            </template>
                                        </template>
    
                                        <span class="periodicty-label"> {{t('Meet.until')}}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                            <a-date-picker 
                                                v-model:value="form.periodEndDate" 
                                                class="period-date"
                                                :disabled-date="disablePeriodDates" 
                                                disabled
                                                :bordered="false" 
                                            />
                                        </a-locale-provider>

                                    </div>

                                </div>

                                <div v-if="periodicity ===  'monthly' ">
                                    <div class="display-container">
                                        <a-tooltip>
                                            <template #title>{{t('Meet.monthlyTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>

                                        <!-- <span class="periodicty-label">{{ t('Meet.Day') }}</span>
                                        <a-input v-model:value="form.dayAnnual" class="periodicity-input" /> -->
                                        <span class="periodicty-label">{{ t('Meet.ofEvery') }}</span>
                                        <a-input v-model:value="form.periodicity" class="periodicity-input" disabled />
                                        <span>{{ t('Meet.Montly') }}</span>

                                        <!-- <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="form.periodicity" class="periodicity-input" />
                                        <span class="periodicty-label">{{ t('Meet.month') }}</span> -->
                                        
                                    </div>

                                    <div class="period-container display">
                                        <span class="periodicty-label"> {{t('Meet.until')}}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                            <a-date-picker 
                                                v-model:value="form.periodEndDate" 
                                                class="period-date"
                                                :disabled-date="disablePeriodDates" 
                                                disabled
                                                :bordered="false" 
                                            />
                                        </a-locale-provider>

                                    </div>

                                    <!-- <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConfUpdate" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday">{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday">{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday">{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday">{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday">{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday">{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config">

                                   
                                        

                                        <template v-if="weeklyConfUpdate.length === 7">
                                            <span> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                                        </template>

                                        <template v-else>
                                            <span> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConfUpdate" :key="day">
                                                <span>{{day}} , </span>
                                            </template>
                                        </template>
    
                                        <span> {{t('Meet.until')}}</span>
                                        <a-date-picker 
                                            v-model:value="form.periodEndDate" 
                                            class="period-date"
                                            :disabled-date="disableDates" 
                                            :bordered="false" 
                                        />

                                    </div> -->

                                    

                                </div>

                                <div v-if="periodicity === 'yearly'">

                                    <div class="display-container">

                                        <a-tooltip>
                                            <template #title>{{t('Meet.annualTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>

                                        <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="form.periodicity" class="periodicity-input" disabled />
                                        <span class="periodicty-label">{{ t('Meet.year') }}</span>

                                    </div>

                                    <!-- <div class="period-container display">
                                        <span class="periodicty-label">{{ t('Meet.On') }}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                            <a-date-picker v-model:value="monthperiod" picker="month" format="MM"  @change="getSelectedMonth" class="month-input" />
                                        </a-locale-provider>
                                        <a-input-number v-model:value="form.dayAnnual" :min="1" :max="maxDays"  :disabled="!monthperiod" class="periodicity-input" />
                                    </div> -->

                                    <div class="display">
                                        <span class="periodicty-label"> {{t('Meet.until')}}</span>
                                        <a-locale-provider :locale="calendarLocale">
                                        <a-date-picker 
                                            v-model:value="form.periodEndDate" 
                                            class="period-date"
                                            disabled
                                            :disabled-date="disablePeriodDates" 
                                            :bordered="false" 
                                        />
                                        </a-locale-provider>

                                    </div>

                                    <!-- <div>
                                        <a-tooltip>
                                            <template #title>{{t('Meet.annualTxt')}}</template>
                                            <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                        </a-tooltip>
                                        <span class="periodicty-label">{{ t('Meet.every') }}</span>
                                        <a-input v-model:value="form.periodicity" class="periodicity-input" />
                                        <span class="periodicty-label">{{ t('Meet.year') }}</span>


                                    </div>

                                    <div class="check-container">
                                        <a-checkbox-group v-model:value="weeklyConfUpdate" >
                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Monday">{{ t('Meet.Monday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Tuesday">{{ t('Meet.Tuesday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Wednesday">{{ t('Meet.Wednesday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>


                                            <a-row class="days-container">
                                                <a-col :span="8">
                                                    <a-checkbox value="Thursday">{{ t('Meet.Thursday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Friday">{{ t('Meet.Friday') }}</a-checkbox>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-checkbox value="Saturday">{{ t('Meet.Saturday') }}</a-checkbox>
                                                </a-col>
                                            </a-row>

                                        </a-checkbox-group>
                                    </div>

                                    <div class="period-config">

                                   
                                        

                                        <template v-if="weeklyConfUpdate.length === 7">
                                            <span> {{ t('Meet.MeetingEvery') }} {{t('Meet.everyday')}}</span>
                                        </template>

                                        <template v-else>
                                            <span> {{ t('Meet.MeetingEvery') }} </span>
                                            <template v-for="day in weeklyConfUpdate" :key="day">
                                                <span>{{day}} , </span>
                                            </template>
                                        </template>
    
                                        <span> {{t('Meet.until')}}</span>
                                        <a-date-picker 
                                            v-model:value="form.periodEndDate" 
                                            class="period-date"
                                            :disabled-date="disableDates" 
                                            :bordered="false" 
                                        />

                                    </div> -->

                                </div>

                            </a-col>
                        </a-row>
                    </a-col>

                </a-row>


                <a-row  v-if="checkedPeriodicityUpdate === true">
                    <a-col :span="5">
                        <button 
                            class="availibility-btn" 
                            @click="checkAvailabilityUpdate()"
                            :disabled="isButtonDisabled"
                           
                        >{{t('Meet.CheckAvailibility')}}</button>
                    </a-col>
                    <a-col :span="19" v-if="state.beginCheck  === true">
                        <span v-if="state.periodical===false" class="periodical-error">
                            {{t('Meet.periodicalError')}},
                            <span class="discover-title" @click="openDiscover">{{t('Meet.discover')}}</span>
                        </span>
                        <span class="periodical-success"  v-if="state.periodical===true && state.displaySucees === true ">
                            <check-circle-two-tone two-tone-color="#52c41a" />
                            {{t('Meet.periodicalSuccess')}}
                        </span>

                    </a-col>

                </a-row>

                <a-row :gutter="16" v-if="openSlots && checkedPeriodicityUpdate === true  && state.periodical===false">

                    <!-- <a-descriptions v-for="slot in form.AllSlots" :key="slot"  bordered size="small" class="mr" :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']">
                        <a-descriptions-item  :label="t('Meet.Date')" :span="3">
                            <span>{{slot.date}}</span>
                        </a-descriptions-item>

                        <a-descriptions-item :label="t('Meet.Available')">
                            <span v-if="slot.availability[0].active === 1">
                                <check-circle-two-tone two-tone-color="#52c41a" />
                            </span>
                            <span v-if="slot.availability[0].active === 0">
                                <close-circle-two-tone two-tone-color="#ff4d4f"/>
                            </span>
                        </a-descriptions-item>
                    </a-descriptions> -->

                    <a-descriptions 
                        v-for="(slot, index) in form.AllSlots" 
                        :key="index" 
                        bordered 
                        size="small" 
                        class="mr" 
                        :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']"
                        >
                            
                        <template v-for="(availability, idx) in slot.availability" :key="idx">
                            <a-descriptions-item :label="t('Meet.Date')" :span="3">
                                <span>{{ slot.date }} - {{availability.slot}}</span>
                            </a-descriptions-item>
                            <a-descriptions-item :label="t('Meet.Available')">
                            <span v-if="availability.active === 1">
                                <check-circle-two-tone two-tone-color="#52c41a" />
                            </span>
                            <span v-if="availability.active === 0">
                                <close-circle-two-tone two-tone-color="#ff4d4f" />
                            </span>
                            </a-descriptions-item>
                        </template>
                    </a-descriptions>

                </a-row>



               
                

                <a-row :gutter="16">
                    <a-col :span="12">
                        <button class="request-btn" @click="showEquipments(form.id)">{{ t('Meet.Equipements') }} </button>
                    </a-col>

                    <a-col :span="12">
                        <button class="request-btn" @click="showServices(form.id)">{{ t('Meet.Services') }} </button>
                    </a-col>
                </a-row>

              


                

               


              

              





                



            </a-form>
            <template #footer>
                <a-space>
                    <a-button  type="primary" @click="update(form.id)">{{ t('Meet.Update') }}</a-button>
                    <a-button type="primary" @click="cancelUpdate" danger >{{ t('Meet.Cancel') }}</a-button>
                </a-space>
            </template>

        </a-drawer>
    <!--update drawer -->

    <!-- invite user modal -->
    <a-modal v-model:open="openUserModal" >
        <template #footer>
            <div class="btns-container">
                <a-button key="back" @click="cancelInviteModal">{{ t('Meet.Cancel') }}</a-button>
            </div>
        </template>

        <template #title>
            <div class="invite-user-container">
                <TeamOutlined class="users-icon" />
                <p :class="{ 'is-rtl': textDirection==='rtl'}"><span class="modal-title">{{ t('Meet.InviteParticipant') }}</span></p>

            </div>
        </template>

        <a-form  ref="inviteFormRef" :model="inviteForm" :rules="usersRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="users" >
                        <template #label>
                            <div > 
                                <span class="label-form">{{ t('Meet.Participants') }}</span>  
                            </div>

                        </template>
                        <div class="label-container">
                            <span class="txt-form">{{ t('Meet.InviteParticipantTxt') }}</span>
                        </div>

                        <a-select
                            v-model:value="user"
                            mode="tags"
                            style="width: 100%"
                            :placeholder="t('Meet.Participants')"
                            :options="invitations"
                            @change="handleMultiUserChange"
                        ></a-select>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>


    </a-modal>

    <!-- equipment request modal  -->
    <a-modal v-model:open="openEqModal" @cancel="handleCancelEq">
        <template #footer>
            <div class="btns-container">
                <a-button key="submit" type="primary"  @click="addEquipement">{{ t('Meet.Confirm') }}</a-button>
                <a-button key="back" @click="handleCancelEq">{{ t('Meet.Cancel') }}</a-button>
            </div>
        </template>

        <template #title>
            <div class="invite-user-container">
                <p :class="{ 'is-rtl': textDirection==='rtl'}"><span class="modal-title">{{ t('Meet.Equipements') }}</span></p>

            </div>
        </template>

        <a-form  ref="formEquipmentRef" :model="formEquipment"  layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <!-- equipments update -->


            <a-row :gutter="16">

                <a-col :span="24">
                    <a-form-item  name="equipmenets">
                        <template #label>
                            <span class="label-form">{{t('Meet.Equipements')}}</span>
                        </template>
                            
                        <a-select
                            v-model:value="form.items_id"
                            mode="multiple"
                            style="width: 100%"
                            :options="optionsUpdate"
                            @change="handleUpdateItem"
                            show-search
                        ></a-select>

                    </a-form-item>
                </a-col>

            </a-row>

            <!-- new equipment requests -->
            <a-row v-for="item in state.newEqArray " :key="item" :gutter="16">
                <a-col :span="12">
                    <span class="label-form">{{item.name}}</span>
                </a-col>
                

                <a-col  :span="10">
                    <a-form-item has-feedback  :name="'qtEq' + item.id" :validate-status="getValidatingStatusQT(item.id,'qtEq')" :help="getValidatingHelpQT(item.id,'qtEq')">
                        <a-input :id="'qtEq' + item.id" v-model:value="item.qte" :placeholder="t('Meet.Quantity')" class="input-form" :disabled="item.oldItem===true" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- old approved equipments -->
            <a-row v-for="item in state.itemsArray " :key="item" :gutter="16">
                <a-col :span="12">
                    <span class="label-form">{{item.name}}</span>
                </a-col>
                

                <a-col  :span="6">
                    <a-form-item has-feedback  >
                        <a-input v-model:value="item.qte" :placeholder="t('Meet.Quantity')" class="input-form" :disabled="item.oldItem===true" />
                    </a-form-item>
                </a-col>

                <a-col  :span="6" class="tag-container">


                    <a-tag v-if="item.approved === 1 " color="green" >
                        <template #icon>
                            <check-circle-outlined  />
                        </template>
                        {{t('Meet.Approved')}}
                    </a-tag>

                    <a-tag v-if="item.approved=== 0" color="red" >
                        <template #icon>
                            <CloseCircleOutlined />
                        </template>
                        {{t('Meet.Disapproved')}}
                    </a-tag>
                    
                </a-col>
            </a-row>


        </a-form>


    </a-modal>

    <!-- service request modal  -->

    <a-modal v-model:open="openSerModal" @cancel="handleCancelSer">
        <template #footer>
            <div class="btns-container">
                <a-button key="submit" type="primary"  @click="addService">{{ t('Meet.Confirm') }}</a-button>
                <a-button key="back" @click="handleCancelSer">{{ t('Meet.Cancel') }}</a-button>
            </div>
        </template>

        <template #title>
            <div class="invite-user-container">
                <p :class="{ 'is-rtl': textDirection==='rtl'}"><span class="modal-title">{{ t('Meet.Services') }}</span></p>

            </div>
        </template>

        <a-form  ref="formServiceRef" :model="formService"  layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            
            <!-- services update -->

            <a-row :gutter="16">

                <a-col :span="24">
                    <a-form-item  name="services">
                        <template #label>
                            <span class="label-form">{{t('Meet.Services')}}</span>
                        </template>
                            
                        <a-select
                            v-model:value="form.items_id_ser"
                            mode="multiple"
                            style="width: 100%"
                            :options="optionsUpdateSer"
                            @change="handleUpdateSer"
                            show-search
                        ></a-select>

                    </a-form-item>
                </a-col>

            </a-row>

            <!-- new services request -->
            <a-row v-for="item in state.newSerArray " :key="item" :gutter="16">
                <a-col :span="12">
                    <span class="label-form">{{item.name}}</span>
                </a-col>
                

                <a-col  :span="10">
                    <a-form-item has-feedback  :name="'qt' + item.id" :validate-status="getValidatingStatusQT(item.id,'qt')" :help="getValidatingHelpQT(item.id,'qt')">
                        <a-input :id="'qt' + item.id" v-model:value="item.qte" :placeholder="t('Meet.Quantity')" class="input-form" :disabled="item.oldItem===true" />
                    </a-form-item>
                </a-col>


                <a-col  :span="6" class="tag-container">

                    <a-tag v-if="item.approved===1 || item.approved===true" color="red" >
                        <template #icon>
                            <check-circle-outlined  />
                        </template>
                        {{t('Meet.Approved')}}
                    </a-tag>

                    <a-tag v-if="item.approved===0 || item.approved===false" color="red" >
                        <template #icon>
                            <CloseCircleOutlined />
                        </template>
                        {{t('Meet.Disapproved')}}
                    </a-tag>
                    
                </a-col>
            </a-row>


            <!-- old approvied services -->
            <a-row v-for="item in state.itemsArraySer " :key="item" :gutter="16">
                <a-col :span="12">
                    <span class="label-form">{{item.name}}</span>
                </a-col>
                

                <a-col  :span="6">
                    <a-form-item has-feedback  >
                        <a-input  v-model:value="item.qte" :placeholder="t('Meet.Quantity')" class="input-form" :disabled="item.oldItem===true" />
                    </a-form-item>
                </a-col>


                <a-col  :span="6" class="tag-container">

                    <a-tag v-if="item.approved===1 || item.approved===true" color="green" >
                        <template #icon>
                            <check-circle-two-tone two-tone-color="#52c41a"/>
                        </template>
                        {{t('Meet.Approved')}}
                    </a-tag>

                    <a-tag v-if="item.approved===0 || item.approved===false" color="red" >
                        <template #icon>
                            <CloseCircleOutlined />
                        </template>
                        {{t('Meet.Disapproved')}}
                    </a-tag>
                    
                </a-col>
            </a-row>


        </a-form>
    </a-modal>


    

    
</template>

<script  lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, watch, onBeforeUnmount, getCurrentInstance } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  CloseCircleOutlined,
  ScheduleOutlined,
  CheckCircleOutlined,
  UserOutlined,
  //LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  TeamOutlined,
    QuestionCircleOutlined,
    SearchOutlined,
  
} from '@ant-design/icons-vue';
import {  notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs, { Dayjs } from 'dayjs';
import Loader from '../../components/Looder.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import arLocale from '@fullcalendar/core/locales/ar';
import { Empty } from 'ant-design-vue'
import { equipementsService, internalRequestService, reservationsService, roomsService, serviceService, userService } from '@/_services';
import { co, dA, en } from '@fullcalendar/core/internal-common';
import duration from 'dayjs/plugin/duration';
import ar_EG from 'ant-design-vue/es/locale/ar_EG';
import en_GB from 'ant-design-vue/es/locale/en_GB';
import fr_FR from 'ant-design-vue/es/locale/fr_FR';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { statisticToken } from 'ant-design-vue/es/theme/internal';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(duration);
export default defineComponent({
    name: 'indexMeetings',
    components: {
        
        ScheduleOutlined,
        FullCalendar,
        //LoadingOutlined,
        Loader,
        CheckCircleOutlined,
        CloseCircleOutlined,
        UserOutlined,
        CheckCircleTwoTone,
        CloseCircleTwoTone,
        TeamOutlined,
        QuestionCircleOutlined,
        
    },

    setup() {
        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        
        const loading = ref(false);
        const { t } = useI18n();
        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        interface User {
            label: string;
            value: string;
        }


        const state = reactive({
            data: [] as any[],
            rooms: [] as any[],

            users: []  as any[],
            externalUser:[] as any[],
            foundUsers: []as any[],
            notFoundUsers: []as any[],
            value: [] as any[],
            fetching: false,
            qtArray:[] as any[],
            itemsArray:[] as any[],
            newEqArray:[] as any[],
            



            qtsArray:[] as any[],
            itemsArraySer:[] as any[],
            newSerArray:[] as any[],

            usersUpdate: []as any[],
            valueUpdate: []as any[],

            fetchingUpdate:false,

            userRole:'',


            participants:[] as any[],
            participantsData:[] as any[],

            roomWD:[] as any[],

            wdStart:'',
            wdEnd:'',

            periodical :true,
            displaySucees:false,
            beginCheck:false,

            errorPeriodicityUpdate:false,

            validationErrors: {
                
                room_id:'',
                dateMeet: '',
                startTime:'',
                durationTime:'',

                periodEndDate: '',
                participants: '',
                

            },

            disableServiceAdd:true,
            selectedRoom:null,

           






        });

        //form  
        const formRef = ref();
        const addFormRef = ref();

        const addForm = reactive({
            id:'',
            room_id:'',
            status:'',
            description:'',
            
            date: [] as Dayjs[],
            participants:'',
            external:'',
            
            equipmenets: [] as any,
            services: [] as any,
            motif:'',

            dateMeet:null as Dayjs | null,
            durationTime:null as dayjs.Dayjs | null,
            startTime:null as dayjs.Dayjs | null,
            
            periodicity:'',

            roomWD:'',

            periodEndDate:null as Dayjs | null,

            dayAnnual:'',

            AllSlots : [] as any,
            notifMail:'',
            
        });

        interface ItemInfo {
            id:any,
            name: any;
            qte: any;
            approved:any;
        }

        const form = reactive({
            id:'',
            room_id:'',
            room_name:'',
            status:'',
            description:'',
            date: [] as Dayjs[],
            participants:'',
            created_by_name:'',
            participant_names:[],
            start:'',
            end:'',
            motif:'',
            start_date: null as Dayjs | null,
            end_date: null as Dayjs | null,
            approved:false,
            items_id:[] as string[],
            items_info:[] as ItemInfo[],

            items_id_ser:[] as string[],
            items_info_ser:[] as ItemInfo[],

            participant_id:[] as string[],

            allParticipants:[] as any[],

            externalParticipants:[] as any[],


            dateMeet:null as Dayjs | null,
            durationTime:null as dayjs.Dayjs | null,
            startTime:null as dayjs.Dayjs | null,

            periodEndDate:null as Dayjs | null,
            periodicity:'',

            dayAnnual:'',
            AllSlots : [] as any,
            periodEndDateFormatted:'',

            isPeriodic :true,
            allUsersUpdate:0,
            notifMail:'',


        });


        const rules :Record<string, Rule[]> = {  
            date: [{ required: true, message: t('Meet.RequiredField') }],
            motif: [{ required: true, message: t('Meet.RequiredField') }],
            room_id: [{ required: true, message: t('Meet.RequiredField') }],
            dateMeet:[{ required: true, message: t('Meet.RequiredField') }],
            startTime:[{ required: true, message: t('Meet.RequiredField') }],
            durationTime:[{ required: true, message: t('Meet.RequiredField') }],
            // participants:[{ required: true, message: t('Meet.RequiredField') }],
            periodEndDate:[{ required: true, message: t('Meet.RequiredField') }],
        };

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        const getRooms=()=>{
            roomsService.getAllWithoutPagination().then((res)=>{
                const availableRooms = res.data.filter((room: { status: string; }) => room.status === 'available');

                state.rooms=availableRooms;
                state.roomWD = res.data[0].working_days;
                state.wdStart=state.roomWD[0].start;
                state.wdEnd=state.roomWD[0].end;

                // let endDate = new Date(`1970-01-01T${state.wdEnd}Z`); 
                // endDate.setHours(endDate.getHours() + 1);
                // const formattedEndDate = endDate.toISOString().substring(11, 19);
                // state.wdEnd = formattedEndDate;



                const calendarApi = calendarRef?.value?.getApi();
                if (calendarApi) {
                    calendarApi.setOption('slotMinTime', state.wdStart);
                    calendarApi.setOption('slotMaxTime', state.wdEnd);
                }
            }).catch((error)=>{
                console.log('error getting rooms', error);
            })
        }


        //drawers 

        //add meeting
        const openAdd = ref<boolean>(false);
        const showAddDrawer = () => {
            updateModalWidth();
            //getServices();
            //getEquipments();
            getRooms();
            openAdd.value = true;
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();

            state.users = [];
            state.fetching = false;
            state.value=[];

            state.qtArray=[];
            state.qtsArray=[];
            items.value=[];

            addForm.services=[];
            addForm.equipmenets=[];

            validationStatusQT.value={};
            validationHelpQT.value={};

            value.value = undefined;
            hackValue.value = undefined; 


            addForm.dateMeet=null;

            addForm.periodicity='';
            addForm.periodEndDate= null;
            weeklyConf.value = [...initialWeeklyConf];

            form.dayAnnual='';
            monthperiod.value=null;

            addForm.dayAnnual="";

            state.periodical===true;
            checkedPeriodicity.value=false;
            state.displaySucees = false;
            openSlots.value=false;
            addForm.AllSlots=[];
            state.beginCheck=false;


            // Reset errors
            state.validationErrors = {
                dateMeet: '',
                periodEndDate: '',
                participants: '',
                room_id:'',
                startTime:'',
                durationTime:'',
            };

        };

        //multi select users with search

       

        let debounceTimer: ReturnType<typeof setTimeout> | null = null;

        const fetchUser = (value: any) => {

            
            //disable fetching when the input is empty
            if(value===''){
                return;
            }


            state.users = [];
           
            state.fetching = true;


            //clear previous timer
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }


            //fetch users 

            debounceTimer = setTimeout(() => {
                userService.filterUsers("first_name", value)
                .then((res) => {
                    const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
                        label: `${user.first_name} ${user.last_name}`,
                        value: user.id,
                        external:false,
                    }));

                    
                    state.users = data;

                    //console.log('users',state.users);
                    
                    state.fetching = false;

                    if(data.length===0){
                        //console.log('user not found', data);
                        state.users.push({
                            label: value,
                            value: value,
                            external: true,
                        });
                    }
                    

                    //console.log('state.users : ', state.value);


                })
                .catch((error) => {
                    console.log('error fetching users', error);
                });
            }, 700);





           
             

        };
     
        watch(state.value, () => {
            //console.log('watcher state.value', state.value);
            state.users = [];
            state.fetching = false;
        });
        

        const handleUsersSelect=(value: any[])=>{
            //console.log('selected user', value);
            

            //const selectedUserIds = value.map(item => item.value).join(',');
            //addForm.participants = selectedUserIds;
            //console.log('participants', addForm.participants);

            const internalUsers = value.filter(item => !isNaN(item.value));
            const externalUsers = value.filter(item => isNaN(item.value));


            const selectedUserIds = internalUsers.map(item => item.value).join(',');

            const externalEmails = externalUsers.map(item => item.label).join(',');

            addForm.participants = selectedUserIds;
            addForm.external = externalEmails;

            //console.log('participants', addForm.participants);
            //console.log('external', addForm.external);
        }

        const convertToString = (date:any) => {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        // multiselect indiv
        const fetchParticipant=(event:any)=>{
            //console.log('typed txt', event.target.value);
            const searchedData = event.target.value

            userService.filterUsers("first_name", searchedData)
                .then((res) => {
                    
                    //console.log('res', res);

                    const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
                        label: `${user.first_name} ${user.last_name}`,
                        value: user.id,
                        external:false,
                    }));

                    //console.log('data', data);

                    state.participantsData = data;

                    //state.participantsData.push(data);

                    //console.log('participants',state.participantsData);

                }).catch((error) => {
                    console.log('error fetching users', error);
                });

        }



        //multiselect equipments
        let items= ref<any[]>([]);
        let item_id=ref();

        const options=ref();

        const getEquipmentss=()=>{
            equipementsService.getAll().then((res)=>{
                options.value = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                }));
                //item_id.value=options.value[0].value;
            }).catch((error)=>{
                console.log('error getting equipments', error);
            })
        }

        const getEquipments =()=>{

            equipementsService.getAll().then((res)=>{
                const equipments = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                    serving_time: item.serving_time
                }));

                const { startDateTime, endDateTime} = checkAvailableServices(addForm.dateMeet, addForm.startTime, addForm.durationTime);


                const dateMeet = dayjs(addForm.dateMeet).format('YYYY-MM-DD');
                const startMeetTime = '09:00:00';
                const startMeetTotalDate = dayjs(`${dateMeet} ${startMeetTime}`, 'YYYY-MM-DD HH:mm:ss');
                console.log('start reservation time ', startMeetTotalDate);
                console.log('startDateTime', startDateTime)

                const filteredEquipments = equipments.filter((equipment:any) => {

                    const equipmentStartTime=startMeetTotalDate.add(equipment.serving_time, 'hour');
                    //console.log('service start time', equipmentStartTime)
                    return startDateTime.isSameOrAfter(equipmentStartTime);

                });

                options.value = filteredEquipments;
                console.log('available equipments', filteredEquipments)

            }).catch((error)=>{
                console.log('error getting equipments', error);

            })

        }


        const handleMultiChange = (value: any[]) => {
            items.value=value;
            addForm.equipmenets=value;
            state.qtArray = state.qtArray.filter((item) => value.includes(item.item_id));
            
            if(value){
                value.forEach((itemId)=>{
                    
                    const selectedItem = options.value.find((option: any) => option.value === itemId);
                    if (selectedItem && !state.qtArray.some((item) => item.item_id === itemId) ) {
                        state.qtArray.push({
                            
                            item_id: itemId,
                            item_name: selectedItem.label,
                            item_quantity:selectedItem.quantity,
                        });
                    }
                })
            }

            //console.log('qt array', state.qtArray);

        }


        //multiselect services

        let itemServices= ref<any[]>([]);
        let item_ser_id=ref();

        const optionServices=ref();


        //modal service
        const formAddServiceRef = ref();

        const formAddService = reactive({
            id:'',
            services: [] as any,
        }); 

        const openAddService = ref<boolean>(false);
        const serviceAddModal=()=>{
            openAddService.value = true;
        }

        const confirmService=()=>{

            let servicesString: string[] = [];
            let hasErrors = false;

            state.qtsArray.forEach((item)=>{

                const txtInput = document.getElementById(`itemS${item.item_id}`) as HTMLInputElement;

                if(txtInput.value ===""){
                    //console.log('here', txtInput);
                    validationStatusQT.value[`itemS${item.item_id}`] = 'error';
                    validationHelpQT.value[`itemS${item.item_id}`] = t('Meet.RequiredField');
                    hasErrors = true;
                }else if(!isNum(txtInput.value)){
                
                    validationStatusQT.value[`itemS${item.item_id}`] = 'error';
                    validationHelpQT.value[`itemS${item.item_id}`] = t('Meet.numericField');
                    hasErrors = true;
                }else{
                    validationStatusQT.value[`itemS${item.item_id}`] = '';
                    validationHelpQT.value[`itemS${item.item_id}`] = '';

                    servicesString.push(`${item.item_id}-qte:${txtInput.value}`);




                    
                }


            });

            if(!hasErrors){
                state.disableServiceAdd=false;
            }
        }

       

        const checkAvailableServices=(date:any, start:any, duration:any)=>{
            const dateMeet = dayjs(date).format('YYYY-MM-DD');
            const startTime = dayjs(start).format('HH:mm');

            const startDateTime = dayjs(`${dateMeet} ${startTime}`, 'YYYY-MM-DD HH:mm');

            const durationHours = dayjs(duration).hour();
            const durationMinutes = dayjs(duration).minute();
            const durationObj = dayjs.duration({ hours: durationHours, minutes: durationMinutes });
            const endDateTime = startDateTime.add(durationObj);

            console.log('startDateTime', startDateTime, 'endDateTime', endDateTime);
            
            return { startDateTime, endDateTime};
        }

        const getServicess=()=>{
            serviceService.getAll().then((res)=>{
                const services = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                    serving_time: item.serving_time
                }));

                const { startDateTime, endDateTime} = checkAvailableServices(addForm.dateMeet, addForm.startTime, addForm.durationTime);

                const filteredServices = services.filter((service:any) => {


                    const parsedServingTime = parseInt(service.serving_time, 10);

                    console.log('parsedservingtime', parsedServingTime);

                    if (isNaN(parsedServingTime)) {
                        console.error(`invalid serving_time for service ID ${service.id}: ${service.serving_time}`);
                        return false;
                    }

                    const serviceAvailableTime = startDateTime.add(parsedServingTime, 'hour');

                    console.log('serviceAvailableTime', serviceAvailableTime.format('YYYY-MM-DD HH:mm'));


                    return serviceAvailableTime.isSameOrBefore(endDateTime);


                });
                optionServices.value = filteredServices;
                console.log('available services', filteredServices)
            }).catch((error)=>{
                console.log('error getting equipments', error);
            })
        }

        const getServices =()=>{

            serviceService.getAll().then((res)=>{
                const services = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                    serving_time: item.serving_time
                }));

                const { startDateTime, endDateTime} = checkAvailableServices(addForm.dateMeet, addForm.startTime, addForm.durationTime);


                const dateMeet = dayjs(addForm.dateMeet).format('YYYY-MM-DD');
                const startMeetTime = state.wdStart;
                const startMeetTotalDate = dayjs(`${dateMeet} ${state.wdStart}`, 'YYYY-MM-DD HH:mm:ss');
                console.log('start reservation time ', startMeetTotalDate);
                console.log('startDateTime', startDateTime)

                const filteredServices = services.filter((service:any) => {

                    const serviceStartTime=startMeetTotalDate.add(service.serving_time, 'hour');
                    console.log('service start time', serviceStartTime)
                    return startDateTime.isSameOrAfter(serviceStartTime);

                });

                optionServices.value = filteredServices;
                console.log('available services', filteredServices)

            }).catch((error)=>{
                console.log('error getting equipments', error);

            })

        }


        //watcher dates
       

       


        const handleMultiChangeServices = (value: any[]) => {
            itemServices.value=value;
            formAddService.services=value;
            state.qtsArray = state.qtsArray.filter((item) => value.includes(item.item_id));
            
            if(value){
                value.forEach((itemId)=>{
                    
                    const selectedItem = optionServices.value.find((option: any) => option.value === itemId);
                    if (selectedItem && !state.qtsArray.some((item) => item.item_id === itemId) ) {
                        state.qtsArray.push({
                            item_id: itemId,
                            item_name: selectedItem.label,
                            item_quantity:selectedItem.quantity,
                        });
                    }
                })
            }

            //console.log('qts array', state.qtsArray);

        }

        //validation 
        interface ValidationStatus {
            [key: string]: string;
        }

        interface ValidationHelp {
        [key: string]: string;
        }

        const validationStatusQT = ref<ValidationStatus>({});

        const validationHelpQT=ref<ValidationHelp>({});

        const getValidatingStatusQT=(id:any, fieldType: string)=>{
            return validationStatusQT.value[`${fieldType}${id}`] || '';
        }

        const getValidatingHelpQT=(id:any, fieldType: string)=>{
            return validationHelpQT.value[`${fieldType}${id}`] || '';
        }


        function isNum(value:any) {
            return !isNaN(parseFloat(value)) && isFinite(value);
        }

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                    color: 'white'
                }
            });
        };

        //disable previous dates

        const disableDates=(current: Dayjs)=>{
            /*
            const today = dayjs().startOf('day');
            return current && current.isBefore(today);
            */
            const today = dayjs().startOf('day');
            return current && (current.isBefore(today) || addForm.date[0] && !current.isSame(addForm.date[0], 'day'));
          

        }

        const disablePeriodDates=(current: Dayjs)=>{
            if (!addForm.dateMeet) return false;
            return current.isBefore(addForm.dateMeet, 'day') || current.isSame(addForm.dateMeet, 'day');

        }

      
        const disablePreviousDates=(current:Dayjs)=>{
            const today = dayjs().startOf('day');
            return current && (current.isBefore(today));

        }
      
        const disabledRangeTimex = (date: Dayjs, type: 'start' | 'end') => {
            const currentTime = dayjs();

            //disable previous hours for today 
            if (date && date.isSame(dayjs(), 'day')) {
                if (type === 'start') {
                    return {
                        disabledHours: () => Array.from({ length: currentTime.hour() }, (_, i) => i),
                        //disabledMinutes: () => Array.from({ length: currentTime.minute() }, (_, i) => i)
                        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).filter(min => min % 5 !== 0)

                    };
                
                }


                if (type === 'end') {
                    return {
                        disabledHours: () => Array.from({ length: currentTime.hour() }, (_, i) => i),
                        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).filter(min => min % 5 !== 0)
                    };
                
                }
            }
            return {};
            
        };

        const disabledRangeTimeXXX=(date: Dayjs, type: 'start' | 'end') =>{
            const currentTime = dayjs();


            const disabledMinutesUpToCurrent = Array.from({ length: currentTime.minute() }, (_, i) => i);

            const disabledMinutesNotMultipleOfFive = Array.from({ length: 60 }, (_, i) => i).filter(min => min % 30 !== 0);

            if (date && date.isSame(dayjs(), 'day')) {
            if (type === 'start' || type === 'end') {
                return {
                disabledHours: () => Array.from({ length: currentTime.hour() }, (_, i) => i),
                disabledMinutes: () => {
                    const combinedDisabledMinutes = new Set([...disabledMinutesUpToCurrent, ...disabledMinutesNotMultipleOfFive]);
                    return Array.from(combinedDisabledMinutes);
                }
                };
            }
            }
            return {
                disabledMinutes: () => disabledMinutesNotMultipleOfFive
            };
        }

        const disabledRangeTime = (date: Dayjs, type: 'start' | 'end') => {
            const currentTime = dayjs();
            const currentHour = currentTime.hour();
            const currentMinute = currentTime.minute();

            const wdStartHour = dayjs(state.wdStart, 'HH:mm').hour();
            const wdEndHour = dayjs(state.wdEnd, 'HH:mm').hour();

            const disabledMinutesUpToCurrent = Array.from({ length: currentMinute }, (_, i) => i);
            const disabledMinutesNotMultipleOfThirty = Array.from({ length: 60 }, (_, i) => i).filter(min => min % 30 !== 0);

            const disabledMinutes = () => {
                const combinedDisabledMinutes = new Set([...disabledMinutesUpToCurrent, ...disabledMinutesNotMultipleOfThirty]);
                return Array.from(combinedDisabledMinutes);
            };

            const disabledHours = () => {
                const disabledHours = [];
                for (let i = 0; i < 24; i++) {
                    if (i < wdStartHour || i >= wdEndHour) {
                        disabledHours.push(i);
                    }
                }
                return disabledHours;
            };

            if (date && date.isSame(currentTime, 'day')) {
                return {
                    disabledHours: () => {
                        const disabledHoursToday = [];
                        for (let i = 0; i < 24; i++) {
                            if (i < wdStartHour || i >= wdEndHour || i < currentHour) {
                                disabledHoursToday.push(i);
                            }
                        }
                        return disabledHoursToday;
                    },
                    disabledMinutes: (selectedHour: number) => {
                        if (selectedHour === currentHour) {
                            return disabledMinutes();
                        }
                        return disabledMinutesNotMultipleOfThirty;
                    }
                };
            } else {
                return {
                    disabledHours: disabledHours,
                    disabledMinutes: () => disabledMinutesNotMultipleOfThirty
                };
            }
        };







        
        const handleChangePicker =(date: Dayjs | string, dateString: string)=>{
            console.log('changed date', date);
        }

        //test range
        type RangeValue = [Dayjs, Dayjs];
        const dates = ref<RangeValue>();
        const value = ref<RangeValue>();
        const hackValue = ref<RangeValue>();

        const disabledDate = (current: Dayjs) => {
            const today = dayjs().startOf('day');

            if (current && current.isBefore(today)) {
                return true;
            }

            if (!dates.value || (dates.value as any).length === 0) {
                return false;
            }
            
      
            const tooLate = dates.value[0] && !current.isSame(dates.value[0], 'day');
            const tooEarly = dates.value[1] && !current.isSame(dates.value[0], 'day');

            return tooEarly || tooLate;

        };

        const onOpenChange = (open: boolean) => {
            if (open) {
                dates.value = [] as any;
                hackValue.value = [] as any;
            } else {
                hackValue.value = undefined;
            }
        };

        const onChange = (val: RangeValue) => {
            value.value = val;
        };

        const onCalendarChange = (val: RangeValue) => {
            //console.log('val', val);

            dates.value = val;

            if(val && val.length === 2){
                addForm.date=val;

                //console.log('addForm.date filled', addForm.date)
            }
        };

        //invite users modal
        const openUserModal = ref<boolean>(false);

        const inviteFormRef = ref();

        const openInviteUsers=()=>{
            openUserModal.value=true;
        }

        const cancelInviteModal = () => {
            openUserModal.value = false;
            inviteFormRef.value.resetFields();
        };

        const inviteUser=()=>{
            inviteFormRef.value.validate().then(()=>{
               console.log('invitations', addForm.external);
            }).catch((error:any)=>{
                console.log('error validation');
            });
        }

        
        const isValidEmail = (email: string) => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        };
        

        const handleMultiUserChange = (value: string) => {
            

            // if (isValidEmail(value)) {
            //     user.value.push(value);
            //     console.log(`selected inviation ${value}`);
            // } else {
            //     console.log('Invalid email address');
            // }
            //console.log('user', user.value.join(','));
            addForm.external=user.value.join(',');


        };

        const user =ref<string[]>([]);
        const invitations: string[] = [];

        const inviteForm = reactive({
            id:'',
            users:user,
        });

        const usersRules: Record<string, Rule[]> = {
            users: [{ required: true,message:t('Meet.RequiredField')}],
        }

        






        const addReservation=()=>{

            

            let hasErrors = false;

            let equipmentString: string[] = [];
            let servicesString: string[] = [];
            
            state.qtsArray.forEach((item)=>{


                const txtInput = document.getElementById(`itemS${item.item_id}`) as HTMLInputElement;

                if(txtInput.value ===""){
                    //console.log('here', txtInput);
                    validationStatusQT.value[`itemS${item.item_id}`] = 'error';
                    validationHelpQT.value[`itemS${item.item_id}`] = t('Meet.RequiredField');
                    hasErrors = true;
                }else if(!isNum(txtInput.value)){
                
                    validationStatusQT.value[`itemS${item.item_id}`] = 'error';
                    validationHelpQT.value[`itemS${item.item_id}`] = t('Meet.numericField');
                    hasErrors = true;
                }else{
                    validationStatusQT.value[`itemS${item.item_id}`] = '';
                    validationHelpQT.value[`itemS${item.item_id}`] = '';

                    servicesString.push(`${item.item_id}-qte:${txtInput.value}`);




                    
                }


            });


            state.qtArray.forEach((item)=>{


                const txtInput = document.getElementById(`item${item.item_id}`) as HTMLInputElement;
                const priceInput = document.getElementById(`price${item.item_id}`) as HTMLInputElement;

                if(txtInput.value ===""){
                    //console.log('here', txtInput);
                    validationStatusQT.value[`item${item.item_id}`] = 'error';
                    validationHelpQT.value[`item${item.item_id}`] = t('Meet.RequiredField');
                    hasErrors = true;
                }else if(!isNum(txtInput.value)){
                
                    validationStatusQT.value[`item${item.item_id}`] = 'error';
                    validationHelpQT.value[`item${item.item_id}`] = t('Meet.numericField');
                    hasErrors = true;
                }else{
                    validationStatusQT.value[`item${item.item_id}`] = '';
                    validationHelpQT.value[`item${item.item_id}`] = '';

                    equipmentString.push(`${item.item_id}-qte:${txtInput.value}`);
                }


            });

            if (!hasErrors) {
                addFormRef.value
                .validate()
                .then(()=>{

                    //console.log('value', value.value);

                    
                    //old
                    //if (value.value && value.value.length === 2) {
                        //const [start, end] = value.value;
                        //console.log('Start:', start.format('YYYY-MM-DD HH:mm'));
                        //console.log('End:', end.format('YYYY-MM-DD HH:mm'));
                   

                        const formData= new FormData();
                        formData.append('motif', addForm.motif);
                       
                        formData.append('room_id', addForm.room_id);
                        formData.append('participants', addForm.participants);
                        formData.append('description',addForm.notifMail);

                        formData.append('equipments',equipmentString.join(','));
                        formData.append('services',servicesString.join(','));

                        formData.append('external_participants', addForm.external);

                        //console.log('equipement', equipmentString.join(','));
                        //console.log('services', servicesString.join(','));

                        //console.log('participants', addForm.participants);
                        //console.log('external', addForm.external);

                        //format dates
                        const dateMeet = dayjs(addForm.dateMeet).format('YYYY-MM-DD');
                        const startTime = dayjs(addForm.startTime).format('HH:mm');

                        const startDateTime = dayjs(`${dateMeet} ${startTime}`, 'YYYY-MM-DD HH:mm');
                        
                        const durationHours = dayjs(addForm.durationTime).hour();
                        const durationMinutes = dayjs(addForm.durationTime).minute();
                        const durationObj = dayjs.duration({ hours: durationHours, minutes: durationMinutes });
                        const endDateTime = startDateTime.add(durationObj);



                    
                        console.log('startTime',dayjs(startDateTime).format('YYYY-MM-DD HH:mm:ss'));
                        console.log('endTime', dayjs(endDateTime).format('YYYY-MM-DD HH:mm:ss') );

                        

                        formData.append('start', dayjs(startDateTime).format('YYYY-MM-DD HH:mm:ss'));
                        formData.append('end', dayjs(endDateTime).format('YYYY-MM-DD HH:mm:ss'));

                        const periodical = checkedPeriodicity.value? 1:0;
                        formData.append('periodical',periodical.toString() );

                        let periodicalSettings;
                        if(checkedPeriodicity.value === true){

                        
                            if(periodicity.value === 'daily'){
                                addForm.periodicity = weeklyConf.value.length.toString();

                                periodicalSettings={
                                    type:'daily',
                                    periodicity : 1,
                                    daysOfWeek : weeklyConf.value,
                                    endDate : dayjs(addForm.periodEndDate).format('YYYY-MM-DD'),
                                    time : dayjs(addForm.startTime).format('HH:mm'),
                                    duration: dayjs(addForm.durationTime).format('HH:mm'),
                                }
                            }else if(periodicity.value === 'weekly' ) {
                                periodicalSettings={
                                    type:'weekly',
                                    periodicity : addForm.periodicity,
                                    daysOfWeek : weeklyConf.value,
                                    endDate : dayjs(addForm.periodEndDate).format('YYYY-MM-DD'),
                                    time : dayjs(addForm.startTime).format('HH:mm'),
                                    duration: dayjs(addForm.durationTime).format('HH:mm'),
                                }
                            }else if (periodicity.value === 'monthly') {

                                periodicalSettings={
                                    type:'monthly',
                                    periodicity : addForm.periodicity,
                                    daysOfWeek : "",
                                    endDate : dayjs(addForm.periodEndDate).format('YYYY-MM-DD'),
                                    time : dayjs(addForm.startTime).format('HH:mm'),
                                    duration: dayjs(addForm.durationTime).format('HH:mm'),
                                }

                            }else if(periodicity.value === 'yearly') {
                                //console.log('go heeere', periodicity.value)

                                //const monthDay = dayjs(monthperiod.value).format('MM') +"-"+addForm.dayAnnual
                                periodicalSettings={
                                    type:'yearly',
                                    periodicity : addForm.periodicity,
                                    daysOfWeek : [],
                                    endDate : dayjs(addForm.periodEndDate).format('YYYY-MM-DD'),
                                    time : dayjs(addForm.startTime).format('HH:mm'),
                                    duration: dayjs(addForm.durationTime).format('HH:mm'),
                                }

                            }

                            //console.log('periodicalSettings', periodicalSettings)
                            formData.append("periodical_settings", JSON.stringify(periodicalSettings))

                        }else{
                            formData.append("periodical_settings","");
                        }
                        
                        
                        loading.value=true;

                    
                        reservationsService.create(formData).then((res)=>{
                            //getAllByMonth();

                            const calendarApi = calendarRef?.value?.getApi();
                            if (calendarApi) {
                                const start = calendarApi.view.activeStart;
                                const end = calendarApi.view.activeEnd;
                                getByMonth(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
                            }
                            showSuccessNotification(t('Meet.MeetingAddedSuccess'))
                        }).catch((error)=>{
                            //console.log('error adding reservation',error);
                            loading.value=false;
                            if (error && error === 'Room is occupied') {
                                showErrorNotification(t('Meet.RoomOccupied'));
                            }
                        }).finally(()=>{
                            resetFormAdd();
                            openAdd.value=false;
                            loading.value=false;
                        });

                       
                    //}
                   
                    
                }).catch((error:any) => {
                    console.error('Validation error:', error);
                });
            }else{
                console.log('error validaton dynamic fields');
            }
        }

        //display 
        const openDisplay = ref<boolean>(false);
    
        interface Participant {
            id: string;
            name:string;
            photo:string;
        }
        const particpants_array = ref<Participant[]>([]); 

        interface Equipment {
            id: string;
            name:string;
            qte:string;
            approved:string;
        }
        const equipmenets_array = ref<Equipment[]>([]); 
        
        interface Service {
            id: string;
            name:string;
            qte:string;
            approved:string;
        }
        const services_array = ref<Service[]>([]); 

        interface ExternalParticipant {
            id: string;
            name:string;
            notified:string;
        }
        const external_participants = ref<ExternalParticipant[]>([]);
        
        
        const convertDate = (date:any) => {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);

            return `${year}-${month}-${day}`;
        };



        const getReservationById = async (id: string) => {
            loading.value=true;
            let itemsIds: any[] = [];
            let itemsIdsSer: any[] = [];

            let itemsIdsPart: any[] = [];


            try{

                const res = await reservationsService.show(id);
                form.id = res.data.id;
                form.room_id = res.data.room_id;
                form.room_name = res.data.room.name;
                form.created_by_name = res.data.user.first_name+" "+res.data.user.last_name;
                form.start=res.data.start;
                form.end = res.data.end;
                form.motif=res.data.motif;
                form.notifMail=res.data.description;

                const start = dayjs(res.data.start);
                const end = dayjs(res.data.end);
                valueUpdate.value = [start, end];
                hackValueUpdate.value = [start, end];

                form.dateMeet = dayjs(res.data.start);
                form.startTime=dayjs(res.data.start);

                const duration = dayjs.duration(end.diff(start));
                const hours = duration.hours();
                const minutes = duration.minutes();
            
                form.durationTime = dayjs().hour(hours).minute(minutes).second(0);



                

                res.data.instantReservations

                form.start_date = dayjs(res.data.start);
                form.end_date = dayjs(res.data.end);
                form.approved=res.data.approved === 1;

                

                particpants_array.value = res.data.participants.map((participant: any) => {
                    itemsIdsPart.push(participant.participant_id); 

                    return {
                        id: participant.participant_id,
                        name: participant.first_name + " " + participant.last_name,
                        photo: participant.photo
                    };
                });

               

                external_participants.value = res.data.external_participants.map((item: any) => {
                    return {
                        id: item.id,
                        email: item.email,
                        notified: item.notified,
                    };
                });

                //new

                const externalParticipants = res.data.external_participants.map((item: { id: any; email: any; notified: any; }) => {
                    return {
                        id: item.id,
                        email: item.email,
                        notified: item.notified,
                        external: true,  
                    };
                });

                const internalParticipants = res.data.participants.map((participant: { participant_id: any; first_name: string; last_name: string; photo: any; }) => {
                    

                    return {
                        id: participant.participant_id,
                        name: participant.first_name + " " + participant.last_name,
                        photo: participant.photo,
                        external: false,  
                    };
                });

                const allParticipants = [...internalParticipants, ...externalParticipants];
                form.allUsersUpdate= allParticipants.length;

                form.allParticipants = allParticipants.map((participant) => {
                    if (participant.external) {
                        return participant.email;  
                    } else {
                        return participant.name;  
                    }
                });

                //console.log('all participants', allParticipants);



                //form.participants = res.data.participants.map((participant: any) => participant.first_name+" "+participant.last_name);
                
                
                
                form.approved=res.data.approved === 1;
                form.participant_id=itemsIdsPart;


                equipmenets_array.value = res.data.equipments.map((item: any) => {
                    return {
                        id: item.id,
                        name: item.name,
                        qte: item.quantity,
                        approved:item.approved
                    };
                });

                services_array.value = res.data.services.map((item: any) => {
                    return {
                        id: item.id,
                        name: item.name,
                        qte: item.quantity,
                        approved:item.approved
                    };
                });

               


                //getting for update 
                state.itemsArray = res.data.equipments.map((detail: any) => {
                    itemsIds.push(detail.id); 
                    return {
                        id: detail.id,
                        name: detail.name,
                        qte: detail.quantity,
                        approved: detail.approved,
                        oldItem:true,
                    };
                });


                //form.items_id=itemsIds;
                form.items_info=state.itemsArray;

                //getting services

                state.itemsArraySer = res.data.services.map((detail: any) => {
                    itemsIdsSer.push(detail.id); 
                    return {
                        id: detail.id,
                        name: detail.name,
                        qte: detail.quantity,
                        approved: detail.approved,
                        oldItem:true,
                    };
                });


                //form.items_id_ser=itemsIdsSer;
                form.items_info_ser=state.itemsArray;


                //periodicty
              
                if(res.data.periodical_settings !== null){
                    form.isPeriodic=true;
                    periodicity.value = res.data.periodical_settings.type;
                    weeklyConfUpdate.value = res.data.periodical_settings.daysOfWeek;
                    form.periodEndDate = dayjs(res.data.periodical_settings.endDate);
                    checkedPeriodicityUpdate.value = res.data.periodical === 1;
                    form.periodicity = res.data.periodical_settings.periodicity;
                    form.periodEndDateFormatted = form.periodEndDate.format('YYYY-MM-DD')

                }else if(res.data.periodical_origin_settings !==null){
                    form.isPeriodic=true;
                    periodicity.value = res.data.periodical_origin_settings.type;
                    weeklyConfUpdate.value = res.data.periodical_origin_settings.daysOfWeek;
                    form.periodEndDate = dayjs(res.data.periodical_origin_settings.endDate);
                    checkedPeriodicityUpdate.value = res.data.periodical_origin_settings.periodicity === 1;
                    form.periodicity = res.data.periodical_origin_settings.periodicity;
                    form.periodEndDateFormatted = form.periodEndDate.format('YYYY-MM-DD')
                }else{
                    form.isPeriodic=false;
                    checkedPeriodicityUpdate.value = false;
                }

                console.log('form.isperiodic', form.isPeriodic);
               


                

                


                loading.value = false;


            }catch(error){
                console.error('Error fetching reservation by id:', error);

            }
        }

        const capitalize = (str:string)=>{
            if (typeof str !== 'string' || str.length === 0) {
                return str; 
            }
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        
        //convert date format from "YYYY-MM-DD" to "DD-MM-YYYY"
        function formatDisplatyDate(dateString:string) {
            const [datePart, timePart] = dateString.split(' '); 
            const [year, month, day] = datePart.split('-');
            return `${day}-${month}-${year}`;
        }


        //extract time from datetime string "YYYY-MM-DD HH:mm:ss"
        function extractTime(dateTimeString:string) {
            return dateTimeString.split(' ')[1];
        }

        const showDisplayDrawer =(id:string)=>{
            updateModalWidth();
            getReservationById(id);

            openDisplay.value = true;
        }

        const showDeletePopup = (id: string) => {
            Swal.fire({
                title: t('Meet.TitleDeleteMeeting'),
                text: t('Meet.TextDeleteMeeting'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Delete'),
                cancelButtonText: t('Meet.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteReservation(id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const deleteReservation=(id:string)=>{
            loading.value=true;
            reservationsService.delete(id).then((res)=>{
                const calendarApi = calendarRef?.value?.getApi();
                if (calendarApi) {
                    const start = calendarApi.view.activeStart;
                    const end = calendarApi.view.activeEnd;
                    getByMonth(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
                }
                showSuccessNotification(t('Meet.MeetingDeletedSuccess'))
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                loading.value=false;
            })
        }

        //update drawer
        const open = ref<boolean>(false);

        const showDrawer = async (id:any) => {
            updateModalWidth();
            await getReservationById(id);
            getRooms();
            getEquipmentsForUpdate();
            getServicesForUpdate();
            open.value = true;  
        };

        const handleDateChange = (dates: Dayjs[]) => {
            form.start_date = dates[0];
            form.end_date = dates[1];
        };

        const cancelUpdate=()=>{
            open.value=false;

            validationStatusQT.value={};
            validationHelpQT.value={};

            state.periodical===true;
            checkedPeriodicity.value=false;
            state.displaySucees = false;
            state.beginCheck=false;




        
        }

        //range picker update
        
        const datesUpdate = ref<RangeValue>();
        const valueUpdate = ref<RangeValue>();
        const hackValueUpdate = ref<RangeValue>();

        const disableDateUpdate=(current: Dayjs)=>{
            const today = dayjs().startOf('day');

            if (current && current.isBefore(today)) {
                return true;
            }

            if (!datesUpdate.value || (datesUpdate.value as any).length === 0) {
                return false;
            }
            

            if (!datesUpdate.value || (datesUpdate.value as any).length === 0) {
                return false;
            }
            const tooLate = datesUpdate.value[0] && !current.isSame(datesUpdate.value[0], 'days') ;
            const tooEarly = datesUpdate.value[1] && !current.isSame(datesUpdate.value[0], 'days');
            return tooEarly || tooLate;
        }

        const onOpenChangeUpdate = (open: boolean) => {
            //console.log('open change')
            if (open) {
                datesUpdate.value = [] as any;
                hackValueUpdate.value = [] as any;
            } else {
                hackValueUpdate.value = undefined;
            }
        };

        
        const onChangeUpdate = (val: RangeValue) => {
            valueUpdate.value = val;
            if(val){
                form.start_date=val[0];
                form.end_date=val[1];
            }
            
        };

        const onCalendarChangeUpdate = (val: RangeValue) => {
            //console.log('val', val);

            datesUpdate.value = val;

            if(val && val.length === 2){
                form.date=val;

                //console.log('form.date filled', form.date)
            }
        };





        //equipment request modal
        const openEqModal = ref<boolean>(false);

        const showEquipments=(id:string)=>{
            getReservationById(id);
            formEquipment.reservation_id=id;
            openEqModal.value=true;
        }

        const handleCancelEq = () => {
            
            openEqModal.value = false;
            formEquipmentRef.value.resetFields();
            state.newEqArray=[];
            validationStatusQT.value={};
            validationHelpQT.value={};
            form.items_id=[];

        };

        const formEquipmentRef = ref();

        const formEquipment = reactive({
            id:'',
            reservation_id:'',
            equipement_id:'',
            quantity:'',
        }); 

        const addEquipement=()=>{

            let equipmentQt: string[] = [];
            let equipmentsId : string[] = [];
            let hasErrors = false;

            state.newEqArray.forEach((item)=>{


                const txtInput = document.getElementById(`qtEq${item.id}`) as HTMLInputElement;

                if(txtInput.value ===""){
                    //console.log('here', txtInput);
                    validationStatusQT.value[`qtEq${item.id}`] = 'error';
                    validationHelpQT.value[`qtEq${item.id}`] = t('Meet.RequiredField');
                    hasErrors = true;
                }else if(!isNum(txtInput.value)){
                
                    validationStatusQT.value[`qtEq${item.id}`] = 'error';
                    validationHelpQT.value[`qtEq${item.id}`] = t('Meet.numericField');
                    hasErrors = true;
                }else{
                    validationStatusQT.value[`qtEq${item.id}`] = '';
                    validationHelpQT.value[`qtEq${item.id}`] = '';


                    equipmentQt.push(txtInput.value);
                    equipmentsId.push(item.id);
                }


            });

           
            if(!hasErrors){

               
                let payload={
                    reservation_id:formEquipment.reservation_id,
                    equipment_ids:equipmentsId.join(','),
                    quantities:equipmentQt.join(','),
                }
                //console.log('payload', payload);

               
                loading.value=true;
                internalRequestService.createEquipmentRequest(payload).then((res)=>{
                    loading.value=false;
                    showSuccessNotification(t('Meet.EquipmentAddedSuccess'));
                    handleCancelEq();
                   
                }).catch((error)=>{
                    loading.value=false;
                    console.log('error equipment request', error);
                });
               
               
            }

            
        }
        //service modal 
        const openSerModal = ref<boolean>(false);

        const showServices=(id:string)=>{
            getReservationById(id);
            formService.reservation_id=id;
            openSerModal.value=true;
        }

        const handleCancelSer = () => {
            openSerModal.value = false;
            formServiceRef.value.resetFields();
            form.items_id_ser=[];
            state.newSerArray=[];
            validationStatusQT.value={};
            validationHelpQT.value={};

        };

        const formServiceRef = ref();

        const formService = reactive({
            id:'',
            reservation_id:'',
            equipement_id:'',
            quantity:'',
        }); 

        const addService=()=>{


            let serviceQt: string[] = [];
            let servicesId : string[] = [];
            let hasErrors = false;

            state.newSerArray.forEach((item)=>{


                const txtInput = document.getElementById(`qt${item.id}`) as HTMLInputElement;

                if(txtInput.value ===""){
                    //console.log('here', txtInput);
                    validationStatusQT.value[`qt${item.id}`] = 'error';
                    validationHelpQT.value[`qt${item.id}`] = t('Meet.RequiredField');
                    hasErrors = true;
                }else if(!isNum(txtInput.value)){
                
                    validationStatusQT.value[`qt${item.id}`] = 'error';
                    validationHelpQT.value[`qt${item.id}`] = t('Meet.numericField');
                    hasErrors = true;
                }else{
                    validationStatusQT.value[`qt${item.id}`] = '';
                    validationHelpQT.value[`qt${item.id}`] = '';

                    serviceQt.push(txtInput.value);
                    servicesId.push(item.id);
                }


            });


           
            if(!hasErrors){

             
                let payload={
                    reservation_id:formService.reservation_id,
                    service_ids:servicesId.join(','),
                    quantities:serviceQt.join(','),
                }

                //console.log('payload', payload);
                loading.value=true;
                internalRequestService.createServiceRequest(payload).then((res)=>{
                    loading.value=false;
                    showSuccessNotification(t('Meet.serviceAddedSuccess'));
                    handleCancelSer();
                    
                }).catch((error)=>{
                    loading.value=false;
                    console.log('error equipment request', error);
                })
            }



            
        }






        //multiselect with serach update users:

        let debounceTimerUpdate: ReturnType<typeof setTimeout> | null = null;

        const fetchUserUpdate = (value: any) => {
            //console.log('fetching user', value);
            state.usersUpdate = [];
            state.fetchingUpdate = true;


            //clear previous timer
            if (debounceTimerUpdate) {
                clearTimeout(debounceTimerUpdate);
            }


            //fetch users 

            debounceTimerUpdate = setTimeout(() => {
                userService.filterUsers("first_name", value)
                .then((res) => {
                    const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
                        label: `${user.first_name} ${user.last_name}`,
                        value: user.id,
                        external:false,
                    }));

                    //console.log('data', data);
                    state.usersUpdate = data;
                    state.fetchingUpdate = false;

                    if(data.length===0){
                        //console.log('user not found', data);
                        state.usersUpdate.push({
                            label: value,
                            value: value,
                            external: true,
                        });
                    }


                })
                .catch((error) => {
                    console.log('error fetching users', error);
                });
            }, 500);
             

        };

        watch(state.valueUpdate, () => {
            state.usersUpdate = [];
            state.fetchingUpdate = false;
        });

        const handleUsersUpdateSelect=(value: any[])=>{
            form.allUsersUpdate=0;
            //console.log('form.participant_id',form.participant_id);
            //console.log('selected user', value);
            form.allUsersUpdate=value.length;
            //console.log('formAlluSER uO', form.allUsersUpdate);

            //old withou external participants
            /*
            const selectedUserIds = value
                .map(item => parseInt(item.value)) 
                .filter(item => !isNaN(item))
                .join(',');
               



            form.participant_id = [...form.participant_id, ...selectedUserIds.split(',')];
            console.log('participants', form.participant_id);

            */


            const internalUsers = value.filter(item => !isNaN(Number(item.value)));
            const externalUsers = value.filter(item => isNaN(Number(item.value)));

         

            const realExternal=  externalUsers.filter(item => item.value.includes('@'));
            const oldInternal = externalUsers.filter(item => !item.value.includes('@'));

            //console.log('realExternal', realExternal);

            const internalUserIds = internalUsers.map(item => item.value);

         

            form.participant_id = [...form.participant_id, ...internalUserIds];

            const externalWithLabel = realExternal.filter(item => item.label).map(item => item.label);
            form.externalParticipants= externalWithLabel;

            //console.log('form.participants_id', form.participant_id);
            //console.log('external',externalWithLabel);

           


          
            
        }

        const filteredUsersUpdate = computed(() => {
            const selectedUserIds = form.participant_id;
            return state.usersUpdate.filter((user:any) => !selectedUserIds.includes(user.value));
        });

        //multiselect equipments for update
        let optionsUpdate=ref();

        const getEquipmentsForUpdates=()=>{
            equipementsService.getAll().then((res)=>{
                optionsUpdate.value =res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                }));
            }).catch((error)=>{
                console.log('error getting equipments', error);
            });
        }

        const getEquipmentsForUpdate=()=>{
            equipementsService.getAll().then((res)=>{
                const equipments = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                    serving_time: item.serving_time
                }));

                const { startDateTime, endDateTime} = checkAvailableServices(form.dateMeet, form.startTime, form.durationTime);


                const dateMeet = dayjs(form.dateMeet).format('YYYY-MM-DD');
                const startMeetTime = '09:00:00';
                const startMeetTotalDate = dayjs(`${dateMeet} ${startMeetTime}`, 'YYYY-MM-DD HH:mm:ss');
                console.log('start reservation time ', startMeetTotalDate);
                console.log('startDateTime', startDateTime)

                const filteredEquipments = equipments.filter((equipment:any) => {

                    const equipmentStartTime=startMeetTotalDate.add(equipment.serving_time, 'hour');
                    //console.log('service start time', equipmentStartTime)
                    return startDateTime.isSameOrAfter(equipmentStartTime);

                });

                optionsUpdate.value = filteredEquipments;
                console.log('available equipments update', filteredEquipments)

            }).catch((error)=>{
                console.log('error getting equipments', error);

            })
        }

        const handleUpdateItem=(value:any[])=>{
            

            state.newEqArray=state.newEqArray.filter((item) => value.includes(item.id));
            if(value){
                value.forEach((itemId)=>{
                    const existingItem = state.newEqArray.find((item) => item.id === itemId);
                    if(!existingItem){
                        const newItem= optionsUpdate.value.find((opt:any)=>opt.value===itemId)
                        state.newEqArray.push({
                            id:itemId,
                            name:newItem.label,
                            oldItem:false,
                        });
                    }

                    
                });
            }

            //console.log('new eq array', state.newEqArray);

            /*
            state.itemsArray = state.itemsArray.filter((item) => value.includes(item.id));
            if(value){
                value.forEach((itemId)=>{
                    const selectedItem=form.items_info.find((option:any)=>option.id===itemId);
                    if(selectedItem && !state.itemsArray.some((itm:any)=>itm.id===itemId)    ){
                        state.itemsArray.push({
                            id:selectedItem.id,
                            name:selectedItem.name,
                            quantity:selectedItem.qte,
                            approved:selectedItem.approved,
                            oldItem:true,
                        })
                    }else if( !selectedItem && !state.itemsArray.some((itm:any)=>itm.id===itemId)){
                        const newItem= optionsUpdate.value.find((opt:any)=>opt.value===itemId)
                        state.itemsArray.push({
                            id:itemId,
                            name:newItem.label,
                            oldItem:false,
                        })
                    }
                });
            }
            */

        }


        
        //multiselect services for update
        let optionsUpdateSer=ref();

        const getServicesForUpdates=()=>{
            serviceService.getAll().then((res)=>{
                const services =res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                }));

                const { startDateTime } = checkAvailableServices(form.dateMeet, form.startTime, form.durationTime);

                const filteredServices = services.filter((service:any) => {

                    const parsedServingTime = parseInt(service.serving_time, 10);
                    console.log('parsedservingtime', parsedServingTime);

                    if (isNaN(parsedServingTime)) {
                        console.error(`invalid serving_time for service ID ${service.id}: ${service.serving_time}`);
                        return false;
                    }

                    const serviceAvailableTime = startDateTime.add(parsedServingTime, 'hour');
                    //console.log('serviceAvailableTime', serviceAvailableTime);
                    return dayjs().isSameOrAfter(serviceAvailableTime);
                });
                optionsUpdateSer.value = filteredServices;
            }).catch((error)=>{
                console.log('error getting services', error);
            });
        }

        const getServicesForUpdate=()=>{

            serviceService.getAll().then((res)=>{
                const services = res.data.map((item:any) => ({
                    value:item.id,
                    label: item.name,
                    serving_time: item.serving_time
                }));

                const { startDateTime, endDateTime} = checkAvailableServices(form.dateMeet, form.startTime, form.durationTime);


                const dateMeet = dayjs(form.dateMeet).format('YYYY-MM-DD');
                const startMeetTime = '09:00:00';
                const startMeetTotalDate = dayjs(`${dateMeet} ${startMeetTime}`, 'YYYY-MM-DD HH:mm:ss');
                console.log('start reservation time ', startMeetTotalDate);
                console.log('startDateTime', startDateTime)

                const filteredServices = services.filter((service:any) => {

                    const serviceStartTime=startMeetTotalDate.add(service.serving_time, 'hour');
                    console.log('service start time', serviceStartTime)
                    return startDateTime.isSameOrAfter(serviceStartTime);

                });

                optionsUpdateSer.value = filteredServices;
                console.log('available services', filteredServices)

            }).catch((error)=>{
                console.log('error getting equipments', error);

            })

        }
        const handleUpdateSer=(value:any[])=>{

            state.newSerArray=state.newSerArray.filter((item) => value.includes(item.id));
            if(value){
                value.forEach((itemId)=>{
                    const existingItem = state.newSerArray.find((item) => item.id === itemId);

                    if(!existingItem){
                        const newItem= optionsUpdateSer.value.find((opt:any)=>opt.value===itemId)
                        state.newSerArray.push({
                            id:itemId,
                            name:newItem.label,
                            oldItem:false,
                        })
                    }

                    
                });
            }

            //console.log('newSerArray', state.newSerArray);

            /*
            state.itemsArraySer = state.itemsArraySer.filter((item) => value.includes(item.id));
            if(value){
                value.forEach((itemId)=>{
                    const selectedItem=form.items_info_ser.find((option:any)=>option.id===itemId);
                    if(selectedItem && !state.itemsArraySer.some((itm:any)=>itm.id===itemId)    ){
                        state.itemsArraySer.push({
                            id:selectedItem.id,
                            name:selectedItem.name,
                            quantity:selectedItem.qte,
                            approved:selectedItem.approved
                        })
                    }else if( !selectedItem && !state.itemsArraySer.some((itm:any)=>itm.id===itemId)){
                        const newItem= optionsUpdateSer.value.find((opt:any)=>opt.value===itemId)
                        state.itemsArraySer.push({
                            id:itemId,
                            name:newItem.label,
                        })
                    }
                });
            }*/

        }

 

        const formatToStringDate = (date:any) => {
            if (!date || !date.$d) {
                return ''; 
            }

            const year = date.$y;
            const month = date.$M + 1;
            const day = date.$D < 10 ? '0' + date.$D : date.$D;
            const hours = date.$H < 10 ? '0' + date.$H : date.$H;
            const minutes = date.$m < 10 ? '0' + date.$m : date.$m;
            const seconds = date.$s < 10 ? '0' + date.$s : date.$s;

            const stringDate= `${year}-${month < 10 ? '0' : ''}${month}-${day} ${hours}:${minutes}:${seconds}`;
            return stringDate;
        };

       

        const update=(id:string)=>{


            formRef.value.validate().then(()=>{

                let hasErrors = false;
                let equipmentString: string[] = [];
                let servicesString: string[] = [];

                state.newEqArray.forEach((item)=>{


                    const txtInput = document.getElementById(`qtEq${item.id}`) as HTMLInputElement;

                    if(txtInput.value ===""){
                        //console.log('here', txtInput);
                        validationStatusQT.value[`qtEq${item.id}`] = 'error';
                        validationHelpQT.value[`qtEq${item.id}`] = t('Meet.RequiredField');
                        hasErrors = true;
                    }else if(!isNum(txtInput.value)){
                        validationStatusQT.value[`qtEq${item.id}`] = 'error';
                        validationHelpQT.value[`qtEq${item.id}`] = t('Meet.numericField');
                        hasErrors = true;
                    }else{
                        validationStatusQT.value[`qtEq${item.id}`] = '';
                        validationHelpQT.value[`qtEq${item.id}`] = '';

                        equipmentString.push(`${item.id}-qte:${txtInput.value}`);
                    }


                });


                state.newSerArray.forEach((item)=>{


                    const txtInput = document.getElementById(`qt${item.id}`) as HTMLInputElement;

                    if(txtInput.value ===""){
                        //console.log('here', txtInput);
                        validationStatusQT.value[`qt${item.id}`] = 'error';
                        validationHelpQT.value[`qt${item.id}`] = t('Meet.RequiredField');
                        hasErrors = true;
                    }else if(!isNum(txtInput.value)){
                    
                        validationStatusQT.value[`qt${item.id}`] = 'error';
                        validationHelpQT.value[`qt${item.id}`] = t('Meet.numericField');
                        hasErrors = true;
                    }else{
                        validationStatusQT.value[`qt${item.id}`] = '';
                        validationHelpQT.value[`qt${item.id}`] = '';

                        servicesString.push(`${item.id}-qte:${txtInput.value}`);
                    }


                });





                if(!hasErrors){
                    

                    let formData = new FormData();
                    // formData.append('start', form.start_date!.format('YYYY-MM-DD HH:mm'));
                    // formData.append('end', form.end_date!.format('YYYY-MM-DD HH:mm'));
                    formData.append('room_id', form.room_id);
                    formData.append('motif',form.motif);
                    formData.append('description', form.notifMail)
                
                    if(form.participant_id.length!==0){
                        formData.append('participants',form.participant_id.join(','));
                    }

                    if(form.externalParticipants.length!==0){
                        formData.append('external_participants',form.externalParticipants.join(','));
                    }

                    formData.append('equipments',equipmentString.join(','));
                    formData.append('services',servicesString.join(','));

                    //console.log('equipement', equipmentString.join(','));
                    //console.log('services', servicesString.join(','));

                    //format dates
                    const dateMeet = dayjs(form.dateMeet).format('YYYY-MM-DD');
                    const startTime = dayjs(form.startTime).format('HH:mm');

                    const startDateTime = dayjs(`${dateMeet} ${startTime}`, 'YYYY-MM-DD HH:mm');
                    const durationHours = dayjs(form.durationTime).hour();
                    const durationMinutes = dayjs(form.durationTime).minute();
                    const durationObj = dayjs.duration({ hours: durationHours, minutes: durationMinutes });
                    const endDateTime = startDateTime.add(durationObj);

                    //console.log('startTime',dayjs(startDateTime).format('YYYY-MM-DD HH:mm:ss'));
                    //console.log('endTime', dayjs(endDateTime).format('YYYY-MM-DD HH:mm:ss') );

                    formData.append('start', dayjs(startDateTime).format('YYYY-MM-DD HH:mm:ss'));
                    formData.append('end', dayjs(endDateTime).format('YYYY-MM-DD HH:mm:ss'));

                    //periodicity

                    const periodical = checkedPeriodicityUpdate.value? 1:0;
                    formData.append('periodical',periodical.toString() );

                    let periodicalSettings;
                    if(periodical === 1){       
                        if(periodicity.value === 'daily'){
                            form.periodicity = weeklyConfUpdate.value.length.toString();

                            periodicalSettings={
                                type:'daily',
                                periodicity : 1,
                                daysOfWeek : weeklyConfUpdate.value,
                                endDate : dayjs(form.periodEndDate).format('YYYY-MM-DD'),
                                time : dayjs(form.startTime).format('HH:mm'),
                                duration: dayjs(form.durationTime).format('HH:mm'),
                            }
                        }else if(periodicity.value === 'weekly' ) {

                            periodicalSettings={
                                type:'weekly',
                                periodicity : form.periodicity,
                                daysOfWeek : weeklyConf.value,
                                endDate : dayjs(form.periodEndDate).format('YYYY-MM-DD'),
                                time : dayjs(form.startTime).format('HH:mm'),
                                duration: dayjs(form.durationTime).format('HH:mm'),
                            }

                        }else if (periodicity.value === 'monthly') {

                            periodicalSettings={
                                type:'monthly',
                                periodicity : form.periodicity,
                                daysOfWeek : "",
                                endDate : dayjs(form.periodEndDate).format('YYYY-MM-DD'),
                                time : dayjs(form.startTime).format('HH:mm'),
                                duration: dayjs(form.durationTime).format('HH:mm'),
                            }

                        }else if(periodicity.value === 'yearly') {
                            //const monthDay = dayjs(monthperiod.value).format('MM') +"-"+form.dayAnnual
                            periodicalSettings={
                                type:'yearly',
                                periodicity : form.periodicity,
                                daysOfWeek : "",
                                endDate : dayjs(form.periodEndDate).format('YYYY-MM-DD'),
                                time : dayjs(form.startTime).format('HH:mm'),
                                duration: dayjs(form.durationTime).format('HH:mm'),
                            }

                        }

                       

                        formData.append("periodical_settings", JSON.stringify(periodicalSettings))

                    }else{
                        formData.append("periodical_settings", "")
                    }
                   


                   
                    loading.value=true;
                    reservationsService.update(id, formData).then((res)=>{
                        loading.value=false;
                        const calendarApi = calendarRef?.value?.getApi();
                        if (calendarApi) {
                            const start = calendarApi.view.activeStart;
                            const end = calendarApi.view.activeEnd;
                            getByMonth(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
                        }
                        showSuccessNotification(t('Meet.MeetingUpdatedSuccess'));
                    }).catch((error)=>{
                        loading.value=false;
                        console.log(error);
                        if (error && error === 'Room is occupied') {
                            showErrorNotification(t('Meet.RoomOccupied'));
                        }
                    }).finally(()=>{
                        open.value=false;

                        cancelUpdate();
                    });
                
                   

                }else{
                    console.log('error validation dynamic fields');
                }
            }).catch((error:any)=>{
                console.log('error validation form', error);
            })
        }



        //dates 
        const date = ref(dayjs());

        const formatDate = (date:any)=>{
            const year = date.getFullYear();
            const month = date.getMonth() + 1; 
            const day = date.getDate();
            const endOfMonth = `${year}-${month.toString().padStart(2, '0')}-${day}`;
            return endOfMonth;
        }
        const getEndOfMonth = (date:any) => {
            return dayjs(date).endOf('month').format('YYYY-MM-DD');
        };


        const getStartDate = () => {
            const currentMonth = date.value.month() + 1; 
            const currentYear = date.value.year();
            const startDate = dayjs(`${currentYear}-${currentMonth}-01`).format('YYYY-MM-DD');
            return startDate;
        };

        const getEndDate = () => {
            const currentMonth = date.value.month() + 1; 
            const currentYear = date.value.year();
            const endDate = dayjs(`${currentYear}-${currentMonth}-01`).endOf('month').format('YYYY-MM-DD');
            return endDate;
        };

        
        const transformDateStr =(stringDate:string)=>{
            return stringDate.split(' ')[0];
        }

        const getDateFromDateStr =(stringDate:string)=>{
            const time = stringDate.split(' ')[1]; // extracting time part
            const [hours, minutes] = time.split(':'); // splittinh hours and minutes
            return `${hours}:${minutes}`; //formatting to hh:mm
        }


        interface Reservation {
            id: string;
            customer: {
                contact_name: string;
            };
            reservation_datetime: string;
        }
        let reservations=reactive<Reservation[]>([]);
        const transformReservationsForCalendar = (res:any) => {
            return res.map((reservation:any) => {
                const color = (reservation.room.color === 'null' || !reservation.room.color) ? '#8e1c77' : reservation.room.color;


                return {
                    id:reservation.id,
                    title:reservation.motif +"  -  "+ reservation.room.name,
                    start: reservation.start,
                    end: reservation.end,
                    eventBackgroundColor:color,
                    roomColor: color 
                };
            });
            
        };

        const handleDatesSet = (info: { start: string|number|dayjs.Dayjs|Date|null|undefined; end: string|number|dayjs.Dayjs|Date|null|undefined; }) => {
            const start = dayjs(info.start).format('YYYY-MM-DD');
            const end = dayjs(info.end).format('YYYY-MM-DD');
            getByMonth(start, end);
        };


        const getAllByMonth=()=>{
            loading.value = true;
        
            reservationsService.getByMonth(getStartDate(), getEndDate()).then((response)=>{
                //console.log('reservations', response.data);
                const reservation : any[]= response.data;
                reservations.splice(0, reservations.length, ...transformReservationsForCalendar(reservation));
                //console.log('reservations', reservations);
            }).catch((error)=>{
                console.log("error get reservations", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const getByMonth =(startDate:any, endDate:any)=>{
            loading.value = true;
            let room_id="";
            if(filtredRoom.value){
                room_id=filtredRoom.value;
            }
        
            reservationsService.getByMonthAndRoom(startDate, endDate,room_id).then((response)=>{
                //console.log('reservations next month :', response.data);
                const reservation : any[]= response.data;
                reservations.splice(0, reservations.length, ...transformReservationsForCalendar(reservation));
                //console.log('reservations next month :', reservations);
            }).catch((error)=>{
                console.log("error get  reservations", error);
            }).finally(()=>{
                loading.value = false;
            });
        }


        //calender
        

        const showActionSwal = (id:string) => {

            const instance = getCurrentInstance();
            const globalProperties = instance?.appContext.config.globalProperties;


            const isManagerOrAdmin = state.userRole === 'MANAGER' || state.userRole === 'ADMIN';

            Swal.fire({
                title: t('Meet.MeetingActions'),
                text: t('Meet.MeetingTxtActions'),
                icon: 'question',
                showCancelButton: isManagerOrAdmin,
                confirmButtonColor: '#fc8019',
                confirmButtonText: t('Meet.View'),
                cancelButtonColor: "#dc3545",
                cancelButtonText:  t('Meet.Delete'),

                showDenyButton: isManagerOrAdmin,
                denyButtonColor: '#171717',
                denyButtonText:  t('Meet.Update'),

                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    showDisplayDrawer(id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    showDeletePopup(id);
                }else if (result.isDenied) {
                    console.log("update");
                    showDrawer(id);
                }
            })
           
        };


        

        const handleEventClick =(clickInfo:any)=>{

          const id=clickInfo.event._def.publicId;
          //showDisplayDrawer(id);
          showActionSwal(id);
        }

       


        const handleDateSelect =(info : any)=>{
            //console.log('info',info);

            //not open for previous dates
            const currentDate = dayjs();
            const selectedDate = dayjs(info.start);
            const endDate = dayjs(info.end);


            if(state.userRole === 'ADMIN' || state.userRole === 'MANAGER'){

                if (selectedDate.isBefore(currentDate, 'day')) {
                    //console.log('Unable to open, it is a previous date');
                    showErrorNotification( t('Meet.errorOpenModal') );
                    return;
                }

                if(info.view.type === 'timeGridWeek' || info.view.type === 'timeGridDay'){
                    const selectedTime = selectedDate.format('HH:mm');
                    //console.log('selected time', selectedTime);

                    addForm.dateMeet=selectedDate;
                    addForm.startTime=dayjs(selectedTime, 'HH:mm');

                    //duration
                    const endTime = endDate.format('HH:mm');
                    const startTimeObj = dayjs(selectedTime, 'HH:mm');
                    const endTimeObj = dayjs(endTime, 'HH:mm');
                    const durationObj = dayjs.duration(endTimeObj.diff(startTimeObj));

                    const hours = Math.floor(durationObj.asHours());
                    const minutes = durationObj.minutes();
                    const durationFormatted = dayjs().hour(hours).minute(minutes).format('HH:mm');
                    //console.log('duration formatted', durationFormatted);
                    addForm.durationTime=dayjs(durationFormatted, 'HH:mm');
                    

                    //open add drawer 

                    updateModalWidth();
                    getServices();
                    getEquipments();
                    getRooms();
                    openAdd.value=true;


                

                
                }

                if(info.view.type === 'dayGridMonth'){
                    
                    addForm.dateMeet=selectedDate;

                    //open add drawer 
                    updateModalWidth();
                    //getServices();
                    //getEquipments();
                    getRooms();
                    openAdd.value=true;
                    
                }
            }
           

            
            
        }

        //format wd :
       

        const calendarRef = ref<any>(null);

        const calendarOptions = {
            
           
            plugins: [dayGridPlugin,timeGridPlugin, interactionPlugin],
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            customButtons: { 

               
               today: {
                    text: t('Meet.Today'),
                    click: () => {
                        const calendarApi = calendarRef?.value?.getApi();
                        if (calendarApi) {
                            calendarApi.today();
                        }
                    }
                },

            },

            locales: [ arLocale, frLocale ],
            locale:locale.value,


            initialView: 'dayGridMonth',
            editable: true,
            selectable: true,
            selectMirror: true,
            weekends: true,
            events:reservations,
            select: handleDateSelect,
            eventClick: handleEventClick,
            eventDidMount: (info: { event: { extendedProps: { roomColor: any; }; }; el: { style: { backgroundColor: any; color: string; }; }; }) => {
                // setting the background color from extendedProps
                const roomColor = info.event.extendedProps.roomColor;
                if (roomColor) {
                    info.el.style.backgroundColor = roomColor;
                    info.el.style.color = '#fff'; 
                }else{
                    info.el.style.backgroundColor = '#171717';
                    info.el.style.color = '#fff'; 

                }
            },
            allDaySlot:false,
            slotMinTime:'08:00:00',
            slotMaxTime:'20:00:00',
            slotDuration:"00:30:00",
           
            datesSet: handleDatesSet,
            hiddenDays: [ 6, 0 ],

            dayMaxEventRows: true,

            views: {
                timeGrid: {
                    eventMaxStack : 1 // adjust to 2 only for timeGridWeek/timeGridDay
                }
            }
           

            

            
        };

        const resizeCalendar = () => {
            const calendarApi = calendarRef.value?.getApi();
            const width = window.innerWidth;
            let newView = 'dayGridMonth';
            let newToolbar = {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
            };

            if (width < 601) {
                newView = 'timeGridDay';
                newToolbar = {
                    left: 'prev,next',
                    center: 'title',
                    right: 'today',
                };
            } else if (width < 1025) {
                newView = 'timeGridWeek';
                newToolbar = {
                    left: 'prev,next today',
                    center: 'title',
                    right: '',
                };
            }

            if (calendarApi && calendarApi.view.type !== newView) {
                calendarApi.changeView(newView);
                calendarApi.setOption('headerToolbar', newToolbar);
            }
        };

        const getUserRole=()=>{
            let user_role = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).role : '';
            //console.log('user role', user_role);
            state.userRole=user_role;
        }

        const getFirstLetter=(name:string)=>{ 
            return name.charAt(0).toUpperCase();
        }

        //filter rooms 
        const filtredRoom = ref(null);

        const getReservationsRoom=(room:string)=>{
            console.log('the room is', room);
            const calendarApi = calendarRef?.value?.getApi();

            if (calendarApi) {
                const start = calendarApi.view.activeStart;
                const end = calendarApi.view.activeEnd;
                getByMonth(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
            }
           
            
        }





        onMounted(() => {


            resizeCalendar();
            window.addEventListener('resize', resizeCalendar);

            getUserRole();
            getRooms();

            // const calendarApi = calendarRef?.value?.getApi();
            // if (calendarApi) {
            //     const start = calendarApi.view.activeStart;
            //     const end = calendarApi.view.activeEnd;
            //     //getByMonth(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
            // }

        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', resizeCalendar);
        });

        watch(locale, (newLocale) => {
            const calendarApi = calendarRef.value?.getApi();
            if (calendarApi) {
                calendarApi.setOption('locale', newLocale);
            }
        });


        


        //new date 

       
        const getSelectedRoom=(selected : any)=>{
            console.log('selected room', selected);

            //const selectedRoom = state.rooms.find(room => room.id === selected);
            //state.selectedRoom=selectedRoom;
            //console.log('state.selected room', state.selectedRoom);
        }

        const getRoomById=(id:string)=>{
            roomsService.show(id).then((res)=>{
                //console.log('res')
            }).catch((error)=>{
                console.log(error);
            })
        }
        

        const disableWDdHours = () => {
            const startHour = parseInt(state.wdStart.split(':')[0], 10);
            const endHour = parseInt(state.wdEnd.split(':')[0], 10);
            const hours = [];

            for (let i = 0; i < 24; i++) {
                if (i < startHour || i > endHour) {
                    hours.push(i);
                }
            }

            return hours;
        }   

        
        const formatToMinutesStringDate = (date:any) => {
            if (!date || !date.$d) {
                return ''; 
            }

           
            const hours = date.$H < 10 ? '0' + date.$H : date.$H;
            const minutes = date.$m < 10 ? '0' + date.$m : date.$m;

            const stringDate= `${hours}`;
            return stringDate;
        };

        let formattedTime='';
        const handleStartTime = (info:any) =>{
            formattedTime='';
            //console.log('info', info);
            formattedTime = formatToMinutesStringDate(info);
            //console.log('formatted time',formattedTime);

            getServices();

            getEquipments();
        }

        const handleStartTimeUpdate = (info:any) =>{
            formattedTime='';
            //console.log('info', info);
            formattedTime = formatToMinutesStringDate(info);
            //console.log('formatted time',formattedTime);

            getServicesForUpdate();
            getEquipmentsForUpdate();
        }

        const disabledDuration = reactive({
            hours: [] as any[],
            minutes: [] as any[]
        })

        const disableDurationHours = () =>{
            if (!addForm.startTime) {
                return [];
            }
            //return disabledDuration.hours
            //console.log('addForm.startTime', addForm.startTime);

            //const startHour = Number.parseInt(formattedTime);
            const startHour = Number.parseInt(addForm.startTime.format('HH'));
            const endHour = parseInt(state.wdEnd.split(':')[0], 10);
            const hours = [];

            for (let i = 1; i < 24; i++) {
                
                if (startHour + i > endHour) {
                   hours.push(i);
                }

            }

            
            disableDurationMinutes(0);

            return hours;
           

        }

        const handleDurationPicker=(info:any)=>{
            if(!addForm.startTime){
                return [];
            }
            const selectedHour= formatToMinutesStringDate(info);
            const selectedMinute=getMinutesDate(info);
            const totDuration = (Number.parseInt(selectedHour)*60) + Number.parseInt(selectedMinute);
            //console.log('total duation select event', totDuration, 'selectedHour', selectedHour, 'selected minutes', selectedMinute);

            disableDurationMinutes(selectedHour);
        }

        const getMinutesDate = (date:any) => {
            if (!date || !date.$d) {
                return ''; 
            }

           
            const minutes = date.$m < 10 ? '0' + date.$m : date.$m;

            const stringDate= `${minutes}`;
            return stringDate;
        };

        let selectedDuration='';
        const getDuration=(duration:any)=>{
            selectedDuration='';
            selectedDuration=duration;
        }


        
        const calculateDisabledDuration =()=>{
           //console.log('calling ffct', addForm.durationTime)
            if(!addForm.startTime || selectedDuration=== ''){
                disabledDuration.hours = [];
                disabledDuration.minutes = [];
                return;
            }

            //console.log('enter')



            const selectedDurationH=formatToMinutesStringDate(selectedDuration);
            const selectedDurationM=getMinutesDate(selectedDuration);

            const totDurationMinutes= (Number.parseInt(selectedDurationH)*60) + Number.parseInt(selectedDurationM);
            //console.log('selected duration', selectedDurationH, 'selectedDurationM ', selectedDurationM, 'total', totDurationMinutes);



            
            const selectedTimeHours = formatToMinutesStringDate(addForm.startTime);
            const selectedTimeMinutes = getMinutesDate(addForm.startTime);
            const totSelectedTimeMinutes = (Number.parseInt(selectedTimeHours)*60) + Number.parseInt(selectedTimeMinutes);
            //console.log('selected time', selectedTimeHours, 'selectedTimeMinutes ', selectedTimeMinutes , 'total', totSelectedTimeMinutes);

            
            const endHour = parseInt(state.wdEnd.split(':')[0], 10);
            const endMinute = parseInt(state.wdEnd.split(':')[1], 10);
            const totalEndMinutes  = (endHour * 60)+endMinute;

            const hours = [];

            for (let i = 0; i < 24; i++) {
                const startTimePlusDuration = totSelectedTimeMinutes + (i * 60);
                if (startTimePlusDuration >= totalEndMinutes) {
                    hours.push(i);
                }
            }
            //console.log('hours', hours);
            disabledDuration.hours = hours;

            const minutes = [];
            const remainingMinutes = totalEndMinutes - totSelectedTimeMinutes;

            for (let i = 0; i < 60; i += 5) {
                if (i > remainingMinutes % 60) {
                    minutes.push(i);
                }
            }
            disabledDuration.minutes = minutes;

            
        }

        //watch(() => addForm.startTime, disableDurationHours);
        // watch(() => addForm.durationTime, calculateDisabledDuration);


        //minutes 
        const disableDurationMinutes = (hour:any) =>{

            if(!addForm.startTime){
                return [];
            }

            //console.log('hour', hour);
            const totDuration = (Number.parseInt(hour)*60);

            
            const selectedTimeHours = formatToMinutesStringDate(addForm.startTime);
            const selectedTimeMinutes = getMinutesDate(addForm.startTime);
            const totSelectedTimeMinutes = (Number.parseInt(selectedTimeHours)*60) + Number.parseInt(selectedTimeMinutes);
            //console.log('selected time', selectedTimeHours, 'selectedTimeMinutes ', selectedTimeMinutes , 'total', totSelectedTimeMinutes);

            
            const endHour = parseInt(state.wdEnd.split(':')[0], 10);
            const endMinute = parseInt(state.wdEnd.split(':')[1], 10);
            const totalEndHourMinutes = (endHour * 60)+endMinute;

            //console.log('end hour ', endHour, "end minute", endMinute, 'total', totalEndHourMinutes);
            const minutes = [];

            for (let i = 0; i < 60; i += 5) {
                //console.log('i', i);
                //console.log('duration+totSelectedTimeMinutes',totDuration + totSelectedTimeMinutes)
               
                if ((totDuration + totSelectedTimeMinutes) >= totalEndHourMinutes) {
                    minutes.push(i);
                }
            }

            if (minutes.length > 0) {
                minutes.shift();
            }

            //console.log('minutes', minutes);

            return minutes;
            
           


        }

        //disable duration for update 
        const disableDurationMinutesUpdate = (hour:any) =>{

            if(!form.startTime){
                return [];
            }

            //console.log('hour', hour);
            const totDuration = (Number.parseInt(hour)*60);

            
            const selectedTimeHours = formatToMinutesStringDate(form.startTime);
            const selectedTimeMinutes = getMinutesDate(form.startTime);
            const totSelectedTimeMinutes = (Number.parseInt(selectedTimeHours)*60) + Number.parseInt(selectedTimeMinutes);
            //console.log('selected time', selectedTimeHours, 'selectedTimeMinutes ', selectedTimeMinutes , 'total', totSelectedTimeMinutes);

            
            const endHour = parseInt(state.wdEnd.split(':')[0], 10);
            const endMinute = parseInt(state.wdEnd.split(':')[1], 10);
            const totalEndHourMinutes = (endHour * 60)+endMinute;

            //end hour ', endHour, "end minute", endMinute, 'total', totalEndHourMinutes);
            const minutes = [];

            for (let i = 0; i < 60; i += 5) {
                //console.log('i', i);
                //console.log('duration+totSelectedTimeMinutes',totDuration + totSelectedTimeMinutes)
               
                if ((totDuration + totSelectedTimeMinutes) >= totalEndHourMinutes) {
                    minutes.push(i);
                }
            }

            if (minutes.length > 0) {
                minutes.shift();
            }

            //console.log('minutes', minutes);

            return minutes;
            
           


        }

        const disableDurationHoursUpdate = () =>{
            if (!form.startTime) {
                return [];
            }
            //return disabledDuration.hours
            console.log('addForm.startTime', form.startTime);

            //const startHour = Number.parseInt(formattedTime);
            const startHour = Number.parseInt(form.startTime.format('HH'));
            const endHour = parseInt(state.wdEnd.split(':')[0], 10);
            const hours = [];

            for (let i = 1; i < 24; i++) {
                
                if (startHour + i > endHour) {
                   hours.push(i);
                }

            }

            
            disableDurationMinutes(0);

            return hours;
           

        }



        


        //periodicity
        const checkedPeriodicity = ref<boolean>(false);
        const checkedPeriodicityUpdate = ref<boolean>(false);


        const periodicity = ref<string>("daily");

        const radioStyle = reactive({
            display: 'flex',
            height: '35px',
            lineHeight: '35px',
        });
        const initialWeeklyConf = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

        const weeklyConf = ref([...initialWeeklyConf]);

        const monthPicker = ref<Dayjs>();
        //annual per
        const monthperiod = ref(null); 
        const annualDate = ref<boolean>(false);


        //periodicty update 
        const weeklyConfUpdate = ref([]);

        //calendar locale
        const calendarLocale = computed(() => {

            if(locale.value==='ar'){
                return ar_EG;
            }else if(locale.value==='fr'){
                return fr_FR
            }else if(locale.value==='en'){
                return en_GB
            }else{
                return en_GB
            }
        });

        const translateDay = (day:string) => {
            return t(`Meet.${day}`);
        };
        const maxDays = ref(31);

        const getSelectedMonth=(month:any)=>{
            if (month) {
                const daysInMonth = dayjs(month).daysInMonth();
                maxDays.value = daysInMonth;
            } else {
                maxDays.value = 0; 
            }
        }

        
        //check availibility for periodicty
        const validationDisabled = ref(false);

        const formRules = computed(() => {
            return validationDisabled.value ? {} : {
                dateMeet: [{ required: true, message: t('Meet.RequiredField') }],
                motif: [{ required: true, message: t('Meet.RequiredField') }],
                room_id: [{ required: true, message: t('Meet.RequiredField') }],
                startTime: [{ required: true, message: t('Meet.RequiredField') }],
                durationTime: [{ required: true, message: t('Meet.RequiredField') }],
                periodEndDate: [{ required: true, message: t('Meet.RequiredField') }],
            };
        });

        const checkAvailability=()=>{
            state.beginCheck=true;
            if(checkedPeriodicity.value === true){

                //skip validation rules by setting the flag
                validationDisabled.value = true;

                const errors = [];

                // Reset errors
                state.validationErrors = {
                    dateMeet: '',
                    periodEndDate: '',
                    participants: '',
                    room_id:'',
                    startTime:'',
                    durationTime:'',
                };

                if (!addForm.room_id || addForm.room_id === '') {
                    state.validationErrors.room_id = 'Room is required.';
                    errors.push('Room is required.');
                }

                if (!addForm.dateMeet) {
                    state.validationErrors.dateMeet = 'Date is required.';
                    errors.push('Date is required.');
                }

                
                if (!addForm.startTime) {
                    state.validationErrors.startTime = 'Start Time is required.';
                    errors.push('Start time is required.');
                }


                if (!addForm.durationTime) {
                    state.validationErrors.durationTime = 'Date is required.';
                    errors.push('Duration is required.');
                }

               
                if (!state.value || state.value.length === 0) {
                    state.validationErrors.participants = 'Participants are required.';
                    errors.push('Participants are required.');
                }

                if (!addForm.periodEndDate || addForm.periodEndDate === null) {
                    state.validationErrors.periodEndDate = 'End date is required.';
                    errors.push('End date is required.');
                }

                if (errors.length > 0) {
                    
                    return;
                }


                const nbUsers=addForm.participants.split(',').length;
                let totalUsers;
                if(addForm.external!==''){
                    const nbExternal = addForm.external.split(',').length;
                    totalUsers= nbUsers+nbExternal;
                }else{
                    totalUsers=nbUsers;
                }

                const formData= new FormData();

                const startDate = dayjs(addForm.dateMeet).format('YYYY-MM-DD');
                const endDate = dayjs(addForm.periodEndDate).add(1, 'day').format('YYYY-MM-DD');
                const durationHours = dayjs(addForm.durationTime).hour() *60;
                const durationMinutes = dayjs(addForm.durationTime).minute();
                const startTime = dayjs(addForm.startTime).format('HH:mm');
                const totalDuration = durationHours + durationMinutes;
                
                if(periodicity.value === 'daily' || periodicity.value === 'weekly'){
                    //console.log('aaaa', periodicity.value)
                    const daysString = weeklyConf.value.map(day => day).join(',');
                    formData.append('weekDays', daysString );

                }else{
                    formData.append('weekDays', "" );   
                    formData.append('type', periodicity.value );
                    formData.append('periodicity', addForm.periodicity );
                }

                //console.log('duration hours',durationHours,"duration minutes", durationMinutes )
                formData.append('start_date', startDate.toString() );
                formData.append('end_date', endDate.toString() );
                formData.append('start_time', startTime.toString() );
                formData.append('duration', totalDuration.toString());
                formData.append('capacity', totalUsers.toString() );

                const start_time = dayjs(addForm.startTime, 'HH:mm');



                reservationsService.checkRoomAvailibility(formData).then((res)=>{
                    //console.log('res', res);
                    const availabilityData = res.rooms;
                    const roomId = addForm.room_id;
                    const periodicalAvailability = availabilityData.find((item: { id: string; }) => item.id === roomId)?.periodical_availability;

                    addForm.AllSlots = periodicalAvailability;

                    //console.log('periodical_availability for room_id', roomId, periodicalAvailability);

                    //check availibility
                    let allAvailable = true;
                    periodicalAvailability.forEach((item: any) => {
                        item.availability.forEach((period: any) => {
                            if (period.active === 0) {
                                //console.log('no go');
                                allAvailable = false;
                            }
                        });
                    });

                    if (allAvailable) {
                        //console.log('go go');
                        state.periodical = true;
                        state.displaySucees=true;
                    } else {
                        state.periodical = false;
                    }

                    // periodicalAvailability.forEach((item:any)=>(
                    //     item.availability.forEach((period:any)=>{
                    //         //if there's one slot not available , so all periodical not available
                    //         if(period.active === 0){
                    //             console.log('no go');
                    //             state.periodical=false;
                    //             return;
                    //         }else{
                    //             console.log('go');
                    //             state.periodical=true;
                    //         }
                    //     })


                    // ))

                   
                }).catch((error)=>{
                    console.log('error api check availibilty', error);
                }). finally(()=>{
                    // re-enable validation after checking availability
                    validationDisabled.value = false;
                })

                
            }else{
                console.log('')
            }
           


            








            
        }

        const formState = computed(() => ({
            room_id: form.room_id,
            participants: state.value,
            dateMeet: form.dateMeet,
            startTime:form.startTime,
            durationTime:form.durationTime,

        }));

        watch(
            formState,
            (newVal) => {
                updateBeginSearch(newVal.room_id, newVal.participants, newVal.dateMeet, newVal.startTime, newVal.durationTime);
            },
            { deep: true } 
        );

        function updateBeginSearch(room_id: string, participants: string|any[], dateMeet: any, startTime :any, durationTime:any) {
            if (!room_id || participants.length === 0 || !dateMeet || !startTime || !durationTime ) {
                state.beginCheck = false;
            } else {
                state.beginCheck = true;
            }
        }


        const checkAvailabilityUpdate=()=>{ 
            

           
            if(checkedPeriodicityUpdate.value === true){
                if(
                    !form.dateMeet || !form.startTime || !form.allParticipants || !form.room_id
                ){
                    console.log('go back');
                    state.beginCheck=false;
                    return;

                }

                
                state.beginCheck=true;
                let totalUsers;
                // let totalInternal = particpants_array.value.length;
                // let totalExternal = external_participants.value.length;
                totalUsers= form.allUsersUpdate;
                

                const formData= new FormData();

                const startDate = dayjs(form.dateMeet).format('YYYY-MM-DD');
                const endDate = dayjs(form.periodEndDate).add(1, 'day').format('YYYY-MM-DD');
                const durationHours = dayjs(form.durationTime).hour() *60;
                const durationMinutes = dayjs(form.durationTime).minute();
                const startTime = dayjs(form.startTime).format('HH:mm');
                const totalDuration = durationHours + durationMinutes;
                
                if(periodicity.value === 'daily' || periodicity.value === 'weekly'){
                    console.log('aaaa', periodicity.value)
                    const daysString = weeklyConfUpdate.value.map(day => day).join(',');
                    formData.append('weekDays', daysString );

                }else{
                    formData.append('weekDays', "" );   
                    formData.append('type', periodicity.value );
                    formData.append('periodicity', form.periodicity );
                }

                console.log('duration hours',durationHours,"duration minutes", durationMinutes )
                formData.append('start_date', startDate.toString() );
                formData.append('end_date', endDate.toString() );
                formData.append('start_time', startTime.toString() );
                formData.append('duration', totalDuration.toString());
                formData.append('capacity', totalUsers.toString() );
                formData.append('reservation_id', form.id);

                const start_time = dayjs(form.startTime, 'HH:mm');



                reservationsService.checkRoomAvailibilityUpdate(formData).then((res)=>{
                    console.log('res', res);
                    
                    const availabilityData = res.rooms;
                    const roomId = form.room_id;
                    const periodicalAvailability = availabilityData.find((item: { id: string; }) => item.id === roomId)?.periodical_availability;

                    form.AllSlots = periodicalAvailability;

                    console.log('periodical_availability for room_id', roomId, periodicalAvailability);

                    //check availibility
                    let allAvailable = true;
                    periodicalAvailability.forEach((item: any) => {
                        item.availability.forEach((period: any) => {
                            if (period.active === 0) {
                                console.log('no go');
                                allAvailable = false;
                            }
                        });
                    });

                    if (allAvailable) {
                        console.log('go go');
                        state.periodical = true;
                        state.displaySucees=true;
                    } else {
                        state.periodical = false;
                    }

                    // periodicalAvailability.forEach((item:any)=>(
                    //     item.availability.forEach((period:any)=>{
                    //         //if there's one slot not available , so all periodical not available
                    //         if(period.active === 0){
                    //             console.log('no go');
                    //             state.periodical=false;
                    //             return;
                    //         }else{
                    //             console.log('go');
                    //             state.periodical=true;
                    //         }
                    //     })


                    // ))

                   



                   
                }).catch((error)=>{
                    console.log('error api check availibilty', error);
                })

                
            }   
           


            








            
        }

        const openSlots = ref<boolean>(false);
        const openDiscover=()=>{
            openSlots.value=true;
        }

       const isButtonDisabled = computed(() => {
        return !form.room_id || 
                !form.periodEndDate || 
                !form.durationTime || 
                !form.startTime ||
                form.allParticipants.length === 0;
        });


       



    


        return{
            getReservationsRoom,
            filtredRoom,
            handleStartTimeUpdate,
            confirmService,
            formAddServiceRef,
            formAddService,
            serviceAddModal,
            openAddService,
            formRules,
            isButtonDisabled,
            capitalize,
            checkAvailabilityUpdate,
            openSlots,
            openDiscover,
            disablePeriodDates,
            checkAvailability,
            getSelectedRoom,

            maxDays,
            getSelectedMonth,
            translateDay,
            calendarLocale,
            annualDate,
            monthperiod,
            checkedPeriodicityUpdate,
            weeklyConfUpdate,
            checkedPeriodicity,
            monthPicker,
            weeklyConf,
            radioStyle,
            periodicity,

            calculateDisabledDuration,

            disabledDuration,
            modalWidth,
            t,
            locale,
            loading,
            textDirection,
            simpleImage,
            navigatePage,
            state,
            formRef,
            addFormRef,
            addForm,
            form,
            rules,
            openAdd,
            showAddDrawer,
            resetFormAdd,
            fetchUser,
            handleUsersSelect,
            addReservation,
            openDisplay,
            showDisplayDrawer,
            particpants_array,
            handleDateChange,
            open,
            showDrawer,
            update,
            cancelUpdate,
            calendarRef,
            calendarOptions,
            options,
            handleMultiChange,
            getValidatingStatusQT,
            getValidatingHelpQT,
            optionServices,
            handleMultiChangeServices,
            equipmenets_array,
            services_array,
            optionsUpdate,
            handleUpdateItem,
            optionsUpdateSer,
            handleUpdateSer,
            formatDisplatyDate,
            extractTime,
            handleUsersUpdateSelect,
            disableDates,
            disabledRangeTime,
            openUserModal,
            openInviteUsers,
            cancelInviteModal,
            inviteFormRef,
            inviteForm,
            user,
            invitations,
            handleMultiUserChange,
            usersRules,
            inviteUser,
            openEqModal,
            showEquipments,
            handleCancelEq,
            addEquipement,
            formEquipmentRef,
            formEquipment,
            openSerModal,
            handleCancelSer,
            formServiceRef,
            formService,
            showServices,
            addService,
            handleChangePicker,
            fetchUserUpdate,
            filteredUsersUpdate,

            fetchParticipant,
            external_participants,
            getFirstLetter,


            hackValue,
            value,
            disabledDate,
            onOpenChange,
            onChange,
            onCalendarChange,

            valueUpdate,
            hackValueUpdate,
            disableDateUpdate,
            onOpenChangeUpdate,
            onChangeUpdate,
            onCalendarChangeUpdate,

            


            disableWDdHours,
            handleStartTime,
            disableDurationHours,
            disableDurationMinutes,
            getDuration,
            handleDurationPicker,
            disableDurationMinutesUpdate,
            disableDurationHoursUpdate
        }

       
    },
})
</script>

<style >

.ant-select-selector{
    text-align: start;
}


a.fc-col-header-cell-cushion{
    color: var(--secondary-color, #fc8019);
}

.fc .fc-daygrid-day-number {
    color: var(--secondary-color, #fc8019) !important;
}

.fc-timegrid-more-link{
    background: #d3d9ef;
    color: #3b8dff;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 2px;
    margin-bottom: 5px;
    padding: 10px;
}


.fc .fc-more-popover .fc-popover-body {
    min-width: 220px;
    padding: 10px;
    overflow-y: auto;
    max-height: 500px;
}

</style>
<style scoped>
    @import '../../assets/css/fullCalender.css';

  

    .mr-l{
        margin-left: 15px;
    }

    .space-label{
        margin-left: 10px;
    }

    .month-input{
        margin-left: 5px;
        width: 50%;
    }

    .period-container{
        margin: 20px 0px;
    }

    .calendar-event-title {
        max-width: 100%; 
        white-space: normal; 
        overflow: hidden;  
        text-overflow: ellipsis;
        display: -webkit-box;  
        -webkit-line-clamp: 2;  
        -webkit-box-orient: vertical;  
        word-break: break-word;  
    }

    .breadcrumb-icon{
        cursor: pointer;
    }

   .add-btn {
        color: var(--primary-color, #171717);
        border: 2px solid var(--primary-color, #171717);
        box-shadow: inset 0 0 0 0 var(--primary-color, #171717);

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
    }

    .add-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 var(--primary-color, #171717);
        border-color: var(--primary-color, #171717);
    }

    .request-btn{
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        padding: 8px;
        background:#fff ;
        color: var(--primary-color, #171717);
        border: none;
        border-radius: 6px;
        line-height: 1.5;
        cursor: pointer;
        border: 2px solid var(--primary-color, #171717);
        box-shadow: inset 0 0 0 0 var(--primary-color, #171717);
    }

    .request-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 var(--primary-color, #171717);
        border-color: var(--primary-color, #171717);
    }

    .btn-container {
        padding: 10px;
        background-color: #f5f5f5cb;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border-radius: 6px;
    }

    .add-btn-container{
        display: flex;
        justify-content: flex-end;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

        
    .info-title{
        font-size: 14px;
        font-weight: 600;
        padding-left: 15px;
        margin-right: 10px;
    }

    .info-title-rtl{
        font-size: 14px;
        font-weight: 600;
        padding-right: 15px;
    }

    .title-list{
        font-weight: 400 !important;
        color: black !important;
        margin-left:10px ;
    }

    .title-list-rtl{
        font-weight: 400 !important;
        color: black !important;
        margin-right:10px ;
    }

    .list-item-container{
        display: flex !important;
        align-items: baseline !important;
        margin-left: 15px;
        margin-bottom: 10px;

    }

    
    .list-item-container-rtl{
        display: flex !important;
        align-items: baseline !important;
        margin-left: 15px;
        margin-bottom: 10px;
        direction: rtl;

    }

    .switch-container {
        display: flex;
        align-items: center;
    }

    .switch-container p {
        margin-right: 16px;
        font-size: 14px;
        font-weight: 600;
    }

    .approve-tag-container{
        display: flex;
        justify-content: flex-end;
    }

    .ingredient-des{
        margin-bottom: 15px;
    }

    .ingredient-des-rtl{
        margin-bottom: 15px;
        direction: rtl;
    }

    .mr{
        margin-right: 10px;
    }

    .tag-container{
        margin-top: 5px;
    }

    
    .start-date{
        margin-right: 15px;
    }

    .invite-users-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .invite-user-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .users-icon{
        font-size: 35px;
    }

    .label-container{
        margin-bottom: 10px;

    }

    .txt-form{
        color: #d9d9d9;
    }

    .modal-title{
        font-weight: 500;
        font-size:20px ;
    }

    .tooltip-icon{
     margin-right: 5px;
    }

    .tooltip-icon-rtl{
     margin-left: 5px;
    }

 
   /* CSS for RTL */
   .is-rtl {
     direction:rtl;
   }

    /* filter container */
    .filter-container{
        display: flex;
        justify-content: space-between;
    }

    .add-btn-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .select-filter{
        width: 100% !important;
        margin-right: 10px;
    }

    .filter-date{
        width: 100%;
    }

    /* new date*/
    .date-picker-date{
        width: 100%;
    }

    .label{
        font-weight: 600;
    }

    .label-container{
        margin-bottom: 10px;
    }

    .periodicty-container{
        margin-bottom: 20px;
    }

    .periodicity-input{
        width: 20%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .days-container{
        display: flex;
        flex-direction: column;
        margin-right: 30px;
    }

    .check-container{
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .month-picker-input{
        margin-left: 10px;
    }

    .check-container{
        display: flex;
        gap:10px;
    }

    .period-date{
        width: 50% !important;   
        margin-left: 5px;
    }

    .periodicty-label{
        font-weight: 600;
       
    }
    .ml{
        margin-left: 5px!important;
    }
    .mr{
        margin-right: 5px!important;
    }


    .required-icon{
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
    }

    .display{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        align-items: center;
    }


    .display-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    .periodical-error{
        color:#ff4d4f;
        font-size: 14px;
    }

    .periodical-success{

        color:green;
        font-size: 14px;

    }

    .availibility-btn{
        padding: 5px 10px;
        background-color: var(--primary-color, #171717);
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
        cursor: pointer;
        margin-right: 10px;
    }

    .availibility-btn:disabled{
        cursor: not-allowed;
    }

    .discover-title{
        font-weight: 600;
        cursor: pointer;
    }

    
.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

.action-btn-drawer{
    display: flex !important;
    justify-content: center;
    align-items: center;
}


@media (min-width: 768px) {
   .add-btn{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}

.error-border{
    border: 2px solid #ff4d4f;
    border-radius: 6px;
}

.error-message{
    color: #ff4d4f;
}

.periodicity-display-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.number{
    margin: 5px;
}

 

   
  





    

</style>

