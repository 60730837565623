<template>

    <Loader :is-loaded="!loading"></Loader>

    <a-row>
        <a-col :span="24" class="options-container">
            <a-segmented v-model:value="activeTab" :options="tabList" @change="onTabChange"/>
        </a-col>
    </a-row>

        <template v-if="activeTab === t('Meet.Profile') ">
            <a-card class="profile-card" >
                <template #title>
                    <a-row class="title-row"  align="middle">
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span:12 }" :xxl="{ span:12 }" class="title-text">
                            <p class="title-txt">{{ t('Meet.ProfileDetails') }}</p>
                        </a-col>
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span:12 }" :xxl="{ span:12 }" class="avatar-col">

                            <div class="avatar-wrapper">
                                <a-avatar  :size="70">
                                    <img v-if="profileForm.photo" :src="profileForm.photo" alt="avatar" />
                                    <p v-else class="avatar-text">{{state.firstName}}</p>
                                </a-avatar>
                                <CameraOutlined class="camera-icon" @click="triggerFileInput"/>
                                <input type="file" ref="fileInput" class="file-input" @change="handleFileChange" />

                            </div>
                        </a-col>
                    </a-row>
    
                </template>
                <a-form ref="profileRef" :model="profileForm"  :rules="rules" layout="vertical">
                        <a-row :gutter="16">   
                            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span:12 }" :xxl="{ span:12 }">
                                <a-form-item  name="firstName">
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.FisrtName') }}</span>
                                    </template>
                                    <a-input  v-model:value="profileForm.firstName" :placeholder="t('Meet.FisrtName')" class="input-form" />
                                </a-form-item>
                            </a-col>
                            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span:12 }" :xxl="{ span:12 }">
                                <a-form-item  name="lastName">
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.LastName') }}</span>
                                    </template>
                                    <a-input  v-model:value="profileForm.lastName" :placeholder="t('Meet.LastName')" class="input-form" />
                                </a-form-item>
                            </a-col>
                        </a-row>    
                        <a-row :gutter="16">   
                            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span:12 }" :xxl="{ span:12 }">
                               <a-form-item
                                    name="email" has-feedback
                                    :validate-status="validatingStatus"
                                    :help="validatingHelp"
                                >

                                    <template #label>
                                    <span class="label-form">{{ t('Meet.Email') }}</span>
                                    </template>

                                    <a-input v-model:value="profileForm.email" :placeholder="t('Meet.Email')"  @blur="validateEmail" />
                                </a-form-item>
                            </a-col>

                            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span:12 }" :xxl="{ span:12 }">
                               <a-form-item
                                    name="phone" has-feedback
                                >

                                    <template #label>
                                    <span class="label-form">{{ t('Meet.Phone') }}</span>
                                    </template>

                                    <a-input v-model:value="profileForm.phone" :placeholder="t('Meet.Phone')" />
                                </a-form-item>
                            </a-col>
                           
                        </a-row>    
                </a-form>

                <a-row class="card-footer">
                    <a-space>
                        <a-button  type="primary" @click="updateUser(profileForm.id)">{{ t('Meet.SaveChanges') }}</a-button>
                        <a-button type="primary" danger @click="resetFormProf">{{ t('Meet.Cancel') }}</a-button>
                    </a-space>
                </a-row>
            </a-card>


        </template>
        <template v-else-if="activeTab === t('Meet.Security')">
            <a-card class="profile-card">
                <template #title>
                    <a-row class="title-row"  align="middle">
                        <a-col :span="12" class="title-text">
                            <p>{{ t('Meet.ChangePassword')}}</p>
                        </a-col>
                    </a-row>
                </template>

                <a-form  ref="securityRef" :model="securityForm"  :rules="securityRules" layout="vertical">
                    <a-row>
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span:24 }" :xxl="{ span:24 }">
                            <a-form-item  has-feedback name="current">
                                <template #label>
                                    <span class="label-form">{{ t('Meet.CurrentPass') }}</span>
                                </template>
                                <a-input-password v-model:value="securityForm.current"  autocomplete="off" />

                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span:24 }" :xxl="{ span:24 }">
                            <a-form-item  has-feedback name="pass">
                                <template #label>
                                    <span class="label-form">{{ t('Meet.Password') }}</span>
                                </template>
                                <a-input-password v-model:value="securityForm.pass" type="password" autocomplete="off" />

                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-item  has-feedback name="checkPass">
                                <template #label>
                                    <span class="label-form">{{ t('Meet.Confirmpassword') }}</span>
                                </template>
                                <a-input-password v-model:value="securityForm.checkPass" type="password" autocomplete="off" />
                            </a-form-item>
                        </a-col>
                    </a-row>

                </a-form>
                <a-row class="card-footer">
                    <a-space>
                        <a-button  type="primary" @click="updateUserPass()">{{ t('Meet.UpdatePass') }}    </a-button>
                        <a-button type="primary" danger @click="resetFormPass">{{ t('Meet.Cancel') }}</a-button>
                    </a-space>
                </a-row>
            </a-card>
        </template>


      
  
 
</template>
<script  lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, watch, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
EyeOutlined,
DeleteOutlined,
SearchOutlined,
PercentageOutlined,
EditOutlined,
CameraOutlined,

} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { userService} from '@/_services'
import dayjs , { Dayjs }from 'dayjs'; 
import { useI18n } from 'vue-i18n';
import Loader from '../../components/Looder.vue'

export default defineComponent({
    name: 'indexProfile',
    components: {
        Loader,
        // EyeOutlined,
        // DeleteOutlined,
        // SearchOutlined,
        // PercentageOutlined,
        // EditOutlined,
        // LbrxLoaderSpinner,
        CameraOutlined
    },
    setup() {

        const loading = ref(false);
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );

        const { t } = useI18n();

        const dirtyFields = reactive({
            firstName: false,
            lastName: false,
            email: false,
            userName: false,
        });
        
       
        


        const state = reactive({
            firstName: '',
        });

        const activeTab = ref(t('Meet.Profile'));
        const tabList = [t('Meet.Profile'), t('Meet.Security')];

        //form 
        const profileRef = ref();
        const securityRef = ref();

        const profileForm = reactive({
            id: '',
            firstName: '',
            lastName: '',
            userName:'',
            email:'',
            phone:'',
            photo:null as string | null,
            created_at: null as Dayjs | null,
        });
      
        let changedEmail=false;

        watch(()=>profileForm.email,(newValue, oldValue) =>{
            console.log('old value', oldValue);
            console.log('new value', newValue);
            const userEmail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).email : '';

            if(newValue!==oldValue && userEmail!==newValue){
                changedEmail=true;
            }
        });
        

        const securityForm = reactive({
            current:'',
            pass: '',
            checkPass: '',
        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Meet.RequiredField') }],
            firstName: [{ required: true, message: t('Meet.RequiredField') }],
            lastName: [{ required: true, message: t('Meet.RequiredField') }],
            email: [
                { required: true, message: t('Meet.RequiredField') , trigger: ['blur', 'change'] },
                {
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.resolve(); 
                        }
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                            return Promise.reject(t('Meet.invalidEmail'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                }
                 
            ], 
            userName: [{ required: true, message: t('Meet.RequiredField')}],

        };

        const validatePass = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Meet.RequiredField'));
            } else if (value.length < 8) {
                return Promise.reject(t('Meet.PasswordLengthError'));
            }else if (value === securityForm.current) {
                return Promise.reject(t('Meet.passwordIndenticalError'));
            }else {
                if (securityForm.checkPass !== '') {
                    securityRef?.value?.validateFields('checkPass');
                }
                return Promise.resolve();
            }
        };
        const validatePass2 = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Meet.RequiredField'));
            } else if (value !== securityForm.pass) {
                return Promise.reject(t('Meet.PasswordMatchError'));
            } else {
                return Promise.resolve();
            }
        };
        const securityRules: Record<string, Rule[]> = {
            current: [{ required: true, message: t('Meet.RequiredField') }],
            pass: [{ required: true, validator: validatePass, trigger: 'change' }],
            checkPass: [
                { required: true,validator: validatePass2, trigger: 'change' }
            ],
        };

        const validatingStatus = ref('');
        const validatingHelp = ref('');

        const validateEmail = () => {

            console.log('begin validation email');
            const email = profileForm.email.trim();
            console.log('changed email value', changedEmail);
            if (changedEmail===false) {
                // email isn't changed, no need to validate
                console.log('no email validation ');
                console.log(dirtyFields)
                return;
            }
            
            const payload = { email: email };
            validatingStatus.value = 'validating'; 
            validatingHelp.value = t('Meet.validationEmailTxt');

            // userService.verifyEmail(payload)
            // .then(response => {
            //     console.log('response', response);
            //     if (response.message==="Email available for use") {
            //         validatingStatus.value = 'success';
            //         validatingHelp.value = t('Meet.EmailValidRep');
            //         changedEmail=false;
            //     } else {
            //         validatingStatus.value = 'error'; 
            //         validatingHelp.value = t('Meet.EmailInUse');
            //     }
            // })
            // .catch(error => {
            //     console.error('Error validating email:', error);
            //     validatingStatus.value = 'error'; 
            //     validatingHelp.value = t('Meet.EmailInUse');
            // });
        };

        const resetFormPass = () => {
            securityRef.value.resetFields();
        };

        const resetFormProf = () => {
            profileRef.value.resetFields();
        };


        const getUserById=(id:string)=>{
            
            userService.show(id)
            .then(res => {
                console.log('user', res.data);

                profileForm.id = res.data.id;
                profileForm.firstName=res.data.first_name;
                profileForm.lastName=res.data.last_name;
                profileForm.email=res.data.email;
                profileForm.phone=res.data.phone;
                profileForm.created_at=res.data.created_at;
            })
            .catch(error => {
                console.error('Error fetching user by id:', error);
            });
        }

        const onTabChange = (tab: string) => {
            if (tab === 'Profile') {
                const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';
                getUserById(userId); 
            }
        }

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                color: 'white'
                }
            });
            resetFormPass();
        };

        const updateUser =(id:string)=>{
        
            profileRef!.value!
            .validate()
            .then(()=>{

                let formData= new FormData();
                formData.append('first_name', profileForm.firstName);
                formData.append('last_name', profileForm.lastName);
                formData.append('phone', profileForm.phone);
                formData.append('email', profileForm.email);

                loading.value=true;
                userService.update(id,formData).then((res)=>{
                    getUserById(id);
                    showSuccessNotification(t('Meet.userUpdatedSuccess'));
                    loading.value = false;
                }).catch((err) => {
                    console.log(err);
                    loading.value = false;
                }); 

                
               
            }).catch((error:any)=>{
                console.log('error validation form', error);
            });
        }

        
        const updateUserPass =()=>{
        
            securityRef!.value!
            .validate()
            .then(()=>{
                const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';

                let formData=new FormData();
                formData.append('old_password',securityForm.current);
                formData.append('password', securityForm.pass);
                loading.value=true;
                userService.changePassword(userId,formData ).then((response)=>{
                    resetFormPass();
                    loading.value = false;

                    showSuccessNotification(t('Meet.PasswordUpdateSuccess'));

                }).catch((err) => {
                    loading.value = false;
                    if (err=== "wrong old password") {
                        showErrorNotification(t('Meet.currentPassWrong'));
                    } else {
                        console.log('Error updating passwordSS', err);
                    }
                    
                });
                
            }).catch((error:any)=>{
                console.log('error validation form', error);
            });
        }


        const getFirstLetter=(name:string)=>{ 
            state.firstName= name.charAt(0).toUpperCase();
        }

        //update user photo
        const previewImage = ref('');
        const isModalVisible = ref(false);

        const triggerFileInput = () => {
            console.log('trigger');
            const fileInput = document.querySelector('.file-input') as HTMLInputElement;
            if (fileInput) {
                fileInput.click();
            }
        };

        const handleFileChange = (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (target.files && target.files.length > 0) {
                const file = target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    profileForm.photo = e.target?.result as string;
                    console.log('profileForm.photo', profileForm.photo);
                };
                reader.readAsDataURL(file);
            }   
        };

       





        onMounted(() => {
            const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';
            const firstName = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).first_name : '';

            getUserById(userId);
            getFirstLetter(firstName.toString());
        });

        


    



        return{
            t,
            locale,
            textDirection,
            Loader,
            loading,
            state,
            activeTab,
            tabList,
            profileRef,
            securityRef,
            profileForm,
            securityForm,
            onTabChange,
            updateUser,
            rules,
            securityRules,
            validatingStatus,
            validatingHelp,
            validateEmail,
            dirtyFields,
            resetFormPass,
            resetFormProf,
            updateUserPass,
            triggerFileInput,
            handleFileChange,
            showErrorNotification,

          








        }    
    },
})
</script>
<style scoped>


    .file-input {
        display: none; 
    }

    .options-container{
        display: flex;
        margin-bottom: 24px;
    }

   
    .profie-card{
        width: 100%;
    }

    .label-form {
        font-size: 14px;
        font-weight: 600;
    }

    .input-form{
        padding:.422rem .875rem;
        color: #6f6b7d;

    }

    .card-footer{
        margin-top: 24px;
    }

    .card-title{
        display: flex;
    }

    .title-row{
        padding: 0 10px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .title-text{
        text-align: left;
    }
    .title-text p{
        font-size: 18px;
        color: #4B4650;
    }

    .avatar-col {
        text-align: right; 
        height: 50px;
    }

   
    .avatar-text{
        font-size: 30px;
        line-height: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }

    .avatar-wrapper {
        position: relative;
        display: inline-block;
    }

    .camera-icon{
        cursor: pointer;
        font-size: 18px;

        position: absolute;
        bottom: 0;
        right: 0;
        width: 24px; 
        height: 24px; 

        background-color: #fff;
        border-radius: 15px;

        display: flex;
        align-items: center;
        justify-content: center;

        
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    
    /* CSS for RTL */
    .is-rtl {
        direction:rtl;
    }

    .title-txt{
        font-size: 14px;
    }

    .label-form{
        font-size: 14px;
    }

    @media (min-width: 768px) {

        .label-form{
            font-size: 16px;
        }

        .title-txt{
            font-size: 18px;
        }

    }

    @media (min-width: 1024px) {

        .title-txt{
            font-size: 20px;
        }

        .label-form{
            font-size: 18px;
        }

    }

    
   

</style>
