<template>

    <Looder :is-loaded="!loading"></Looder>
   
   <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
           <span class="txt"> {{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
            <span class="txt">{{t('Meet.Services')}} </span>
        </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer" >
            {{ t('Meet.Add') }}
        
        </a-button>
    </div>
    

    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
        
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        
        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template>

        <template #bodyCell="{ column, record }">
            <div class="table-cell">
                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                        <template #title>
                            <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                        </template>
                        <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.id)">
                            <template #icon>
                            <EyeOutlined class="tbl-icon" />
                            </template>
                        </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Edit') }}</span>
                            </template>

                            <a-button class="tbl-btn" type="primary" @click="() => showDrawer(record.id)">
                            <template #icon>
                                <EditOutlined class="tbl-icon" />
                            </template>
                            </a-button>

                        </a-tooltip>


                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Delete') }} </span>
                            </template>
                            <a-button class="tbl-btn" type="primary" danger @click="showDeletePopup(record.id)">
                                <template #icon>
                                <DeleteOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'description'">
                    <span v-if="record.description != 'null' ">{{record.description}}</span>
                    <span v-else></span>
                </template>
                <span v-if="column.key!=='description'" >{{ record[column.dataIndex] }}</span>

            </div>
        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

                
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows"/>

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Meet.page')}}</a-select-option>
        </a-select>
    </div>

    <!-- add drawer --> 
    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >

        <template #title>
            <p  class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.Addservice')}}</p>
        </template>


        <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.name" :placeholder="t('Meet.Name')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item   name="description" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="addForm.description" :rows="4" :placeholder="t('Meet.Description')" />
                    </a-form-item>
                </a-col>
            </a-row>

        
            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item name="managers">

                        <template #label>
                            <span class="label-form">{{ t('Meet.Managers') }}</span>
                        </template>

                        <a-select
                            v-model:value="state.managers"
                            mode="multiple"
                            label-in-value
                            :placeholder="t('Meet.selectManagers')"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetching ? undefined : null"
                            :options="state.allManagers"
                            @search="fetchManager"
                        >

                            <template v-if="state.fetching" #notFoundContent>
                                <a-spin size="small" />
                            </template>

                        </a-select>

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">

                <a-col :span="24" >
                    <a-form-item name="time" >

                        <template #label>
                            <a-tooltip>
                                <template #title>{{t('Meet.minResToolTip')}}</template>
                                <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                            </a-tooltip>
                            <span class="label-form">{{ t('Meet.minResTime') }}</span>
                        </template>
                        <div class="customize-input">
                            <a-input-number v-model:value="addForm.time" step="1" min="0" style="width: 100%">
                                <template #prefix><ClockCircleOutlined /></template>
                            </a-input-number>
                        </div>

                    </a-form-item>

                </a-col>
               
            </a-row>

            <a-row>
                <a-col :span="2">
                    <a-form-item   name="icons" >

                        <template #label>
                            <span class="label-form">{{ t('Meet.Icon') }}</span>
                        </template>

                        <a-select v-model:value="addForm.icon">
                            <a-select-option v-for="icon in icons" :key="icon.name" :value="icon.name">
                                <img :src="icon.path" :alt="icon.name" width="20" height="20" style="margin-right: 8px;" />
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                </a-col>
            </a-row>

            <!-- <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="photo">
                    <template #label>
                        <span class="label-form">{{ t('Meet.Photo') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleChange"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                        <p class="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                        <p class="ant-upload-hint">
                        {{ t('Meet.dropTitle2') }}
                        </p>

                    </a-upload-dragger>   
                   


                    </a-form-item>
                </a-col>
            </a-row> -->

          
          
            
        </a-form>
        <template #footer>
            <a-space>
            <a-button  type="primary" @click="add">{{ t('Meet.Add') }}</a-button>
            <a-button type="primary" danger  @click="resetFormAdd">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>
    </a-drawer>

    <!-- update drawer -->
    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :width="modalWidth"
        :placement="locale === 'ar' ? 'left' : 'right'"

    >

        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.Updateeservice')}}</p>
        </template>

        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row>
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="form.name" :placeholder="t('Meet.Name')" class="input-form"  />
                    </a-form-item>
                </a-col>     
            </a-row>
            
            <a-row>
                <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Meet.Description')" />
                    </a-form-item>
                </a-col>   
            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-item  name="managers">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Managers') }}</span>
                        </template>
                        <a-select
                            v-model:value="form.managers"
                            mode="multiple"
                            label-in-value
                            :placeholder="t('Meet.selectManagers')"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetchingUpdate ? undefined : null"
                            :options="state.allManagersUpdate"
                            @search="fetchManagerUpdate"
                        >

                            <template v-if="state.fetchingUpdate" #notFoundContent>
                                <a-spin size="small" />
                            </template>

                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">

                <a-col :span="24" >
                    <a-form-item name="time" >

                        <template #label>
                            <a-tooltip>
                                <template #title>{{t('Meet.minResToolTip')}}</template>
                                <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                            </a-tooltip>
                            <span class="label-form">{{ t('Meet.minResTime') }}</span>
                        </template>
                        <div class="customize-input">
                            <a-input-number v-model:value="form.time" step="1" min="0" style="width: 100%">
                                <template #prefix><ClockCircleOutlined /></template>
                            </a-input-number>
                        </div>

                    </a-form-item>

                </a-col>
               
            </a-row>

            <a-row>
                <a-col :span="2">
                    <a-form-item   name="icons" >

                        <template #label>
                            <span class="label-form">{{ t('Meet.Icon') }}</span>
                        </template>

                        <a-select v-model:value="form.icon">
                            <a-select-option v-for="icon in icons" :key="icon.name" :value="icon.name">
                                <img :src="icon.path" :alt="icon.name" width="20" height="20" style="margin-right: 8px;" />
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                </a-col>
            </a-row>


            <!-- <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="photo">
                    <template #label>
                        <span class="label-form">{{ t('Meet.Photo') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleUpdateChangeFile"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                        <p class="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                        <p class="ant-upload-hint">
                         {{ t('Meet.dropTitle2') }}
                        </p>

                    </a-upload-dragger> 

                    <template v-if="form.photo!==null && !state.fileChanged">
                        <div>
                            <p class="photo-info" @click="() => setVisible(true)"><PaperClipOutlined /><span class="photo-title"> {{extractFilename(form.photo)}}</span> <DeleteOutlined  @click="deletePhoto" /></p>

                            <a-image
                                :width="200"
                                :style="{ display: 'none' }"
                                :preview="{
                                visible,
                                onVisibleChange: setVisible,
                                }"
                                :src="form.photo"
                            />
                        </div>
                    </template>       
                   


                    </a-form-item>
                </a-col>
            </a-row> -->
           
        </a-form>


        <template #footer>
            <a-space>
                <a-button  type="primary" @click="update(form.id)">{{ t('Meet.Update') }}</a-button>
                <a-button type="primary" danger @click="cancelUpdate">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>

    </a-drawer>

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >

        
        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.serviceInformation')}}</p>
        </template>

        
        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.Name') }} :</span> 
                    <img v-if="iconPath" :src="iconPath" :alt="form.icon" width="20" height="20"  class="icon-name"/>
                    <span class="info-txt">{{form.name}}</span>
                </p>

            </a-col> 

        </a-row>

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.Description') }} :</span> 
                    <span class="info-txt"  v-if="form.description != 'null' ">{{form.description}}</span>
                    <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>

                </p>

            </a-col> 

        </a-row>

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.minResTime') }} :</span> 
                    <span class="info-txt" v-if="form.time != null ">{{form.time}} {{ t('Meet.hours') }}</span>
                     <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                </p>

            </a-col> 

        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}" v-if="form.managers.length !== 0">
            <a-col :span="24">                    
                <p><span class="info-title">{{ t('Meet.Managers') }} :</span></p>
            </a-col>
        </a-row>
        <ul v-for="item in form.managers " :key="item" :class="{ 'is-rtl': textDirection==='rtl'}">
            <li class="info-txt">{{item.label}}</li>
        </ul>

        <!-- <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
            
            <a-col :span="24">
                <p><span class="info-title">{{ t('Meet.Photo') }} :</span> </p>
                <a-image
                    :width="100"
                    :height="100"
                    :src="form.photo"
                    v-if="form.photo!==null"
                    :class="{ 'is-rtl': textDirection==='rtl'}"
                />  

                <img 
                    :width="100"
                    src="@/assets/img/nophoto.png"
                    v-if="form.photo===null"
                    :class="{ 'is-rtl': textDirection==='rtl'}"
                />
            </a-col>

        </a-row> -->

       
       

    </a-drawer>


</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import { notification, UploadChangeParam, UploadFile, UploadProps } from 'ant-design-vue';
import Swal from 'sweetalert2';
import {
  
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  InboxOutlined,
  PaperClipOutlined,
  PlusOutlined,
  MinusOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,



} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../components/Looder.vue';
import { serviceService, userService } from '@/_services';
export default defineComponent({

    name: "servicesIndex",
    components: {
        Looder,
        SearchOutlined,
        DeleteOutlined,
        EyeOutlined,
        EditOutlined,
        ClockCircleOutlined,
        QuestionCircleOutlined,
        
    },
    setup(){

        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };
        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const loading = ref(false);

        const { t } = useI18n();

        const searchInput = ref<HTMLInputElement | null>(null);

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            locations: [] as any[],
            fileChanged:false,

            managers: [] as any[],
            fetching: false,
            allManagers: []  as any[],

            fetchingUpdate: false,
            allManagersUpdate: []  as any[],



        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:t('Meet.Name'),
                key:'name',
                dataIndex:'name',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                            if (input instanceof HTMLInputElement) {
                                searchInput.value = input;
                                searchInput.value.focus();
                            }
                        }, 100);
                    }
                },

            },
            {
                title:t('Meet.Description'),
                key:'description',
                dataIndex:'description'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        //form
        const addFormRef = ref();
        const formRef = ref();

        const form = reactive({
            id:'',
            name:'',
            description:'',
            photo:'',
            icon:'',
            managers:[] as any[], 
            time:'',
        });

        const addForm = reactive({
            id:'',
            name:'',
            description:'',
            photo:'',
            icon:'',
            time:'',
        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true,message:t('Meet.RequiredField')}],
            time: [{ required: true,message:t('Meet.RequiredField')}],

        };

        
        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 20,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        
        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    serviceService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                serviceService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any, pageSize:number) => {
            currentPage.value = newPage;
            perPage.value=pageSize;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    serviceService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
               


            }else{
                loading.value = true;
                serviceService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        //search 
        const filter=(key:string, value:string)=>{
            loading.value=true;
            currentPage.value=1;
            serviceService.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText) {
                filter(dataIndex,state.searchText);
            }
        };
            
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getAll();
        };

        const getById=(id:string)=>{
            loading.value=true;
            serviceService.show(id).then((res)=>{
                form.id = res.data.id;
                form.name = res.data.name;
                form.description = res.data.description;
                form.photo = res.data.photo;
                form.managers = res.data.users.map((user: { id: string; first_name: string; last_name: string }) => ({
                    label: `${user.first_name} ${user.last_name}`,
                    value: user.id
                }));
                form.icon = res.data.icon;
                form.time = res.data.serving_time ? res.data.serving_time :'';
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                loading.value=false;
            });
        }

        const iconPath = computed(() => {
            return form.icon ? require(`@/assets/icons/${form.icon}.svg`) : '';
        });

        
        // display drawer 
        const openDisplay = ref<boolean>(false);
        const showDisplayDrawer = (id: string) => {
            updateModalWidth();
            getById(id);
            openDisplay.value = true;
        };

        //update drawer
        const open = ref<boolean>(false);
        const showDrawer = (id:string) => {
            updateModalWidth();
            getById(id);
            open.value = true;
        };
        const cancelUpdate=()=>{
            open.value=false;
            fileList.value=[];

        }
        const fileUpdateInfo = ref<UploadFile | null>(null);

        const handleUpdateChangeFile = (info: UploadChangeParam) => {
            state.fileChanged=true;
            fileUpdateInfo.value=info.file;
        };

        function extractFilename(url:any) {
            const parts = url.split('/');
            const filename = parts[parts.length - 1];
            return filename;
        }

        const deletePhoto=()=>{
            form.photo="";
            state.fileChanged=true;
        }

        //img visible 
        const visible = ref<boolean>(false);
        const setVisible = (value:any): void => {
        visible.value = value;
        };

        //update managers
        let debounceTimerUpdate: ReturnType<typeof setTimeout> | null = null;

        const fetchManagerUpdate = (value: any) => {
            state.allManagersUpdate = [];

            // Disable fetching when the input is empty
            if (value === '') {
                return;
            }

            state.fetchingUpdate = true;

            // Clear previous timer
            if (debounceTimerUpdate) {
                clearTimeout(debounceTimerUpdate);
            }

            // Fetch managers with debounce
            debounceTimerUpdate = setTimeout(() => {
                userService.filterUsers("first_name", value)
                    .then((res) => {
                        const data = res.data.map((user: { first_name: any; last_name: any; id: any }) => ({
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id,
                            external: false
                        }));

                        state.allManagersUpdate = data;
                        console.log('users', state.allManagersUpdate);

                        state.fetchingUpdate = false;
                        console.log('state.managers : ', form.managers);
                    })
                    .catch((error) => {
                        console.log('error fetching managers', error);
                    });
            }, 700);
        };

        const update=(id:string)=>{
            formRef.value
            .validate()
            .then(()=>{
                let formData= new FormData();
                formData.append('name', form.name);
                formData.append('description', form.description);
                formData.append('icon', form.icon);
                formData.append('serving_time', form.time);
                formData.append('managers', form.managers.map((manager) => manager.value).join(','));


                // if (fileUpdateInfo.value) {
                //     if (fileUpdateInfo.value instanceof Blob) { 
                //         formData.append('photo', fileUpdateInfo.value);
                //     } else {
                //         console.warn('Selected file is not a valid Blob object');
                //     }
                // }
                
                
                loading.value=true;
                serviceService.update(id,formData).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.serviceUpdatedSuccess'));
                    loading.value = false;
                }).catch((err) => {
                    console.log(err);
                    loading.value = false;
                }).finally(() => {
                    console.log('finish')
                    cancelUpdate();
                }); 



            }).catch((error:any)=>{
                console.log(error);
            })
        }


        //add darwer 
        const openAdd = ref<boolean>(false);
        const showAddDrawer = () => {
            
            updateModalWidth();
            openAdd.value = true;
        };


        const resetFormAdd = () => {
            addFormRef.value.resetFields();
            fileList.value=[];
        }

        //file
        const fileList = ref([]);
        const beforeUpload = () => {
            return false;
        };
        const fileInfo = ref<UploadFile | null>(null);

        const handleChange = (info: UploadChangeParam) => {
            fileInfo.value=info.file;
        };

        function handleDrop(e: DragEvent) {
            console.log(e);
        }

        //managers 
        let debounceTimer: ReturnType<typeof setTimeout> | null = null;
        const fetchManager = (value: any) => {

            state.allManagers=[];
            
            //disable fetching when the input is empty
            if(value===''){
                return;
            }

            state.fetching = true;

            //clear previous timer
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }


            //fetch managers 
            debounceTimer = setTimeout(() => {
                userService.filterUsers("first_name",value)
                .then((res) => {
                    const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
                        label: `${user.first_name} ${user.last_name}`,
                        value: user.id,
                        external:false,
                    }));

                    
                    state.allManagers = data;
                    console.log('users',state.allManagers);
                    
                    state.fetching = false;
                    console.log('state.managers : ', state.managers);
                })
                .catch((error) => {
                    console.log('error fetching managers', error);
                });
            }, 700);





           
             

        };

        //icons
        const importIcons = () => {
            const iconsContext = require.context('@/assets/icons', false, /\.svg$/);
            return iconsContext.keys().map((key) => ({
                name: key.replace('./', '').replace('.svg', ''), // Extract the file name without extension
                path: iconsContext(key), // Get the path of the SVG file
            }));
        };

        const icons = importIcons();



        const add=()=>{
            addFormRef.value.validate().then(()=>{

                let formData = new FormData();
                formData.append('name',addForm.name);
                formData.append('icon',addForm.icon);
                // if (fileInfo.value) {
                //     if (fileInfo.value instanceof Blob) { 
                //         formData.append('photo', fileInfo.value);
                //     } else {
                //         console.warn('Selected file is not a valid Blob object');
                //     }
                // }
                
                formData.append('description',addForm.description);
                formData.append('serving_time',addForm.time);

                formData.append('managers', state.managers.map((manager) => manager.value).join(','));
                
                loading.value=true;
                serviceService.create(formData).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.serviceAddedSuccess'));
                }).then((error)=>{
                    console.log(error);
                }).finally(()=>{
                    resetFormAdd();
                    openAdd.value=false;
                    loading.value=false;

                })

            }).catch((error:any)=>{
                console.log('error validation form', error);
            })
        }



        const getAll=()=>{
            loading.value=true;
            serviceService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.meta.total;
            }).catch((error)=>{
                console.log("error get equipements", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const deleteService=(id:string)=>{
            loading.value=true;
            serviceService.delete(id).then((res)=>{
                getAll();
                showSuccessNotification(t('Meet.serviceDeletedSuccess'))
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                loading.value=false;
            })
        }

        const showDeletePopup = (id: string) => {
            Swal.fire({
                title: t('Meet.TitleDeleteservice'),
                text: t('Meet.TextDeleteservice'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Delete'),
                cancelButtonText: t('Meet.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteService(id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        
        onMounted(() => {
            getAll();
        });


        return{
            fetchManagerUpdate,
            fetchManager,
            modalWidth,
            t,
            locale,
            textDirection,
            simpleImage,
            loading,
            searchInput,
            state,
            navigatePage,
            columns,
            addFormRef,
            formRef,
            form,
            addForm,
            rules,
            perPage,
            currentPage,
            selectPagination,
            handleSelectPaginationChange,
            handlePageChange,
            handleSearch,
            handleReset,
            openAdd,
            showAddDrawer,
            resetFormAdd,
            fileList,            
            handleChange,
            handleDrop,
            beforeUpload,
            add,
            openDisplay,
            showDisplayDrawer,
            open,
            showDrawer,
            cancelUpdate,
            showDeletePopup,
            update,
            handleUpdateChangeFile,
            extractFilename,
            setVisible,
            visible,
            deletePhoto,
            icons,
            iconPath
            









            









        }
    }

})

</script>

<style scoped>

.icon-name{
    margin: 0px 5px -5px 5px;
}
.table-container {
  overflow-x: auto;
}


.breadcrumb-icon{
  cursor: pointer;
}

.add-btn {
    color: var(--primary-color, #171717);
    border: 2px solid var(--primary-color, #171717);
    box-shadow: inset 0 0 0 0 var(--primary-color, #171717);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 var(--primary-color, #171717);
    border-color: var(--primary-color, #171717);
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}


.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 10px;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.photo-info{
  padding:0px 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.photo-info:hover{
    background: #efefefec;
}

.time-input{
    width: 100%;
}


/* CSS for RTL */
.is-rtl {
    direction:rtl;
}


.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

.icon-select{
    width: 100% !important;
}

@media (min-width: 768px) {
   .add-btn{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}


.customize-input{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
}

.tooltip-icon{
    margin-right: 5px;
}

.tooltip-icon-rtl{
    margin-left: 5px;
}



</style>