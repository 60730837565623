<template>

<a-row class="feedback-container">
    <Loader :is-loaded="!loading"></Loader>

    <a-col :span="24">
        <a-row>
            <a-col :span="24" class="img-container">
                <img v-if=" $logo ==='nologo' " src="@/assets/img/meetix-logo.png" alt="meetix-img" class="feedback-img"  />
                <img v-else :src="$logo" alt="meetix-img" class="feedback-img"  />
            </a-col>
        </a-row>

        <!-- card -->
        <a-row>
            <a-col :span="24">
                <div class="feed-card">
                    <span class="rate-title">{{t('Meet.feedbackTxt')}}</span>

                    <a-form ref="addFormRef" :model="addForm" :rules="rules">


                        <div class="rate-container">
                            <span class="rate-sub-title">{{t('Meet.feedbackTxtRoom')}}</span>
                            <div class="item-container">
                                <span class="room-name">{{form.roomName}}</span>

                                <a-form-item name="roomRate" class="room-rate">
                                    <a-rate v-model:value="addForm.roomRate" />
                                </a-form-item>
                            </div>
                            <a-form-item name="roomNote" class="text-area">
                                <a-textarea v-model:value="addForm.roomNote" :rows="4" :placeholder="t('Meet.feedTxt')" />
                            </a-form-item>
                        </div>

                        <div class="rate-title-container" v-if="form.equipments.length!==0">
                            <span class="rate-sub-title">{{t('Meet.feedbackTxtEquipment')}}</span>
                        </div>

                        <div v-for="(equipment, index) in form.equipments" :key="index" class="rate-container">
                            <div class="item-container">
                                <span class="room-name">{{equipment.name}}</span>
                                <a-rate v-model:value="equipment.rate" />
                            </div>

                            <div class="info-container">
                                <a class="toggle-txt" @click="toggleTextarea('equipments', index)">{{ equipment.showTextarea ? 'Hide Reason' : 'Tell us why' }}</a>
                                <a-textarea v-model:value="equipment.note" :rows="4" :placeholder="t('Meet.feedTxt')" v-if="equipment.showTextarea" />
                            </div>

                        </div>

                        <div class="rate-title-container" v-if="form.services.length!==0">
                            <span class="rate-sub-title">{{t('Meet.feedbackTxtService')}}</span>
                        </div>

                        <div v-for="(service, index) in form.services" :key="service" class="rate-container">
                            <div class="item-container">
                                <span class="room-name">{{service.name}}</span>
                                <a-rate v-model:value="service.rate" />
                            </div>
                            <div class="info-container">
                                <a class="toggle-txt" @click="toggleTextarea('services', index)">{{ service.showTextarea ? 'Hide Reason' : 'Tell us why' }}</a>
                                <a-textarea v-model:value="service.note" :rows="4" :placeholder="t('Meet.feedTxt')"  v-if="service.showTextarea" />
                            </div>
                        </div>


                        <!-- submit -->
                        <div class="submit-container">
                            <button class="submit-btn" @click="submitFeed">{{t('Meet.submit')}}</button>
                        </div>
                    </a-form>

                    

                </div>
            </a-col>


            
        </a-row>


    </a-col>
    
</a-row>
    
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, watch, onBeforeUnmount } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import {  notification } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Loader from '../../components/Looder.vue'
import { feedbackSevrice, reservationsService } from '@/_services';

export default {
    name:'feedbackSubmit',
    components:{
        Loader,
    },
    setup() {

        const loading = ref(false);
        const { locale, t } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const reservationId = ref(route.params.reservation_id);
        const userId = ref(route.params.user_id);


        const navigatePage = (link: string) => {
            router.push(link); 
        };
        const state = reactive({
            rateValue:1,
            feedbackable:'service',
            note:'',
        });

        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 20,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                    color: 'white'
                }
            });
        };

        const addFormRef = ref();
        const formRef = ref();

        const form = reactive({
            resId:'',
            motif:'',
            roomName:'',
            roomId:'',
            equipments:[] as any[],
            services:[] as any[],

        });

        const addForm = reactive({
            resId:'',
            motif:'',
            roomName:'',
            roomId:'',
            equipments:[] as any[],
            services:[] as any[],

            roomRate:1,
            roomNote:'',
        });

        const rules: Record<string, Rule[]> = {
            roomRate: [{ required: true,message:t('Meet.RequiredField')}],
            roomNote: [{ required: true,message:t('Meet.RequiredField')}],

        };

        const getReservationById=(id:any)=>{
            loading.value=true;
            feedbackSevrice.getFeedbackReservation(id).then((res)=>{
                form.resId=res.data.id,
                form.motif=res.data.motif;
                form.equipments = res.data.equipments.map((item: any) => {
                    return {
                       id:item.id,
                       name:item.name,
                        rate: 0,
                        note: '',
                        showTextarea: false
                    };
                });
                form.services = res.data.services.map((item: any) => {
                    return {
                       id:item.id,
                       name:item.name,
                        rate: 0,
                        note: '',
                        showTextarea: false
                    };
                });
                form.roomId=res.data.room.id,
                form.roomName=res.data.room.name;  

                loading.value=false;
            }).catch((error)=>{
                loading.value=false;
                console.log('error fetch reservation by id', error);
            })

        }

        const toggleTextarea = (type: 'equipments' | 'services', index: number) => {
            form[type][index].showTextarea = !form[type][index].showTextarea;
        };

        const submitFeed=()=>{
            addFormRef.value.validate().then(async ()=>{
                loading.value = true;

                try {

                    let roomPayload={
                        feedbackable_id:form.roomId,
                        feedbackable_type:'room',
                        rate:addForm.roomRate,
                        note:addForm.roomNote,
                        reservation_id:form.resId,
                        user_id:userId.value,
                    }
                    console.log('roomPayload',roomPayload);

                    await feedbackSevrice.create(roomPayload);
                
                    form.equipments.forEach(async (item)=>{
                        if(item.rate !== 0){
                            let payloadEquipment={
                                feedbackable_id:item.id,
                                feedbackable_type:'equipment',
                                rate:item.rate,
                                note:item.note,
                                reservation_id:form.resId,
                                user_id:userId.value,
                            }
                            await feedbackSevrice.create(payloadEquipment);
                            console.log('payload equipment', payloadEquipment);
                        }
                    });

                    form.services.forEach(async (item)=>{

                        if(item.rate !== 0){
                            let payloadService={
                                feedbackable_id:item.id,
                                feedbackable_type:'service',
                                rate:item.rate,
                                note:item.note,
                                reservation_id:form.resId,
                                user_id:userId.value,
                            }
                            await feedbackSevrice.create(payloadService);
                            console.log('payload service', payloadService);
                        }

                    });

                    showSuccessNotification(t('Meet.feedbackAdSuccess'));

                } catch (error:any) {

                    console.error('Error submitting feedback:', error);

                    if (error && error.error && error.error.message === "your feedback already submitted") {
                        showErrorNotification('Your feedback is already submitted')
                    }else{
                        showErrorNotification('Your feedback is already submitted')
                        /*
                        notification.error({
                            message: "Erreur",
                            description: "Erreur d'ajout feedback"
                        });
                        */

                    }

                  
                } finally {
                    loading.value = false;
                    addFormRef.value.resetFields();
                    navigatePage('/login');
                }

                    
            }).catch((error:any)=>{
                console.log('error validation');
            })
        }

        onMounted(()=>{
            getReservationById(reservationId.value);

        });

        return{
            loading,
            t,
            navigatePage,
            state,
            addForm,
            form,
            formRef,
            addFormRef,
            rules,
            submitFeed,
            toggleTextarea,
        }


        
    },
}
</script>

<style scoped>

.feedback-container{
    height: 100vh;
}

.img-container{
    margin: 40px 0px;
}

.feedback-img{
    height: auto;
    width: 60%;
}
.feed-card{
    margin:0px 10px 20px 10px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
}



.rate-title{
    font-weight: 600;
    font-size: 18px;
    
}

.rate-sub-title{
    font-weight: 500;
    font-size: 16px;
    color: var(--primary-color, #171717);

}


.rate-title-container{
    display: flex;
}

.rate-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
    margin: 10px 0px 0px 0px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}

.room-name{
    font-weight: 600;
}

.item-container{
    display: flex;
    align-items: baseline;
    gap: 20px;
}

.submit-btn{
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  padding: 8px;
  background:var(--primary-color, #171717) ;
  color: #fff;
  border: none;
  border-radius: 6px;
  line-height: 1.5;
  cursor: pointer;
}

.submit-btn:hover{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    
}

.submit-btn:focus{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);    
}

.submit-btn:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.info-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
}

.text-area{
    width: 100%;
    margin-top: 0;
}

.room-rate{
    padding: 0;
    margin: 0;
}

.submit-container{
    margin: 30px 0px 0px 0px;
}

.toggle-txt{
    color: #c02015;
}

.rate-title-container{
    margin: 30px 0px 0px 0px;
}

@media (min-width: 768px) {

    .feedback-img{
        height: auto;
        width: 20%;
    }

    .img-container{
        margin: 60px 0px;
    }
    .feed-card{
        margin: 0px 30px 20px 30px;
    }

    .rate-title{
        font-size: 22px;
    }


}


@media (min-width: 1024px) {

    .feedback-img{
        height: auto;
        width: 20%;
    }

    .img-container{
        margin-top: 80px 0px;
    }

    .feed-card{
        margin: 0px 50px 20px 50px;        
    }

    .rate-title{
        font-size: 24px;
    }


}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


</style>