import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const feedbackSevrice = {
    show,
    getAllWithPagination,
    filter,
    getAll,
    getFeedbackReservation,
    create
};

function getAll() {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json"}, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.feedback.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}


function getAllWithPagination(currentPage: number, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json"}, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.feedback.getAll}?page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function filter(key:string,value:string, page:any, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.feedback.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}



function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json"}, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.feedback.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getFeedbackReservation(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json"}, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.feedback.reservationFeed.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function create(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.feedback.create}`, requestOptions)
        .then(
            handleResponse
        );
}



function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.setItem('selectedKey', '0');


                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
