<template>

    <Loader :is-loaded="!loading"></Loader>

   <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            <span class="txt">{{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> 
            <span class="txt">{{t('Meet.Users')}}</span>
        </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container" >
        <a-button class="add-btn" @click="showAddDrawer" >
            {{ t('Meet.Add') }}
        </a-button>
    </div>

    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
        
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template>

        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template> 

        <template #bodyCell="{ column, record }">
            <div class="table-cell">
            <template v-if="column.key == 'actions'">
                <a-space>
                    <a-tooltip >
                        <template #title>
                            <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                        </template>
                        <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.id)">
                            <template #icon>
                            <EyeOutlined class="tbl-icon" />
                            </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip >
                        <template #title>
                            <span class="tooltip-txt">{{ t('Meet.Edit') }}</span>
                        </template>

                        <a-button class="tbl-btn" type="primary" @click="() => showDrawer(record.id)">
                        <template #icon>
                            <EditOutlined  class="tbl-icon" />
                        </template>
                        </a-button>

                    </a-tooltip>


                    <a-tooltip >
                        <template #title>
                            <span class="tooltip-txt">{{ t('Meet.Delete') }}</span>
                        </template>
                        <a-button class="tbl-btn" type="primary" danger @click="showDeletePopup(record.id)">
                            <template #icon>
                                <DeleteOutlined class="tbl-icon" />
                            </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip >
                        <template #title>
                            <span class="tooltip-txt">{{ t('Meet.Permissions') }}</span>
                        </template>
                        <a-button  class="wd-btn tbl-btn" @click="showPermissions(record.id)">
                            <template #icon>
                                <KeyOutlined  class="tbl-icon"/>
                            </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip >
                        <template #title>
                            <span class="tooltip-txt">{{ t('Meet.Password') }}</span>
                        </template>
                        <a-button  class="pwd-btn tbl-btn" @click="openUpdatePass(record.id)">
                            <template #icon>
                                <LockOutlined  class="tbl-icon"/>
                            </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip >
                        <template #title>
                            <span class="tooltip-txt">{{ t('Meet.Role') }}</span>
                        </template>
                        <a-button  class="role-btn tbl-btn" @click="openUpdateRole(record.id)">
                            <template #icon>
                                <SettingOutlined class="tbl-icon" />
                            </template>
                        </a-button>
                    </a-tooltip>

                 
                   
                </a-space>
            </template>

            <template v-if="column.key=='name'">

              <p>{{record.first_name}} {{record.last_name}}</p>

            </template>

            <template v-if="column.key=='role'">
                <a-tag v-if="record.role==='ADMIN'"  >
                    
                    {{t('Meet.ADMIN')}}
                </a-tag>

                <a-tag v-if="record.role==='EMPLOYEE'" >
                    <!-- <template #icon>
                        <CloseCircleOutlined />
                    </template> -->
                    {{t('Meet.EMPLOYEE')}}
                </a-tag>

                <a-tag v-if="record.role==='MANAGER'"  >
                    {{t('Meet.MANAGER')}}
                </a-tag>

            </template>

            <template v-if="column.key === 'img'" >
                <div class="img-container">

                    <img 
                        class="img-table"
                        :src="record.photo" 
                        v-if="record.photo!==null" 
                        height="100%"
                        width="100%"
                    />

                    <a-avatar v-if="record.photo===null">
                        <template #icon><UserOutlined /></template>
                    </a-avatar>


                </div>
                        

            </template>

            </div>



        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

                
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"  :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows" />

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Meet.page')}}</a-select-option>
        </a-select>
    </div>

    
    <!-- add drawer --> 
    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.Adduser')}}</p>
        </template>


        <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item   name="first_name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.FisrtName') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.first_name" :placeholder="t('Meet.FisrtName')" class="input-form"/>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item   name="last_name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.LastName') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.last_name" :placeholder="t('Meet.LastName')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item   name="email" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Email') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.email" :placeholder="t('Meet.Email')" class="input-form"/>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item   name="phone" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Phone') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.phone" :placeholder="t('Meet.Phone')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- <a-row>
                <a-col :span="24">

                    <a-form-item   name="ad_integration" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Ad_intergation') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.ad_integration" :placeholder="t('Meet.Ad_intergation')" class="input-form"/>
                    </a-form-item>

                </a-col>
               
            </a-row> -->



            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-item  has-feedback name="pass">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Password') }}</span>
                        </template>
                        <a-input-password v-model:value="addForm.pass" :placeholder="t('Meet.Password')" type="password" autocomplete="off" />

                    </a-form-item>

                </a-col>

                <a-col :span="12">
                    <a-form-item  has-feedback name="checkPass">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Confirmpassword') }}</span>
                        </template>
                        <a-input-password v-model:value="addForm.checkPass" :placeholder="t('Meet.Confirmpassword')" type="password" autocomplete="off" />
                    </a-form-item>
                </a-col>

            </a-row>

            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="role_id">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Role') }}</span>
                        </template>

                        <a-select v-model:value="addForm.role_id" show-search :option-filter-prop="'label'">

                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option v-for="role in state.roles" :label="role.name" :key="role.id" :value="role.id" :selected="role.id === addForm.role_id ">{{ role.name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

            
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="photo">
                    <template #label>
                        <span class="label-form">{{ t('Meet.Photo') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleChange"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                        <p class="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                        <p class="ant-upload-hint">
                        {{ t('Meet.dropTitle2') }}
                        </p>

                    </a-upload-dragger>   
                   


                    </a-form-item>
                </a-col>
            </a-row>


           
           


           

           

            


           
            
        </a-form>
        <template #footer>
            <a-space>
            <a-button  type="primary" @click="add">{{ t('Meet.Add') }}</a-button>
            <a-button type="primary" danger  @click="resetFormAdd">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>
    </a-drawer>

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >

        
        <template #title>
            <p  class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.userInformation')}}</p>
        </template>

        
        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">

            <a-col flex="90px" class="photo-container">
                <a-image
                    width="100%"
                    height="100%"
                    :src="form.photo"
                    v-if="form.photo!==null"
                    :class="{ 'is-rtl': textDirection==='rtl'}"
                />  

                <a-avatar v-if="form.photo===null">
                    <template #icon><UserOutlined /></template>
                </a-avatar>

                
            </a-col>

            <a-col flex="auto">
                <p>
                    <span class="info-txt">{{form.first_name}} {{form.last_name}}</span>
                </p>

            </a-col>
        
          

        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Email') }} :</span> 
                    <span class="info-txt">{{form.email}}</span>
                </p>
            </a-col>
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Phone') }} :</span> 
                    <span class="info-txt">{{form.phone}}</span>
                </p>
            </a-col>
               
        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.Ad_intergation') }} :</span> 
                    <span class="info-txt">{{form.ad_integration}}</span>
                </p>
            </a-col>
         
               
        </a-row>


       

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Role') }} :</span> 

                    <a-tag v-if="form.role==='ADMIN'"  >
                        
                        <span class="info-txt">{{t('Meet.ADMIN')}}</span>
                    </a-tag>

                    <a-tag v-if="form.role==='EMPLOYEE'" >
                        <!-- <template #icon>
                            <CloseCircleOutlined />
                        </template> -->
                        <span class="info-txt">{{t('Meet.EMPLOYEE')}}</span>
                    </a-tag>

                    <a-tag v-if="form.role==='MANAGER'"  >
                        <span class="info-txt">{{t('Meet.MANAGER')}}</span>
                    </a-tag>

               
                </p>
            </a-col>

            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.MemberSince') }} :</span> 
                    <span class="info-txt">{{formatDateDrawer(form.created_at)}}</span>
                </p>
            </a-col>
          
        </a-row>


        
        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Permissions') }} :</span> 
                </p>
            </a-col>
        </a-row>

        <a-row key="permissions" class="permission-container"  :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-tag v-for="item in permissions_array" :key="item.id" :bordered="false" class="permission-tag" >
                <span class="info-txt">{{ item.name }}</span>
            </a-tag>
        </a-row>



        <!-- <a-row v-for="item in permissions_array" :key="item" class="permission-container">
            <a-tag :bordered="false">{{item.name}}</a-tag>
        </a-row> -->

        

       

       

    </a-drawer>

    <!-- update drawer -->
    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :width="modalWidth"
        :placement="locale === 'ar' ? 'left' : 'right'"

    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.Updateeuser')}}</p>
        </template>

        <a-form ref="formRef" :model="form" :rules="rulesUpdate" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item   name="first_name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.FisrtName') }}</span>
                        </template>
                        <a-input  v-model:value="form.first_name" :placeholder="t('Meet.FisrtName')" class="input-form"/>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item   name="last_name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.LastName') }}</span>
                        </template>
                        <a-input  v-model:value="form.last_name" :placeholder="t('Meet.LastName')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>
            
            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item   name="email" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Email') }}</span>
                        </template>
                        <a-input  v-model:value="form.email" :placeholder="t('Meet.Email')" class="input-form"/>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item   name="phone" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Phone') }}</span>
                        </template>
                        <a-input  v-model:value="form.phone" :placeholder="t('Meet.Phone')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="24">

                    <a-form-item   name="ad_integration" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Ad_intergation') }}</span>
                        </template>
                        <a-input  v-model:value="form.ad_integration" :placeholder="t('Meet.Ad_intergation')" class="input-form" disabled/>
                    </a-form-item>

                </a-col>
               
            </a-row>

        

           

            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="photo">
                    <template #label>
                        <span class="label-form">{{ t('Meet.Photo') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleUpdateChangeFile"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                        <p class="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                        <p class="ant-upload-hint">
                         {{ t('Meet.dropTitle2') }}
                        </p>

                    </a-upload-dragger> 

                    <template v-if="form.photo!==null && !state.fileChanged">
                        <div>
                            <p class="photo-info" @click="() => setVisible(true)"><PaperClipOutlined /><span class="photo-title"> {{extractFilename(form.photo)}}</span> <DeleteOutlined  @click="deletePhoto" /></p>

                            <a-image
                                :width="200"
                                :style="{ display: 'none' }"
                                :preview="{
                                visible,
                                onVisibleChange: setVisible,
                                }"
                                :src="form.photo"
                            />
                        </div>
                    </template>       
                   


                    </a-form-item>
                </a-col>
            </a-row>
           
        </a-form>


        <template #footer>
            <a-space>
                <a-button  type="primary" @click="update(form.id)">{{ t('Meet.Update') }}</a-button>
                <a-button type="primary" danger @click="cancelUpdate">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>

    </a-drawer>

    <!-- permission modal -->
    <a-modal v-model:open="openModal" @cancel="resetPermission">

        <template #footer>
            <div class="btns-container">
                <a-button key="submit" type="primary"  @click="addPermissions">{{ t('Meet.Confirm') }}</a-button>
                <a-button key="back" @click="resetPermission">{{ t('Meet.Cancel') }}</a-button>
            </div>
        </template>

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}"><span class="modal-title">{{ t('Meet.Permissions') }}</span></p>
        </template>

        <a-form  ref="formPermRef" :model="FormPerm" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

            <a-row>
                <a-col :span="24">
                    <a-form-item name="permissions">

                       

                        <!-- 
                        <a-select
                            v-model:value="FormPerm.permissions"
                            mode="multiple"
                            style="width: 100%"
                            :placeholder="t('Meet.Permissions')"
                            :options="options"
                            @change="handleMultiChange"
                            show-search
                            :option-filter-prop="'label'"
                        >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                        </a-select> -->

                        <a-select
                            v-model:value="FormPerm.permissions"
                            mode="multiple"
                            style="width: 100%"
                            :placeholder="t('Meet.Permissions')"
                            show-search
                            :option-filter-prop="'label'"
                            @change="handleMultiChange"
                        >
                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>
                            <a-select-option
                                v-for="option in options"
                                :key="option.value"
                                :value="option.value"
                                :disabled="isPermissionDisabled(option.value)"
                            >
                                {{ option.label }}
                            </a-select-option>
                        </a-select>

                       

                    </a-form-item>
                </a-col>
            </a-row>

        </a-form>


    </a-modal>


    <!-- update password modal -->
    <a-modal v-model:open="openPassModal" @cancel="cancelPassModal">
        <template #footer>
            <div class="btns-container">
                <a-button key="submit" type="primary"  @click="updateUserPass">{{ t('Meet.Confirm') }}</a-button>
                <a-button key="back" @click="cancelPassModal">{{ t('Meet.Cancel') }}</a-button>
            </div>
        </template>

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}"><span class="modal-title">{{ t('Meet.UpdateUsersPassword') }}</span></p>
        </template>
        <a-form  ref="securityRef" :model="securityForm"  :rules="securityRules" layout="vertical">
            <a-row>
                <a-col :span="24">
                    <a-form-item  has-feedback name="current">
                        <template #label>
                            <span class="label-form">{{ t('Meet.CurrentPass') }}</span>
                        </template>
                        <a-input-password v-model:value="securityForm.current"  autocomplete="off" />

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-item  has-feedback name="pass">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Password') }}</span>
                        </template>
                        <a-input-password v-model:value="securityForm.pass" type="password" autocomplete="off" />

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-item  has-feedback name="checkPass">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Confirmpassword') }}</span>
                        </template>
                        <a-input-password v-model:value="securityForm.checkPass" type="password" autocomplete="off" />
                    </a-form-item>
                </a-col>
            </a-row>

        </a-form>
    </a-modal>

    <!-- update role modal -->
    <a-modal v-model:open="openRoleModal" @cancel="cancelRoleModal">

        <template #footer>
            <div class="btns-container">
                <a-button key="submit" type="primary"  @click="updateUserRole">{{ t('Meet.Confirm') }}</a-button>
                <a-button key="back" @click="cancelRoleModal">{{ t('Meet.Cancel') }}</a-button>
            </div>
        </template>

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}"><span class="modal-title">{{ t('Meet.UpdateUserRole') }}</span></p>
        </template>
        <a-form  ref="roleRef" :model="roleForm"   layout="vertical">
          
           <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="role_id">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Role') }}</span>
                        </template>

                        <a-select v-model:value="form.role_id" show-search :option-filter-prop="'label'">

                            <template #notFoundContent>
                                <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option v-for="role in state.roles" :label="role.name" :key="role.id" :value="role.id" :selected="role.id === form.role_id ">{{ role.name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row> 
      


        </a-form>

    </a-modal>





    
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, watch } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  LockOutlined,
  UserOutlined,
  InboxOutlined,
  PaperClipOutlined,
  KeyOutlined,
  SettingOutlined
  
} from '@ant-design/icons-vue';
import {  notification, UploadChangeParam, UploadFile } from 'ant-design-vue';
import { loadRouteLocation, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs, { Dayjs } from 'dayjs';
import Loader from '../../components/Looder.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import arLocale from '@fullcalendar/core/locales/ar';
import { Empty } from 'ant-design-vue'
import { equipementsService, permissionService, reservationsService, rolesService, roomsService, serviceService, userService } from '@/_services';

export default defineComponent({
    name: 'indexUsers',
    components: {
        
        Loader,
        SearchOutlined,
        DeleteOutlined,
        EyeOutlined,
        EditOutlined,
        UserOutlined,
        InboxOutlined,
        PaperClipOutlined,
        KeyOutlined,
        LockOutlined,
        SettingOutlined
    },
    setup() {
        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            locations: [] as any[],
            room_id:'',
            roles: [] as any[],
            fileChanged:false,
            permissions: [] as any[],
            user_id:'',
            permissions_array: [] as any[],



        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:"",
                dataIndex: 'img',
                key: 'img',
            },
            {
                title:t('Meet.Name'),
                key:'name',
                dataIndex:'name',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                            if (input instanceof HTMLInputElement) {
                                searchInput.value = input;
                                searchInput.value.focus();
                            }
                        }, 100);
                    }
                },

            },
            {
                title:t('Meet.Email'),
                key:'email',
                dataIndex:'email'

            },
            {
                title:t('Meet.Role'),
                key:'role',
                dataIndex:'role'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];
        //form
        const addFormRef = ref();
        const formRef = ref();

        const formPermRef =ref();

        const FormPerm = reactive({
            permissions: [] as any,
            permissions_ids:'',
            per_ids:[] as any,
            ids:[]as any,
        })


        const form = reactive({
            id:'',
            first_name:'',
            last_name:'',
            photo:'',
            phone:'',
            email:'',
            role:'',
            createdAt:'',
            pass: '',
            checkPass: '',
            role_id:'',
            ad_integration:'',

          

        });

        const addForm = reactive({
            id:'',
            first_name:'',
            last_name:'',
            photo:'',
            phone:'',
            email:'',
            role:'',
            createdAt:'',
            pass: '',
            checkPass: '',
            role_id:3,
            permissions: [] as any,
            permissions_ids:'',
            ad_integration:'',


        });

        const validatePass = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject( t('Meet.RequiredField') );
            } else if (value.length < 8) {
                return Promise.reject(t('Meet.PasswordLengthError'));
            } else {
                if (addForm.checkPass !== '' || form.checkPass !== '') {
                    formRef?.value?.validateFields('checkPass');
                }
                return Promise.resolve();
            }
        };

        const validatePass2 = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject( t('Meet.RequiredField') );
            } else if (value !== addForm.pass ||  value !== addForm.pass) {
                return Promise.reject(t('Meet.PasswordMatchError'));
            } else {
                return Promise.resolve();
            }
        };

        const rules: Record<string, Rule[]> = {
            ad_integration: [{ required: true, message: t('Meet.RequiredField') }],
            first_name: [{ required: true, message: t('Meet.RequiredField') }],
            last_name: [{ required: true, message: t('Meet.RequiredField') }],
            email: [
                { required: true, message: t('Meet.RequiredField') , trigger: 'change'},
                {
                    validator: (rule, value) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value) && value ) {
                            return Promise.reject(t('Meet.invalidEmail'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                }
                 
            ], 
            phone: [
                { required: true, message: t('Meet.RequiredField') },
                {
                    validator: (rule, value) => {
                        if (value && value.length !== 8) {
                            return Promise.reject(t('Meet.invalidPhoneLength'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                }
            ],
            pass: [{ required: true, validator: validatePass, trigger: 'change' }],
            checkPass: [{ validator: validatePass2, trigger: 'change' }],
            role_id: [{ required: true, message: t('Meet.RequiredField') }],

        };

        const rulesUpdate: Record<string, Rule[]> = {
            first_name: [{ required: true, message: t('Meet.RequiredField') }],
            last_name: [{ required: true, message: t('Meet.RequiredField') }],
            email: [
                { required: true, message: t('Meet.RequiredField') , trigger: 'change'},
                {
                    validator: (rule, value) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value) && value ) {
                            return Promise.reject(t('Meet.invalidEmail'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                }
                 
            ], 
            phone: [
                { required: true, message: t('Meet.RequiredField') },
                {
                    validator: (rule, value) => {
                        if (value && value.length !== 8) {
                            return Promise.reject(t('Meet.invalidPhoneLength'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                }
            ],
            pass: [{ required: true, validator: validatePass, trigger: 'change' }],
            checkPass: [{ validator: validatePass2, trigger: 'change' }],
            role_id: [{ required: true, message: t('Meet.RequiredField') }],

        };

        

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    userService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data.data;
                        state.totalPages = res.data.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                userService.getAllUsers(currentPage.value.toString(), perPage.value.toString()).then((response)=>{
                    state.data=response.data.data;
                    state.totalPages=response.data.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any, pageSize:number) => {
            currentPage.value = newPage;
            perPage.value=pageSize;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    userService.filter("first_name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data.data;
                        state.totalPages=res.data.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
               


            }else{
                loading.value = true;
                userService.getAllUsers(currentPage.value.toString(), perPage.value.toString()).then((response)=>{
                    state.data=response.data.data;
                    state.totalPages=response.data.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        //search 
        const filter=(key:string, value:string)=>{
            loading.value=true;
            currentPage.value=1;
            userService.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data.data;
                state.totalPages = res.data.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText) {
                filter("first_name",state.searchText);
            }
        };
            
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getAll();
        };


        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 20,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                color: 'white'
                }
            });
            resetFormPass();
        };

        interface Permissions {
            id: string;
            name:string;
        }
        const permissions_array = ref<Permissions[]>([]);
        
        const formatName=(str:string)=>{
            return str.replace(/_/g, ' '); 

        }

        const getById=(id:string)=>{
            permissions_array.value=[];
            loading.value=true;
            userService.show(id).then((res)=>{
                form.id=res.data.id;
                form.first_name=res.data.first_name;
                form.last_name=res.data.last_name;
                form.phone=res.data.phone;
                form.email=res.data.email;
                form.photo=res.data.photo;
                form.role=res.data.role;
                form.createdAt=res.data.created_at;
                form.ad_integration=res.data.ad_integration;


                let permissionsIds: any[] = [];

                res.data.permissions.forEach((permission: any) => {
                    permissionsIds.push(permission.id); 
                    permissions_array.value.push({
                        id: permission.id,
                        name: formatName(permission.name)
                    });
                });

                res.data.roles.forEach((role: any) => {
                    form.role_id=role.id;
                    roleForm.role_id=role.id;
                  

                    role.permissions.forEach((permission: any) => {
                        return permissions_array.value.push({
                          id: permission.id,
                          name: formatName(permission.name),
                        });
                    });
                });

                FormPerm.permissions= permissionsIds;
                FormPerm.per_ids=permissionsIds


            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                loading.value=false;
            })
        }


        // display drawer 
        const openDisplay = ref<boolean>(false);

        const showDisplayDrawer = (id: string) => {
            updateModalWidth();
            getById(id);
            openDisplay.value = true;
        };

        const formatDateDrawer=(dateString:string)=>{
            return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
        }

        //add drawer
        const openAdd = ref<boolean>(false);
        const showAddDrawer = () => {
            updateModalWidth();
            getRoles();
            openAdd.value = true;
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
            fileList.value=[];

        }
        const getRoles=()=>{
            rolesService.getAll().then((res)=>{
                state.roles = res.data.filter((role: any) => role.name !== 'KIOSK');
            }).catch((error)=>{
                console.log(error);
            })
        }

        //file
        const fileList = ref([]);
        
        const beforeUpload = () => {
            return false;
        };
        const fileInfo = ref<UploadFile | null>(null);

        const handleChange = (info: UploadChangeParam) => {
            fileInfo.value=info.file;
        };

        function handleDrop(e: DragEvent) {
            console.log(e);
        }

        const add=()=>{
            addFormRef.value.validate().then(()=>{
                loading.value=true;
                let formData = new FormData();
                formData.append('first_name', addForm.first_name);
                formData.append('last_name', addForm.last_name);
                formData.append('phone', addForm.phone);
                formData.append('email', addForm.email);
                formData.append('password', addForm.pass);
                formData.append('role_id', addForm.role_id.toString());
                //formData.append('ad_integration', "1");

                if (fileInfo.value) {
                    if (fileInfo.value instanceof Blob) { 
                        formData.append('photo', fileInfo.value);
                    } else {
                        console.warn('Selected file is not a valid Blob object');
                    }
                }

                userService.createUser(formData).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.userAddedSuccess'));
                }).then((error)=>{
                    console.log(error);
                }).finally(()=>{
                    resetFormAdd();
                    openAdd.value=false;
                    loading.value=false;
                })
            }).catch(()=>{
                console.log('');
            })
        }

        //update drawer
        const open = ref<boolean>(false);

        const showDrawer = (id:string) => {
            updateModalWidth();
            getRoles();
            getById(id);
            open.value = true;
        };

        const cancelUpdate=()=>{
            open.value=false;
            fileList.value=[];
        }

        const fileUpdateInfo = ref<UploadFile | null>(null);
        const handleUpdateChangeFile = (info: UploadChangeParam) => {
            state.fileChanged=true;
            fileUpdateInfo.value=info.file;
        };

        function extractFilename(url:any) {
            const parts = url.split('/');
            const filename = parts[parts.length - 1];
            return filename;
        }

        const deletePhoto=()=>{
            form.photo="";
            state.fileChanged=true;
        }

        const update=(id:string)=>{
            formRef.value.validate().then(()=>{
                let formData= new FormData();
                formData.append('first_name', form.first_name);
                formData.append('last_name', form.last_name);
                formData.append('phone', form.phone);
                formData.append('email', form.email);
                formData.append('ad_integration', form.ad_integration);

               

                formData.append('role_id', form.role_id);

                
                if (fileUpdateInfo.value) {
                    if (fileUpdateInfo.value instanceof Blob) { 
                        formData.append('photo', fileUpdateInfo.value);
                    } else {
                        console.warn('Selected file is not a valid Blob object');
                    }
                }

                loading.value=true;
                userService.update(id,formData).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.userUpdatedSuccess'));
                    loading.value = false;
                }).catch((err) => {
                    console.log(err);
                    loading.value = false;
                }).finally(() => {
                    cancelUpdate();
                }); 






            }).catch(()=>{
                console.log('');
            })

        }
        //img visible 
        const visible = ref<boolean>(false);
        const setVisible = (value:any): void => {
            visible.value = value;
        };

        //permissions
        const options=ref();

        const getPermissions=()=>{
            loading.value=true;
            permissionService.getAll().then((res)=>{
                options.value = res.data.map((item:any) => ({
                    value:item.id,
                    label: formatName(item.name),
                }));
                loading.value=false;
            }).catch((error)=>{
                loading.value=false;
                console.log('error getting permissions', error);
            });

        }

        const isPermissionDisabled=(permissionId: string)=>{
            return permissions_array.value.some(permission => permission.id === permissionId);
        }

        const handleMultiChange = (value: any[]) => {

            console.log('Current value:', value);

            FormPerm.ids=value;
            //const newlySelectedIds = FormPerm.permissions.filter((itemId: any) => !FormPerm.per_ids.includes(itemId));
            //FormPerm.ids=newlySelectedIds;
        };



        const deleteUser=(id:string)=>{
            loading.value=true;
            userService.delete(id).then((res)=>{
                getAll();
                showSuccessNotification(t('Meet.userDeletedSuccess'))
            }).catch((error)=>{
                console.log(error);
            }).finally(() => {
                loading.value=false;
            })
        }

        const showDeletePopup = (id: string) => {
            Swal.fire({
                title: t('Meet.TitleDeleteuser'),
                text: t('Meet.TextDeleteuser'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Delete'),
                cancelButtonText: t('Meet.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteUser(id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };


        //permissions modal 
        const openModal = ref<boolean>(false);
        
        const showPermissions=(id:string)=>{
            getPermissions();

            getById(id);

            state.user_id=id;
            openModal.value=true;
        }

        const resePerForm=()=>{
            formPermRef.value.resetFields();
        }
        const resetPermission = () => {
            openModal.value = false;
            resePerForm();
        };

        const addPermissions=()=>{

            console.log('permissions_ids',FormPerm.ids.join(','));
            let formData = new FormData();
            formData.append('permissions_ids',FormPerm.ids.join(','));
            loading.value=true;
            permissionService.change(state.user_id, formData).then((res)=>{
                loading.value=false;
                openModal.value=false;
                showSuccessNotification(t('Meet.permissionsAddedSuccess'));

            }).catch((error)=>{
                loading.value=false;
                openModal.value=false;
                console.log('error changing permissions', error)
            })
        }

        //update password modal

        const openPassModal = ref<boolean>(false);

        const securityRef = ref();

        const securityForm = reactive({
            current:'',
            pass: '',
            checkPass: '',
            userId:'',
        });

        
        const validatePwd = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Meet.RequiredField'));
            } else if (value.length < 8) {
                return Promise.reject(t('Meet.PasswordLengthError'));
            }else if (value === securityForm.current) {
                return Promise.reject(t('Meet.passwordIndenticalError'));
            }  else {
                if (securityForm.checkPass !== '') {
                    securityRef?.value?.validateFields('checkPass');
                }
                return Promise.resolve();
            }
        };
        const validatePwd2 = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Meet.RequiredField'));
            } else if (value !== securityForm.pass) {
                return Promise.reject(t('Meet.PasswordMatchError'));
            } else {
                return Promise.resolve();
            }
        };
    
        const securityRules: Record<string, Rule[]> = {
            current: [{ required: true, message: t('Meet.RequiredField') }],
            pass: [{ required: true, validator: validatePwd, trigger: 'change' }],
            checkPass: [
                { required: true,validator: validatePwd2, trigger: 'change' }
            ],
        };


        
        const openUpdatePass=(id:string)=>{
            securityForm.userId=id;
            openPassModal.value=true;
        }

        const resetFormPass=()=>{
            securityRef.value.resetFields();
        }
        const cancelPassModal = () => {
            openPassModal.value = false;
            resetFormPass();
        };

        const updateUserPass =()=>{
        
            securityRef!.value!
            .validate()
            .then(()=>{

                let formData=new FormData();
                formData.append('old_password',securityForm.current);
                formData.append('password', securityForm.pass);
                loading.value=true;
                userService.changePassword(securityForm.userId,formData ).then((response)=>{
                    cancelPassModal();
                    loading.value = false;

                    showSuccessNotification(t('Meet.PasswordUpdateSuccess'));

                }).catch((err) => {
                    loading.value = false;
                    if (err=== "wrong old password") {
                        showErrorNotification(t('Meet.currentPassWrong'));
                        cancelPassModal();
                    } else {
                        console.log('Error updating passwordSS', err);
                    }
                    
                });
                
            }).catch((error:any)=>{
                console.log('error validation form', error);
            });
        }


        //update role 
        
        const openRoleModal = ref<boolean>(false);

        const roleRef = ref();

        const roleForm = reactive({
            role_id:'',
            userId:'',
        });

        const resetFormRole=()=>{
            roleRef.value.resetFields();
        }
        const cancelRoleModal = () => {
            openRoleModal.value = false;
            resetFormRole();
        };


         
        const openUpdateRole=(id:string)=>{
            getRoles();
            getById(id);
            roleForm.userId=id;
            openRoleModal.value=true;
        }

        const updateUserRole =()=>{

            let formData=new FormData();
            formData.append('role_id', form.role_id);
        
        
            loading.value=true;
            userService.changeRole(roleForm.userId , formData).then((response)=>{
                cancelRoleModal();
                loading.value = false;

                showSuccessNotification(t('Meet.RoleUpdateSuccess'));

            }).catch((err) => {
                loading.value = false;
                console.log('error', err);
                
            });
            
           
        }






        const getAll=()=>{
            loading.value=true;
            userService.getAllUsers(currentPage.value.toString(), perPage.value.toString()).then((response)=>{
                state.data=response.data.data;
                state.totalPages = response.data.total;
            }).catch((error)=>{
                console.log("error get rooms", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

       


      
        onMounted(() => {
            getAll();
        });

        return{
            rulesUpdate,
            modalWidth,
            locale,
            textDirection,
            simpleImage,
            loading,
            t,
            searchInput,
            state,
            navigatePage,
            columns,
            addFormRef,
            formRef,
            form,
            addForm,
            rules,
            perPage,
            currentPage,
            selectPagination,
            handleSelectPaginationChange,
            handlePageChange,
            filter,
            handleSearch,
            handleReset,
            showSuccessNotification,
            openDisplay,
            showDisplayDrawer,
            formatDateDrawer,
            permissions_array,
            openAdd,
            showAddDrawer,
            resetFormAdd,
            add,
            beforeUpload,
            handleDrop,
            handleChange,
            fileList,
            open,
            showDrawer,
            fileUpdateInfo,
            handleUpdateChangeFile,
            extractFilename,
            deletePhoto,
            visible,
            setVisible,
            cancelUpdate,
            showDeletePopup,
            options,
            handleMultiChange,
            formPermRef,
            FormPerm,
            openModal,
            resetPermission,
            showPermissions,
            addPermissions,
            update,
            isPermissionDisabled,
            openPassModal,
            openUpdatePass,
            cancelPassModal,
            securityRef,
            securityForm,
            securityRules,
            updateUserPass,
            openRoleModal,
            roleRef,
            roleForm,
            openUpdateRole,
            updateUserRole,
            cancelRoleModal,






























            







        }


        
    },
})
</script>

<style scoped>

.table-container {
  overflow-x: auto;
}

.breadcrumb-icon{
  cursor: pointer;
}

.add-btn {
    color: var(--primary-color, #171717);
    border: 2px solid var(--primary-color, #171717);
    box-shadow: inset 0 0 0 0 var(--primary-color, #171717);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 var(--primary-color, #171717);
    border-color: var(--primary-color, #171717);
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}



.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.wd-btn{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
}

.wd-btn:hover{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
}

.pwd-btn{
    color: var(--third-color, #d35100);
    border-color: var(--third-color, #d35100);
    background: #fafafa;
}

.pwd-btn:hover{
    color: var(--third-color, #d35100);
    border-color: var(--third-color, #d35100);
    background: #fafafa;
}


.role-btn{
    color: #6610f2;
    border-color: #6610f2;
    background: #fafafa;
}

.role-btn:hover{
    color: #6610f2;
    border-color: #6610f2;
    background: #fafafa;
}
.wd-title{
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
}

.range-picker{
    width: 100%;
}


.wd-container{
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
}

.modal-title{
    margin-bottom: 20px;
    font-size: 14px;
}


.btns-container{
    margin-top: 10px;
}

.switch-txt{
    margin-left: 5px;
}

.ingredient-des{
    margin-bottom: 15px;
}

.photo-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.permission-container{
    display: flex;
}

.permission-tag{
    margin-right: 8px;
    margin-top: 8px;
}

/* photo */
.photo-info{
  padding:0px 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.photo-info:hover{
    background: #efefefec;
}

.img-container{
    display: flex;
    max-width: 30px;
}

/* CSS for RTL */
.is-rtl {
    direction:rtl;
}

.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

@media (min-width: 768px) {
   .add-btn,
   .modal-title{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-title,
    .modal-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}


</style>
