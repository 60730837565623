<template>
    <Looder :is-loaded="!loading"></Looder>
   
   <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            <span class="txt">{{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> 
            <span class="txt">{{t('Meet.FeedBack')}} </span>
        </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    

    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
        
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template>


        <template #bodyCell="{ column, record }">
            <div class="table-cell">
                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                            </template>
                            <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.reservationId)">
                                <template #icon>
                                <EyeOutlined  class="tbl-icon"/>
                                </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'room_rate'">
                    <a-rate :value="record.roomRate" allow-half disabled />
                </template>

                <template v-if="column.key === 'service_rate'">
                    <a-rate :value="record.serviceRate" allow-half disabled />
                </template>

                <template v-if="column.key === 'equipment_rate'">
                    <a-rate :value="record.equipmentRate" allow-half disabled />
                </template>

                <span v-if="column.key!=='room_rate' && column.key!=='service_rate' && column.key!=='equipment_rate'">{{ record[column.dataIndex] }}</span>

            </div>


            
            

        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name' || column.key ==='user'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

                <template  v-if="column.key === 'feedbackable_type'">
                    <div class="search-btn-radio">
                        <a-radio-group  v-model:value="type" @change="selectedType" class="search-radio-grp">
                            <a-radio value="room" class="radio-item">{{t('Meet.Room')}}</a-radio>
                            <a-radio value="reservation" class="radio-item">{{t('Meet.Meetings')}}</a-radio>
                            <a-radio value="service" class="radio-item">{{t('Meet.Service')}}</a-radio>

                        </a-radio-group>  
                    </div>   
                </template>

                <template  v-if="column.key === 'rate'">
                    <div class="rate-container">
                        <a-rate v-model:value="rateValue" />
                    </div>
                   
                </template>

                
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows"/>

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Meet.page')}}</a-select-option>
        </a-select>
    </div>

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >

        
        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.feedBackInfo')}}</p>
        </template>

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
         
                <p>
                    <span class="info-title">{{ t('Meet.Reservation') }} :</span> 
                    <span class="info-txt">{{ selectedFeedback.reservation }}</span>
                </p>


            </a-col>

            <a-col :span="12">
         
                <p>
                    <span class="info-title">{{ t('Meet.CreatedAt') }} :</span> 
                    <span class="info-txt">{{ selectedFeedback.created_at }}</span>
                </p>


            </a-col>

           
        </a-row>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Participant') }} :</span> 
                    <span class="info-txt">{{ selectedFeedback.user }}</span>
                </p>
            </a-col>

            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Date') }} :</span> 
                    <span class="info-txt">{{ selectedFeedback.feedback_date }}</span>
                </p>
            </a-col>
        </a-row>

          
        <a-row v-if="selectedFeedback.roomFeedback.length">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.RoomRate') }} :</span> 
                </p>
                <p :class="[locale === 'ar' ? 'sub-title-rtl' : 'sub-title']"   > 
                    <span class="info-txt">{{ t('Meet.avgRate') }} </span> 
                    <a-rate :value="selectedFeedback.roomRate" allow-half disabled  :class="[locale === 'ar' ? 'rate-avg-rtl' : 'rate-avg']"/>
                </p>
            </a-col>
        </a-row>

      

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}" v-if="selectedFeedback.roomFeedback.length">
            <a-col :span="24">
                
               <a-descriptions  v-for="(feedback, index) in selectedFeedback.roomFeedback" :key="index" bordered size="small" :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']">
                    <a-descriptions-item :label="t('Meet.Name')" :span="3">
                        <span class="info-txt">{{feedback.name}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Rate')" >
                      
                        <a-rate :value="feedback.rate" allow-half disabled />

                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Note')" >
                        <span class="info-txt">{{feedback.note}}</span>
                    </a-descriptions-item>

               </a-descriptions>


            </a-col>

            
        </a-row>

        <a-row  v-if="selectedFeedback.serviceFeedback.length">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.serviceRate') }} :</span> 
                </p>
                <p :class="[locale === 'ar' ? 'sub-title-rtl' : 'sub-title']"   > 
                    <span class="info-txt">{{ t('Meet.avgRate') }} :</span> 
                    <a-rate :value="selectedFeedback.serviceRate" allow-half disabled :class="[locale === 'ar' ? 'rate-avg-rtl' : 'rate-avg']" />
                </p>
            </a-col>
        </a-row>

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}" v-if="selectedFeedback.serviceFeedback.length">
            <a-col :span="24">
                
               <a-descriptions  v-for="(feedback, index) in selectedFeedback.serviceFeedback" :key="index" bordered size="small" :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']">
                    <a-descriptions-item :label="t('Meet.Name')" :span="3">
                        <span class="info-txt">{{feedback.name}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Rate')" >
                      
                        <a-rate :value="feedback.rate" allow-half disabled />

                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Note')" >
                        <span class="info-txt">{{feedback.note}}</span>
                    </a-descriptions-item>

               </a-descriptions>


            </a-col>

            
        </a-row>


        <a-row v-if="selectedFeedback.equipmentFeedback.length">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.EquipmentRate') }} :</span> 
                </p>
                <p :class="[locale === 'ar' ? 'sub-title-rtl' : 'sub-title']"   > 
                    <span class="info-txt">{{ t('Meet.avgRate') }} :</span> 
                    <a-rate :value="selectedFeedback.equipmentRate" allow-half disabled  :class="[locale === 'ar' ? 'rate-avg-rtl' : 'rate-avg']" />
                </p>
            </a-col>
        </a-row>

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}" v-if="selectedFeedback.equipmentFeedback.length">
            <a-col :span="24">
                
               <a-descriptions  v-for="(feedback, index) in selectedFeedback.equipmentFeedback" :key="index" bordered size="small" :class="[locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des']">
                    <a-descriptions-item :label="t('Meet.Name')" :span="3">
                        <span class="info-txt">{{feedback.name}}</span>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Rate')" >
                      
                        <a-rate :value="feedback.rate" allow-half disabled />

                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Meet.Note')" >
                        <span class="info-txt">{{feedback.note}}</span>
                    </a-descriptions-item>

               </a-descriptions>


            </a-col>

            
        </a-row>

        
       

       
       

    </a-drawer>

</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import {
  
  SearchOutlined,
  EyeOutlined,
 
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../components/Looder.vue';
import { feedbackSevrice } from '@/_services';
import dayjs , { Dayjs }from 'dayjs'; 
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';

import 'dayjs/locale/fr'; 
import 'dayjs/locale/ar'; 
export default defineComponent({
    name: "feedBackIndex",
    components: {
        Looder,
        SearchOutlined,
        EyeOutlined,
    
    },
    setup() {

        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };
        
        dayjs.extend(localeData);
        dayjs.extend(relativeTime);


        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            selectedType:'',

        });
        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };
        const columns =[
            {
                title:t('Meet.Reservation'),
                key:'reservation',
                dataIndex:'reservation',


            },
            {
                title:t('Meet.Date'),
                key:'date',
                dataIndex:'date'
            },
            {
                title:t('Meet.RoomRate'),
                key:'room_rate',
                dataIndex:'room_rate',
               
            },
            {
                title:t('Meet.serviceRate'),
                key:'service_rate',
                dataIndex:'service_rate',
               
            },
            {
                title:t('Meet.EquipmentRate'),
                key:'equipment_rate',
                dataIndex:'equipment_rate',
               
            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        // form
        const form = reactive({
            id:'',
            feedback_type:'',
            rate:'',
            note:'',

            created_at:'',
            user_name:'',


            
        });

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        
        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    feedbackSevrice.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

                if(rateValue.value!==0){
                    loading.value = true;
                    feedbackSevrice.filter("rate",rateValue.value.toString(),currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                if(state.selectedType!==''){
                    loading.value = true;
                    feedbackSevrice.filter("feedbackable_type",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

            }else{

                loading.value = true;
                feedbackSevrice.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    feedbackSevrice.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

                if(rateValue.value!==0){
                    loading.value = true;
                    feedbackSevrice.filter("rate",rateValue.value.toString(),currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                if(state.selectedType!==''){
                    loading.value = true;
                    feedbackSevrice.filter("feedbackable_type",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }
               


            }else{
                loading.value = true;
                feedbackSevrice.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        //search 
        const rateValue = ref<number>(0);
        const type = ref<string>('1');
        const selectedType =(value:any)=>{
            state.selectedType = value.target.value;
        }

        const filter=(key:string, value:string)=>{
            loading.value=true;
            feedbackSevrice.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'feedbackable_type' && state.selectedType!=='') {
                filter(dataIndex,state.selectedType);
            }


            if (dataIndex === 'rate' && rateValue.value) {
                filter(dataIndex,rateValue.value.toString());
            }
        };
            
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;
            state.selectedType='';
            rateValue.value=0;
            type.value="";


            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';

            rateValue.value=0;
            getAll();
        };

        // display drawer 
        const openDisplay = ref<boolean>(false);
        const selectedFeedback = ref<any>(null);


        const showDisplayDrawer = (id: string) => {
            updateModalWidth();
           fetchFeedBack(id);
            openDisplay.value = true;
        };

        const fetchFeedBack=(id:string)=>{
            const feedback = state.data.find((item: any) => item.reservationId === id);
           
           if(feedback){
                selectedFeedback.value=feedback;
            }

        }

        const getById=(id:string)=>{
            loading.value=true;
            feedbackSevrice.show(id).then((res)=>{
                form.id=res.data.id;
                form.feedback_type=res.data.feedbackable_type;
                form.rate=res.data.rate;
                form.note=res.data.note;
                form.created_at=res.data.created_at;
                form.user_name=res.data.user.first_name+" "+res.data.user.last_name;
               
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                loading.value=false;
            })
        }

        const formatDateDrawer=(dateString:string)=>{
            return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
        }

        const lang=localStorage.getItem('Language')|| 'en';
        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).locale(lang).fromNow();
        }
        const formatDate = (dateString: string) => {
            return dayjs(dateString).format('YYYY-MM-DD HH:mm');
        }


        const transformData = (data: any[]): any[] => {

           
            const groupedData : { [key: string]: any } = {};
            data.forEach((outerItem) => {

                outerItem.forEach((item: any) => {
                    const reservationId = item.reservation_id;
               
                    if (!groupedData[reservationId]) {
                        groupedData[reservationId] = {
                            reservationId: reservationId,
                            reservation: item.reservation?.motif || null,
                            date:  item.created_at ? formatDisplayDate(item.created_at)  : null,
                            feedback_date: item.created_at ?formatDate(item.created_at) : null,
                            created_at:item.reservation?.created_at ?formatDate(item.reservation.created_at) : null,
                            user: item.user ?item.user?.first_name+" "+item.user?.last_name : null,

                            roomRate: null,
                            roomRateSum: 0,
                            roomRateCount: 0,
                            roomFeedback: [],

                            serviceRateSum: 0,
                            serviceRateCount: 0,
                            serviceRate: null,
                            serviceFeedback: [],

                            equipmentRateSum: 0,
                            equipmentRateCount: 0,
                            equipmentRate: null,
                            equipmentFeedback: []
                        };
                    }

                    let feedbackableName = null;
                    if (item.feedbackable_type === "App\\Models\\Room") {
                        feedbackableName = item.feedbackable?.name || null;
                    } else if (item.feedbackable_type === "App\\Models\\ReservationService") {
                        feedbackableName = item.feedbackable?.service?.name || null;
                    } else if (item.feedbackable_type === "App\\Models\\ReservationEquipment") {
                        feedbackableName = item.feedbackable?.equipment?.name || null;
                    }

                    const feedbackEntry = { 
                        rate: item.rate,
                        note: item.note,
                        name: feedbackableName  
                    };

                    if (item.feedbackable_type === "App\\Models\\Room") {
                        groupedData[reservationId].roomRateSum += item.rate;
                        groupedData[reservationId].roomRateCount += 1;
                        groupedData[reservationId].roomFeedback.push(feedbackEntry);
                    } else if (item.feedbackable_type === "App\\Models\\ReservationService") {
                        groupedData[reservationId].serviceRateSum += item.rate;
                        groupedData[reservationId].serviceRateCount += 1;
                        groupedData[reservationId].serviceFeedback.push(feedbackEntry);
                    } else if (item.feedbackable_type === "App\\Models\\ReservationEquipment") {
                        groupedData[reservationId].equipmentRateSum += item.rate;
                        groupedData[reservationId].equipmentRateCount += 1;
                        groupedData[reservationId].equipmentFeedback.push(feedbackEntry);
                    }
                });  
            });

            Object.values(groupedData).forEach((reservation) => {
                if (reservation.roomRateCount > 0) {
                    reservation.roomRate = Math.round(reservation.roomRateSum / reservation.roomRateCount);
                }
                if (reservation.serviceRateCount > 0) {
                    reservation.serviceRate = Math.round(reservation.serviceRateSum / reservation.serviceRateCount);
                }
                if (reservation.equipmentRateCount > 0) {
                    reservation.equipmentRate = Math.round(reservation.equipmentRateSum / reservation.equipmentRateCount);
                }

                // removing intermediate sum and count 
                delete reservation.roomRateSum;
                delete reservation.roomRateCount;
                delete reservation.serviceRateSum;
                delete reservation.serviceRateCount;
                delete reservation.equipmentRateSum;
                delete reservation.equipmentRateCount;
            });
            return Object.values(groupedData);
        };

        const getAll=()=>{
            loading.value=true;
            feedbackSevrice.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                //state.data=response.data;
                //state.totalPages = response.meta.total;
                state.data = transformData(response.data);
                state.totalPages = response.meta.total;
                console.log('state data',state.data )
            }).catch((error)=>{
                console.log("error get feedbacks", error);
            }).finally(()=>{
                loading.value = false;
            });
        }
        
        onMounted(() => {
            getAll();
        });


        return{
            modalWidth,
            locale,
            textDirection,
            simpleImage,
            loading,
            t,
            searchInput,
            state,
            navigatePage,
            columns,
            form,
            perPage,
            currentPage,
            selectPagination,
            handleSelectPaginationChange,
            handlePageChange,
            handleSearch,
            handleReset,
            openDisplay,
            showDisplayDrawer,
            rateValue,
            type,
            selectedType,
            formatDateDrawer,
            selectedFeedback





            











        }
        
    },
})
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.breadcrumb-icon{
  cursor: pointer;
}


.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}



.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 10px;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

/* CSS for RTL */
.is-rtl {
    direction:rtl;
}

.search-btn-radio{
    display: flex;
}

.search-radio-grp{
    display: flex;
    flex-direction: column !important;
}

.rate-container{
    display: flex;
}


.ingredient-des{
    margin-bottom: 15px;
}

.ingredient-des-rtl{
    margin-bottom: 15px;
    direction: rtl;
}
.sub-title{
    margin-left: 5px;
}

.sub-title-rtl{
    margin-right: 5px;
}

.rate-avg{
    margin-left: 10px;
}

.rate-avg-rtl{
    margin-left: 10px;
}


/* CSS for RTL */
.is-rtl {
    direction:rtl;
}
.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

@media (min-width: 768px) {
  
   .modal-title{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

   
    .drawer-title,
    .info-title,
    .modal-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}

</style>