<template>

   <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            <span class="txt">{{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>  <span class="txt">{{t('Meet.statistics')}}</span> </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="date-container">
        <div class="type-container">

            <a-select v-model:value="selectValue" show-search class="select-calender" @change="handleChange" >
                
                <template #notFoundContent>
                    <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                </template>

                <a-select-option value="daily" :label="t('Meet.daily')">{{ t('Meet.daily') }}</a-select-option>
                <a-select-option value="period" :label="t('Meet.Period')">{{ t('Meet.Period') }}</a-select-option>
                <a-select-option value="monthly" :label="t('Meet.monthly')">{{ t('Meet.monthly') }}</a-select-option>
                <a-select-option value="annual" :label="t('Meet.annual')">{{ t('Meet.annual') }}</a-select-option>

            </a-select>

        </div>
        <div class="calendar-container">
            <div class="days-container" v-if="selectValue==='period'">
                <a-tooltip>
                    <template #title>
                        {{t('Meet.daysTooltipTxt')}}
                    </template>
                    <QuestionCircleOutlined />
                </a-tooltip>
                <a-select
                    v-model:value="days"
                    :options="daysOptions"
                    style="width: 120px"

                    @change="handleDaysChange"
                >
                </a-select>
            </div>
           
            <a-date-picker v-model:value="monthlyPicker" picker="month"  class="calender-input"   @change="handleMonthly" v-if="selectValue==='monthly'"/>
            <a-range-picker v-model:value="annualRange" picker="year" class="calender-input" @change="handleAnnualChange" v-if="selectValue==='annual'" />

        </div>
    </div>

    <a-row class="chart-container">

        <a-col :span="24" class="chart-wrapper">
            <apexchart  :key="chartKey" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            <a-spin size="large"  v-if="loadingRes" class="chart-loader" />
           
        </a-col>

    </a-row>

    <!-- top services / equipments -->

    <div class="date-container">
        <div class="type-container">

            <a-select v-model:value="selectTop" show-search class="select-calender" @change="handleChangeTop" >
                
                <template #notFoundContent>
                    <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                </template>

                <a-select-option value="daily" :label="t('Meet.daily')">{{ t('Meet.daily') }}</a-select-option>
                <a-select-option value="monthly" :label="t('Meet.monthly')">{{ t('Meet.monthly') }}</a-select-option>
                <a-select-option value="annual" :label="t('Meet.annual')">{{ t('Meet.annual') }}</a-select-option>

            </a-select>
        </div>

        <div class="calendar-container">
        
            <a-date-picker v-model:value="monthlyPickerTop" picker="month"  class="calender-input"   @change="handleMonthlyTop" v-if="selectTop==='monthly'"/>
            <a-range-picker v-model:value="annualRangeTop" picker="year" class="calender-input" @change="handleAnnualChangeTop" v-if="selectTop==='annual'" />

        </div>
    </div>

    <a-row class="chart-container" :gutter="16">
        <a-col :span="12">
            <apexchart  :key="chartSerKey" type="bar" height="350" :options="chartServicesOptions" :series="servicesSeries"></apexchart>
            <a-spin size="large"  v-if="loadingTopSer" class="chart-loader" />

        </a-col>

        <a-col :span="12">
            <apexchart :key="chartEqKey" type="bar" height="350" :options="chartEquipmentsOptions" :series="equipmentsSeries"></apexchart>
            <a-spin size="large"  v-if="loadingTopEq" class="chart-loader" />

        </a-col>
    </a-row>

    <!-- room occupancy -->
    <div class="date-container">
        <div class="type-container">

            <a-select v-model:value="selectOccupancy" show-search class="select-calender" @change="handleChangeOccupancy" >
                
                <template #notFoundContent>
                    <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
                </template>

                <a-select-option value="daily" :label="t('Meet.daily')">{{ t('Meet.daily') }}</a-select-option>
                <a-select-option value="monthly" :label="t('Meet.monthly')">{{ t('Meet.monthly') }}</a-select-option>
                <a-select-option value="annual" :label="t('Meet.annual')">{{ t('Meet.annual') }}</a-select-option>

            </a-select>
        </div>

        <div class="calendar-container">
        
            <a-date-picker v-model:value="monthlyPickerOccupancy" picker="month"  class="calender-input"   @change="handleMonthlyOccupancy" v-if="selectOccupancy==='monthly'"/>
            <a-range-picker v-model:value="annualRangeOccupancy" picker="year" class="calender-input" @change="handleAnnualChangeOccupancy" v-if="selectOccupancy==='annual'" />

        </div>
    </div>
    <a-row class="chart-container">

        <a-col :span="24" class="chart-wrapper">
            <apexchart  :key="chartOccupancyKey" type="bar" height="350" :options="occupancyChartOptions" :series="occupancySeries"></apexchart>
            <a-spin size="large"  v-if="loadingOccupancy" class="chart-loader" />
           
        </a-col>

    </a-row>


</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed, Ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  
  QuestionCircleOutlined
  
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import dayjs , { Dayjs }from 'dayjs'; 
import { staticticsService } from '@/_services';
import type { SelectProps } from 'ant-design-vue';

export default defineComponent({
    name: "roomsIndex",
    components: {
        QuestionCircleOutlined,
    },
    setup() {

        const primaryColor = ref('');
        const secondaryColor = ref('');
        const thirdColor = ref('');

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const state= reactive({
            topServices:[] as any[],

        });


        //charts
        //mixed chart reservation
        const chartKey = ref(0);

        const series: Ref<{ name: string; type: string; data: number[] }[]>  = ref([
            { name: t('Meet.approved'), type: 'column', data: [] },
            { name: t('Meet.nonApproved'), type: 'column', data: [] },
            { name: t('Meet.Total'), type: 'line', data: [] }
        ]);

        watch([primaryColor, secondaryColor, thirdColor], () => {
            chartOptions.value.colors = [primaryColor.value, secondaryColor.value, thirdColor.value];

            chartOptions.value.yaxis[0].axisTicks.color = primaryColor.value;
            chartOptions.value.yaxis[0].axisBorder.color = primaryColor.value;
            chartOptions.value.yaxis[0].labels.style.colors = primaryColor.value;
            chartOptions.value.yaxis[0].title.style.color = primaryColor.value;

            chartOptions.value.yaxis[1].axisTicks.color = secondaryColor.value;
            chartOptions.value.yaxis[1].axisBorder.color = secondaryColor.value;
            chartOptions.value.yaxis[1].labels.style.colors = secondaryColor.value;
            chartOptions.value.yaxis[1].title.style.color = secondaryColor.value;

            chartOptions.value.yaxis[2].axisTicks.color = thirdColor.value;
            chartOptions.value.yaxis[2].axisBorder.color = thirdColor.value;
            chartOptions.value.yaxis[2].labels.style.colors = thirdColor.value;
            chartOptions.value.yaxis[2].title.style.color = thirdColor.value;



            chartServicesOptions.value.colors=[primaryColor.value, secondaryColor.value, thirdColor.value];
            chartEquipmentsOptions.value.colors=[primaryColor.value, secondaryColor.value, thirdColor.value];
            occupancyChartOptions.value.colors=[primaryColor.value, secondaryColor.value, thirdColor.value, '#F44F5E','#E55A89',primaryColor.value, secondaryColor.value, thirdColor.value];
        });


        const chartOptions = ref({
            chart: { 
                height: 350,
                type: 'line',
                stacked: false,
                fontFamily: 'Exo 2, sans-serif',
                toolbar: {
                    show: true,
                    tools: {
                        download: true,  
                        zoomin: true,    
                        zoomout: true,   
                        pan: false,      
                        reset: false,    
                        selection: false 
                    },
                    autoSelected: 'zoom' 
                }

            },
            dataLabels: { enabled: false },
            colors: [primaryColor.value, secondaryColor.value, thirdColor.value],
            stroke: { width: [1, 1, 4] },
            xaxis: { 
                categories: [] as string[],

            }, 
            yaxis: [
                {
                    seriesName: 'Approved',
                    axisTicks: { show: true, color: primaryColor.value  },
                    axisBorder: { show: true, color: primaryColor.value },
                    labels: { style: { colors: primaryColor.value } },
                    title: { text: t('Meet.approved'), style: { color:primaryColor.value } }
                },
                {
                    seriesName: 'Non-Approved',
                    opposite: true,
                    axisTicks: { show: true},
                    axisBorder: { show: true, color: secondaryColor.value },
                    labels: { style: { colors: secondaryColor.value } },
                    title: { text: t('Meet.nonApproved'), style: { color: secondaryColor.value } }
                },
                {
                    seriesName: 'Total',
                    opposite: true,
                    axisTicks: { show: true },
                    axisBorder: { show: true, color: thirdColor.value },
                    labels: { style: { colors: thirdColor.value } },
                    title: { text: t('Meet.Total'), style: { color: thirdColor.value } }
                },
            ],
            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'topLeft',
                    offsetY: 30,
                    offsetX: 60
                }
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40
            },
            title: {
                text: t('Meet.Meetings'),
                align: 'center',
                floating: true,
                style: {
                    fontWeight: 600,
                    fontSize: '16px',
                }
            },
           
        });

        const updateChartSeries = (data: any[], type:string) => {
            const approvedData = data.map(item => item.approved);
            const nonApprovedData = data.map(item => item.non_approved);
            const totalData = data.map(item => item.total);

            series.value[0].data = approvedData;
            series.value[1].data = nonApprovedData;
            series.value[2].data = totalData;

            // xaxis config
            let categories: string[] = [];

            if (type === 'daily') {
                categories = data.map(item => String(item.hour));
            } else if (type === 'monthly') {
                categories = data.map(item => String(item.day)); 
            } else if (type === 'yearly') {
                categories = data.map(item => String(item.year)); 
            }else if(type === 'period' ){
                if(days.value === '-1'){
                    categories = data.map(item => String(item.hour));
                }else{
                    categories = data.map(item => `${item.day}/${item.month}`); 
                }
            }
            chartOptions.value.xaxis.categories = categories;
            chartKey.value++;
        };

        //date pickers
        const selectValue = ref('daily');

        const handleChange=(selectedType:any)=>{
            console.log('selecteType', selectedType);

            //reset old values 
            series.value[0].data = [];
            series.value[1].data = [];
            series.value[2].data = [];

            if(selectValue.value === 'daily'){
                loadingRes.value=true;
                staticticsService.getReservations("daily","","","").then((res)=>{
                    //console.log('res', res);
                    updateChartSeries(res.data,"daily");
                    loadingRes.value=false;
                }).catch((error)=>{
                    console.log('error filter reservation', error);
                    loadingRes.value=false;
                });

            }else if(selectValue.value === 'period'){
                loadingRes.value=true;
                staticticsService.getReservations("","","",days.value).then((res)=>{
                    updateChartSeries(res.data,"daily");
                    loadingRes.value=false;
                }).catch((error)=>{
                    console.log('error filter reservation', error);
                    loadingRes.value=false;
                });
            }else if(selectValue.value === 'monthly'){
                const currentDate=dayjs();
                monthlyPicker.value = currentDate;
                const startOfMonth = currentDate.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = currentDate.endOf('month').format('DD-MM-YYYY');
                loadingRes.value=true;
                staticticsService.getReservations("daily ",startOfMonth,endOfMonth,"").then((res)=>{
                    updateChartSeries(res.data,"monthly");
                    loadingRes.value=false;
                }).catch((error)=>{
                    console.log('error filter reservation', error);
                    loadingRes.value=false;
                });


            }

        }

        const days = ref('-1');
        const daysOptions = ref<SelectProps['options']>([
            {
                value: '-1',
                label: 'Yesterday',
            },
            {
                value: '15',
                label: 'Past 15 days',
            },
            {
                value: '30',
                label: 'Past 30 Days',
            },
            {
                value: '60',
                label: 'Past 60 Days',
            },
        ]);

        const monthlyPicker = ref<Dayjs | null>(null);

        const disabledDate = (current: Dayjs) => {

            const user = JSON.parse(localStorage.getItem('user')||'null');
            const creationDate=user.created_at;

            const currentDate=dayjs();

            // disable dates beyond the current month and year
            const isAfterCurrentMonthYear = current.isAfter(currentDate, 'month') || 
                                            current.isAfter(currentDate, 'year');

            // disable dates before the store creation month and year
            const isBeforeCreationMonthYear = current.isBefore(creationDate, 'month') ||
                                            current.isBefore(creationDate, 'year');

            return isAfterCurrentMonthYear || isBeforeCreationMonthYear;

        };

        const handleMonthly=(date: any )=>{
           if(date!==null){

                //reset old values 
                series.value[0].data = [];
                series.value[1].data = [];
                series.value[2].data = [];

                const startOfMonth = date.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = date.endOf('month').format('DD-MM-YYYY');
                loadingRes.value=true;
                staticticsService.getReservations("daily ",startOfMonth,endOfMonth,"").then((res)=>{
                    //console.log('res', res);
                    updateChartSeries(res.data,"monthly");
                    loadingRes.value=false;

                }).catch((error)=>{
                    console.log('error filter reservation', error);
                    loadingRes.value=false;
                });
           }
        }

        //yearly range picker
        type RangeValue = [Dayjs, Dayjs];
        const annualRange = ref<RangeValue>();
        const handleAnnualChange=(date: any )=>{
            console.log('date', date);
            if (date && date[0] && date[1]) {

                //reset old values 
                series.value[0].data = [];
                series.value[1].data = [];
                series.value[2].data = [];

                const startYear = date[0].$y;
                const endYear = date[1].$y;

                loadingRes.value=true;
                staticticsService.getReservations("yearly",startYear,endYear,"").then((res)=>{
                    //console.log('res', res);
                    updateChartSeries(res.data,"yearly");
                    loadingRes.value=false;
                }).catch((error)=>{
                    console.log('error filter reservation', error);
                    loadingRes.value=false;
                });


            }
        }

        const handleDaysChange=(param:string)=>{
            console.log('selected param', param);
            //reset old values 
            series.value[0].data = [];
            series.value[1].data = [];
            series.value[2].data = [];
            loadingRes.value=true;
            staticticsService.getReservations("","","",param).then((res)=>{
                //console.log('res', res);
                updateChartSeries(res.data,"period");
                loadingRes.value=false;
            }).catch((error)=>{
                console.log('error filter reservation', error);
                loadingRes.value=false;
            });
        }

        const loadingRes = ref(false);


        const getDailyRes=async()=>{
            console.log('getDailyRes laoding')
            loadingRes.value=true;
            staticticsService.getReservations("daily","","","").then((res)=>{
                updateChartSeries(res.data,"daily");
                loadingRes.value=false;
            }).catch((error)=>{
                console.log('error filter reservation', error);
                loadingRes.value=false;
            });
        }


        //top requested services  : 
        const selectTop = ref('daily');
        const loadingTopSer = ref(false);
        const loadingTopEq = ref(false);



        const handleChangeTop=(selectedType:any)=>{
            console.log('selecteType', selectedType);

            //reset old values 
            servicesSeries.value[0].data = [];
            equipmentsSeries.value[0].data = [];

            if(selectTop.value === 'daily'){

                getTopServices("daily","","");
                getTopEquipments("daily","","");
            }else if(selectTop.value === 'monthly'){
                const currentDate=dayjs();
                monthlyPickerTop.value = currentDate;
                const startOfMonth = currentDate.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = currentDate.endOf('month').format('DD-MM-YYYY');

                getTopServices("monthly",startOfMonth,endOfMonth);
                getTopEquipments("monthly",startOfMonth,endOfMonth);
            }

        }

        const monthlyPickerTop = ref<Dayjs | null>(null);
        const handleMonthlyTop=(date: any )=>{
           if(date!==null){

                //reset old values 
                servicesSeries.value[0].data = [];
                equipmentsSeries.value[0].data = [];
                

                const startOfMonth = date.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = date.endOf('month').format('DD-MM-YYYY');

                getTopServices("monthly",startOfMonth,endOfMonth);
                getTopEquipments("monthly",startOfMonth,endOfMonth);
                
           }
        }

        const annualRangeTop = ref<RangeValue>();

        const handleAnnualChangeTop=(date: any )=>{
            console.log('date', date);
            if (date && date[0] && date[1]) {

                //reset old values 
                servicesSeries.value[0].data = [];
                equipmentsSeries.value[0].data = [];
                

                const startYear = date[0].$y;
                const endYear = date[1].$y;

                getTopServices("yearly",startYear,endYear);
                getTopEquipments("yearly",startYear,endYear);
            }
        }

        const chartSerKey =  ref(0);

        const servicesSeries = ref([
            {
                name: t('Meet.averageQuantity'),
                data: []
            }
        ]);

        const chartServicesOptions =ref({
            chart: { 
                height: 350,
                type: 'bar',
                fontFamily: 'Exo 2, sans-serif',
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                borderRadiusApplication: 'end',
                horizontal: true,
              }
            },
            colors: [primaryColor.value, secondaryColor.value, thirdColor.value],
            dataLabels: {
              enabled: true
            },
            xaxis: {
              categories: [],
            },
            title: {
                text: t('Meet.TopRequestedServices'),
                align: 'center',
                floating: true,
                style: {
                    fontWeight: 600,
                    fontSize: '16px',
                }
            },
        })

        const getTopServices= async (custom:string, from:string, to:string)=>{
            console.log('top service loading')
            loadingTopSer.value=true;
            staticticsService.getTopServices(custom,from,to).then((res)=>{
               
               
                const data = res.map((item: { avg_quantity: any; }) => parseFloat(item.avg_quantity).toFixed(2));
                const categories = res.map((item: { name: any; }) => item.name);

                servicesSeries.value[0].data=data;
               
                // xaxis config
                chartServicesOptions.value.xaxis.categories = categories;
                chartSerKey.value++;

                loadingTopSer.value=false;
               

            }).catch((error)=>{
                loadingTopSer.value=false;
                console.log('error getting top services', error);
            })
        }

        //get top equipments
        const colors = ['#9b2180', '#ffc107', '#c02015'];
        const chartEqKey = ref(0);
        const equipmentsSeries = ref([
            {
                name: 'avergage quantity',
                data: []
            }
        ]);
        

       const chartEquipmentsOptions =ref({
            chart: { 
                height: 350,
                type: 'bar',
                fontFamily: 'Exo 2, sans-serif',
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                borderRadiusApplication: 'end',
                horizontal: true,
              }
            },
            colors:  [primaryColor.value, secondaryColor.value, thirdColor.value],
            dataLabels: {
              enabled: true
            },
            xaxis: {
                categories: [],
                
            },
            title: {
                text: t('Meet.TopRequestedEquipements'),
                align: 'center',
                floating: true,
                style: {
                    fontWeight: 600,
                    fontSize: '16px',
                }
            },
       })

        const getTopEquipments= async (custom:string, from:string, to:string)=>{
            console.log('top service loading');
            loadingTopEq.value=true;
            staticticsService.getTopEquipments(custom,from,to).then((res)=>{

                const data = res.map((item: { avg_quantity: any; }) => parseFloat(item.avg_quantity).toFixed(2));
                const categories = res.map((item: { name: any; }) => item.name);

                equipmentsSeries.value[0].data=data;
               
                // xaxis
                chartEquipmentsOptions.value.xaxis.categories = categories;


                chartEqKey.value++;
                loadingTopEq.value=false;

            }).catch((error)=>{
                console.log('error getting top services', error);
                loadingTopEq.value=false;
            })
        }

        //room occupancy
        const chartOccupancyKey = ref(0);

        const selectOccupancy = ref('daily');
        const loadingOccupancy = ref(false);

        const occupancySeries = ref([
            {
                name: 'Occupancy rate %',
                data: [],
            }
        ]);

        const occupancyChartOptions = ref({
            chart: {
                type: 'bar',
                height: 350,
               fontFamily: 'Exo 2, sans-serif',
            },
            plotOptions: {
              bar: {
                borderRadius: 0,
                horizontal: true,
                distributed: true,
                barHeight: '80%',
                isFunnel: true,
              },
            },
            colors: [
              primaryColor.value,
              secondaryColor.value,
              thirdColor.value,
              '#F44F5E',
              '#E55A89',
              primaryColor.value,
              secondaryColor.value,
              thirdColor.value,
            ],
            dataLabels: {
              enabled: true,
              formatter: function (val: any, opt: { w: { globals: { labels: { [x: string]: any; }; }; }; dataPointIndex: string|number; }) {
                return opt.w.globals.labels[opt.dataPointIndex] 
              },
              dropShadow: {
                enabled: true,
              },
            },
            title: {
              text: t('Meet.RoomOccupancy'),
              align: 'center',
              floating:true,
              style: {
                fontWeight: 600,
                fontSize: '16px',
               }
            },
            xaxis: {
                categories: [],
            },
            legend: {
              show: false,
            },
        })

        const getRoomOccupancy= async (custom:string, from:string, to:string)=>{
            console.log('room occupancy laoding')
            loadingOccupancy.value=true;
            staticticsService.getRoomOccupancyRate(custom,from,to).then((res)=>{
                console.log('res occupancy', res);

                const data =  res.map((item: { occupancy_rate: any; }) => parseFloat(item.occupancy_rate).toFixed(2));
                const categories = res.map((item: { name: any; }) => item.name);

                occupancySeries.value[0].data=data;
                occupancyChartOptions.value.xaxis.categories = categories;

                chartOccupancyKey.value++;
                loadingOccupancy.value=false;



            }).catch((error)=>{
                loadingOccupancy.value=false;
                console.log('error getting room occupancy', error);
            })
        }

        const handleChangeOccupancy=(selectedType:any)=>{
            console.log('selectedType', selectedType);
            occupancySeries.value[0].data=[];
            if(selectOccupancy.value === 'daily'){
                getRoomOccupancy("daily","","");
            }else if(selectOccupancy.value === 'monthly'){
                const currentDate=dayjs();
                monthlyPickerOccupancy.value = currentDate;
                const startOfMonth = currentDate.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = currentDate.endOf('month').format('DD-MM-YYYY');

               getRoomOccupancy("monthly",startOfMonth,endOfMonth);
            }
        }

        const monthlyPickerOccupancy = ref<Dayjs | null>(null);
        const handleMonthlyOccupancy=(date: any )=>{
            if(date!==null){
                const startOfMonth = date.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = date.endOf('month').format('DD-MM-YYYY');
                getRoomOccupancy("monthly",startOfMonth,endOfMonth);
            }

        }


        const annualRangeOccupancy = ref<RangeValue>();
        const handleAnnualChangeOccupancy=(date: any )=>{
            console.log('date', date);
            if (date && date[0] && date[1]) {
                const startYear = date[0].$y;
                const endYear = date[1].$y;

                getRoomOccupancy("yearly",startYear,endYear);

            }
        }



        const getConfig= async ()=>{
            const themeTemplate = localStorage.getItem('themeTemplate');
            if(themeTemplate){
                const theme= JSON.parse(themeTemplate);
                primaryColor.value = theme.primaryColor;
                secondaryColor.value = theme.secondaryColor;
                thirdColor.value = theme.thirdColor;
            }else{

                primaryColor.value='#171717';
                secondaryColor.value='#fc8019';
                thirdColor.value='#d35100';

            }

        }

       onMounted(async () => {
            await getConfig(); //get config rises first
            await getDailyRes();
            await getTopServices("daily", "", "");
            await getTopEquipments("daily", "", "");
            await getRoomOccupancy("daily", "", "");
        });
      
        return{
            occupancySeries,
            occupancyChartOptions,
            chartOccupancyKey,
            selectOccupancy,
            loadingOccupancy,
            monthlyPickerOccupancy,
            handleMonthlyOccupancy,
            annualRangeOccupancy,
            handleAnnualChangeOccupancy,
            handleChangeOccupancy,
            selectTop,
            loadingTopSer,
            loadingTopEq,
            handleChangeTop,
            monthlyPickerTop,
            handleMonthlyTop,
            annualRangeTop,
            handleAnnualChangeTop,

            loadingRes,
            chartEqKey,
            equipmentsSeries,
            chartEquipmentsOptions,
            chartServicesOptions,
            chartSerKey,
            servicesSeries,
            chartKey,
            annualRange,
            handleDaysChange,
            daysOptions,
            chartOptions,
            series,
            locale,
            textDirection,
            simpleImage,
            loading,
            t,
            state,
            navigatePage,
            monthlyPicker,
            selectValue,
            handleChange,
            disabledDate,
            handleMonthly,
            handleAnnualChange,
            days,


        }
    }
})
</script>

<style scoped>


.breadcrumb-icon{
  cursor: pointer;
}



.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.btns-container{
    margin-top: 10px;
}


/* dates  */

.date-container{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: row-reverse;

}

.days-container{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: flex-start;
}

/*charts*/

.chart-container{
    margin-top: 50px;
    position: relative;
}

.chart-wrapper {
  position: relative; 
}

.chart-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; 
}

.chart-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px); 
  z-index: 5; 
  display: block;
  visibility: hidden;
}

.chart-wrapper.loading::after {
  visibility: visible; 
}






/* CSS for RTL */
.is-rtl {
    direction:rtl;
}


.chart-loader {
  text-align: center;
  margin-top: 20px;
}


</style>