<template>
   <Loader :is-loaded="!loading"></Loader>
    <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                <span class="txt"> {{t('Meet.Dashboard')}}</span>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 
                <span class="txt"> {{t('Meet.Rooms')}} </span>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 
                <span class="txt">{{t('Meet.Locations')}}</span>
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container" v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
        <a-button class="add-btn" @click="showAddDrawer" >
            {{ t('Meet.Add') }}
        
        </a-button>
    </div>

    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
        
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template>

        <template #bodyCell="{ column, record }">
            <div class="table-cell">
                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                            </template>
                            <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.id)">
                                <template #icon>
                                    <EyeOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Edit') }}</span>
                            </template>

                            <a-button class="tbl-btn" type="primary" @click="() => showDrawer(record.id)">
                            <template #icon>
                                <EditOutlined class="tbl-icon" />
                            </template>
                            </a-button>

                        </a-tooltip>


                        <a-tooltip v-if="state.userRole === 'ADMIN' || state.userRole === 'MANAGER' ">
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Delete') }}</span>
                            </template>
                            <a-button class="tbl-btn" type="primary" danger @click="showDeletePopup(record.id)">
                                <template #icon>
                                <DeleteOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>
                <span >{{ record[column.dataIndex] }}</span>

            </div>


        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
                <div style="padding: 8px">
                    <template  v-if="column.key === 'name'">
                        <a-input
                            ref="searchInput"
                            :placeholder="`${column.title}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        /> 
                    </template>

                 
                    <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    >
                        {{ t('Meet.Search') }}
                    </a-button>
                    <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                        {{ t('Meet.Reset') }}
                    </a-button>
                </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows" />

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Meet.page')}}</a-select-option>
        </a-select>
    </div>


    <!-- add drawer --> 
    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >

        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.AddRoomLocation')}}</p>
        </template>


        <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.name" :placeholder="t('Meet.Name')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>


            <a-row>
                <a-col :span="24">
                    <a-form-item name="description">

                    <template #label>
                        <span class="label-form">{{ t('Meet.Description') }}</span>
                    </template>
                    <a-textarea v-model:value="addForm.description" :rows="4" :placeholder="t('Meet.Description')" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- <a-row :gutter="16">   
                <a-col :span="24">
                <a-form-item  name="photo">
                    <template #label>
                    <span class="label-form">{{ t('Meet.Photo') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleChange"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                    <p class="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                    <p class="ant-upload-hint">
                        {{ t('Meet.dropTitle2') }}
                    </p>

                    </a-upload-dragger>

                </a-form-item>
                </a-col>
            </a-row> -->

          

          


           

           

            


           
            
        </a-form>
        <template #footer>
            <a-space>
            <a-button  type="primary" @click="add">{{ t('Meet.Add') }}</a-button>
            <a-button type="primary" danger  @click="resetFormAdd">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>
    </a-drawer>

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >

        
        <template #title>
            <p  class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.RoomLocationInformation')}}</p>
        </template>

        
        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
            
            <!-- <a-col flex="150px">
                <a-image
                    :width="100"
                    :height="100"
                    :src="form.ph"
                    v-if="form.icon!==null"
                    :class="{ 'is-rtl': textDirection==='rtl'}"
                />  

                <img 
                    :width="100"
                    src="@/assets/img/nophoto.png"
                    v-if="form.icon===null"
                    :class="{ 'is-rtl': textDirection==='rtl'}"
                />
            </a-col> -->

            <a-col :span="24">
                
                <p>
                    <span class="info-title">{{ t('Meet.Name') }} :</span> 
                    <span class="info-txt">{{form.name}}</span>
                </p>
               
            </a-col>

           

            

        </a-row>

        <a-row  :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t('Meet.Description') }} :</span> 
                    <span class="info-txt">{{form.description}}</span>
                </p>
            </a-col>
        </a-row>

    </a-drawer>


    <!-- update drawer -->
    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :width="modalWidth"
        :placement="locale === 'ar' ? 'left' : 'right'"

    >

        <template #title>
            <p class="drawer-title" :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.UpdateRoomLocation')}}</p>
        </template>

        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row>
                <a-col :span="24">
                    <a-form-item   name="name" >
                        <template #label>
                            <span class="label-form">{{ t('Meet.Name') }}</span>
                        </template>
                        <a-input  v-model:value="form.name" :placeholder="t('Meet.Name')" class="input-form"  />
                    </a-form-item>
                </a-col>     
            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                            <span class="label-form">{{ t('Meet.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Meet.Description')" />
                    </a-form-item>
                </a-col>   
            </a-row>

            <!-- <a-row :gutter="16">   
                <a-col :span="24">
                    <a-form-item  name="photo">
                    <template #label>
                        <span class="label-form">{{ t('Meet.Photo') }}</span>
                    </template>

                    <a-upload-dragger
                        v-model:fileList="fileList"
                        name="file"
                        :multiple="false"
                        :max-count="1"
                        @change="handleUpdateChangeFile"
                        @drop="handleDrop"
                        :before-upload="beforeUpload"
                    >

                        <p class="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p class="ant-upload-text">{{ t('Meet.dropTitle1') }}</p>
                        <p class="ant-upload-hint">
                         {{ t('Meet.dropTitle2') }}
                        </p>

                    </a-upload-dragger> 
                    <template v-if="form.photo!==null && !state.fileChanged">
                        <div>
                            <p class="photo-info" @click="() => setVisible(true)"><PaperClipOutlined /><span class="photo-title"> {{extractFilename(form.photo)}}</span> <DeleteOutlined  @click="deletePhoto" /></p>

                            <a-image
                                :width="200"
                                :style="{ display: 'none' }"
                                :preview="{
                                visible,
                                onVisibleChange: setVisible,
                                }"
                                :src="form.icon"
                            />
                        </div>
                    </template>    
                   


                    </a-form-item>
                </a-col>
            </a-row> -->
        </a-form>


        <template #footer>
            <a-space>
                <a-button  type="primary" @click="update(form.id)">{{ t('Meet.Update') }}</a-button>
                <a-button type="primary" danger @click="cancelUpdate">{{ t('Meet.Cancel') }}</a-button>
            </a-space>
        </template>

    </a-drawer>



    
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import dayjs , { Dayjs }from 'dayjs'; 
import { notification, UploadChangeParam, UploadFile } from 'ant-design-vue';
import Swal from 'sweetalert2';
import Loader from '../../../components/Looder.vue';


import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  InboxOutlined,
  PaperClipOutlined
 


 
  
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../components/Looder.vue';
import { roomLocationService } from '@/_services';
export default defineComponent({
    name: "roomsLocationsIndex",
    components: {
        Loader,
        //InboxOutlined,
        SearchOutlined,
        DeleteOutlined,
        EyeOutlined,
        EditOutlined,
        //PaperClipOutlined,
    },
    setup() {

        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };
        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );

        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            fileChanged:false,
            userRole:'',

        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:t('Meet.Name'),
                key:'name',
                dataIndex:'name',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                            if (input instanceof HTMLInputElement) {
                                searchInput.value = input;
                                searchInput.value.focus();
                            }
                        }, 100);
                    }
                },


            },
            {
                title:t('Meet.Description'),
                key:'description',
                dataIndex:'description'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];
        //form
        const addFormRef = ref();
        const formRef = ref();

        const form = reactive({
            id:'',
            name:'',
            description:'',
            icon:'',

        });


        const addForm = reactive({
            id:'',
            name:'',
            description:'',
        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true,message:t('Meet.RequiredField')}],
            description: [{ required: true,message:t('Meet.RequiredField')}],
        };

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            console.log('selected Per page:', selectedItem);
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    roomLocationService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                roomLocationService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any, pageSize:number) => {
            currentPage.value = newPage;
            perPage.value=pageSize;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    roomLocationService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
               


            }else{
                loading.value = true;
                roomLocationService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }


        //search 
        const filter=(key:string, value:string)=>{
            loading.value=true;
            currentPage.value=1;
            roomLocationService.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
 
            if (dataIndex === 'name' && state.searchText) {
                filter(dataIndex,state.searchText);
            }
        };
        
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getAll();
        };




        
        //add darwer 
        const openAdd = ref<boolean>(false);
        const showAddDrawer = () => {
            updateModalWidth();
            updateModalWidth();
            openAdd.value = true;
        };


        const resetFormAdd = () => {
            addFormRef.value.resetFields();
            fileList.value=[];
        }

        //file
        const fileList = ref([]);
        const beforeUpload = () => {
            return false;
        };
        const fileInfo = ref<UploadFile | null>(null);

        const handleChange = (info: UploadChangeParam) => {
            fileInfo.value=info.file;
        };

        function handleDrop(e: DragEvent) {
            console.log(e);
        }

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const getAll=()=>{
            loading.value=true;
            roomLocationService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.meta.total;
            }).catch((error)=>{
                console.log("error get rooms locations", error);
            }).finally(()=>{
                loading.value = false;
            });
        }
      
        const add=()=>{
            addFormRef.value.validate().then(()=>{
                loading.value=true;
                roomLocationService.create(addForm.name, addForm.description, fileInfo.value).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.RoomLocationAddedSuccess'));
                }).then((error)=>{
                    console.log(error);
                }).finally(()=>{
                    resetFormAdd();
                    openAdd.value=false;
                    loading.value=false;

                })


            }).catch((error:any)=>{
                console.log(error);
            });
        }

        // display drawer 
        const openDisplay = ref<boolean>(false);

        const showDisplayDrawer = (id: string) => {
            updateModalWidth();
            getById(id);
            openDisplay.value = true;
        };
        
       

        const getById=(id:string)=>{
            loading.value=true;
            roomLocationService.show(id).then((res)=>{
                form.id=res.data.id;
                form.name=res.data.name;
                form.description=res.data.description;
                form.icon=res.data.icon;
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                loading.value=false;
            })
        }

        //update drawer 
        const open = ref<boolean>(false);
        const showDrawer = (id:string) => {
            updateModalWidth();
            getById(id);
            open.value = true;
        };

        const cancelUpdate=()=>{
            open.value=false;
        }

        const fileUpdateInfo = ref<UploadFile | null>(null);

        const handleUpdateChangeFile = (info: UploadChangeParam) => {
            state.fileChanged=true;
            fileUpdateInfo.value=info.file;
        };

        function extractFilename(url:any) {
            const parts = url.split('/');
            const filename = parts[parts.length - 1];
            return filename;
        }

        const deletePhoto=()=>{
            form.icon="";
            state.fileChanged=true;
        }

        //img visible 
        const visible = ref<boolean>(false);
        const setVisible = (value:any): void => {
            visible.value = value;
        };

        const update=(id:string)=>{
            formRef.value
            .validate()
            .then(()=>{
                let formData= new FormData();
                formData.append('name', form.name);
                formData.append('description', form.description);

                if (fileUpdateInfo.value) {
                    if (fileUpdateInfo.value instanceof Blob) { 
                        formData.append('photo', fileUpdateInfo.value);
                    } else {
                        console.warn('Selected file is not a valid Blob object');
                    }
                }
                
                
                loading.value=true;
                roomLocationService.update(id,formData).then((res)=>{
                    getAll();
                    showSuccessNotification(t('Meet.RoomLocationUpdatedSuccess'));
                    loading.value = false;
                }).catch((err) => {
                    console.log(err);
                    loading.value = false;
                }).finally(() => {
                    console.log('finish');
                    cancelUpdate();
                }); 



            }).catch((error:any)=>{
                console.log(error);
            })
        }



        const deleteLocation=(id:string)=>{
            loading.value=true;
            roomLocationService.delete(id).then(()=>{
                getAll();
                showSuccessNotification(t('Meet.RoomLocationDeletedSuccess'));

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                loading.value=false;
                console.log('finish');
            })
        }


        const showDeletePopup = (id: string) => {
            Swal.fire({
                title: t('Meet.TitleDeleteRoomLocation'),
                text: t('Meet.TextDeleteRoomLocation'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Delete'),
                cancelButtonText: t('Meet.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteLocation(id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const getUserRole=()=>{
            let user_role = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).role : '';
            console.log('user role', user_role);
            state.userRole=user_role;
        }


        
        onMounted(() => {
            getUserRole();
            getAll();
        });






        return{
            t,
            locale,
            textDirection,
            loading,
            navigatePage,
            perPage,
            currentPage,
            simpleImage,
            state,
            columns,
            showDeletePopup,
            addFormRef,
            addForm,
            rules,
            openAdd,
            showAddDrawer,
            resetFormAdd,
            add,
            fileList,
            beforeUpload,
            handleChange,
            handleDrop,
            selectPagination,
            handleSelectPaginationChange,
            handlePageChange,
            handleSearch,
            handleReset,
            formRef,
            form,
            showDrawer,
            showDisplayDrawer,
            openDisplay,
            open,
            cancelUpdate,
            deletePhoto,
            extractFilename,
            handleUpdateChangeFile,
            visible,
            setVisible,
            update,
            modalWidth,


            












        }
    }


})
</script>

<style scoped>
.breadcrumb-icon{
  cursor: pointer;
}

.add-btn {
    color:  var(--primary-color, #171717);
    border: 2px solid  var(--primary-color, #171717);
    box-shadow: inset 0 0 0 0  var(--primary-color, #171717);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0  var(--primary-color, #171717);
    border-color:  var(--primary-color, #171717);
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 10px;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}


.table-container {
  overflow-x: auto;
}

/* CSS for RTL */
.is-rtl {
    direction:rtl;
}


.tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

.action-btn-drawer{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {
   .add-btn{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}




</style>
