import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c7e1a2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = { class: "txt" }
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = { class: "table-cell" }
const _hoisted_5 = { class: "tooltip-txt" }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { style: {"padding":"8px"} }
const _hoisted_8 = {
  key: 1,
  class: "search-btn-radio"
}
const _hoisted_9 = {
  key: 2,
  class: "rate-container"
}
const _hoisted_10 = { class: "pagination" }
const _hoisted_11 = { class: "info-title" }
const _hoisted_12 = { class: "info-txt" }
const _hoisted_13 = { class: "info-title" }
const _hoisted_14 = { class: "info-txt" }
const _hoisted_15 = { class: "info-title" }
const _hoisted_16 = { class: "info-txt" }
const _hoisted_17 = { class: "info-title" }
const _hoisted_18 = { class: "info-txt" }
const _hoisted_19 = { class: "info-title" }
const _hoisted_20 = { class: "info-txt" }
const _hoisted_21 = { class: "info-txt" }
const _hoisted_22 = { class: "info-txt" }
const _hoisted_23 = { class: "info-title" }
const _hoisted_24 = { class: "info-txt" }
const _hoisted_25 = { class: "info-txt" }
const _hoisted_26 = { class: "info-txt" }
const _hoisted_27 = { class: "info-title" }
const _hoisted_28 = { class: "info-txt" }
const _hoisted_29 = { class: "info-txt" }
const _hoisted_30 = { class: "info-txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Looder = _resolveComponent("Looder")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_rate = _resolveComponent("a-rate")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Looder, {
      "is-loaded": !_ctx.loading
    }, null, 8, ["is-loaded"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Meet.FeedBack')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_table, {
      class: "table-container",
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Meet.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      headerCell: _withCtx(({ column }) => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(column.title), 1)
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        _createElementVNode("div", _hoisted_4, [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Meet.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn tbl-btn",
                        onClick: () => _ctx.showDisplayDrawer(record.reservationId)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key === 'room_rate')
            ? (_openBlock(), _createBlock(_component_a_rate, {
                key: 1,
                value: record.roomRate,
                "allow-half": "",
                disabled: ""
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          (column.key === 'service_rate')
            ? (_openBlock(), _createBlock(_component_a_rate, {
                key: 2,
                value: record.serviceRate,
                "allow-half": "",
                disabled: ""
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          (column.key === 'equipment_rate')
            ? (_openBlock(), _createBlock(_component_a_rate, {
                key: 3,
                value: record.equipmentRate,
                "allow-half": "",
                disabled: ""
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          (column.key!=='room_rate' && column.key!=='service_rate' && column.key!=='equipment_rate')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(record[column.dataIndex]), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
        _createElementVNode("div", _hoisted_7, [
          (column.key === 'name' || column.key ==='user')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          (column.key === 'feedbackable_type')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.type,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
                  onChange: _ctx.selectedType,
                  class: "search-radio-grp"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, {
                      value: "room",
                      class: "radio-item"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.Room')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, {
                      value: "reservation",
                      class: "radio-item"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.Meetings')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, {
                      value: "service",
                      class: "radio-item"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.Service')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (column.key === 'rate')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_a_rate, {
                  value: _ctx.rateValue,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rateValue) = $event))
                }, null, 8, ["value"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Search')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Reset')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_a_pagination, {
        current: _ctx.currentPage,
        "onUpdate:current": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentPage) = $event)),
        pageSize: _ctx.perPage,
        "onUpdate:pageSize": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.perPage) = $event)),
        total: _ctx.state.totalPages,
        "default-page-size": _ctx.perPage,
        showSizeChanger: false,
        onChange: _ctx.handlePageChange,
        class: "paginationArrows"
      }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
      (_ctx.state.totalPages>10)
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.selectPagination,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectPagination) = $event)),
            onChange: _ctx.handleSelectPaginationChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: "10" }, {
                default: _withCtx(() => [
                  _createTextVNode("10 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "20" }, {
                default: _withCtx(() => [
                  _createTextVNode("20 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "50" }, {
                default: _withCtx(() => [
                  _createTextVNode("50 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "100" }, {
                default: _withCtx(() => [
                  _createTextVNode("100 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
        }, _toDisplayString(_ctx.t('Meet.feedBackInfo')), 3)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Meet.Reservation')) + " :", 1),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.selectedFeedback.reservation), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Meet.CreatedAt')) + " :", 1),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.selectedFeedback.created_at), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Meet.Participant')) + " :", 1),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.selectedFeedback.user), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Meet.Date')) + " :", 1),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.selectedFeedback.feedback_date), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        (_ctx.selectedFeedback.roomFeedback.length)
          ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Meet.RoomRate')) + " :", 1)
                    ]),
                    _createElementVNode("p", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'sub-title-rtl' : 'sub-title'])
                    }, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Meet.avgRate')), 1),
                      _createVNode(_component_a_rate, {
                        value: _ctx.selectedFeedback.roomRate,
                        "allow-half": "",
                        disabled: "",
                        class: _normalizeClass([_ctx.locale === 'ar' ? 'rate-avg-rtl' : 'rate-avg'])
                      }, null, 8, ["value", "class"])
                    ], 2)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.selectedFeedback.roomFeedback.length)
          ? (_openBlock(), _createBlock(_component_a_row, {
              key: 1,
              gutter: 16,
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFeedback.roomFeedback, (feedback, index) => {
                      return (_openBlock(), _createBlock(_component_a_descriptions, {
                        key: index,
                        bordered: "",
                        size: "small",
                        class: _normalizeClass([_ctx.locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Name'),
                            span: 3
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_21, _toDisplayString(feedback.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]),
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Rate')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_rate, {
                                value: feedback.rate,
                                "allow-half": "",
                                disabled: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["label"]),
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Note')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_22, _toDisplayString(feedback.note), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"])
                        ]),
                        _: 2
                      }, 1032, ["class"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.selectedFeedback.serviceFeedback.length)
          ? (_openBlock(), _createBlock(_component_a_row, { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Meet.serviceRate')) + " :", 1)
                    ]),
                    _createElementVNode("p", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'sub-title-rtl' : 'sub-title'])
                    }, [
                      _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Meet.avgRate')) + " :", 1),
                      _createVNode(_component_a_rate, {
                        value: _ctx.selectedFeedback.serviceRate,
                        "allow-half": "",
                        disabled: "",
                        class: _normalizeClass([_ctx.locale === 'ar' ? 'rate-avg-rtl' : 'rate-avg'])
                      }, null, 8, ["value", "class"])
                    ], 2)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.selectedFeedback.serviceFeedback.length)
          ? (_openBlock(), _createBlock(_component_a_row, {
              key: 3,
              gutter: 16,
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFeedback.serviceFeedback, (feedback, index) => {
                      return (_openBlock(), _createBlock(_component_a_descriptions, {
                        key: index,
                        bordered: "",
                        size: "small",
                        class: _normalizeClass([_ctx.locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Name'),
                            span: 3
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_25, _toDisplayString(feedback.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]),
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Rate')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_rate, {
                                value: feedback.rate,
                                "allow-half": "",
                                disabled: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["label"]),
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Note')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_26, _toDisplayString(feedback.note), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"])
                        ]),
                        _: 2
                      }, 1032, ["class"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.selectedFeedback.equipmentFeedback.length)
          ? (_openBlock(), _createBlock(_component_a_row, { key: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t('Meet.EquipmentRate')) + " :", 1)
                    ]),
                    _createElementVNode("p", {
                      class: _normalizeClass([_ctx.locale === 'ar' ? 'sub-title-rtl' : 'sub-title'])
                    }, [
                      _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t('Meet.avgRate')) + " :", 1),
                      _createVNode(_component_a_rate, {
                        value: _ctx.selectedFeedback.equipmentRate,
                        "allow-half": "",
                        disabled: "",
                        class: _normalizeClass([_ctx.locale === 'ar' ? 'rate-avg-rtl' : 'rate-avg'])
                      }, null, 8, ["value", "class"])
                    ], 2)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.selectedFeedback.equipmentFeedback.length)
          ? (_openBlock(), _createBlock(_component_a_row, {
              key: 5,
              gutter: 16,
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFeedback.equipmentFeedback, (feedback, index) => {
                      return (_openBlock(), _createBlock(_component_a_descriptions, {
                        key: index,
                        bordered: "",
                        size: "small",
                        class: _normalizeClass([_ctx.locale === 'ar' ? 'ingredient-des-rtl' : 'ingredient-des'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Name'),
                            span: 3
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_29, _toDisplayString(feedback.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]),
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Rate')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_rate, {
                                value: feedback.rate,
                                "allow-half": "",
                                disabled: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["label"]),
                          _createVNode(_component_a_descriptions_item, {
                            label: _ctx.t('Meet.Note')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_30, _toDisplayString(feedback.note), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"])
                        ]),
                        _: 2
                      }, 1032, ["class"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["open", "placement", "width"])
  ], 64))
}