<template>
    <div>
        <Loader :is-loaded="!loading"></Loader>
    </div>

   <a-row class="login-container">

        <a-col :xs="{ span: 24, offset: 0}" :sm="{ span: 24, offset: 0}"  :md="{span: 11, offset: 0}" :lg="{ span: 11, offset: 0 }" :xl="{ span: 11, offset: 0 }" :xxl="{ span: 11, offset: 0 }" class="side-container">
            
            
            
            <div class="logo-container">
                <img src="@/assets/img/meetix-logo.png" alt="meetix-img" class="meetix-logo"/>
            </div>

            <div class="errors-container" v-if="form.errors.length !== 0 || form.errorMessage ">
                
                <div v-if="form.errorMessage!==''" class="invalid-cred-container" >
                    <InfoCircleOutlined  class="error-icon"/>
                    <span>{{t(`Meet.${form.errorMessage}`) }}</span>
                </div>

                <div class="errors-input-container">

                    <div v-for="error in form.errors.slice(0, 1)" :key="error" class="error-form">
                        <InfoCircleOutlined class="error-icon" />
                        <span>{{ t(`Meet.${error.name[0]}`) }} {{ t(`Meet.${error.errors[0]}`) }}</span>
                    </div>

                </div>
                
                
            </div>
            <div class="form-container">
                    
                <a-form ref="formRef" layout="vertical" :rules="rules" :model="form">

                    
                    <a-row >
                        <a-col :span="24">
                            <a-form-item  name="email" :validate-status="''" :help="''">

                                <template #label>
                                    <span class="label-form">{{ t('Meet.Email') }}</span>
                                </template>
                                <a-input v-model:value="form.email" :placeholder="t('Meet.Email')" class="login-input" />
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-item  name="password" :validate-status="''" :help="''">
                                <template #label>
                                    <span class="label-form">{{ t('Meet.Password') }}</span>
                                </template>
                                <a-input-password v-model:value="form.password" v-model:visible="visible"
                                    :placeholder="t('Meet.Password')" class="login-input"  />
                            </a-form-item>
                        </a-col>
                    </a-row>

                    
                    
                    <a-row class="login-btn-container">
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }">
                            <button class="login-btn"  @click="onSubmit">{{ t('Meet.SignIn') }}</button>
                        </a-col>   
                    </a-row>

                    <!-- <a-row class="login-btn-container mt">
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }">
                            <button class="login-ad-btn"  @click="adSubmit">
                                <WindowsOutlined />
                                <span>{{ t('Meet.ad') }}</span>
                            </button>
                        </a-col>   
                    </a-row> -->

                
                </a-form>
            </div>

            
            
        </a-col>

        <a-col  :xs="{ span: 0, offset: 0 }" :sm="{ span: 0, offset: 0 }"  :md="{span: 13, offset: 0}" :lg="{ span: 13, offset: 0 }" :xl="{ span: 13, offset: 0 }" :xxl="{ span: 13 }" class="login-img">
            <img src="@/assets/img/login-meetix.jpg" alt="Meetys Image" class="meetix-side-img"/>
        </a-col>
   </a-row>

</template>
  
<script lang="ts">
import { useI18n } from 'vue-i18n';
import { notification } from 'ant-design-vue';
import { ref, defineComponent, reactive} from 'vue';
import { authService  } from "@/_services";
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import Loader from '../../components/Looder.vue';

import {
  
 //WindowsOutlined,
  InfoCircleOutlined
} from '@ant-design/icons-vue';
export default defineComponent({
    name: "loginIndex",
    components: {
       
        Loader,
        //WindowsOutlined,
        InfoCircleOutlined,
    },

    setup() {
        const loading = ref(false);
       
       
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const openTransferModal = ref<boolean>(false);
        const { t } = useI18n();
        const checked = ref<boolean>(false);
        const visible = ref<boolean>(false);
        const router = useRouter();
        const formRef = ref();

        const form = reactive({
            email:'',
            password:'',
            errorMessage:'',
            errors:[],
        });


        const reset =()=> {
            form.email='',
            form.password='',
            form.errorMessage=''
        }
        
        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style: {
                    backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const OpenRegisterModal = ()=> {
            reset();
            openTransferModal.value = true
        }

        const rules: Record<string, Rule[]> = {
            email: [
                { required: true, message:  'isRequired' },
                {
                    validator: (_, value) => {
                        if (!value) {
                            return Promise.resolve();
                        }
                        if (validEmail(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject('isInvalid');
                    }
                }
            ],
            password: [
                { required: true, message: 'isRequired' }
            ]
        };

        const validEmail = (email: string) => {
            let re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        };

        const onSubmit = () => {
            form.errors=[];
            formRef.value
                .validateFields()
                .then(() => {
                    login();
                })
                .catch((errors: any) => {
                    console.log('error', errors);
                    if (errors.errorFields.length > 0) {
                        form.errors=errors.errorFields;
                    }
                });
        };

        const login = () => {
            loading.value=true;
            authService.login(form.email, form.password).then((res) => {
               
                if(res.role === 'EMPLOYEE' ){
                    router.push("/meetings");
                }else{
                    router.push("/");
                }
                
                
            }).catch((err) => {
                console.log("err", err)
                form.errorMessage = 'invalidCred';
                
            }).finally(()=>{
                loading.value=false;
            })
        };

        const loginAd = () => {

            const formData = new FormData();
            formData.append('email', form.email);
            formData.append('password', form.password);
            formData.append('sso_connection',"1");
            loading.value=true;
            authService.loginAD(formData ).then((res) => {
               
                    
                if(res.role === 'EMPLOYEE' ){
                    console.log('aaaa');
                    
                    router.push("/meetings");
                }else{
                    router.push("/");
                }
                
                
            }).catch((err) => {
                console.log("err", err)
                form.errorMessage = 'invalidCred';                
            }).finally(()=>{
                loading.value=false;
            })
        };

        const adSubmit=()=>{

            formRef.value
            .validateFields()
            .then(() => {
                loginAd();
            })
            .catch((errors: any) => {
                if (errors.errorFields.length > 0) {
                    form.errors=errors.errorFields;
                }
            });
        }

       
        return {
            adSubmit,
            loading,
            showSuccessNotification,
            OpenRegisterModal,
            t,
            checked,
            form,
            login,
            visible,
            rules,
            onSubmit,
            formRef,
            openTransferModal,
            reset,
            navigatePage
            
        }
    }
});
</script>

<style scoped>

.login-container{
   
   
    height: 100vh;
    width: 100vw;
    position: fixed;
    
}

.side-container{
    background: #f8f8fa;
}

.form-container{
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
}

.logo-container{
    margin-top: 100px;
    margin-bottom: 80px;
}

.login-input{
    line-height: 2.5;
    border: none;
    background: #fff;
    font-weight: 500;
}

.login-btn{
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  padding: 8px;
  background:#171717 ;
  color: #fff;
  border: none;
  border-radius: 6px;
  line-height: 1.5;
  cursor: pointer;
}

.login-btn:hover{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    
}

.login-btn:focus{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);    
}

.login-btn:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.login-ad-btn{
  width: 100%;
  color: #171717;
  padding: 8px;
  background: #fff;
  border: none;
  border-radius: 6px;
  line-height: 1.5;
  cursor: pointer;
  border: 1px solid #171717;
}


.login-ad-btn:hover{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    
}

.login-ad-btn:focus{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);    
}

.login-ad-btn:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.ant-form-item .ant-form-item-explain-error{
    display: flex !important;
}



.register-title{
    font-weight: 600;
    color: #9b2180;
    cursor: pointer;
}

.login-img{
    display: flex;
    align-items: center;
    justify-content: center;
}

.label-form{
    font-weight: 600;
}

.log-form{
    display: flex;
    align-content: center;
    justify-content: center;
}

.register-modal{
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    width: 400px;
   
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stepper */

.steps-content {
  margin-top: -45px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.ant-steps-item-icon{
    background-color: #fc8019;
    border-color: #fc8019;

}

.error-msg{
    color: #ea5455;
    font-weight: 400;
    font-size: 16px;
}

.mt{
    margin-top: 20px;
}

.ad-txt{
    font-size: 20px;
}

.error-icon{
    font-size: 20px;
}

.meetix-side-img{
    width:100%;
    height:100%;
    filter: grayscale(30%);

}

.meetix-logo{
    width: 50%;
    height: auto;
}

.errors-container{
    border-radius: 5px;
    border: 1px solid #ff4d4f;
    color: #ff4d4f;
    text-align: start;
    background-color: #ea54541b;
    padding: 10px;
    margin: 30px 30px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;

}

.invalid-cred-container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.errors-input-container{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
}

.error-form{
    display: flex;
    align-items: center;
    gap: 5px;
}











@media (min-width: 768px) {
    
.form-container{
    padding-left: 30px;
    padding-right: 30px;
}

}



@media (min-width: 1024px) {

.form-container{
    padding-left: 30px;
    padding-right: 30px;
}

}


</style>