import { statistic } from "ant-design-vue/es/theme/internal";

function server() {
    return process.env.VUE_APP_API_URL_TN;
}

export const ApiConfigs = {
    base_url: server(),
    /* -------------------------------- */
    // PUSHER
    pusher: {
        auth: "/auth",
    },
    // ACCOUNT
    accounts: {
        captcha: process.env.VUE_APP_CAPTCHA_LINK,
        login: "/auth",
        logout: "/logout",
        Register:"/register"
    },

    rooms:{
        getAll:"/rooms",
        create:"/rooms",
        show: "/rooms/:id",
        update:"/rooms/:id",
        delete: "/rooms/:id",
        addPhotos:"/rooms/pictures/upload",
        deletePhotos:"/rooms/pictures/:id",
        addWorkingDays:"/rooms-working-days/set",
    },
    
    roomLocations:{
        getAll:"/room-locations",
        create:"/room-locations",
        show: "/room-locations/:id",
        update:"/room-locations/:id",
        delete: "/room-locations/:id",
    },

    reservations:{
        getAll:"/reservations",
        create:"/reservations",
        show: "/reservations/:id",
        update:"/reservations/:id",
        delete: "/reservations/:id",
    },
    equipments:{
        getAll:"/equipments",
        create:"/equipments",
        show: "/equipments/:id",
        update:"/equipments/:id",
        delete: "/equipments/:id",
    },
    services:{
        getAll:"/services",
        create:"/services",
        show: "/services/:id",
        update:"/services/:id",
        delete: "/services/:id",
    },
    feedback:{
        getAll:"/feedbacks",
        show:"/feedbacks/:id",
        reservationFeed:"/reservation-for-feedback/:id",
        create:"/feedbacks"
    },

    kiosk:{
        getAll:"/kiosks",
        create:"/kiosks",
        show: "/kiosks/:id",
        update:"/kiosks/:id",
        delete: "/kiosks/:id",
        connect:"/kiosks/",
        logout:"/kiosks/:id/logout",
        refresh:"/kiosks/:id/refresh"
    },

    statistic:{
        dashboard:"/dashboard",
        feedbacks:"/dashboard/recent-feedbacks",
        feedbackRate:"/dashboard/feedbacks-per-cent",
        roomAvailability:"/dashboard/room-availability-stat",
        reservations:"/dashboard/reservation-frequency",
        reservationsWithApproval:"/dashboard/reservation-frequency-approval",
        topServices:"/dashboard/top-requested-services",
        topEquipments:"/dashboard/top-requested-equipments",
        RoomOccupancyRate:"/dashboard/room-occupancy-rate"
    },

    serviceRequests:{
        getAll:'/reservation-services',
        create:'/reservation-services',
        update:"/reservation-services/:id",
        delete: "/reservation-services/:id",
        show:'/reservation-services/:id',
        approve:'/reservations/:idRes/services/:idSer/approve',
        disapprove:'/reservations/:idRes/services/:idSer/approve',
    },
    equipmentRequest:{
        getAll:'/reservation-equipments',
        create:'/reservation-equipments',
        update:"/reservation-equipments/:id",
        delete: "/reservation-equipments/:id",
        show:'/reservation-equipments/:id',
        approve:'/reservations/:idRes/equipments/:idEq/approve',
        disapprove:'/reservations/:idRes/equipments/:idEq/approve',

    },

    user:{
        getAll:"/users",
        create:"/users",
        show: "/users/:id",
        update:"/users/:id",
        delete: "/users/:id",
        checkEmail:"/users/verify-email",
        changePassword:"/users/:id/change-password",
        changeRole:"/users/:id/change-role"
    },
    customer: {
        getAll: "/customers",
        show: "/customers/:id",
        store: "/customers",
        update: "/customers/:id",
        delete: "/customers/:id",
        searchWithoutPagination: "/customers?pagination=off"

    },
    roles:{
        getAll:"/roles",
    },
    permissions:{
        getAll:"/permissions",
        add:"/users/:id/change-permissions"
    },
    roomsAvailibility:{
        get:"/rooms-availability-for-period",
        update:"/rooms-availability-for-period-for-update"
    }

};
