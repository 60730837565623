<template>


    <a-row :gutter="16">
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
            <a-card hoverable>

                <a-flex gap="middle" justify="space-between">
                    <div class="revenu-info">
                        <div :class="[locale === 'ar' ? 'revenu-container-rtl' : 'revenu-container']" >
                            <CalendarOutlined class="revenu-icon"/>
                        </div>
                        <span class="revenu-title" >{{t('Meet.Meetings')}}</span>
                    </div>
                </a-flex>
                <a-skeleton :loading="loading" :size="small"  active >
                    <a-flex align="start" vertical class="numbers-container">
                        <span class="big-title"> {{state.meetingsNumber}} {{t('Meet.Meetings')}}</span>
                        <span class="revenu-title"></span>
                    </a-flex>
                </a-skeleton>
            
            
            </a-card>
        </a-col>


        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
            <a-card hoverable>

                <a-flex gap="middle" justify="space-between">
                    <div class="revenu-info">
                        <div  :class="[locale === 'ar' ? 'order-container-rtl' : 'order-container']" >
                            <ScheduleOutlined class="revenu-icon"/>
                        </div>
                    
                        <span class="revenu-title">{{t('Meet.ReservationRequests')}}</span>
                    </div>

                </a-flex>
                <a-skeleton :loading="loading" :size="small" active >
                    <a-flex align="start" vertical class="numbers-container">
                        <span class="big-title" >  {{state.reservationRequests}} {{t('Meet.Requests')}}</span>
                        <span class="revenu-title"> </span>
                    </a-flex>
                </a-skeleton>
            
            </a-card>
        </a-col>

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
            <a-card hoverable>

                <a-flex gap="middle" justify="space-between">
                    <div class="revenu-info">
                        <div :class="[locale === 'ar' ? 'products-container-rtl' : 'products-container']">
                            <ApartmentOutlined class="revenu-icon"/>
                        </div>  
                        <span class="revenu-title">{{t('Meet.ReservedRooms')}}</span>
                    </div>   
                </a-flex>

                <a-skeleton :loading="loading" :size="small" active >
                    <a-flex align="start" vertical class="numbers-container">
                        <span class="big-title"> {{state.reservedRooms}} {{t('Meet.Rooms')}}</span>
                        <span class="revenu-title">
                        </span>
                    </a-flex>
                </a-skeleton>

            
            
            </a-card>
        </a-col>

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
            <a-card hoverable>

                <a-flex gap="middle" justify="space-between">
                    <div class="revenu-info">
                        <div  :class="[locale === 'ar' ? 'discount-container-rtl' : 'discount-container']">
                            <FormOutlined   class="revenu-icon" />
                        </div>
                        <span class="revenu-title">{{t('Meet.InternalRequests')}}</span>
                    </div>

                </a-flex>
                <a-skeleton :loading="loading" :size="small" active >
                    <a-flex align="start" vertical class="numbers-container">
                        <span class="big-title"> {{state.internalRequests}} {{t('Meet.Requests')}}</span>
                        <span class="revenu-title">
                            
                        </span>
                    </a-flex>
                </a-skeleton>
            
            </a-card>
        </a-col>
   </a-row>

   <a-row :gutter="16">

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 16 }" :xxl="{ span: 16 }" class="card-container">
            <a-card hoverable class="calender-card">

               


                <a-row class="flex-container" :gutter="16">
                    <a-col  :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }" class="flex-item-icon">
                        <CalendarOutlined class="calender-icon" />
                        <p class="calender-title">{{ t('Meet.Meetings') }}</p>
                    </a-col>
                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }" class="flex-item-btn">
                        <a-button class="event-btn" @click="navigatePage('/meetings','2-1')">{{ t('Meet.CreateEvent') }}</a-button>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }" class="flex-item-calender">
                        <p class="calender-sub-title"> {{ t('Meet.EventTitle') }}  </p> 
                        <a-skeleton :loading="loadingMeetings" :size="small" active >

                        <div v-for="item in state.upcomingMeets" :key="item" class="events-container-up">
                            <div class="upcoming-container">
                                <a-badge :color="getTagColor(item.approved)" />

                                <span class="info-cal-title" v-if="item.motif"> {{item.motif}}</span>
                                <span class="info-cal-title" v-else> {{item.room.name}} </span>
                            </div>

                            

                            <span  :class="[locale === 'ar' ? 'info-cal-txt-rtl' : 'info-cal-txt']">{{formatEventDate(item.start,item.end)}}</span>

                            <a-avatar-group
                                :max-count="2"
                                max-popover-trigger="click"
                                size="small"
                                :max-style="{ color: $primaryColor , backgroundColor: '#ffc1074d', cursor: 'pointer' }"
                                :class="[locale === 'ar' ? 'info-cal-txt-rtl' : 'info-cal-txt']"
                            >
                                <template v-for="(participant, index) in item.participants" :key="index">
                                    <a-tooltip :title="`${participant.first_name} ${participant.last_name}`" placement="top">
                                        <a-avatar v-if="participant.photo !== null" :src="participant.photo"></a-avatar>
                                        <a-avatar v-else :style="{backgroundColor: $primaryColor }">
                                            <template #icon>{{getFirstLetter(participant.first_name)}}</template>
                                        </a-avatar>
                                    </a-tooltip>
                                </template>

                                <template v-for="(external, index) in item.external_participants" :key="index">
                                    <a-tooltip :title="`${external.email}`" placement="top">
                                        <a-avatar>
                                            <template #icon><UserOutlined /></template>
                                        </a-avatar>
                                    </a-tooltip>
                                </template>


                            </a-avatar-group>
                            
                        </div>
                        </a-skeleton>

                      
                     
                     

                    </a-col>

                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 16}" :lg="{ span: 16 }" :xl="{ span: 16 }" :xxl="{ span: 16 }" class="flex-item-btn">
                        <div :style="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '4px' }">

                            <a-locale-provider :locale="calendarLocale">
                                <a-calendar 
                                    v-model:value="calendarDash" 
                                    :fullscreen="false" 
                                    @change="handleDashCalendar"
                                />
                            </a-locale-provider>
                        </div>
                    </a-col>
                </a-row>


               

             

                
            </a-card>
        </a-col>

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 8 }" :xxl="{ span: 8 }" class="card-container">
            <a-card hoverable class="donut-card"  >

                <template #title>
                    <p class="title-card">{{t('Meet.RoomOccupancy')}}</p>
                </template>

              
                <canvas class="rooms-donut" id="roomsDonut"   ></canvas>

               

            </a-card>

            <!-- <a-card class="card-container">
                <canvas class="rooms-donut" id="reservationDonut"  width="100" height="140" ></canvas>
            </a-card> -->
        </a-col>

   </a-row>

   <a-row>
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
            <a-card hoverable>
                <div class="flex-item-calender">
                    <p class="calender-title">{{t('Meet.MonthlyResrvationStat')}}</p>
                </div>

                <canvas id="reservationStat"   width="150" height="50" ></canvas>

            </a-card>
        </a-col>
   </a-row>

   <a-row :gutter="16">
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }" class="card-container">
            <a-card hoverable>
                <div class="flex-item-calender">
                    <p class="calender-title">{{t('Meet.RecentReviews')}}</p>
                </div>

                <a-list
                    class="demo-loadmore-list"
                    :loading="initLoading"
                    item-layout="horizontal"
                    
                    :data-source="state.recentFeedbacks"
                >

                    <template #renderItem="{ item }">
                        <a-list-item>
                            
                            <a-skeleton avatar :title="false" :loading="!!item.loading" active>
                                <a-list-item-meta class="feed-item"
                                >

                                    <template #description>
                                        <div class="flex-item-calender">
                                            <p class="feed-desc">{{item.note}}</p>
                                        </div>
                                        
                                    </template>
                                    <template #title>
                                        <div class="flex-item-calender">
                                            <div class="feed-title-container">
                                                <a-rate :value="item.rate" allow-half disabled />
                                                <!-- <a class="view-feed-btn">{{t('Meet.viewMore')}}</a> -->

                                            </div>


                                            <div class="item-title">
                                                <p class="feed-title">{{ item.user.first_name }} {{ item.user.last_name }}</p> 
                                                <p :class="[locale === 'ar' ? 'date-feed-txt-rtl' : 'date-feed-txt']">{{formatDisplayDate(item.created_at)}}</p>
                                            </div>
                                        </div>    
                                            
                                        
                                        
                                    </template>
                                    <template #avatar>
                                        <a-avatar :src="item.user.photo" v-if="item.user.photo!==null" />
                                        <a-avatar  v-if="item.user.photo===null" >
                                            <template #icon><UserOutlined /></template>
                                        </a-avatar>
                                    </template>
                                </a-list-item-meta>
                            </a-skeleton>
                        </a-list-item>
                    </template>
                </a-list>

                <div class="flex-item-calender">
                    <p class="more-action" @click="navigatePage('/feedback', '7')">{{t('Meet.AllRating&Reviews')}} >> </p>
                </div>
            </a-card>
        </a-col>

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }" class="card-container">
            <a-card hoverable>
                <div class="flex-item-calender">
                    <p class="calender-title">{{t('Meet.RatingDistribution')}}</p>
                </div>

                <a-row :gutter="16">
                    <a-col  :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 8 }" :xxl="{ span: 8 }"  class="prog-container">
                        <a-progress 
                            type="circle" 
                            :stroke-color="{
                                '0%': '#ff6384',
                                '100%': '#ff9f40',
                            }"                 
                            :percent="state.nbFeeds" 
                            :format="percent => `${percent}`" 
                            :size="160"
                        />
                    </a-col>

                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 16 }" :xxl="{ span: 16 }">
                        <template v-for="item in state.feedbackRates" :key="item">

                            <a-row class="feed-back-room-container">
                                <a-col :span="6" class="room-name-container">
                                    <span class="room-name-feed" v-if="item.feedbackable !== null">{{item.feedbackable.name}}</span>
                                </a-col>
                               
                                <a-col :span="18">
                                    <a-progress v-if="item.feedbackable !== null" :percent="item.percent_of_feedback"  :stroke-color="item.feedbackable.color ? item.feedbackable.color : 'indigo'" />
                                </a-col>

                            </a-row>
                        </template>
                    </a-col>
                </a-row>

            </a-card>
        </a-col>


   </a-row>

</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed } from 'vue';
import {
ApartmentOutlined,
ScheduleOutlined,
CalendarOutlined,
FormOutlined,
UserOutlined,
QuestionCircleOutlined


} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import {useRouter } from 'vue-router';
import { Chart} from 'chart.js/auto';

import { Empty } from 'ant-design-vue';
import Loader from '../../components/Looder.vue';
import ar_EG from 'ant-design-vue/es/locale/ar_EG';
import en_GB from 'ant-design-vue/es/locale/en_GB';
import fr_FR from 'ant-design-vue/es/locale/fr_FR';
import { feedbackSevrice, staticticsService } from '@/_services';
import dayjs , { Dayjs }from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';


import 'dayjs/locale/fr'; 
import 'dayjs/locale/ar'; 
export default defineComponent({
    name: 'indexDashboard',
    components: {
        ApartmentOutlined,
        FormOutlined,
        ScheduleOutlined,
        CalendarOutlined,
        UserOutlined,
    },
    setup() {
        dayjs.extend(localeData);
        dayjs.extend(weekday);
        dayjs.extend(relativeTime);

       //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );

        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const loading = ref(false);
        const { t } = useI18n();
        const router = useRouter();

        const navigatePage = (link: string, key:string) => {
            router.push(link); 
           
            localStorage.setItem('selectedKey', key);

        };

       

        const state = reactive({
           searchText: '',
           searchedColumn: '',
           meetingsNumber:'',
           reservationRequests:'',
           internalRequests:'',
           reservedRooms:'',
           recentFeedbacks: [] as any[],
           feedbackRates: [] as any[],
           upcomingMeets: [] as any[],
           events:[] as any[],
           feeds:[] as any[],
           nbFeeds:0,
        });

        const dashboardInfo=()=>{
            loading.value=true;
            staticticsService.getCardsInfo().then((res)=>{
                state.meetingsNumber = res.reservations;
                state.reservationRequests=res.reservation_requests;
                state.reservedRooms = res.room_occupied;
                state.internalRequests = res.intern_requests

            }).catch((error:any)=>{
                console.log('error', error);
            }).finally(()=>{
                loading.value=false;
            })
            
        }

        const getRecentFeebacks=()=>{
            staticticsService.getRecentFeedBacks("3").then((res)=>{
                state.recentFeedbacks=res.recent_feedbacks;
            }).catch((error:any)=>{
                console.log('error', error);
            });
        }
        const lang=localStorage.getItem('Language')|| 'en';
        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).locale(lang).fromNow();
        }

        const getFeebackRate=()=>{
            staticticsService.getFeedbackRate("room","5").then((res)=>{
                state.feedbackRates=res.feedback_per_cent;
            }).catch((error:any)=>{
                console.log('error', error);
            });
        }

        const getRoomAvailability=()=>{

            staticticsService.roomAvailability().then((res)=>{
                const data = res.data;
                const roomData = [
                    { name: t('Meet.Occupied'), sum: data.occupied },
                    { name: t('Meet.Available'), sum: data.available }
                ];
                
                donutChart(roomData);

            }).catch((error:any)=>{
                console.log('error', error);
            });
        
        }

        const getReservations=()=>{
            staticticsService.getMonthlyReservations().then((res)=>{
                reservationChart(res.data);
            }).catch((error:any)=>{
                console.log('error', error);
            });
        }

        //charts
        const donutChart=(datas:any[])=>{

            const existingChart = Chart.getChart("roomsDonut");
            if (existingChart) {
                existingChart.destroy();
            }

            let labels: string[] = [];
            let totals:number[]=[];

            

            datas.forEach(item=>{
                labels.push(item.name),
                totals.push(item.sum)
            });

           
            
            return  new Chart("roomsDonut", {
                type: 'doughnut',
                data: {
                    labels:labels ,
                    datasets: [{
                        label: t('Meet.Rooms'),
                        data:totals ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.6)', 
                            'rgba(255, 159, 64, 0.6)', 
                        ]
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        // title: { 
                        //     display: true,
                        //     text: 'Room Occupancy', // Set the title text
                        //     padding: {
                        //         top: 10,
                        //         bottom: 10
                        //     },
                        //     font: {
                        //         size: 18,
                        //         family: "'Exo 2', sans-serif", 
                        //     }
                        // },
                        legend: {
                            position: 'bottom'
                        }
                    },
                    layout: {
                        padding: 0,
                    }
                    
                    
                }
                
                
            });
        }

        const reservationChart=(datas:any[])=>{

            const existingChart = Chart.getChart("reservationStat");
            if (existingChart) {
                existingChart.destroy();
            }

            //const allDays = [ t('Meet.Monday').toLocaleLowerCase(), t('Meet.Tuesday').toLocaleLowerCase(), t('Meet.Wednesday').toLocaleLowerCase(), t('Meet.Thursday').toLocaleLowerCase(), t('Meet.Friday').toLocaleLowerCase(), t('Meet.Saturday').toLocaleLowerCase(), t('Meet.Sunday').toLocaleLowerCase()];

            const allDays = [
                dayjs().day(1).locale(lang).format('dddd').toLowerCase(), // monday
                dayjs().day(2).locale(lang).format('dddd').toLowerCase(), // tuesday
                dayjs().day(3).locale(lang).format('dddd').toLowerCase(), // wednesday
                dayjs().day(4).locale(lang).format('dddd').toLowerCase(), // thursday
                dayjs().day(5).locale(lang).format('dddd').toLowerCase(), // friday
                dayjs().day(6).locale(lang).format('dddd').toLowerCase(), // saturday
                dayjs().day(0).locale(lang).format('dddd').toLowerCase()  // sunday
            ];

            const dayOfWeekCount: { [key: string]: number } = {
                [allDays[0]]: 0, // monday
                [allDays[1]]: 0, // tuesday
                [allDays[2]]: 0, 
                [allDays[3]]: 0, 
                [allDays[4]]: 0, 
                [allDays[5]]: 0, 
                [allDays[6]]: 0  
            };

            datas.forEach(reservation => {
                const { day, month, year, total } = reservation;
                const date = dayjs(`${year}-${month}-${day}`).locale(lang);
                const dayOfWeek = date.format('dddd').toLowerCase();

                if (dayOfWeekCount[dayOfWeek] !== undefined) {
                    dayOfWeekCount[dayOfWeek] += total;
                }

                //console.log('res date', dayOfWeek);
            });

           
            
            /*
            datas.forEach(reservation => {

                const { day, month, year, total } = reservation;
                const date = dayjs(`${year}-${month}-${day}`).locale(lang);
                const dayOfWeek = date.format('dddd').toLowerCase();

                switch (dayOfWeek) {
                    case 'monday':
                        dayOfWeekCount.monday += total;
                        break;
                    case 'tuesday':
                        dayOfWeekCount.tuesday += total;
                        break;
                    case 'wednesday':
                        dayOfWeekCount.wednesday += total;
                        break;
                    case 'thursday':
                        dayOfWeekCount.thursday += total;
                        break;
                    case 'friday':
                        dayOfWeekCount.friday += total;
                        break;
                    case 'saturday':
                        dayOfWeekCount.saturday += total;
                        break;
                    case 'sunday':
                        dayOfWeekCount.sunday += total;
                        break;
                }


               

                console.log('res date', dayOfWeek);

            });
            */

            const reservationCounts = allDays.map(day => dayOfWeekCount[day]);

            console.log('reservation count', reservationCounts);


            

            return  new Chart("reservationStat", {
                type: 'bar',
                data: {
                    labels:allDays,
                    datasets: [{
                        label: t('Meet.Meetings'),
                        data:reservationCounts,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 205, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(201, 203, 207, 0.2)'
                        ],
                        borderColor: [
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(201, 203, 207)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        
                        legend: {
                            position: 'bottom'
                        }
                    },
                    layout: {
                        padding: 0,
                    }
                    
                    
                }
                
                
            });

        }

        
        const loadingMeetings= ref(false);
        const getUpcomingMeets=()=>{
            const currentTime=dayjs();
            const startDate = currentTime.startOf('month').format('YYYY-MM-DD');
            const endDate = currentTime.endOf('month').format('YYYY-MM-DD');
            console.log('start date', startDate);
            console.log('end date', endDate);

            loadingMeetings.value=true;
            staticticsService.getCurrentResrvations(startDate, endDate).then((res)=>{
                //console.log('upcoming events', res.data);
                state.upcomingMeets=res.data.slice(0, 3);
                state.events=res.data;
            }).catch((error)=>{
                console.log('error  fetching upconmong meetings', error)
            }).finally(()=>{
                loadingMeetings.value=false;
            })


        }

        const transformData = (data: any[]): any[] => {

           
            const groupedData : { [key: string]: any } = {};
            data.forEach((outerItem) => {
                outerItem.forEach((item: any) => {
                    if (item.feedbackable_type === "App\\Models\\Room") {
                        const reservationId = item.reservation_id;

                        if (!groupedData[reservationId]) {
                            groupedData[reservationId] = {
                                reservationId: reservationId,
                                reservation: item.reservation?.motif || null,
                                rooms: []
                            };
                        }

                        const feedbackableName = item.feedbackable?.name || null;
                        groupedData[reservationId].rooms.push({
                            feedbackableName: feedbackableName,
                            feedback: item.feedback || null
                        });
                    }
                });
            });
            

           
            return Object.values(groupedData);
        };

        const getAllFeeds=()=>{
            loading.value=true;
            feedbackSevrice.getAll().then((response)=>{
                state.feeds = transformData(response.data);
            
                state.nbFeeds=state.feeds.length;
            }).catch((error)=>{
                console.log("error get feedbacks", error);
            }).finally(()=>{
                loading.value = false;
            });
        }


       


      

        

        

        const formatEventDate= (startDate:string , endDate:string)=>{

            const start = dayjs(startDate);
            const end = dayjs(endDate); 
            
            const startFormat = start.format('MMM D, HH:mm');
            const endFormat = end.format('HH:mm');

            const formattedDate = `${startFormat} - ${endFormat}`;
            
            return formattedDate;
        }

        const getParticipantsNames=(participants:any)=> {
            if (participants.length > 2) {
                const firstTwoNames = participants.slice(0, 2).map((p: { first_name: any; last_name: any; }) => `${p.first_name} ${p.last_name}`);
                return `${firstTwoNames.join(', ')} and others`;
            }
            return participants.map((p: { first_name: any; last_name: any; }) => `${p.first_name} ${p.last_name}`).join(', ');
        }

        const  getTagColor=(item:any)=> {
            return item.approved ? '#f50' : '#87d068'; 
        }

        //feedbacks

        const initLoading = ref(true);
        //const list = ref([]);
        const list = ref<Array<{ name: string; description: string; rate: number; photo: string | null; loading: boolean; }>>([]);


        const getFeedBack=()=>{
            initLoading.value = true;
           
            const mockData = [
            { name: 'Oussema Mettichi', description: 'Great meeting', rate: 4, photo: null, loading: false },
            { name: 'Oussema Mettichi', description: 'Great service', rate: 3, photo: null, loading: false },
            ];
            list.value = mockData;
            initLoading.value = false;
          

        }

        interface feedBackRooms {
            id: string;
            name:string;
            percentage:string;
            color:string;
        }
        const feedBackRooms_array = ref<feedBackRooms[]>([]); 
        const getFeedBackRooms=()=>{

            const mockedData: feedBackRooms[] = [
                { id: '1', name: 'Room 1A1', percentage: '54', color:'rgb(255, 99, 132, 0.6)' },
                { id: '1', name: 'Room 1A2', percentage: '50' , color:'rgb(255, 159, 64, 0.6)'},
                { id: '3', name: 'Room 1A3', percentage: '42', color:'rgb(255, 205, 86, 0.6)' },
                { id: '4', name: 'Room 1A4', percentage: '37', color:'rgb(75, 192, 192, 0.6)' },
                { id: '5', name: 'Room 1A5', percentage: '29' , color:'rgb(54, 162, 235, 0.6)'},

            ];

            feedBackRooms_array.value = mockedData;

        }

        //calendar locale
        const calendarLocale = computed(() => {

            if(locale.value==='ar'){
                return ar_EG;
            }else if(locale.value==='fr'){
                return fr_FR
            }else if(locale.value==='en'){
                return en_GB
            }else{
                return en_GB
            }
        });
        const getFirstLetter=(name:string)=>{ 
            return name.charAt(0).toUpperCase();
        }

        const calendarDash = ref<Dayjs>();

        const handleDashCalendar = (info:any)=>{
            console.log('selected date', dayjs(info).format('YYYY-MM-DD') );
            const day  = dayjs(info).format('YYYY-MM-DD')
            
        }





      
        onMounted(() => { 

            dashboardInfo();
            getRecentFeebacks();
            getFeebackRate();
            getRoomAvailability();
            getReservations();

            getUpcomingMeets();

            getFeedBack();

            getAllFeeds();

            getFeedBackRooms();

        })




       
        return{
            loadingMeetings,
            loading,
            handleDashCalendar,
            calendarDash,
            t,
            simpleImage,
            navigatePage,
            state,
            locale,
            textDirection,
            list,
            initLoading,
            feedBackRooms_array,
            calendarLocale,
            formatDisplayDate,
            formatEventDate,
            getParticipantsNames,
            getTagColor,
            getFirstLetter,




        }
    },
})
</script>
<style scoped>



/* feedback  */

.line{

    color: rgba(0, 0, 0, 0.45);
    border-color: rgba(0, 0, 0, 0.45);
    border-width: 0.05px;
    border-style: solid;

}

.demo-loadmore-list {
  min-height: 350px;
 
}


.view-feed-btn{
    font-weight: 400;

}

.feed-title-container{
    display: flex;
    align-items: baseline;
    justify-content: space-between !important;
}





.more-action{
    color: var(--primary-color, #171717);
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}
.item-title{
    display:flex;
    align-items: baseline;
    flex-direction: row;
}

.feed-title{
    font-weight: 700;
    font-size: 14px;
}

.date-feed-txt{
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    margin-left: 10px;
    font-size: 14px;
}

.date-feed-txt-rtl{
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    margin-right: 10px;
}

.feed-desc{
    color:rgba(0, 0, 0, 0.704) ;
    margin-top: -10px;
    font-size: 14px;
}

.feed-item{
    display: flex !important;
    align-items: baseline !important;
}


.feed-back-room-container{
    display: flex;
    align-items: baseline;
    margin-bottom: 13px;
}

.prog-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-name-feed{
    font-weight: 500;
    font-size: 14px;

    white-space: normal;
    word-wrap: break-word;
}


/* feedback  */


.title-card{
    margin: 0!important;
    padding: 0!important;
}

.donut-card{
    height: 100%;
    width: 100%;
}


/* .donut-card {
    width: 100%;
    height:100%;
}

.rooms-donut{
    width: 60%;
    height: 60%;
} */


.rooms-donut{
    max-height: 230px;
    display: flex;
}

.info-cal-title{
    font-weight: 500;
    text-align: start;
}

.info-cal-txt-rtl{
    display: flex;
    margin-right: 20px;
    color: #828282;
    margin-bottom: 20px;
}

.info-cal-txt{
    display: flex;
    margin-left: 20px;
    color: #828282;
}

.flex-item-calender{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.calender-sub-title{
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: start;

}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-item-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-item-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.event-btn{
    border-radius: 15px;
    color: #fff;
    background-color: var(--primary-color, #171717);

    border-color: var(--primary-color, #171717);
    font-size: 16px;

}

.event-btn:hover{
    border-radius: 15px;
    color: #fff;
    background-color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);

}


.calender-icon{
    margin-right: 10px;
    color: var(--primary-color, #171717);
    font-weight: 400;
    font-size: 18px;
}

.calender-title{
    font-weight: 600;
    font-size: 18px;
}

.dashboard-title{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   
}

.dash-title{
    font-weight: 600;
    font-size: 18px;
   
}

.dash-sub-title{
    margin-top: -30px;
    color: #828282;
}

.btn-dash-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.select-calender{
    width: 100%;
}

.btn-dash{
    border-radius: 20px !important;
    font-weight: 500;
}

.revenu-info{
    display: flex;
    align-items: center;
}

.revenu-container{
    background-color: #fff5e6;
    color: #ffc107;
    border-radius: 7px;
    margin-right: 3px;
}

.revenu-container-rtl{
    background-color: #fff5e6;
    color: #ffc107;
    border-radius: 7px;
    margin-left: 3px;
}

.order-container{
    background-color: #eae8fd;
    color: #8e1c77;
    border-radius: 7px;
    margin-right: 3px;
}

.order-container-rtl{
    background-color: #eae8fd;
    color: #8e1c77;
    border-radius: 7px;
    margin-left: 3px;
}

.products-container{
    background-color: #d39da236;
    color: #dc3545;
    border-radius: 7px;
    margin-right: 3px;
}

.products-container-rtl{
    background-color: #d39da236;
    color: #dc3545;
    border-radius: 7px;
    margin-left: 3px;
}


.discount-container{
    background-color: #f7b37b30;
    color: #fd7e14;
    border-radius: 7px;
    margin-right: 3px;
}

.discount-container-rtl{
    background-color: #f7b37b30;
    color: #fd7e14;
    border-radius: 7px;
    margin-left: 3px;
}


.revenu-icon{
    padding: 8px;
    font-size: 18px;
}

.revenu-title{
    color: #828282;
    font-size: 16px;
}

.big-title{
    font-weight: 700;
    font-size: 23px;
}

.numbers-container{
    margin-top: 10px;
}

.rise-icon{
    color: #28c76f;
    font-size: 16px;
    padding-right: 4px;
}

.fall-icon{
    color: #ea5455;
    font-size: 16px;
    padding-right: 4px;
}

.fall-icon-rtl{
    color: #ea5455;
    font-size: 16px;
    padding-left: 4px;
}
.fall-txt{
    color: #ea5455;
    font-weight: 600; 
}
.rise-txt{
    color: #28c76f;
    font-weight: 600;
}

.normal-txt{
    color: #7367f0;
}

.charts-container{
    margin-top: 10px;
}

.charts-title{
    font-weight: 700;
    font-size: 16px;
}

.warning-title{
    font-weight: 600;
    font-size: 16px;
    color: #ea5455;
}
.donut-chart-container{
    display: flex;
}

.donut-container{
    max-height: 230px;
    display: flex;
}

.revenue-chart-container{
    max-height: 230px;
}

.popular-table{
    margin-top: 10px;
}

.tbl-title{
    margin-bottom: 15px;
}

.warning-icon{
    color: #ea5455;
    font-size: 24px !important;
    padding-right: 10px;
}

.warning-icon-rtl{
    color: #ea5455;
    font-size: 24px !important;
    padding-left: 10px;
}



.warning-txt{
    color: #ea5455;

}

.select-input{
    width: 20%;
}

.calender-container{
    display:flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.calender-input{
    width: 100%;
}

.card-container {
    margin-top: 10px;
}

.end-prod-txt{
    margin-left: 5px;
}

.end-prod-txt-rtl{
    margin-right: 5px;
}

.upcoming-container{
    display: flex;
    justify-content: flex-start;
}

.events-container-up{
    margin-bottom: 20px;
}

.room-name-container{
   text-align: start;
    width: 100%; 
}





@media (max-width: 765px) {
  .card-container {
    margin-top: 20px;
  }

 



 
} 

@media (max-width: 1200px) {
    .donut-container {
        max-height: 230px !important; 
        overflow: auto !important; 
    }

    .revenue-chart-container {
        max-height: 230px !important; 
        overflow: auto !important; 
    }
}

/* @media (max-width: 1314px) {
    .end-prod-txt {
         display: none; /
        
    }
} */


.card-container2 {
    margin-top: 20px;
}



/* CSS for RTL */
.is-rtl {
    direction:rtl;
}

@media (min-width: 768px) {

    .info-cal-txt{
        font-size: 14px;
    }

    .more-action,
    .feed-title,
    .date-feed-txt,
    .room-name-feed{
        font-size: 16px;
    }

    .revenu-title,
    .info-cal-title,
    .title-card{
        font-size: 18px;
    }

    .calender-title,
    .calender-sub-title,
    .feed-desc{
        font-size: 20px;
    }
}

@media (min-width: 1024px) {

    .info-cal-txt,
    .room-name-feed{
        font-size: 16px;
    }

    .revenu-title,
    .info-cal-title,
    .more-action,
    .feed-title,
    .date-feed-txt{
        font-size: 18px;
    }

    .info-cal-title,
    .title-card{
        font-size: 20px;
    }

    .calender-title,
    .calender-sub-title,
    .feed-desc{
        font-size: 22px;
    }

}










</style>