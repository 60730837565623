<template>

    <Looder :is-loaded="!loading"></Looder>

    
    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Meet.Dashboard')}}
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{t('Meet.Settings')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div>
        <a-row>
            <a-card
                class="card-settings"
                :tab-list="settingsTitle"
                :active-tab-key="key"
                @tabChange="key => onTabChange(key, 'key')"
            >
                <p v-if="key === 'notif'">

                    <a-form ref="formRef" :model="form"  layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                        <!-- <a-collapse v-model:activeKey="activeKey" :bordered="false" ghost> -->
                            
                            <!-- <a-collapse-panel key="1" >

                                <template #header>
                                    <div class="custom-header">{{t('Meet.SMS')}}</div>
                                </template>

                                <a-row class="checkBox-container">
                                    <a-checkbox v-model:checked="checkeSMS">{{t('Meet.SMSTxtCheck')}}</a-checkbox>
                                </a-row>
                                <a-row :gutter="16" v-if="checkeSMS===true">   
                                    <a-col :span="24">
                                        <a-form-item   name="msgTxt" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.msgTxtTool')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.SmsContent') }}</span>
                                            </template>
                                            <a-textarea v-model:value="form.msgTxt" :rows="4" :placeholder="t('Meet.SmsContent')" />

                                        </a-form-item>
                                    </a-col>
                                </a-row>
                            </a-collapse-panel> -->

                            <!-- <a-collapse-panel key="2" > -->
                                
                                <template #header>
                                    <div class="custom-header">{{t('Meet.Email')}}</div>
                                </template>

                                <a-row >
                                    <a-col  :span="24">
                                        <a-form-item  name="url" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.url')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.url') }}</span>
                                            </template>
                                            <a-input v-model:value="form.emailFeedTxt"  :placeholder="t('Meet.url')" />

                                        </a-form-item>
                                       
                                    </a-col>
                                </a-row>

                                <a-row >
                                    <a-col class="configure-btn-container" :span="12">
                                        
                                        <a-switch v-model:checked="sendFeedbackEmails" size="small" />

                                        <span>{{t('Meet.receiveFeedbackEmails')}}</span>
                                    </a-col>
                                </a-row>

                                <!-- equipment/service mail --> 
                                <a-row class="checkBox-container">
                                    <a-checkbox v-model:checked="checkMailConf">{{t('Meet.ConfEmailCheck')}}</a-checkbox>
                                </a-row>
                                <a-row :gutter="16" v-if="checkMailConf===true">   
                                    <a-col :span="24">
                                        <a-form-item   name="emailConfirmTxt" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.emailTxtTool')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.EmailContentConf') }}</span>
                                            </template>
                                            <a-textarea v-model:value="form.emailConfirmTxt" :rows="4" :placeholder="t('Meet.EmailContentConf')" />

                                        </a-form-item>
                                    </a-col>
                                </a-row>

                                <!-- meeting mail -->

                                <a-row class="checkBox-container">
                                    <a-checkbox v-model:checked="checkMailRes">{{t('Meet.ResEmailCheck')}}</a-checkbox>
                                </a-row>
                                <a-row :gutter="16"  v-if="checkMailRes===true">   
                                    <a-col :span="24">
                                        <a-form-item   name="emailTxt" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.emailTxtTool')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.EmailContentMeet') }}</span>
                                            </template>
                                            <a-textarea v-model:value="form.emailConfirmTxt" :rows="4" :placeholder="t('Meet.EmailContentMeet')" />

                                        </a-form-item>
                                    </a-col>
                                </a-row>

                                <!-- feedback mail --> 
                                <a-row class="checkBox-container" v-if="sendFeedbackEmails" >
                                    <a-checkbox v-model:checked="checkMailFeed">{{t('Meet.feedbackEmailCheck')}}</a-checkbox>
                                </a-row>
                                <a-row :gutter="16" v-if="checkMailFeed===true && sendFeedbackEmails" >   
                                    <a-col :span="24">
                                        <a-form-item   name="emailConfirmTxt" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.emailTxtTool')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.EmailContentFeed') }}</span>
                                            </template>
                                            <a-textarea v-model:value="form.emailFeedTxt" :rows="4" :placeholder="t('Meet.EmailContentConf')" />

                                        </a-form-item>
                                    </a-col>
                                </a-row>



                              

                            <!-- </a-collapse-panel> -->

                        <!-- </a-collapse> -->

                        <a-row class="card-footer">
                            <a-space>
                                <a-button  type="primary">{{ t('Meet.Update') }}    </a-button>
                                <a-button type="primary" danger >{{ t('Meet.Cancel') }}</a-button>
                            </a-space>
                        </a-row>

                    </a-form>

                  
                </p>

                <p v-if="key=== 'themes' ">

                    <a-form ref="formThemeRef" :model="formTheme"  :class="{ 'is-rtl': textDirection==='rtl'}">
                        <a-row>
                            <a-col :span="12">
                                <a-form-item   name="primaryColor" >
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.PrimaryColor') }}</span>
                                    </template>

                                    <input type="color" v-model="formTheme.primaryColor"  class="input-color" /> 
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-row>
                            <a-col :span="12">
                                <a-form-item   name="secondColor" >
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.SecondaryColor') }}</span>
                                    </template>

                                    <input type="color" v-model="formTheme.secondColor"  class="input-color" /> 
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-row>
                            <a-col :span="12">
                                <a-form-item   name="thirdColor" >
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.ThirdColor') }}</span>
                                    </template>

                                    <input type="color" v-model="formTheme.thirdColor"  class="input-color" /> 
                                </a-form-item>
                            </a-col>
                        </a-row>


                        <a-row class="card-footer">
                            <a-space>
                                <a-button  type="primary">{{ t('Meet.Update') }}    </a-button>
                                <a-button type="primary" danger >{{ t('Meet.Cancel') }}</a-button>
                            </a-space>
                        </a-row>

                    </a-form>        
                   
                </p>

                <p v-if="key==='msGraph'">
                    <a-form ref="msGraphFormRef" :model="msGraphForm" :rules="msExRules"  layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                        
                        <a-row>
                            <a-col :span="24">
                                <a-form-item name="clientId" >
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.clientId') }}</span>
                                    </template>

                                    <a-input  v-model:value="msGraphForm.clientId" /> 
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-row>
                            <a-col :span="24">
                                <a-form-item name="clientSecret" >
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.clientSecret') }}</span>
                                    </template>
                                    <a-input-password v-model:value="msGraphForm.clientSecret" />
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-row>
                            <a-col :span="24">
                                <a-form-item name="tenantId" >
                                    <template #label>
                                        <span class="label-form">{{ t('Meet.tenantId') }}</span>
                                    </template>

                                    <a-input  v-model:value="msGraphForm.tenantId"   /> 
                                </a-form-item>
                            </a-col>
                        </a-row>


                        <a-row class="card-footer">
                            <a-space>
                                <a-button  type="primary">{{ t('Meet.Update') }}    </a-button>
                                <a-button type="primary" danger >{{ t('Meet.Cancel') }}</a-button>
                            </a-space>
                        </a-row>
                    
                    </a-form>        
                </p>



                <!-- theme -->
                <p v-if="key === 'theme'">
                    <a-row >

                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 10}" :lg="{ span: 10 }" :xl="{ span: 10 }" :xxl="{ span: 10 }" >
                            <a-form ref="formThemeRef" :model="formTheme"  layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                                <a-row >   
                                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }">
                                        <a-form-item   name="freeColor" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.FreeColorTxt')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']" />
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.FreeColor') }}</span>
                                            </template>

                                            <input type="color" v-model="formTheme.freeColor"  class="input-color" @focus="handleFreeChange"  /> 
                                        </a-form-item>
                                    </a-col>

                                </a-row>

                                <a-row :gutter="16">   
                                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }">
                                        <a-form-item   name="waitingColor" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.WaitingColorTxt')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']"/>
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.WaitingColor') }}</span>
                                            </template>
                                            <input type="color" v-model="formTheme.waitingColor" class="input-color" @focus="handleWaitingChange"  />
                                        </a-form-item>
                                    </a-col>

                                </a-row>

                                <a-row>   
                                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }">
                                        <a-form-item   name="bookedColor" >
                                            <template #label>
                                                <a-tooltip>
                                                    <template #title>{{t('Meet.BookedColorTxt')}}</template>
                                                    <QuestionCircleOutlined :class="[locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon']"/>
                                                </a-tooltip>
                                                <span class="label-form">{{ t('Meet.BookerColor') }}</span>
                                            </template>
                                            <input type="color" v-model="formTheme.bookedColor" class="input-color" @focus="handleBookedChange"/>
                                        </a-form-item>
                                    </a-col>

                                </a-row>


                                <a-row>   
                                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }">
                                        <a-form-item   name="opacity" >
                                            <template #label>
                                                <span class="label-form">{{ t('Meet.ColorOpacity') }}</span>
                                            </template>

                                            <a-row class="slider-container">
                                                <a-col :span="20">
                                                    <a-slider  v-model:value="formTheme.opacity" class="slider-input" @afterChange="handleOpacityChange"/>
                                                </a-col>

                                                <a-col :span="4">
                                                    <span class="slider-number">{{formTheme.opacity}}</span>
                                                </a-col>
                                            </a-row>
                                            
                                        </a-form-item>
                                    </a-col>

                                </a-row>

                            </a-form>
                        </a-col>

                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 14}" :lg="{ span: 14 }" :xl="{ span: 14 }" :xxl="{ span: 14 }">
                            <div class="filter-status-container">
                                <a-segmented v-model:value="activeTab" :options="tabList"/>
                            </div>

                            <template v-if="activeTab === t('Meet.Free') ">

                                <a-card hoverable>

                                    <div class="preview-container">

                                        <a-row>
                                            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="header-container">
                                                <img src="@/assets/img/header_meet.png" alt="meetix--img"  class="header-img"  />
                                            </a-col>
                                        </a-row>

                                        <a-row>
                                            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 14}" :lg="{ span: 14 }" :xl="{ span: 14 }" :xxl="{ span: 14 }" class="content-container">
                                                <img src="@/assets/img/content_meetix.jpg" alt="meetix-img"  class="content-img"/>

                                                <span class="color-span" :style="{ backgroundColor: formTheme.freeColor, opacity:formTheme.opacity/100 }"></span>
                                                <div class="title-txt">
                                                    <p class="title-preview">15:17 Lundi, 13 Mai 2024</p>
                                                    <p class="status-txt">DISPONIBLE</p>
                                                </div>
                                               
                                                <div class="plus-container">
                                                    <PlusOutlined class="plus-icon"/>
                                                </div>
                                                    
                                               

                                                <div class="title-txt-bottom">
                                                    <p>Reserver maintenant</p>
                                                </div>


                                            </a-col>
                                            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 10}" :lg="{ span: 10 }" :xl="{ span: 10 }" :xxl="{ span: 10 }"  class="navbar-container">
                                                <img src="@/assets/img/nav-meetix.png" alt="meetix-img"  class="navbar-img"  />
                                            </a-col>
                                        </a-row>



                                    </div>

                                </a-card>

                            </template>

                            <template v-if="activeTab === t('Meet.Waiting') ">

                                <a-card hoverable>

                                    <div class="preview-container">

                                        <a-row>
                                            <a-col :span="24" class="header-container">
                                                <img src="@/assets/img/header_meet.png" alt="meetix--img"  class="header-img"  />
                                            </a-col>
                                        </a-row>

                                        <a-row>
                                            <a-col :span="14" class="content-container">
                                                <img src="@/assets/img/content_meetix.jpg" alt="meetix-img"  class="content-img"/>

                                                <span class="color-span" :style="{ backgroundColor: formTheme.waitingColor, opacity:formTheme.opacity/100 }"></span>
                                                <div class="title-txt">
                                                    <p class="title-preview">15:17 Lundi, 13 Mai 2024</p>
                                                    <p class="status-txt">PROCHAINE REUINION</p>
                                                </div>
                                                
                                                <div class="waiting-container">

                                                    <!-- <img src="@/assets/icons/waiting.svg" class="waiting-icon" alt="waiting-svg" > -->
                                                    <!-- <PlusOutlined class="plus-icon"/> -->
                                                </div>
                                                    

                                                <div class="title-txt-bottom">
                                                    <p class="title-bottom">COMMENCE DANS</p>
                                                    <p class="title-bottom-waiting">14 Minutes</p>
                                                </div>


                                            </a-col>
                                            <a-col :span="10"  class="navbar-container">
                                                <img src="@/assets/img/nav-meetix.png" alt="meetix-img"  class="navbar-img"  />
                                            </a-col>
                                        </a-row>



                                    </div>

                                </a-card>

                            </template>

                            <template v-if="activeTab === t('Meet.Booked') ">

                                <a-card hoverable>

                                    <div class="preview-container">

                                        <a-row>
                                            <a-col :span="24" class="header-container">
                                                <img src="@/assets/img/header_meet.png" alt="meetix--img"  class="header-img"  />
                                            </a-col>
                                        </a-row>

                                        <a-row>
                                            <a-col :span="14" class="content-container">
                                                <img src="@/assets/img/content_meetix.jpg" alt="meetix-img"  class="content-img"/>

                                                <span class="color-span" :style="{ backgroundColor: formTheme.bookedColor, opacity:formTheme.opacity/100 }"></span>
                                                <div class="title-txt">
                                                    <p class="title-preview">15:17 Lundi, 13 Mai 2024</p>
                                                    <p class="status-txt">OCCUPEE</p>

                                                </div>


                                                <div class="numbers-container">
                                                    <span class="number-txt">59:30</span>
                                                </div>

                                                <a-row :gutter="16" class="title-txt-bottom-booked">
                                                    <a-col :span="16">
                                                        <div class="bottom-titles">
                                                            <p class="bottom-first-title">Réunion en cours</p>

                                                            <p class="bottom-sub-title-small">13:00 -> 14:00</p>

                                                            <p class="bottom-sub-title-bold">Board Meeting</p>
                                                            <p class="bottom-sub-title-small">Achraf Ammar</p>
                                                        </div>
                                                    </a-col>

                                                    <a-col :span="8" class="user-icon-container">
                                                        <!-- <img src="@/assets/icons/users.svg" class="users-icon" alt="users-svg" > -->
                                                    </a-col>
                                                </a-row>
                                                    
                                                <!--
                                                <div class="title-txt-bottom-booked">
                                                    <div class="bottom-titles">
                                                        <p class="bottom-first-title">Réunion en cours</p>

                                                        <p class="bottom-sub-title-small">13:00 -> 14:00</p>

                                                        <p class="bottom-sub-title-bold">Board Meeting</p>
                                                        <p class="bottom-sub-title-small">Achraf Ammar</p>
                                                    </div>

                                                   <div class="user-icon-container">
                                                        <img src="@/assets/icons/users.svg" class="users-icon" alt="users-svg" >
                                                   </div>

                                                </div>
                                                -->

                                            </a-col>
                                            <a-col :span="10"  class="navbar-container">
                                                <img src="@/assets/img/nav-meetix.png" alt="meetix-img"  class="navbar-img"  />
                                            </a-col>
                                        </a-row>



                                    </div>

                                </a-card>

                            </template>

                        </a-col>
                    </a-row>

                    <a-row class="card-footer">
                        <a-space>
                            <a-button  type="primary">{{ t('Meet.Update') }}    </a-button>
                            <a-button type="primary" danger >{{ t('Meet.Cancel') }}</a-button>
                        </a-space>
                    </a-row>

                </p>
               
            
            </a-card>
        </a-row>
    </div>

    



</template>

<script  lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import { notification, UploadFile, UploadProps } from 'ant-design-vue';
import Swal from 'sweetalert2';
import {
  
    QuestionCircleOutlined,
    PlusOutlined,
  
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../components/Looder.vue';
export default defineComponent({
    name: "settingsIndex",
    components: {
        Looder,
        QuestionCircleOutlined,
        PlusOutlined,
      
    },

    setup() {

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        //tab
        const settingsTitle = [
            {
                key: 'notif',
                tab: t('Meet.Email'),
            },

            {
                key: 'themes',
                tab: t('Meet.Themes'),
            },
            {
                key: 'msGraph',
                tab: t('Meet.MicrosoftGraph'),
            },
            // {
            //     key: 'msExchange',
            //     tab: t('Meet.MicrosoftExchange'),
            // },
           
            // {
            //     key: 'theme',
            //     tab: t('Meet.Themes'),
            // },
           
            
        ];
        const key = ref('notif');

        const onTabChange = (value: string, type: string) => {
            console.log("value : ",value,"type: ,", type);
           
            key.value = value;
           
        };

        //form 
        const formRef = ref();

        const form = reactive({
            id: '',
            msgTxt:'',
            emailConfirmTxt:'',
            emailFeedTxt:'',

        });

        const msGraphFormRef = ref();

        const msGraphForm = reactive({
            clientId:'',
            clientSecret:'',
            tenantId:'',
        });

        const keyVisible = ref<boolean>(false);


        const msExRules : Record<string, Rule[]> = {
            clientId: [{ required: true,message:t('Meet.RequiredField')}],
            clientSecret: [{ required: true,message:t('Meet.RequiredField')}],
            tenantId: [{ required: true,message:t('Meet.RequiredField')}],
        }


       
        

        //collapse 
        const activeKey = ref(['1']);


        const formThemeRef = ref();
        const opacity_slider = ref<number>(0);

        const formTheme = reactive({
            id: '',
            freeColor:'',
            waitingColor:'',
            bookedColor:'',
            opacity:opacity_slider,
            logo:'',
            bgPhoto:'',

            primaryColor:'',
            secondaryColor:'',
            thirdColor:'',

        });


        const rules: Record<string, Rule[]> = {
         
           
        };

       

        // preview tab
        const activeTab = ref(t('Meet.Free'));
        const tabList = [t('Meet.Free'), t('Meet.Waiting'),  t('Meet.Booked')];

        //input colors 
        const handleFreeChange=()=>{
            activeTab.value=t('Meet.Free');
        }

        const handleWaitingChange=()=>{
            activeTab.value=t('Meet.Waiting');
        }

        const handleBookedChange=()=>{
            activeTab.value=t('Meet.Booked');
        }

        const handleOpacityChange=(value:any)=>{
            console.log('opacity change', formTheme.opacity/100); 
        }

        //checkBox
        const checkeSMS = ref(false);

        const checkMailConf = ref(false);

        const checkMailRes = ref(false);

        const checkMailFeed = ref(false);

        const sendFeedbackEmails = ref(true);








        return{
            locale,
            textDirection,
            simpleImage,
            loading,
            t,
            navigatePage,
            settingsTitle,
            key,
            onTabChange,
            activeKey,
            formRef,
            form,
            rules,
            formThemeRef,
            formTheme,
            activeTab,
            tabList,
            handleFreeChange,
            handleWaitingChange,
            handleBookedChange,
            handleOpacityChange,
            checkeSMS,
            checkMailConf,
            checkMailRes,
            checkMailFeed,
            sendFeedbackEmails,
            msGraphFormRef,
            msGraphForm,
            msExRules,
            keyVisible,


        }


        
    },
})
</script>

<style scoped>

    .checkBox-container{
        margin-bottom: 10px;
    }

    .slider-container{
        display: flex;
        align-items: center;
    }

    .slider-number{
        font-size: 18px;
    }

    /* preview*/

    .filter-status-container{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .preview-container {
        max-width:70%; 
        /* max-width: 800px;  */
        height: auto; /* ensuring the height adjusts according to the width */
    }

   
    .header-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .header-img{
        max-height: 100%;
        max-width: 100%;
    }

    .content-container{
        display: flex;
        justify-content: flex-start;
        position: relative;
    }

    .content-img{
       max-height: 100%;
       max-width: 100%;

       
    }

    .overlay-container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .color-span{
        position: absolute;

        top: 0;
        left: 0;
        width: 100%; 
        height: 100%; 
        z-index: 1;
        /* opacity: 0.8; */
    }

    .navbar-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }


    .navbar-img{
        max-height: 100%;
        max-width: 100%;
    }

    .title-txt{
        position: absolute;
        transform: translate(-50%, -50%);
        top:15%;
        left:50%;
        z-index: 2;
        text-align: center;
        color: white;
        margin-bottom: 0;

        width: 100%;

    }

    .title-txt-bottom{

        position: absolute;
        transform: translate(-50%, 0);
        bottom: 5px; 
        left:50%;
        z-index: 2;
        text-align: center;
        color: white;

        width: 100%;

    }

    .img-txt {

        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
        color: white;

        /* position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px; 
        box-sizing: border-box; */
    }

    .img-txt p {
        margin-bottom: 10px;
        color: white; 
        font-size: 12px;
    }

    .status-txt{
        font-weight: 700 !important;
        font-size: 17px !important;
        margin-top: 0;
    }

    .title-preview{
        margin-bottom:0 ;
    }

    .plus-container{

        background-color: #ffffff48;
        border-radius: 50%;
        width:35%; 
        height: 35%; 
        display: flex;
        justify-content: center;

        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        position: absolute;
        z-index: 2;
        color: #ffffff;

      

    }

    .plus-icon{
        font-weight: 800 !important;
        font-size: 55px !important;
    }

    .waiting-icon{
       max-width: 90% !important;
       max-height: 90% !important;
    }

    .title-bottom{
        margin-bottom: 0;
    }

    .title-bottom-waiting{
        margin-top: 0;
    }

    .numbers-container{

        background-color: #ffffff48;
        border-radius: 50%;
        width: 30%; 
        height: 30%; 
        display: flex;
        justify-content: center;

        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        position: absolute;
        z-index: 2;
        color: #ffffff;
    }

    .number-txt{
        color: #ffffff;
        font-weight: 500;
        font-size: 25px;
    }

    .title-txt-bottom-booked{
        background-color: #ffffff48;
        position: absolute;
        transform: translateX(-50%);
        bottom:0;
        left:50%;
        z-index: 2;
        text-align: center;
        color: #ffffff;
        width: 90%;
        margin-bottom: 5px;

        margin-left: 0 !important;
        margin-right: 0 !important;


        /* new */
        /* display: flex;
        justify-content: space-between;
        align-items: center; */

    }

    .bottom-titles{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
    }

    .bottom-titles p{
        margin-bottom: 0;
        margin-top: 0;
    }

    .bottom-first-title{
        font-size: 16px!important;
        margin-bottom: 3px!important;
    }

    .bottom-sub-title-small{
        font-size: 12px!important;
    }

    .bottom-sub-title-bold{
        font-weight: 600;
    }

    .waiting-container{
        width:35%; 
        height: 35%; 
        display: flex;
        justify-content: center;

        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        position: absolute;
        z-index: 2;
        color: #ffffff;
    }

    .user-icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    .users-icon{
        max-width: 100%;
        max-height: 100%;
    }
  


   
    


    /* preview*/

  
    .custom-header{
        font-size: 16px; 
        font-weight: 500;
        line-height: 1.5; 
        display: flex;
    }


    .slider-input{
        max-width: 100%;
    }


    .input-color{
        box-sizing:border-box;
        margin: 0;
        padding: 2px 2px;
        line-height:1.5714285714285714;
        list-style:none;
        position:relative;
        display:inline-block;
        background-color:#ffffff ;
        border-width:1px;
        border-style:solid;
        border-color:#d9d9d9;
        border-radius:6px;
        transition:all 0.2s;
        display: flex;
        max-width: 200px;
    }

    .breadcrumb-icon{
        cursor: pointer;
    }

    .card-settings{
        width: 100%;
        margin-top: 15px;
    }

    .tooltip-icon{
        margin-right: 5px;
    }

    .tooltip-icon-rtl{
        margin-left: 5px;
    }

    .configure-btn-container{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

</style>

