import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import Login from '../views/auth/login.vue'
import PageNotFound from '../views/notFound/pageNotFound.vue'
import Dashboard from '../views/dashboard/dashboard.vue'
import Meetings from '../views/meetings/meeting.vue'
import Rooms from '../views/rooms/rooms.vue'
import Locations from '../views/rooms/locations/locations.vue'
import Equipments from '../views/equipements/equipements.vue'
import Services from '../views/services/services.vue'
import FeedBack from '../views/feedback/feedback.vue'
import Kiosk from '../views/kiosk/kiosk.vue'
import MeetingRequests from '../views/meetings/requests/meetingRequests.vue'
import users from '../views/users/users.vue'
import Requests from '../views/meetings/requests/internalRequests.vue'
import Settings from '../views/settings/settings.vue'
import Profile from '../views/profile/profile.vue'
import ServiceRequests from '../views/meetings/requests/internalServiceRequests.vue'
import EquipmentRequests from '../views/meetings/requests/internalEquipmentRequests.vue'
import statistics from '../views/statistics/statistics.vue'
import feedbackSubmit from '../views/feedback/feedbackSubmit.vue'
import EquipmentMailRequests from '../views/meetings/requests/approvalMail/approvalMailEquipments.vue'
import ServiceMailRequests from '../views/meetings/requests/approvalMail/approvalMailServices.vue'

const routes: Array<RouteRecordRaw> = [



  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    component: HomeView,
    children:[
      {
        path:'/meetings',
        name:'meetings',
        component: Meetings
      },
      {
        path:'/rooms',
        name:'rooms',
        component: Rooms
      },
      {
        path:'/locations',
        name:'locations',
        component: Locations
      },
      {
        path:'/equipments',
        name:'equipments',
        component: Equipments
      },
      {
        path:'/services',
        name:'services',
        component: Services
      },
      {
        path:'/feedback',
        name:'feedback',
        component: FeedBack
      },
      {
        path:'/kiosk',
        name:'kiosk',
        component: Kiosk
      },
      {
        path:'/meeting-requests',
        name:'meeting-requests',
        component: MeetingRequests
      },
      {
        path:'/users',
        name:'users',
        component: users
      },

      {
        path:'/requests',
        name:'requests',
        component: Requests
      },
      
      {
        path:'/settings',
        name:'settings',
        component: Settings
      },

      {
        path:'/profile',
        name:'profile',
        component: Profile
      },
      {
        path:'/service-requests',
        name:'service-requests',
        component: ServiceRequests
      },
      {
        path:'/equipment-requests',
        name:'equipment-requests',
        component: EquipmentRequests
      },

      {
        path:'/',
        name:'dashboard',
        component: Dashboard 
      },

      {
        path:'/statistics',
        name:'statistics',
        component: statistics 
      },
      {
        path:'/equipment-request-mail/:reservation_id',
        name:'equipmentRequestMail',
        component: EquipmentMailRequests 
      },

      {
        path:'/service-request-mail/:reservation_id',
        name:'serviceRequestMail',
        component: ServiceMailRequests 
      },
      
      
    
    ]
  },
  {
    path: '/:catchAll(.*)', 
    component: PageNotFound, 
  },
  {
    path:'/feedback_submit/:reservation_id/user/:user_id',
    name:'feedbacksubmit',
    component: feedbackSubmit,
    props: true,
  },
 



  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ // '../views/AboutView.vue')
  //}//

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  //console.log('111111')
  const userLoggedIn = localStorage.getItem('user') || null;
    if (to.meta.authRequired && userLoggedIn === null) {
      //console.log('2222222')
      return next('/login');
    }

  if (to.path == '/login' && userLoggedIn !== null) {
    //console.log('33333333333')
    return next('/');
  }

    next();
})



export default router
