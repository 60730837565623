import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const roomsService = {
    getAll,
    show,
    create,
    update,
    delete: _delete,
    getAllWithPagination,
    filter,
    addPhotos,
    _deletePhoto,
    addWorkingDays,
    getAllWithoutPagination
};

function getAllWithoutPagination() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.rooms.getAll}?pagination=off`, requestOptions)
        .then(
            handleResponse
        );
}

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.rooms.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAllWithPagination(currentPage: number, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.rooms.getAll}?page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function filter(key:string,value:string, page:any, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.rooms.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function addPhotos(romm_id:any, photo:any){
    const formData = new FormData();
    formData.append('room_id', romm_id);
   
    if(photo!==null){
        formData.append('photo', photo);
    }
    


    const requestOptions = {
        method: "POST",
        headers: { "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: formData,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.rooms.addPhotos}`, requestOptions)
        .then(
            handleResponse
        );
}

function _deletePhoto(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: { ...authHeader(), "Accept": "application/json"}
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.rooms.deletePhotos.replace(':id', id)}`, requestOptions).then(handleResponse);
}


function create(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.rooms.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" ,  "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.rooms.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function update(id: string, formData: FormData) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: { ...authHeader(), "Accept": "application/json" },
        body: formData
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.rooms.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function _delete(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: {...authHeader() , "Accept": "application/json" } 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.rooms.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}
function addWorkingDays(formData:FormData){
  
    const requestOptions = {
        method: "POST",
        headers: { "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: formData,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.rooms.addWorkingDays}`, requestOptions)
        .then(
            handleResponse
        );
}

function handleResponse(response: any) {

     
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');

                localStorage.setItem('selectedKey', '0');


                location.reload();
                authService.logout();

            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });

   
}
