import router from "@/router";
import {authHeader, ApiConfigs} from "../_helpers";

export const authService = {
    login,
    logout,
    generateCaptcha,
    Register,
    createUser,
    loginAD
};

function generateCaptcha() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.logout}`, requestOptions).then(
        handleResponseLougout
    );
}

function loginOld(email: string, password: string) {
    const payload: Record<string, any> = {};

    payload.email = email;
    payload.password = password;
    // payload.captcha = captcha;
    // payload.key = key;
    
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.login}`,
        requestOptions
    ).then(handleResponse)
        .then((user) => {
            if (user) {
                localStorage.setItem("user", JSON.stringify(user));
            }
            return user;
        });
}

function login(email: string, password: string) {
    const payload: Record<string, any> = {};

    payload.email = email;
    payload.password = password;
    
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.login}`,
        requestOptions
    ).then(handleResponse)
        .then((response) => {
            if (response.user) {

                localStorage.setItem("organization", JSON.stringify(response.user.organization));
                localStorage.setItem("user", JSON.stringify(response.user));
                location.reload();
                router.push('/');
                
            }
            return response.user;
        });
}

function loginAD(fromData:FormData) {
    
    
    const requestOptions = {
        method: "POST",
        headers: {"Accept": "application/json"},
        credentials : 'include' as RequestCredentials,
        body: fromData,
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.login}`,
        requestOptions
    ).then(handleResponse)
        .then((response) => {
            if (response.user) {
                localStorage.setItem("user", JSON.stringify(response.user));
                router.push('/');
            }
            return response.user;
        });
}


function Register(payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.Register}`, requestOptions).then(handleResponse);
}

function logout() {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.accounts.logout}`, requestOptions).then(
        handleResponseLougout
    );
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}


function createUser(name:string, description:string,address:string ,
    phone : string,email:string , add_user:string ,
      username:string , password:string,first_name:string,
      last_name:string,phone_number:string,registration_number:string,
    vat_number:string, settings:string,category:string,
    logo:any  ){

    const formData = new FormData();

    formData.append('name',name);
    formData.append('description',description);
    formData.append('address',address);
    formData.append('phone',phone);
    formData.append('email',email);
    formData.append('add_user',add_user);
    formData.append('username',username);
    formData.append('password',password);
    formData.append('first_name',first_name);
    formData.append('last_name',last_name);
    formData.append('phone_number',phone_number);
    formData.append('registration_number',registration_number);
    formData.append('vat_number',vat_number);
    formData.append('settings',settings);
    formData.append('activity_category_id',category);
    if(logo!==null){
        formData.append('logo',logo);
    }
    


    

    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.accounts.Register}`, requestOptions)
        .then(
            handleResponse
        );

    
}



function handleResponseLougout(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem("user");
                localStorage.removeItem("store");
                location.reload();
            }
            const error = response.status || (data && data.message) || response.statusText;
            return error;
        }

        return data;
    });
}
