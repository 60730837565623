import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const userService = {
    getAll,
    show,
    create,
    delete: _delete,
    update,
    getAllUsers,
    filterUsers,
    searchWithFullParams,
    filter,
    createUser,
    changePassword,
    changeRole,
    filterManagers
};

function getAllUsers(page:string, per_page:string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.getAll}?page=${page}&per_page=${per_page}`, requestOptions)
        .then(
            handleResponse
        );
}

function filterUsers(keys:string, values:string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.getAll}?pagination=off&keys=${keys}&values=${values}`, requestOptions)
        .then(
            handleResponse
        );
}

function filterManagers(keys:string, values:string, role_id:string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.getAll}?pagination=off&keys=${keys}&values=${values}&role_id=${role_id}`, requestOptions)
        .then(
            handleResponse
        );
}


function searchWithFullParams(key : string , value:string , currentPage:number , page:number ) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.user.getAll}?key=${key}&first_name=${value}&page=${currentPage}&per_page=${page}`, requestOptions).then(handleResponse);
}

function filter(key:string,value:string, page:any, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}





function getAll(currentPage : number  , perPage:number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json"}, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json"}, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function createUser(formData:FormData){
    const requestOptions = {
        method: "POST",
        headers: { "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: formData,
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.create}`, requestOptions)
        .then(
            handleResponse
        );
}



function create(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.create}`, requestOptions)
        .then(
            handleResponse
        );
}


function _delete(id: string){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json"},

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.user.delete.replace(':id', id)}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, formData: FormData){
    const requestOptions={
        method:"POST",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Accept": "application/json" },
        body: formData
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.user.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}

function changePassword(id:string,formData:FormData){
    const requestOptions = {
        method: "POST",
        headers: { "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: formData,
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.changePassword.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}


function changeRole(id:string,formData:FormData){
    const requestOptions = {
        method: "POST",
        headers: { "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: formData,
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.changeRole.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}








function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                localStorage.setItem('selectedKey', '0');

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}