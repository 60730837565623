import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const staticticsService = {
    getCardsInfo,
    getRecentFeedBacks,
    getFeedbackRate,
    roomAvailability,
    getMonthlyReservations,
    getCurrentResrvations,
    getReservations,
    getTopServices,
    getTopEquipments,
    getRoomOccupancyRate



};

function getCardsInfo() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: { ...authHeader(), "Accept": "application/json"}
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.dashboard}`, requestOptions).then(handleResponse);
}

function getRecentFeedBacks(limit:string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: { ...authHeader(), "Accept": "application/json"}
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.feedbacks}?limit=${limit}`, requestOptions).then(handleResponse);
}


function getFeedbackRate(type:string, limit:string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: { ...authHeader(), "Accept": "application/json"}
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.feedbackRate}?feedbackable_type=${type}&limit=${limit}`, requestOptions).then(handleResponse);
}

function roomAvailability() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: { ...authHeader(), "Accept": "application/json"}
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.roomAvailability}`, requestOptions).then(handleResponse);
}

function getMonthlyReservations() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: { ...authHeader(), "Accept": "application/json"}
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.reservations}?periodical=DAILY`, requestOptions).then(handleResponse);
}

function getCurrentResrvations(start:string, end:string){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.getAll}?pagination=off&start_date=${start}&end_date=${end}&order=desc`, requestOptions).then(handleResponse);
}

function getReservations(custom:string, from:string, to:string, days:string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: { ...authHeader(), "Accept": "application/json"}
    };
    //day based fetch
    if(days!==''){
        return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.reservationsWithApproval}?days=${days}`, requestOptions).then(handleResponse);
    }else{

        
        //handling custom ( from , to)
        if(custom!== '' && from !=='' && to !==''){
            return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.reservationsWithApproval}?custom=${custom}&from=${from}&to=${to}`, requestOptions).then(handleResponse);
        }else if(custom!== '' && from !=='' && to ===''){
            return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.reservationsWithApproval}?custom=${custom}&from=${from}`, requestOptions).then(handleResponse);
        }else if(custom!== '' && from ==='' && to ==='') {
            return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.reservationsWithApproval}?custom=${custom}`, requestOptions).then(handleResponse);
        }
        else {
            return Promise.reject('Invalid parameters: custom, from, and to cannot all be empty.');
        }

    }
}

function getTopServices(custom:string, from:string,to:string){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.topServices}?custom=${custom}&from=${from}&to=${to}`, requestOptions).then(handleResponse);
}


function getTopEquipments(custom:string, from:string,to:string){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.topEquipments}?custom=${custom}&from=${from}&to=${to}`, requestOptions).then(handleResponse);
}

function getRoomOccupancyRate(custom:string, from:string,to:string){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.statistic.RoomOccupancyRate}?custom=${custom}&from=${from}&to=${to}`, requestOptions).then(handleResponse);
}








function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api

                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
