import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';

export const eventBus = createApp({});

import {createI18n} from "vue-i18n";
import fr from "./locales/fr.json";
import en from "./locales/en.json";
import ar from "./locales/ar.json";
import VueApexCharts from "vue3-apexcharts";

if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'en')
}



const i18n = createI18n({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locale: localStorage.getItem('Language'),
    legacy: false,
    fallbackLocale: "en",
    messages: {fr, en, ar},
});

const organization = JSON.parse(localStorage.getItem('organization') || '{}');

const themeTemplate = {

    
    primaryColor: organization.primary_color || '#171717',
    secondaryColor: organization.secondary_color || '#fc8019',
    thirdColor: organization.third_color || '#d35100',
    logo:organization.logo || 'nologo',


    confirmationColor: "#09aa29",

    cart: {
        selectCartItemColor: "#09aa29"
    }
}
localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))


const app = createApp(App)
  .use(router)
  .use(Antd)
  .use(i18n)
  .use(VueApexCharts);

app.config.globalProperties.$primaryColor = themeTemplate.primaryColor;
app.config.globalProperties.$secondaryColor = themeTemplate.secondaryColor;
app.config.globalProperties.$thirdColor = themeTemplate.thirdColor;
app.config.globalProperties.$logo = themeTemplate.logo;

app.mixin({
    mounted() {
      document.documentElement.style.setProperty('--primary-color', themeTemplate.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', themeTemplate.secondaryColor);
      document.documentElement.style.setProperty('--third-color', themeTemplate.thirdColor);
    }
});

app.mount('#app');

