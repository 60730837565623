import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const reservationsService = {
    getByMonth,
    show,
    create,
    update,
    delete: _delete,
    filter,
    getAllWithPagination,
    checkRoomAvailibility,
    checkRoomAvailibilityUpdate,
    getAllWithPaginationOrdred,
    getByMonthAndRoom
};

function getAllWithPagination(currentPage: number, perPage:any){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.getAll}?page=${currentPage}&per_page=${perPage}`, requestOptions).then(handleResponse);
}

function getAllWithPaginationOrdred(currentPage: number, perPage:any){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.getAll}?page=${currentPage}&per_page=${perPage}&order=desc`, requestOptions).then(handleResponse);
}



function getByMonth(start:string, end:string){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.getAll}?pagination=off&start_date=${start}&end_date=${end}`, requestOptions).then(handleResponse);
}

function getByMonthAndRoom(start:string, end:string, roomId:string){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" , "Accept": "application/json" }, 
    };

    if(roomId===''){
        return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.getAll}?pagination=off&start_date=${start}&end_date=${end}`, requestOptions).then(handleResponse);
    }else{
        return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.getAll}?pagination=off&start_date=${start}&end_date=${end}&room_id=${roomId}`, requestOptions).then(handleResponse);
    }

}


function filter(key:string,value:string, page:any, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.reservations.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}




function create(formData:FormData){

    const requestOptions = {
        method: "POST",
        headers: { "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: formData,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.reservations.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.reservations.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function update(id: string, formData: FormData) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: { ...authHeader(), "Accept": "application/json"},
        body: formData
    };


    return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function _delete(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers:  {...authHeader(), "Accept": "application/json"},
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.reservations.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function checkRoomAvailibility(formData:FormData){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.roomsAvailibility.get}`, requestOptions).then(handleResponse);
}

function checkRoomAvailibilityUpdate(formData:FormData){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.roomsAvailibility.update}`, requestOptions).then(handleResponse);
}




function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.setItem('selectedKey', '0');


                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
