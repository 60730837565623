import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ece7545a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"8px"} }
const _hoisted_2 = {
  key: 1,
  class: "search-btn-radio"
}
const _hoisted_3 = {
  key: 2,
  class: "rate-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Looder = _resolveComponent("Looder")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_rate = _resolveComponent("a-rate")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Looder, {
      "is-loaded": !_ctx.loading
    }, null, 8, ["is-loaded"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Meetings')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.InternalRequests')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_table, {
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Meet.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
        _createElementVNode("div", _hoisted_1, [
          (column.key === 'name' || column.key ==='user')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          (column.key === 'type')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.type,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
                  onChange: _ctx.selectedType,
                  class: "search-radio-grp"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, {
                      value: "service",
                      class: "radio-item"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.Service')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, {
                      value: "equipment",
                      class: "radio-item"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.Equipment')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, {
                      value: "other",
                      class: "radio-item"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Meet.Other')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (column.key === 'rate')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_a_rate, {
                  value: _ctx.rateValue,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rateValue) = $event))
                }, null, 8, ["value"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Search')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Reset')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"])
  ], 64))
}