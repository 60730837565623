import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-053ed008"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = { class: "txt" }
const _hoisted_3 = {
  key: 0,
  class: "btn-container"
}
const _hoisted_4 = { class: "table-header" }
const _hoisted_5 = { class: "table-cell" }
const _hoisted_6 = { class: "tooltip-txt" }
const _hoisted_7 = { class: "tooltip-txt" }
const _hoisted_8 = { class: "tooltip-txt" }
const _hoisted_9 = { class: "tooltip-txt" }
const _hoisted_10 = { class: "tooltip-txt" }
const _hoisted_11 = { class: "tooltip-txt" }
const _hoisted_12 = { style: {"padding":"8px"} }
const _hoisted_13 = { class: "pagination" }
const _hoisted_14 = { class: "info-title" }
const _hoisted_15 = { class: "info-txt" }
const _hoisted_16 = { class: "info-title" }
const _hoisted_17 = { class: "info-txt" }
const _hoisted_18 = { class: "info-title" }
const _hoisted_19 = { class: "info-title" }
const _hoisted_20 = { class: "info-title" }
const _hoisted_21 = { class: "info-title" }
const _hoisted_22 = { class: "info-txt" }
const _hoisted_23 = { class: "info-title" }
const _hoisted_24 = { class: "label-form" }
const _hoisted_25 = { class: "label-form" }
const _hoisted_26 = { class: "label-form" }
const _hoisted_27 = { class: "label-form" }
const _hoisted_28 = { class: "label-form" }
const _hoisted_29 = { class: "label-form" }
const _hoisted_30 = { class: "label-form" }
const _hoisted_31 = { class: "label-form" }
const _hoisted_32 = { class: "label-form" }
const _hoisted_33 = { class: "label-form" }
const _hoisted_34 = { class: "label-form" }
const _hoisted_35 = { class: "label-form" }
const _hoisted_36 = { class: "label-form" }
const _hoisted_37 = { class: "label-form" }
const _hoisted_38 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Looder = _resolveComponent("Looder")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_LoginOutlined = _resolveComponent("LoginOutlined")!
  const _component_LogoutOutlined = _resolveComponent("LogoutOutlined")!
  const _component_RedoOutlined = _resolveComponent("RedoOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_check_circle_two_tone = _resolveComponent("check-circle-two-tone")!
  const _component_close_circle_two_tone = _resolveComponent("close-circle-two-tone")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Looder, {
      "is-loaded": !_ctx.loading
    }, null, 8, ["is-loaded"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Meet.Devices')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_button, {
            class: "add-btn",
            onClick: _ctx.showAddDrawer
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_table, {
      class: "table-container",
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Meet.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      headerCell: _withCtx(({ column }) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(column.title), 1)
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        _createElementVNode("div", _hoisted_5, [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Meet.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn tbl-btn",
                        onClick: () => _ctx.showDisplayDrawer(record.id)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
                    ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 0 }, {
                        title: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Meet.Edit')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            class: "tbl-btn",
                            type: "primary",
                            onClick: () => _ctx.showDrawer(record.id)
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_EditOutlined, { class: "tbl-icon" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
                    ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 1 }, {
                        title: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Meet.Delete')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            class: "tbl-btn",
                            type: "primary",
                            danger: "",
                            onClick: ($event: any) => (_ctx.showDeletePopup(record.id))
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_DeleteOutlined, { class: "tbl-icon" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
                    ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 2 }, {
                        title: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Meet.Login')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            class: "login-btn tbl-btn",
                            onClick: ($event: any) => (_ctx.showModal(record))
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_LoginOutlined, { class: "tbl-icon" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
                    ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 3 }, {
                        title: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Meet.LogOut')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            danger: "",
                            class: "tbl-btn",
                            onClick: ($event: any) => (_ctx.logout(record.id))
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_LogoutOutlined, { class: "tbl-icon" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_ctx.state.userRole === 'ADMIN' || _ctx.state.userRole === 'MANAGER' )
                    ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 4 }, {
                        title: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Meet.Refresh')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            class: "refresh-btn tbl-btn",
                            onClick: ($event: any) => (_ctx.refresh(record.id))
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_RedoOutlined, { class: "tbl-icon" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(record[column.dataIndex]), 1)
        ])
      ]),
      customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
        _createElementVNode("div", _hoisted_12, [
          (column.key === 'name')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Search')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Reset')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_a_pagination, {
        current: _ctx.currentPage,
        "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
        pageSize: _ctx.perPage,
        "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
        total: _ctx.state.totalPages,
        "default-page-size": _ctx.perPage,
        showSizeChanger: false,
        onChange: _ctx.handlePageChange,
        class: "paginationArrows"
      }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
      (_ctx.state.totalPages>10)
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.selectPagination,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
            onChange: _ctx.handleSelectPaginationChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: "10" }, {
                default: _withCtx(() => [
                  _createTextVNode("10 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "20" }, {
                default: _withCtx(() => [
                  _createTextVNode("20 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "50" }, {
                default: _withCtx(() => [
                  _createTextVNode("50 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "100" }, {
                default: _withCtx(() => [
                  _createTextVNode("100 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
        }, _toDisplayString(_ctx.t('Meet.DeviceInfo')), 3)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Meet.Name')) + " :", 1),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.form.name), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Meet.Description')) + " :", 1),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.form.description), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, { gutter: 16 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Meet.PrimaryColor')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "color",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.primaryColor) = $event)),
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, _ctx.form.primaryColor]
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Meet.SecondaryColor')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "color",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.secondaryColor) = $event)),
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, _ctx.form.secondaryColor]
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Meet.ThirdColor')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "color",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.thirdColor) = $event)),
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, _ctx.form.thirdColor]
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Meet.Room')) + " :", 1),
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.getRoomName(_ctx.form.room_id)), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Meet.allowVirtualKeyboard')), 1),
                  (_ctx.form.virtualKeyboard === true)
                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                        key: 0,
                        "two-tone-color": "#52c41a"
                      }))
                    : (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                        key: 1,
                        "two-tone-color": "#ff4d4f"
                      }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Meet.AddDevice')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Meet.Name')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.name,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addForm.name) = $event)),
                          placeholder: _ctx.t('Meet.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "description" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('Meet.Description')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: _ctx.addForm.description,
                          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addForm.description) = $event)),
                          rows: 4,
                          placeholder: _ctx.t('Meet.Description')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "primaryColor" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('Meet.PrimaryColor')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addForm.primaryColor) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.addForm.primaryColor]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "secondaryColor" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t('Meet.SecondaryColor')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.addForm.secondaryColor) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.addForm.secondaryColor]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "thirdColor" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t('Meet.ThirdColor')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.addForm.thirdColor) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.addForm.thirdColor]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "room_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t('Meet.Room')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          "show-search": "",
                          allowClear: "",
                          value: _ctx.addForm.room_id,
                          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.addForm.room_id) = $event)),
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.rooms, (room) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: room.id,
                                value: room.id,
                                selected: room.id === _ctx.addForm.room_id ,
                                label: room.name
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(room.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value", "selected", "label"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "virtualKeyboard" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('Meet.allowVirtualKeyboard')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          checked: _ctx.addForm.virtualKeyboard,
                          "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.addForm.virtualKeyboard) = $event))
                        }, null, 8, ["checked"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.open,
      "onUpdate:open": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.open) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      width: _ctx.modalWidth,
      placement: _ctx.locale === 'ar' ? 'left' : 'right'
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Meet.UpdateDevice')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.update(_ctx.form.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Update')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.cancelUpdate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('Meet.Name')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.name,
                          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.name) = $event)),
                          placeholder: _ctx.t('Meet.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "description" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t('Meet.Description')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: _ctx.form.description,
                          "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.description) = $event)),
                          rows: 4,
                          placeholder: _ctx.t('Meet.Description')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "primaryColor" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('Meet.PrimaryColor')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.primaryColor) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.form.primaryColor]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "secondaryColor" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.t('Meet.SecondaryColor')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.secondaryColor) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.form.secondaryColor]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "thirdColor" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('Meet.ThirdColor')), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "color",
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.thirdColor) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.form.thirdColor]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "room_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.t('Meet.Room')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          "show-search": "",
                          allowClear: "",
                          value: _ctx.form.room_id,
                          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.room_id) = $event)),
                          "option-filter-prop": 'label'
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Meet.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.rooms, (room) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: room.id,
                                value: room.id,
                                selected: room.id === _ctx.form.room_id ,
                                label: room.name
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(room.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value", "selected", "label"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "virtualKeyboard" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.t('Meet.allowVirtualKeyboard')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          checked: _ctx.form.virtualKeyboard,
                          "onUpdate:checked": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.virtualKeyboard) = $event))
                        }, null, 8, ["checked"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "width", "placement"]),
    _createVNode(_component_a_modal, {
      open: _ctx.loginModal,
      "onUpdate:open": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.loginModal) = $event)),
      "confirm-loading": _ctx.confirmLoading,
      onOk: _ctx.handleLogin,
      onCancel: _ctx.resetFormLogin
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass([{ 'is-rtl': _ctx.textDirection==='rtl'}, "drawer-title"])
        }, _toDisplayString(_ctx.t('Meet.Login')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "submit",
          type: "primary",
          onClick: _ctx.handleLogin
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('Meet.Login')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          key: "back",
          onClick: _ctx.handleCancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "logInFormRef",
          model: _ctx.loginForm,
          rules: _ctx.logInRules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              "has-feedback": "",
              name: "code"
            }, {
              label: _withCtx(() => [
                _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.t('Meet.DeviceAuthCode')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.loginForm.code,
                  "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.loginForm.code) = $event)),
                  autocomplete: "off"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "confirm-loading", "onOk", "onCancel"])
  ], 64))
}