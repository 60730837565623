import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createSlots as _createSlots, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ca885f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = { class: "txt" }
const _hoisted_3 = { class: "btn-container" }
const _hoisted_4 = { class: "table-header" }
const _hoisted_5 = { class: "table-cell" }
const _hoisted_6 = { class: "tooltip-txt" }
const _hoisted_7 = { class: "tooltip-txt" }
const _hoisted_8 = { class: "tooltip-txt" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { style: {"padding":"8px"} }
const _hoisted_13 = { class: "pagination" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = { class: "customize-input" }
const _hoisted_19 = { class: "label-form" }
const _hoisted_20 = ["src", "alt"]
const _hoisted_21 = { class: "label-form" }
const _hoisted_22 = { class: "label-form" }
const _hoisted_23 = { class: "label-form" }
const _hoisted_24 = { class: "label-form" }
const _hoisted_25 = { class: "customize-input" }
const _hoisted_26 = { class: "label-form" }
const _hoisted_27 = ["src", "alt"]
const _hoisted_28 = { class: "info-title" }
const _hoisted_29 = ["src", "alt"]
const _hoisted_30 = { class: "info-txt" }
const _hoisted_31 = { class: "info-title" }
const _hoisted_32 = {
  key: 0,
  class: "info-txt"
}
const _hoisted_33 = { class: "info-title" }
const _hoisted_34 = {
  key: 0,
  class: "info-txt"
}
const _hoisted_35 = { class: "info-title" }
const _hoisted_36 = { class: "info-txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Looder = _resolveComponent("Looder")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_QuestionCircleOutlined = _resolveComponent("QuestionCircleOutlined")!
  const _component_ClockCircleOutlined = _resolveComponent("ClockCircleOutlined")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Looder, {
      "is-loaded": !_ctx.loading
    }, null, 8, ["is-loaded"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Meet.Equipements')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      class: "table-container",
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Meet.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      headerCell: _withCtx(({ column }) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(column.title), 1)
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        _createElementVNode("div", _hoisted_5, [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Meet.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn tbl-btn",
                        onClick: () => _ctx.showDisplayDrawer(record.id)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Meet.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "tbl-btn",
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record.id)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Meet.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "tbl-btn",
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record.id))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key === 'description')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.description != 'null' )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(record.description), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_10))
              ], 64))
            : _createCommentVNode("", true),
          (column.key!=='description')
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(record[column.dataIndex]), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
        _createElementVNode("div", _hoisted_12, [
          (column.key === 'name')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Search')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Reset')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_a_pagination, {
        current: _ctx.currentPage,
        "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
        pageSize: _ctx.perPage,
        "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
        total: _ctx.state.totalPages,
        "default-page-size": _ctx.perPage,
        showSizeChanger: false,
        onChange: _ctx.handlePageChange,
        class: "paginationArrows"
      }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
      (_ctx.state.totalPages>10)
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.selectPagination,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
            onChange: _ctx.handleSelectPaginationChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: "10" }, {
                default: _withCtx(() => [
                  _createTextVNode("10 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "20" }, {
                default: _withCtx(() => [
                  _createTextVNode("20 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "50" }, {
                default: _withCtx(() => [
                  _createTextVNode("50 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "100" }, {
                default: _withCtx(() => [
                  _createTextVNode("100 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Meet.AddEquipment')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Meet.Name')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.name,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.name) = $event)),
                          placeholder: _ctx.t('Meet.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "description" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Meet.Description')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: _ctx.addForm.description,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addForm.description) = $event)),
                          rows: 4,
                          placeholder: _ctx.t('Meet.Description')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "managers" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Meet.Managers')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.state.managers,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.managers) = $event)),
                          mode: "multiple",
                          "label-in-value": "",
                          placeholder: _ctx.t('Meet.selectManagers'),
                          style: {"width":"100%"},
                          "filter-option": false,
                          "not-found-content": _ctx.state.fetching ? undefined : null,
                          options: _ctx.state.allManagers,
                          onSearch: _ctx.fetchManager
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetching)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "time" }, {
                      label: _withCtx(() => [
                        _createVNode(_component_a_tooltip, null, {
                          title: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('Meet.minResToolTip')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_QuestionCircleOutlined, {
                              class: _normalizeClass([_ctx.locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon'])
                            }, null, 8, ["class"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Meet.minResTime')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_a_input_number, {
                            value: _ctx.addForm.time,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addForm.time) = $event)),
                            step: "1",
                            min: "0",
                            style: {"width":"100%"}
                          }, {
                            prefix: _withCtx(() => [
                              _createVNode(_component_ClockCircleOutlined)
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "icons" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Meet.Icon')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.addForm.icon,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addForm.icon) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: icon.name,
                                value: icon.name
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    src: icon.path,
                                    alt: icon.name,
                                    width: "20",
                                    height: "20",
                                    style: {"margin-right":"8px"}
                                  }, null, 8, _hoisted_20)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.open,
      "onUpdate:open": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.open) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      width: _ctx.modalWidth,
      placement: _ctx.locale === 'ar' ? 'left' : 'right'
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Meet.UpdateeEquipment')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.update(_ctx.form.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Update')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.cancelUpdate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Meet.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Meet.Name')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.name,
                          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.name) = $event)),
                          placeholder: _ctx.t('Meet.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "description" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Meet.Description')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: _ctx.form.description,
                          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.description) = $event)),
                          rows: 4,
                          placeholder: _ctx.t('Meet.Description')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "managers" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Meet.Managers')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.managers,
                          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.managers) = $event)),
                          mode: "multiple",
                          "label-in-value": "",
                          placeholder: _ctx.t('Meet.selectManagers'),
                          style: {"width":"100%"},
                          "filter-option": false,
                          "not-found-content": _ctx.state.fetchingUpdate ? undefined : null,
                          options: _ctx.state.allManagersUpdate,
                          onSearch: _ctx.fetchManagerUpdate
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetchingUpdate)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "time" }, {
                      label: _withCtx(() => [
                        _createVNode(_component_a_tooltip, null, {
                          title: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('Meet.minResToolTip')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_QuestionCircleOutlined, {
                              class: _normalizeClass([_ctx.locale === 'ar' ? 'tooltip-icon-rtl' : 'tooltip-icon'])
                            }, null, 8, ["class"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Meet.minResTime')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_a_input_number, {
                            value: _ctx.form.time,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.time) = $event)),
                            step: "1",
                            min: "0",
                            style: {"width":"100%"}
                          }, {
                            prefix: _withCtx(() => [
                              _createVNode(_component_ClockCircleOutlined)
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "icons" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('Meet.Icon')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.icon,
                          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.icon) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: icon.name,
                                value: icon.name
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    src: icon.path,
                                    alt: icon.name,
                                    width: "20",
                                    height: "20",
                                    style: {"margin-right":"8px"}
                                  }, null, 8, _hoisted_27)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "width", "placement"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
        }, _toDisplayString(_ctx.t('Meet.EquipmentInformation')), 3)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t('Meet.Name')) + " :", 1),
                  (_ctx.iconPath)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.iconPath,
                        alt: _ctx.form.icon,
                        width: "20",
                        height: "20",
                        class: "icon-name"
                      }, null, 8, _hoisted_29))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.form.name), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('Meet.Description')) + " :", 1),
                  (_ctx.form.description != 'null' )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.form.description), 1))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('Meet.minResTime')) + " :", 1),
                  (_ctx.form.time != null && _ctx.form.time !='' )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.form.time) + " " + _toDisplayString(_ctx.t('Meet.hours')), 1))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        (_ctx.form.managers.length !== 0)
          ? (_openBlock(), _createBlock(_component_a_row, {
              key: 0,
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('Meet.Managers')) + " :", 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.managers, (item) => {
          return (_openBlock(), _createElementBlock("ul", {
            key: item,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            _createElementVNode("li", _hoisted_36, _toDisplayString(item.label), 1)
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["open", "placement", "width"])
  ], 64))
}