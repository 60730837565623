<template>
    <Looder :is-loaded="!loading"></Looder>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            <span class="txt">{{t('Meet.Dashboard')}}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> 
            <span class="txt">
                {{t('Meet.Meetings')}}
            </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> 
            <span class="txt">{{t('Meet.InternalEquipmentRequests')}}</span>
        </a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>
          
        <template #emptyText>
            <a-empty :description="t('Meet.NoData')" :image="simpleImage" />
        </template> 

        
        <template #headerCell="{ column }">
            <div class="table-header">{{ column.title }}</div>
        </template>

        <template #bodyCell="{ column, record }">
            <div class="table-cell">

                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.View') }}</span>
                            </template>
                            <a-button class="view-btn tbl-btn" @click="() => showDisplayDrawer(record.id)">
                                <template #icon>
                                    <EyeOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>
                                <span class="tooltip-txt">{{ t('Meet.Delete') }}</span>
                            </template>
                            <a-button   class="tbl-btn" type="primary" danger @click="showDeletePopup(record.id)" :disabled="record.approved === 1">
                                <template #icon>
                                    <DeleteOutlined class="tbl-icon" />
                                </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key==='equipment'">
                    <p v-if="record.equipment!==null">{{record.equipment.name}}</p>
                </template>

                
                <template v-if="column.key==='reservation'">
                    <p class="table-cell" v-if="record.reservation!==null">{{record.reservation.motif}} - {{formatDisplatyDate(record.reservation.start)}} {{ t('Meet.At') }}  {{extractTime(record.reservation.start)}}</p>
                </template>

                <template v-if="column.key==='approved'">
                    
                    <a-switch :checked="isApproved(record.approved)" @change="toggleApproval(record.reservation_id, record.id, record)" size="small" />
                </template>

                <span v-if="column.key!=='equipment' && column.key!=='reservation' && column.key!=='approved'">{{ record[column.dataIndex] }}</span>


            </div>



           

        </template>

        <!-- search -->
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

            
            <div style="padding: 8px">
                <template  v-if="column.key === 'name' || column.key ==='user'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 
                </template>

              

              

                
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Meet.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Meet.Reset') }}
                </a-button>
            </div>
            

            
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination  v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange" class="paginationArrows" />

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Meet.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Meet.page')}}</a-select-option>
        </a-select>
    </div>

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >

        
        <template #title>
            <a-row>
                <a-col :span="12">
                    <p  class="drawer-title"  :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Meet.EquipementRequestInformation')}}</p>
                </a-col>


                <a-col :span="12" class="approve-tag-container">
                    <a-tag v-if="form.approved === true && form.declined === false" color="green" >
                        <template #icon>
                            <check-circle-outlined />
                        </template>
                        <span class="info-txt">{{t('Meet.Approved')}}</span>
                    </a-tag>

                    <a-tag v-if="form.approved === false && form.declined === true" color="red" >
                        <template #icon>
                            <CloseCircleOutlined />
                        </template>
                       <span class="info-txt"> {{t('Meet.Disapproved')}}</span>
                    </a-tag>

                    <a-tag v-if="form.approved === false && form.declined === false" color="orange" >
                        <template #icon>
                            <LoadingOutlined />
                        </template>
                        <span class="info-txt">{{t('Meet.Pending')}}</span>
                    </a-tag>
                </a-col>
            </a-row>
        </template>

        <a-row :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Motif') }} :</span> 
                    <span class="info-txt" v-if=" form.reservation_motif !== ''">{{form.reservation_motif}} </span>
                    <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                </p>
            </a-col>
           
        </a-row> 

        
        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Equipment') }} :</span> 
                    <span class="info-txt" v-if="form.equipement_name !== '' "> {{form.equipement_name}} </span> 
                    <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>

                </p>
            </a-col> 

            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Quantity') }} :</span> 
                    <span class="info-txt" v-if="form.quantity!==''">{{form.quantity}}</span>
                    <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                </p>
            </a-col> 

        </a-row>

       

        <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t('Meet.Date') }} :</span>
                    <span class="info-txt" v-if="form.reservation_start_date!==''">{{formatDisplatyDate(form.reservation_start_date)}}</span>
                    <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                </p>
            </a-col>
            <a-col :span="12">
                <p>
                    <span class="info-title">
                        {{ t('Meet.from') }} 
                    </span> 
                    <span v-if="form.reservation_start_date !==''" class="info-txt" :class="[locale === 'ar' ? 'start-date-rtl' : 'start-date']" >{{extractTime(form.reservation_start_date)}}</span>
                    <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                    <span class="info-title">{{ t('Meet.to') }} </span>
                    <span class="info-txt" v-if="form.reservation_end_date !==''">{{extractTime(form.reservation_end_date)}}</span>
                    <a-tag v-else>{{ t('Meet.Emptyfield') }}</a-tag>
                </p>
            </a-col>
                
        </a-row>

      

       

       


    </a-drawer>



</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, defineComponent, reactive , onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import { notification, UploadFile, UploadProps } from 'ant-design-vue';
import Swal from 'sweetalert2';
import {
  
  SearchOutlined,
  EyeOutlined,
  DeleteOutlined,
  UserOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined
  
} from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import Looder from '../../../components/Looder.vue';
import { internalRequestService, reservationsService, roomLocationService, roomsService } from '@/_services';
import dayjs , { Dayjs }from 'dayjs'; 

export default defineComponent({

    name: "internalEquipmentRequestsIndex",
    components: {
        Looder,
        SearchOutlined,
        EyeOutlined,
        DeleteOutlined,
        // UserOutlined,
        CloseCircleOutlined,
        CheckCircleOutlined,
        LoadingOutlined,

        
    },
    setup() {
        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
        });

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const columns =[
            {
                title:t('Meet.Equipment'),
                key:'equipment',
                dataIndex:'equipment',
                //customFilterDropdown: true,
                

            },
            {
                title:t('Meet.Quantity'),
                key:'quantity',
                dataIndex:'quantity'

            },
             {
                title:t('Meet.Meeting'),
                key:'reservation',
                dataIndex:'reservation'

            },
            {
                title:t('Meet.Approved'),
                key:'approved',
                dataIndex:'approved'

            },
            {
                title:  t('Meet.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        const form = reactive({
            id:'',
            quantity:'',
            approved:false,
            declined:false,
            equipement_name:'',
            reservation_start_date:'',
            reservation_end_date:'',
            reservation_motif:'',
        });

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 5,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                    color: 'white'
                }
            });
        };

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchText!=''){
                    loading.value = true;
                    roomsService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                internalRequestService.getEquipmentRequestPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }

        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchText!==''){

                    loading.value = true;
                    roomsService.filter("name",state.searchText,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
               


            }else{
                loading.value = true;
                internalRequestService.getEquipmentRequestPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        // display drawer 
        const openDisplay = ref<boolean>(false);

        const showDisplayDrawer = (id: string) => {
            updateModalWidth();
            getById(id);
            openDisplay.value = true;
        };

        //convert date format from "YYYY-MM-DD" to "DD-MM-YYYY"
        function formatDisplatyDate(dateString:string) {
            const [datePart, timePart] = dateString.split(' '); 
            const [year, month, day] = datePart.split('-');
            return `${day}-${month}-${year}`;
        }


        //extract time from datetime string "YYYY-MM-DD HH:mm:ss"
        function extractTime(dateTimeString:string) {
            return dateTimeString.split(' ')[1];
        }

        const getById=(id:string)=>{
            loading.value=true;
            internalRequestService.showEquipmentRequest(id).then((res)=>{
                form.id=res.data.id;
                form.equipement_name=res.data.equipment?res.data.equipment.name :'';
                form.quantity= res.data.quantity ? res.data.quantity :'';
                form.approved= res.data.approved === 1;
                form.declined=res.data.declined === 1;
                form.reservation_motif= res.data.reservation ? res.data.reservation.motif :'';
                form.reservation_end_date= res.data.reservation ? res.data.reservation.end :'';
                form.reservation_start_date= res.data.reservation ? res.data.reservation.start :'';
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                loading.value=false;
            })
        }

        const getEquipmentRequests=()=>{
            loading.value=true;
            internalRequestService.getEquipmentRequestPagination(currentPage.value, perPage.value).then((res)=>{
                state.data=res.data;
                state.totalPages = res.meta.total;
            }).catch((error)=>{
                console.log("error get equipemnt requests", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        function isApproved(value:any) {
            return value === 1;
        }

        function toggleApproval(reservation_id:any, id:any, record:any) {
        
            //record.approved = isApproved(record.approved) ? 0 : 1;
            console.log('record',record);
            if (record.approved === 1) {
                console.log('Already approved');
                showErrorNotification(t('Meet.approvalErrorEq'));
                return;
            }
            loading.value=true;
            internalRequestService.approveEquipment(reservation_id, id).then(()=>{
                showSuccessNotification(t('Meet.EquipmentRequestApproved'));
                getEquipmentRequests();
            }).catch((error)=>{
                loading.value=false;
                console.log(error);
            });
        }

        const showDeletePopup = (id: string) => {
            Swal.fire({
                title: t('Meet.TitleDeleteEquipmentRequest'),
                text: t('Meet.TextDeleteEquipmentRequest'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#8e1c77",
                confirmButtonText: t('Meet.Delete'),
                cancelButtonText: t('Meet.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteRequest(id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const deleteRequest=(id:string)=>{
            loading.value=true;
            internalRequestService.deleteEquipmentRequest(id).then(()=>{
                getEquipmentRequests();
                showSuccessNotification(t('Meet.EquipmentRequestDeleted'));

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                loading.value=false;
                console.log('finish');
            })
        }


        
        onMounted(() => {
            getEquipmentRequests();
        });

        return{
            modalWidth,
            locale,
            textDirection,
            simpleImage,
            loading,
            t,
            searchInput,
            state,
            navigatePage,
            columns,
            perPage,
            currentPage,
            selectPagination,
            handlePageChange,
            handleSelectPaginationChange,
            openDisplay,
            showDisplayDrawer,
            isApproved,
            toggleApproval,
            showDeletePopup,
            form,
            formatDisplatyDate,
            extractTime,
        }
        
    },
})
</script>

<style scoped>


.breadcrumb-icon{
  cursor: pointer;
}


.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}


.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.view-btn{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}

.view-btn:hover{
    color: var(--secondary-color, #fc8019);
    border-color: var(--secondary-color, #fc8019);
    background: #fafafa;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 10px;
}

.title-list{
    font-weight: 400 !important;
    color: black !important;
    margin-left:10px ;
}

.search-btn-radio{
    display: flex;
}

.search-radio-grp{
    display: flex;
    flex-direction: column !important;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.paginationArrows{
    direction: ltr !important;
}

.approve-tag-container{
   display: flex;
    justify-content: flex-end;
    align-items: center;
}

.start-date{
    margin-right: 5px;
}

.start-date-rtl{
    margin-left: 5px;
}

.table-container {
  overflow-x: auto;
}



 /* CSS for RTL */
   .is-rtl {
     direction:rtl;
   }

   .tooltip-txt{
    font-size: 16px;
}

.table-header, .table-cell {
    font-size: 14px;
}

@media (min-width: 768px) {
   .add-btn{
        font-size: 16px;
   }

   .btn-icon{
        font-size:18px ;
    }

    .txt,
    .drawer-title{
        font-size: 16px;
    }

    .table-txt,  .label-form, .info-txt{
        font-size: 14px;
    }

    
   .tbl-icon{
    font-size: 16px !important;
   }

   .tooltip-txt,
   .info-title{
        font-size: 16px;
    }

    .table-header, .table-cell {
        font-size: 16px;
    }

   
}

@media (min-width: 1024px) {

    .add-btn,
    .drawer-title,
    .info-title{
        font-size: 18px;
    }

    .btn-icon{
        font-size:20px ;
    }
    .txt{
        font-size: 18px;
    }

    .table-txt,
    .table-cell,
    .label-form,
    .info-txt
    {
        font-size: 16px;
    }

    .tbl-icon{
        font-size: 18px !important;
   }

   .tbl-btn{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip-txt{
        font-size: 18px;
    }

    .table-header {
        font-size: 18px;
    }


}


</style>
