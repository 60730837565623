import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const rolesService = {
    getAll,
   
};

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.roles.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
