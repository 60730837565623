import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const internalRequestService = {
   
    getEquipmentRequest,
    getServiceRequest,
    createEquipmentRequest,
    createServiceRequest,
    getEquipmentRequestPagination,
    getServiceRequestPagination,
    approveService,
    disapproveService,
    approveEquipment,
    disapproveEquipment,
    deleteEquipmentRequest,
    deleteServiceRequest,
    showEquipmentRequest,
    showServiceRequest,
    getReservationEquipmentRequestPagination,
    getReservationServiceRequestPagination,


};

function getEquipmentRequest() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.equipmentRequest.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}

function getEquipmentRequestPagination(currentPage: number, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.equipmentRequest.getAll}?page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function getReservationEquipmentRequestPagination(reservationId:any, currentPage: number, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.equipmentRequest.getAll}?reservation_id=${reservationId}&page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}


function createEquipmentRequest(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.equipmentRequest.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function approveEquipment(reservation_id: string, equipment_id:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.equipmentRequest.approve.replace(':idRes', reservation_id).replace(':idEq', equipment_id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function disapproveEquipment(reservation_id: string, equipment_id:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.equipmentRequest.disapprove.replace(':idRes', reservation_id).replace(':idSer', equipment_id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function deleteEquipmentRequest(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: {...authHeader() , "Accept": "application/json" } 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.equipmentRequest.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function showEquipmentRequest(id:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.equipmentRequest.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}


function getServiceRequest() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.serviceRequests.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}

function showServiceRequest(id:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.serviceRequests.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getServiceRequestPagination(currentPage: number, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.serviceRequests.getAll}?page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function getReservationServiceRequestPagination(reservationId:any, currentPage: number, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.serviceRequests.getAll}?reservation_id=${reservationId}&page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}


function createServiceRequest(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.serviceRequests.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function approveService(reservation_id: string, service_id:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.serviceRequests.approve.replace(':idRes', reservation_id).replace(':idSer', service_id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function disapproveService(reservation_id: string, service_id:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json",  "Accept": "application/json"  },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.serviceRequests.disapprove.replace(':idRes', reservation_id).replace(':idSer', service_id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function deleteServiceRequest(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: {...authHeader() , "Accept": "application/json" } 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.serviceRequests.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}




function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api

                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
