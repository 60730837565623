import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9025a7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = { class: "txt" }
const _hoisted_3 = { class: "txt" }
const _hoisted_4 = { class: "table-header" }
const _hoisted_5 = { class: "table-cell" }
const _hoisted_6 = { class: "tooltip-txt" }
const _hoisted_7 = { class: "tooltip-txt" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "table-cell"
}
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { style: {"padding":"8px"} }
const _hoisted_12 = { class: "pagination" }
const _hoisted_13 = { class: "info-txt" }
const _hoisted_14 = { class: "info-txt" }
const _hoisted_15 = { class: "info-txt" }
const _hoisted_16 = { class: "info-title" }
const _hoisted_17 = {
  key: 0,
  class: "info-txt"
}
const _hoisted_18 = { class: "info-title" }
const _hoisted_19 = {
  key: 0,
  class: "info-txt"
}
const _hoisted_20 = { class: "info-title" }
const _hoisted_21 = {
  key: 0,
  class: "info-txt"
}
const _hoisted_22 = { class: "info-title" }
const _hoisted_23 = {
  key: 0,
  class: "info-txt"
}
const _hoisted_24 = { class: "info-title" }
const _hoisted_25 = { class: "info-title" }
const _hoisted_26 = {
  key: 2,
  class: "info-txt"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Looder = _resolveComponent("Looder")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_check_circle_outlined = _resolveComponent("check-circle-outlined")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Looder, {
      "is-loaded": !_ctx.loading
    }, null, 8, ["is-loaded"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('Meet.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Meet.Meetings')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('Meet.InternalEquipmentRequests')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_table, {
      class: "table-container",
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Meet.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      headerCell: _withCtx(({ column }) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(column.title), 1)
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        _createElementVNode("div", _hoisted_5, [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Meet.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn tbl-btn",
                        onClick: () => _ctx.showDisplayDrawer(record.id)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Meet.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "tbl-btn",
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record.id)),
                        disabled: record.approved === 1
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined, { class: "tbl-icon" })
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key==='equipment')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.equipment!==null)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(record.equipment.name), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.key==='reservation')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (record.reservation!==null)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(record.reservation.motif) + " - " + _toDisplayString(_ctx.formatDisplatyDate(record.reservation.start)) + " " + _toDisplayString(_ctx.t('Meet.At')) + " " + _toDisplayString(_ctx.extractTime(record.reservation.start)), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.key==='approved')
            ? (_openBlock(), _createBlock(_component_a_switch, {
                key: 3,
                checked: _ctx.isApproved(record.approved),
                onChange: ($event: any) => (_ctx.toggleApproval(record.reservation_id, record.id, record)),
                size: "small"
              }, null, 8, ["checked", "onChange"]))
            : _createCommentVNode("", true),
          (column.key!=='equipment' && column.key!=='reservation' && column.key!=='approved')
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(record[column.dataIndex]), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
        _createElementVNode("div", _hoisted_11, [
          (column.key === 'name' || column.key ==='user')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Search')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Meet.Reset')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_a_pagination, {
        current: _ctx.currentPage,
        "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
        pageSize: _ctx.perPage,
        "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
        total: _ctx.state.totalPages,
        "default-page-size": _ctx.perPage,
        showSizeChanger: false,
        onChange: _ctx.handlePageChange,
        class: "paginationArrows"
      }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
      (_ctx.state.totalPages>10)
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.selectPagination,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
            onChange: _ctx.handleSelectPaginationChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: "10" }, {
                default: _withCtx(() => [
                  _createTextVNode("10 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "20" }, {
                default: _withCtx(() => [
                  _createTextVNode("20 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "50" }, {
                default: _withCtx(() => [
                  _createTextVNode("50 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "100" }, {
                default: _withCtx(() => [
                  _createTextVNode("100 / " + _toDisplayString(_ctx.t('Meet.page')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth
    }, {
      title: _withCtx(() => [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: _normalizeClass(["drawer-title", { 'is-rtl': _ctx.textDirection==='rtl'}])
                }, _toDisplayString(_ctx.t('Meet.EquipementRequestInformation')), 3)
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 12,
              class: "approve-tag-container"
            }, {
              default: _withCtx(() => [
                (_ctx.form.approved === true && _ctx.form.declined === false)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      color: "green"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_check_circle_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Meet.Approved')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.form.approved === false && _ctx.form.declined === true)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 1,
                      color: "red"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_CloseCircleOutlined)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Meet.Disapproved')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.form.approved === false && _ctx.form.declined === false)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 2,
                      color: "orange"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_LoadingOutlined)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Meet.Pending')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Meet.Motif')) + " :", 1),
                  ( _ctx.form.reservation_motif !== '')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.form.reservation_motif), 1))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Meet.Equipment')) + " :", 1),
                  (_ctx.form.equipement_name !== '' )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.form.equipement_name), 1))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Meet.Quantity')) + " :", 1),
                  (_ctx.form.quantity!=='')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.form.quantity), 1))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Meet.Date')) + " :", 1),
                  (_ctx.form.reservation_start_date!=='')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.formatDisplatyDate(_ctx.form.reservation_start_date)), 1))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Meet.from')), 1),
                  (_ctx.form.reservation_start_date !=='')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(["info-txt", [_ctx.locale === 'ar' ? 'start-date-rtl' : 'start-date']])
                      }, _toDisplayString(_ctx.extractTime(_ctx.form.reservation_start_date)), 3))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      })),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('Meet.to')), 1),
                  (_ctx.form.reservation_end_date !=='')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.extractTime(_ctx.form.reservation_end_date)), 1))
                    : (_openBlock(), _createBlock(_component_a_tag, { key: 3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Meet.Emptyfield')), 1)
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width"])
  ], 64))
}